import { put, call, select } from 'redux-saga/effects';
import { hasOrders as hasOrdersRepo } from '../../repositories/UserRepository';
import { ApiConstants } from '../../constants/actions';
import * as overlaysActions from '../../actions/overlays';
import {
  getIsAuthenticated,
  getHasOrders as getHasOrdersSelector
} from '../../selectors/login';

export function* getHasOrders() {
  try {
    let hasOrders = false;
    const isAuthenticated = yield select(getIsAuthenticated);
    const stateHasOrders = yield select(getHasOrdersSelector);
    if (isAuthenticated && !stateHasOrders) {
      hasOrders = yield call(hasOrdersRepo);

      yield put({
        type: `${ApiConstants.GET_HAS_ORDERS}_LOADED`,
        payload: { hasOrders }
      });
    }

    yield put({
      type: `${ApiConstants.GET_HAS_ORDERS}_SUCCESS`
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.GET_HAS_ORDERS}_FAILURE`,
      payload: ex
    });
    yield put(
      overlaysActions.showNotification(
        { message: 'messages.has_orders_failed' },
        'error'
      )
    );
  }
}
