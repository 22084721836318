/* \
|*|
|*|  :: cookies.js ::
|*|
|*|  A complete cookies reader/writer framework with full unicode support.
|*|
|*|  Revision #3 - July 13th, 2017
|*|
|*|  https://developer.mozilla.org/en-US/docs/Web/API/document.cookie
|*|  https://developer.mozilla.org/User:fusionchess
|*|  https://github.com/madmurphy/cookies.js
|*|
|*|  This framework is released under the GNU Public License, version 3 or later.
|*|  http://www.gnu.org/licenses/gpl-3.0-standalone.html
|*|
|*|  Syntaxes:
|*|
|*|  * docCookies.setItem(name, value[, end[, path[, domain[, secure]]]])
|*|  * docCookies.getItem(name)
|*|  * docCookies.removeItem(name[, path[, domain]])
|*|  * docCookies.hasItem(name)
|*|  * docCookies.keys()
|*|
\ */

export const getItem = sKey => {
  if (!sKey) {
    return null;
  }
  return (
    decodeURIComponent(
      document.cookie.replace(
        new RegExp(
          `(?:(?:^|.*;)\\s*${encodeURIComponent(sKey).replace(
            /[-.+*]/g,
            '\\$&'
          )}\\s*\\=\\s*([^;]*).*$)|^.*$`
        ),
        '$1'
      )
    ) || null
  );
};
export const setItem = (sKey, sValue, vEnd, sPath, sDomain, bSecure) => {
  if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
    return false;
  }
  let sExpires = '';
  if (vEnd) {
    switch (vEnd.constructor) {
      case String:
        sExpires = `; expires=${vEnd}`;
        break;
      case Date:
        sExpires = `; expires=${vEnd.toUTCString()}`;
        break;
      case Number:
      default:
        sExpires =
          vEnd === Infinity
            ? '; expires=Fri, 31 Dec 9999 23:59:59 GMT'
            : `; max-age=${vEnd}`;
        break;
    }
  }
  document.cookie = `${encodeURIComponent(sKey)}=${encodeURIComponent(
    sValue
  )}${sExpires}${sDomain ? `; domain=${sDomain}` : ''}${
    sPath ? `; path=${sPath}` : ''
  }${bSecure ? '; secure' : ''}`;
  return true;
};

export const hasItem = sKey => {
  if (!sKey || /^(?:expires|max-age|path|domain|secure)$/i.test(sKey)) {
    return false;
  }
  return new RegExp(
    `(?:^|;\\s*)${encodeURIComponent(sKey).replace(/[-.+*]/g, '\\$&')}\\s*\\=`
  ).test(document.cookie);
};

export const removeItem = (sKey, sPath, sDomain) => {
  if (!hasItem(sKey)) {
    return false;
  }
  const key = encodeURIComponent(sKey);
  const domain = sDomain ? `; domain=${sDomain}` : '';
  const path = sPath ? `; path=${sPath}` : '';
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT${domain}${path}`;
  return true;
};

export default {
  removeItem,
  setItem,
  getItem
};
