import React, { PureComponent } from 'react';
import { PagePanel } from 'edenred-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { isLoading as isLoadingSelector } from '../../selectors/saga';
import { FixedPanel } from '../FixedPanel/FixedPanel';
import './LoadingPanel.scss';

class LoadingPanel extends PureComponent {
  render() {
    const { children, isLoading, fixedWidth, ...rest } = this.props;
    const isFunction = typeof children === 'function';

    return (
      <div className="LoadingPanel">
        {fixedWidth ? (
          <FixedPanel className={isLoading ? 'isLoading' : ''}>
            {isFunction ? children({ isLoading }) : children}
          </FixedPanel>
        ) : (
          <PagePanel className={isLoading ? 'isLoading' : ''} {...rest}>
            {isFunction ? children({ isLoading }) : children}
          </PagePanel>
        )}
      </div>
    );
  }
}

LoadingPanel.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.arrayOf(PropTypes.string)
  ]).isRequired,
  fixedWidth: PropTypes.bool
};

LoadingPanel.defaultProps = {
  fixedWidth: false
};

const mapStateToProps = (state, props) => ({
  isLoading: Array.isArray(props.isLoading)
    ? isLoadingSelector(props.isLoading)(state)
    : props.isLoading
});

const ConnectedLoadingPanel = connect(mapStateToProps)(LoadingPanel);

export { ConnectedLoadingPanel as LoadingPanel };
