import styled from 'styled-components';

export const slideoutWidth = '60rem';
export const fieldWidth = '42rem';
export const labelWidth = '30rem';

export const SlideoutStyles = styled.div`
  form {
    padding-bottom: ${props => (props.validationWarning ? '10rem' : '5rem')};
  }

  input[type='text'] {
    &:focus {
      box-shadow: none;
      -webkit-box-shadow: none;
      outline: none;
    }
    font-size: 14px;
  }

  .fix_validation_warning {
    z-index: 1;
    position: fixed;
    right: 0px;
    bottom: 110px;
    width: ${slideoutWidth};
    padding: 0 4rem;
  }

  .resendlink {
    display: flex;

    i {
      position: relative;
      bottom: -3px;
    }
    button {
      padding-left: 0px;
      margin-right: 0 1rem 0 0;
      border: none;
      background-color: transparent;
      font-size: ${props => props.theme.sizeExtraSmall};
      font-weight: 500;
      outline: none;
    }
    &--active {
      button {
        color: ${props => props.theme.primaryBackColorLight};
        cursor: pointer;
      }
    }
    &--disabled {
      button {
        color: ${props => props.theme.gray50};
        cursor: default;
      }
    }
  }

  .secondaryInfo {
    font-size: ${props => props.theme.sizeExtraSmall};
    line-height: 16px;
  }

  .labelWidthMargin {
    margin: ${props => props.theme.sizeExtraSmall} 0 0 181px;
  }

  .line {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
  }

  .actions-section {
    width: ${slideoutWidth};
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
    button {
      margin-right: 40px;
    }
  }
`;

export const EditButtonRelativeContainer = styled.div`
  position: relative;
`;

export const EditButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;
