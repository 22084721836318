import React from 'react';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PagePanel } from 'edenred-ui';

import { VoucherQuantity } from '../../forms/VoucherQuantity/VoucherQuantity';
import { BenefitSummaryContent } from '../BenefitSummaryContent/BenefitSummaryContent';

export function Quantity({
  voucherTypeKey,
  vouchers,
  orderSummary,
  useCalculator,
  isLoading,
  locale,
  header,
  maxOrderTotalAmount,
  voucherMinimumQuantity
}) {
  return (
    <div className="page1">
      <PagePanel title={header}>
        <Col md={12} className="order-page-voucher-quantity">
          <VoucherQuantity
            voucherTypeKey={voucherTypeKey}
            vouchers={vouchers}
            useCalculator={useCalculator}
            locale={locale}
            maxOrderTotalAmount={maxOrderTotalAmount}
            voucherMinimumQuantity={voucherMinimumQuantity}
          />
        </Col>
        <Col md={12}>
          <hr />
        </Col>
        <Col md={12} className="mb-10 order-page-benefit-summary">
          <BenefitSummaryContent
            voucherTypeKey={voucherTypeKey}
            orderSummary={orderSummary}
            isLoading={isLoading}
          />
        </Col>
      </PagePanel>
    </div>
  );
}

Quantity.propTypes = {
  voucherTypeKey: PropTypes.string.isRequired,
  vouchers: PropTypes.array.isRequired,
  orderSummary: PropTypes.object.isRequired,
  useCalculator: PropTypes.bool,
  isLoading: PropTypes.bool,
  locale: PropTypes.string.isRequired,
  header: PropTypes.node,
  maxOrderTotalAmount: PropTypes.number.isRequired,
  voucherMinimumQuantity: PropTypes.number.isRequired
};

Quantity.defaultProps = {
  useCalculator: false,
  isLoading: false,
  header: null
};
