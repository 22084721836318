import { phoneNumber as formatPhoneNumber } from '@epi-helpers/formHelpers/normalizers';

export const getBeneficiaryPayload = values => {
  const {
    personId,
    personalId,
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    addressLine,
    postCode,
    city,
    endOfBenefit
  } = values;
  return {
    personId,
    personalId,
    firstName,
    lastName,
    emailAddress,
    phoneNumber: formatPhoneNumber(phoneNumber),
    endOfBenefit,
    address: {
      addressLine,
      postCode,
      city
    }
  };
};
