import { put, call } from 'redux-saga/effects';
import { logout } from '../../repositories/LogoutRepository';
import {
  ApiConstants,
  ApplicationConstants,
  NavigationConstants
} from '../../constants/actions';
import * as apiActions from '../../actions/api';
import * as overlaysActions from '../../actions/overlays';

import { addressPickerAction } from '@epi-store/addressPicker/addressPicker.slice';

export default function* ApiLogout({ sessionExpired, gotoUrl = '' }) {
  try {
    yield call(logout);
    yield put({
      type: `${ApplicationConstants.CLEAR_FORMS}`
    });
    yield put({
      type: `${ApiConstants.LOGOUT_ACTION}_SUCCESS`
    });
    if (gotoUrl) {
      yield put({
        type: `${NavigationConstants.GO_TO_PAGE}`,
        url: gotoUrl
      });
    }
    const message = sessionExpired
      ? { message: 'messages.session_expired' }
      : { message: 'messages.successful_logout' };
    const messageLevel = sessionExpired ? 'error' : 'success';
    yield put(overlaysActions.showNotification(message, messageLevel));
    yield put(apiActions.dismissApiErrors());
    yield put(addressPickerAction.restoreInitialValues());
  } catch (error) {
    yield put({
      type: `${ApiConstants.LOGOUT_ACTION}_FAILURE`,
      payload: error,
      isError: true
    });
    yield put(
      overlaysActions.showNotification(
        { message: 'messages.logout_failed' },
        'error'
      )
    );
  } finally {
    yield put({
      type: `${ApplicationConstants.REMEMBER_ME}_CLEAR`
    });
  }
}
