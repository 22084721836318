import { put, all, takeLatest } from 'redux-saga/effects';
import {
  ApiConstants,
  TransactionsHistoryConstants
} from '../../constants/actions';

export function* setTransactionsHistoryQueryParameters({ queryParameters }) {
  yield put({
    type: `${TransactionsHistoryConstants.UPDATE_TRANSACTIONS_HISTORY_QUERY_PARAMETERS}`,
    newQueryParameters: queryParameters
  });

  yield put({
    type: `${ApiConstants.LOAD_TRANSACTIONS_HISTORY}_REQUEST`,
    queryParameters
  });
}

export function* resetTransactionsHistoryQueryParameters() {
  yield put({
    type: `${TransactionsHistoryConstants.RESET_TRANSACTIONS_HISTORY_QUERY_PARAMETERS}`
  });
}

export default function* watchTransactionsHistorySaga() {
  yield all([
    takeLatest(
      `${TransactionsHistoryConstants.SET_TRANSACTIONS_HISTORY_QUERY_PARAMETERS}`,
      setTransactionsHistoryQueryParameters
    ),
    takeLatest(
      `${ApiConstants.LOGIN_ACTION}_SUCCESS`,
      resetTransactionsHistoryQueryParameters
    )
  ]);
}
