import { isProduction } from '@epi-selectors/environment';
import createReducer from '../helpers/CreateReducer';
import { OverlaysConstants } from '../constants/actions';

export const getInitialState = () => ({
  notifications: [],
  errorPopup: {
    display: false,
    closable: true
  },
  passwordPopup: {
    message: '',
    show: false,
    isLoading: false
  },
  loginPopup: {
    show: false,
    isLoginPopup: true
  }
});

export default createReducer(getInitialState, {
  [`${OverlaysConstants.SHOW_NOTIFICATION}`]: (state, payload) => {
    // eslint-disable-next-line no-unused-vars
    const { type, ...options } = payload;
    return { notifications: [...state.notifications, { ...options }] };
  },
  [`${OverlaysConstants.HIDE_NOTIFICATION}`]: (state, payload) => {
    const notifications = state.notifications.filter(
      notification => notification.uid !== payload.uid
    );
    return { notifications };
  },

  [`${OverlaysConstants.REMOVE_ALL_NOTIFICATIONS}`]: () => ({
    notifications: []
  }),

  [`${OverlaysConstants.SHOW_ERROR_NOTIFICATION}`]: (state, payload) => ({
    notifications: [
      ...state.notifications,
      { level: 'error', uid: Date.now(), ...payload }
    ]
  }),

  [`${OverlaysConstants.SHOW_ERROR_POPUP}`]: (state, payload) => ({
    errorPopup: {
      display: true,
      closable: !(
        !state.errorPopup.closable ||
        (!payload.closable && payload.closable !== undefined)
      ),
      error: payload.error,
      showDetails: payload.error && !isProduction()
    }
  }),

  [`${OverlaysConstants.SET_PASSWORD_POPUP_VISIBILITY}`]: (
    state,
    { show, message }
  ) => ({
    passwordPopup: { ...state.passwordPopup, show, message }
  }),

  [`${OverlaysConstants.SET_PASSWORD_POPUP_LOADING_STATE}`]: (
    state,
    { isLoading }
  ) => ({
    passwordPopup: { ...state.passwordPopup, isLoading }
  }),

  [`${OverlaysConstants.SET_ERROR_MESSAGE_ON_PASSWORD_POPUP}`]: (
    state,
    { message }
  ) => ({
    errorMessage: message
  }),

  [`${OverlaysConstants.SET_ERROR_MESSAGE_OFF_PASSWORD_POPUP}`]: () => ({
    errorMessage: ''
  }),

  [`${OverlaysConstants.SET_LOGIN_POPUP_VISIBILITY}`]: (state, { show }) => ({
    loginPopup: {
      show,
      isLoginPopup: show ? true : state.loginPopup.isLoginPopup
    }
  }),

  [`${OverlaysConstants.CHANGE_LOGIN_POPUP_CONTENT}`]: (
    state,
    { isLoginPopup }
  ) => ({
    loginPopup: { show: true, isLoginPopup }
  })
});
