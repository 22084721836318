import { FormArrays, FormFields } from '@epi-forms/helpers';
import { ContractStep } from './contractStepperHelper';

export function mapFormToContract(form) {
  const companyInfo = form[ContractStep.CompanyInformation];
  const invoicingInfo = form[ContractStep.InvoicingInformation];
  const contactPersons =
    form[ContractStep.ContactPerson][FormArrays.ContactPersons];
  const contractSignatory = form[ContractStep.ContractSignatory];

  return {
    companyInformation: {
      companyName: companyInfo[FormFields.CompanyName],
      businessIdentityCode: companyInfo[FormFields.BusinessId],
      numberOfEmployees: companyInfo[FormFields.NumberOfEmployees],
      address: companyInfo[FormFields.Address],
      postCode: companyInfo[FormFields.ZipCode],
      city: companyInfo[FormFields.City],
      country: companyInfo[FormFields.Country]
    },
    invoicingInformation: {
      address: invoicingInfo[FormFields.Address],
      postCode: invoicingInfo[FormFields.ZipCode],
      city: invoicingInfo[FormFields.City],
      country: invoicingInfo[FormFields.Country],
      invoiceMethod: invoicingInfo[FormFields.InvoicingMethod],
      emailAddress: invoicingInfo[FormFields.EmailAddress],
      eInvoiceAddress: invoicingInfo[FormFields.EInvoiceAddress],
      operator: invoicingInfo[FormFields.Operator]
    },
    contactPersons: contactPersons.map(contactPerson => ({
      firstName: contactPerson[FormFields.FirstName],
      lastName: contactPerson[FormFields.LastName],
      jobTitle: contactPerson[FormFields.JobTitle],
      emailAddress: contactPerson[FormFields.EmailAddress],
      phoneNumber: contactPerson[FormFields.PhoneNumber]
    })),
    contractSignatory: {
      firstName: contractSignatory[FormFields.FirstName],
      lastName: contractSignatory[FormFields.LastName],
      emailAddress: contractSignatory[FormFields.EmailAddress],
      rightToSign: contractSignatory[FormFields.RightToSign],
      termsAndConditions: contractSignatory[FormFields.AcceptTerms]
    }
  };
}
