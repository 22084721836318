import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  deliveryAddress: {}
};

const addressPickerSlice = createSlice({
  name: 'addressPicker',
  initialState,
  reducers: {
    setDeliveryAddress: (state, { payload }) => {
      state.deliveryAddress = { ...payload };
    },
    restoreInitialValues: () => initialState
  }
});

export const { reducer, actions: addressPickerAction } = addressPickerSlice;
