import {
  amount as amountValidator,
  maxValue,
  minValue,
  required
} from '@epi-helpers/formHelpers/validators';
import performanceHelper from '@epi-helpers/PerformanceHelper';

import { PaymentMethods } from './paymentMethods';

export const HIDDEN_METHODS = [
  PaymentMethods.LinkToOtherPerson,
  PaymentMethods.PrintInvoicePdf
];

export const DISABLED_METHODS = [
  PaymentMethods.LinkToOtherPerson,
  PaymentMethods.PrintInvoicePdf
];

export const depositDebouncer = performanceHelper.debouncer(400);

export const amountValidators = [
  required,
  amountValidator,
  minValue(0.01),
  maxValue(50000)
];
