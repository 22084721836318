import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  PagePanel,
  Tabs,
  Box,
  TransferMoneyIcon,
  LoadingSpinner
} from 'edenred-ui';
import { useSelector, useDispatch } from 'react-redux';

import config from '@epi-config';
import { useMenuDimensions } from '@epi-shared/hooks';
import { getCompanyAccounts } from '@epi-selectors/companyAccounts';
import { LoadCompanyAccount } from '@epi-containers/LoadCompanyAccount/LoadCompanyAccount';
import { getCompanyBeneficariesRequest } from '@epi-api/companyBeneficiaries';
import { EmptyState, TransferPageHeader } from '@epi-shared/components';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { loadCompanyAccounts } from '@epi-actions/api';
import { loadCompanyAccounts as loadCompanyAccountsSelector } from '@epi-selectors/loading';
import {
  TRANSFER_PAGE_QUERY_NAME,
  TRANSFER_PAGE_SEARCH_NAME,
  TransferPageType
} from '@epi-constants/transferPage';

import { Topup } from './components';

export function TransferPage() {
  const { t } = useTranslation();
  const { isMobile } = useMenuDimensions();
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const [footer, setFooter] = useState();
  const [isBeneficiariesLoaded, setIsBeneficiariesLoaded] = useState(false);
  const [hasBeneficiaries, setHasBeneficiaries] = useState(false);
  const isLoading = useSelector(loadCompanyAccountsSelector);
  const accounts = useSelector(getCompanyAccounts);

  const urlParams = new URLSearchParams(search);
  const defaultTabName = urlParams.get(TRANSFER_PAGE_SEARCH_NAME)
    ? urlParams.get(TRANSFER_PAGE_SEARCH_NAME)
    : TransferPageType.masterAccount;

  const handleURLChange = tabName =>
    dispatch(goToPage(pathname + TRANSFER_PAGE_QUERY_NAME + tabName));

  const loadBeneficiaries = async () => {
    const response = await getCompanyBeneficariesRequest();
    setIsBeneficiariesLoaded(true);
    setHasBeneficiaries(response.beneficiaries.length > 0);
  };

  const navigateToEmployees = () => {
    dispatch(goToPage(routerPaths.beneficiaries));
  };

  useEffect(() => {
    loadBeneficiaries();
    dispatch(loadCompanyAccounts());
  }, []);

  const renderPageContent = () => {
    if ((isLoading && accounts.length === 0) || !isBeneficiariesLoaded) {
      return <LoadingSpinner />;
    }

    if (!hasBeneficiaries) {
      return (
        <Box minHeight={600}>
          <EmptyState
            icon={<TransferMoneyIcon />}
            title={t('transfer_money.add_employees_title')}
            description={t('transfer_money.add_employees_description')}
            btnText={t('transfer_money.add_employees_btn')}
            btnOnClick={navigateToEmployees}
          />
        </Box>
      );
    }

    return config.enableVirikeBenefit ? (
      <Tabs
        tabs={tabs}
        defaultTabName={defaultTabName}
        onTabChange={handleURLChange}
      />
    ) : (
      <LoadCompanyAccount updateFooter={setFooter} />
    );
  };

  const tabs = [
    {
      name: TransferPageType.masterAccount,
      label: t('containers.deposit_money.transfer_tab.header'),
      id: 'tab-company-info',
      body: <LoadCompanyAccount updateFooter={setFooter} />
    },
    {
      name: TransferPageType.topupLoad,
      label: t('containers.deposit_money.topup_tab.header'),
      id: 'tab-contact-persons',
      body: <Topup updateFooter={setFooter} />
    }
  ];

  return (
    <PagePanel
      title={t('containers.deposit_money.header')}
      footer={footer}
      headerContent={!isMobile && <TransferPageHeader />}
    >
      {renderPageContent()}
    </PagePanel>
  );
}
