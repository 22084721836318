import tokeys from 'tokeys';

export const AzureVariable = tokeys(['string', 'number', 'boolean']);

export const envVariablesSchema = {
  environment: AzureVariable.string,
  apiHost: AzureVariable.string,
  enablePersistanceOnLocalStorage: AzureVariable.boolean,
  disablePersistanceCompression: AzureVariable.boolean,
  disableReCaptcha: AzureVariable.boolean,
  enableVirikeBenefit: AzureVariable.boolean,
  googleAnalyticsKey: AzureVariable.string,
  onetrustKey: AzureVariable.string,
  enableNewContracts: AzureVariable.boolean,
  showNotificationBanner: AzureVariable.boolean,
  notificationBannerStartDate: AzureVariable.number,
  notificationBannerEndDate: AzureVariable.number,
  disableEditOptionsForOA: AzureVariable.boolean,
  disableAddingContactPersonOnCompanyInformation: AzureVariable.boolean
};
