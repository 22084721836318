import React from 'react';

import { Box, ChevronRightIcon, ButtonMode, Button } from 'edenred-ui';

export const ListContent = (text, onClick) => (
  <>
    {text}
    <Box ml={2}>
      <Button
        id={text}
        icon={<ChevronRightIcon />}
        onClick={onClick}
        mode={ButtonMode.Link}
      />
    </Box>
  </>
);
