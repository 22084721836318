import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { nanoid } from 'nanoid';

const Breadcrumb = ({ backIcon = false, linkSegments }) => {
  const breadcrumbSegments = linkSegments.map(s => (
    <Link key={nanoid()} to={s.link}>
      {s.label}
    </Link>
  ));

  return (
    <p style={{ fontWeight: '500' }}>
      {backIcon && '< '}
      {breadcrumbSegments}
    </p>
  );
};

Breadcrumb.propTypes = {
  backIcon: PropTypes.bool,
  linkSegments: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired
    })
  ).isRequired
};

Breadcrumb.defaultProps = {
  backIcon: false
};

export default Breadcrumb;
