import tokeys from 'tokeys';

export const ReportTypeVirike = tokeys([
  'transactions',
  'financialDocuments',
  'reports',
  'topups'
]);

export const ReportTypeWithoutVirike = tokeys([
  'transactions',
  'financialDocuments',
  'reports'
]);

export const REPORTS_SEARCH_NAME = 'tab';
export const REPORTS_QUERY_NAME = '?tab=';
