import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box } from 'edenred-ui';
import { useFormContext } from 'react-hook-form';

import { FormFields } from '@epi-forms/helpers';

import { Header, Sum, Remainder } from './TopupSummary.styles';
import { useTopupSummary } from './useTopupSummary';

export const TopupSummary = ({
  employeesCount,
  employeesToTopup,
  showTable
}) => {
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const topupPerPerson = watch(FormFields.MoneyAmount);

  const {
    formattedTopup,
    formattedFee,
    formattedVat,
    formattedTopupWithFees,
    formattedRemainingBalance,
    fee,
    vat,
    remainingBalance
  } = useTopupSummary(
    employeesCount,
    topupPerPerson,
    employeesToTopup,
    showTable
  );

  return (
    <Box minWidth="260px">
      <Box display="flex" justifyContent="space-between" marginBottom="10px">
        <Header>
          {t('containers.deposit_money.topup_tab.form.employees.total_amount')}
        </Header>
        <Sum>{formattedTopup}</Sum>
      </Box>
      <Box display="flex" justifyContent="space-between" margin="0 0 10px 10px">
        <Box>
          {t('containers.deposit_money.topup_tab.form.employees.fee', {
            fee
          })}
        </Box>
        <Box>{formattedFee}</Box>
      </Box>
      <Box display="flex" justifyContent="space-between" margin="0 0 10px 10px">
        <Box>
          {t('containers.deposit_money.topup_tab.form.employees.vat', {
            vat
          })}
        </Box>
        <Box>{formattedVat}</Box>
      </Box>
      <Box display="flex" justifyContent="space-between" marginLeft="10px">
        <Sum>
          {t('containers.deposit_money.topup_tab.form.employees.total')}
        </Sum>
        <Sum>{formattedTopupWithFees}</Sum>
      </Box>
      <Remainder hasError={remainingBalance < 0}>
        {t('containers.deposit_money.topup_tab.form.employees.remaining', {
          remaining: formattedRemainingBalance
        })}
      </Remainder>
    </Box>
  );
};

TopupSummary.propTypes = {
  employeesCount: PropTypes.number,
  employeesToTopup: PropTypes.array,
  showTable: PropTypes.bool
};
