import { AsYouType } from 'libphonenumber-js';
import { integer as FormatInteger, decimal as FormatDecimal } from '../numeral';

export const businessId = value => {
  const regexp = /^((FI)?\d{8})$/i;
  if (!value || !regexp.test(value || '')) {
    return value || '';
  }

  const valueLen = value.length;
  return `${value.substring(0, valueLen - 1)}-${value.substring(valueLen - 1)}`;
};

export const integer = value => (value ? `${FormatInteger(value)}` : '');

export const decimal = value => (value ? `${FormatDecimal(value)}` : '');

export const phoneNumber = value => new AsYouType().input(value);

export {
  integer as formatInteger,
  decimal as formatDecimal,
  phoneNumber as formatPhoneNumber,
  businessId as formatBusinessId
};
