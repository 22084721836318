import { put, call, take } from 'redux-saga/effects';
import { UnauthorizedErrorName } from '@epi-constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '@epi-helpers/AuthorizeHelper';
import { parseDecimal } from '../../helpers/numeral';
import { depositCompanyAccount as depositCompanyAccountRepository } from '../../repositories/CompanyAccountsRepository';
import { ApiConstants, NavigationConstants } from '../../constants/actions';
import { isOnlineOrCreditCardPayment } from './getPaymentData';
import { createOnlinePayment } from './createOnlinePayment';
import { routerPaths } from '../../constants/routerPaths';
import { OrderType } from '../../constants/orderType';

export function getOnlinePaymentCallbackUrl() {
  return `${routerPaths.loadCompanyAccountStatus}`;
}

export function getAccountNumber(values) {
  return values.companyAccountNumber;
}

export function getAmountNumber(values) {
  return Math.round(parseDecimal(values.amount) * 100);
}

export function getDepositRequest(values) {
  return {
    payment: {
      method: values.paymentMethod,
      methodCode: values.paymentMethodCode
    },
    accountNumber: getAccountNumber(values),
    amount: getAmountNumber(values)
  };
}

export function* callCreateDepositApi(request) {
  try {
    const deposit = yield call(depositCompanyAccountRepository, request);
    yield put({
      type: `${ApiConstants.DEPOSIT_COMPANY_ACCOUNT}_SUCCESS`,
      payload: deposit
    });
    return deposit;
  } catch (ex) {
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    }
    yield put({
      type: `${ApiConstants.DEPOSIT_COMPANY_ACCOUNT}_FAILURE`,
      payload: ex
    });
    return null;
  }
}

export function* createDepositOrder({ values }) {
  const request = yield call(getDepositRequest, values);
  const deposit = yield call(callCreateDepositApi, request);

  if (!deposit) {
    return;
  }

  const statusRoute = getOnlinePaymentCallbackUrl();

  if (isOnlineOrCreditCardPayment(request.payment)) {
    yield call(
      createOnlinePayment,
      deposit.paymentPublicId,
      OrderType.digital,
      statusRoute
    );
    yield take(`${ApiConstants.CREATE_NEW_PAYMENT}_SUCCESS`);
  } else {
    yield put({
      type: `${NavigationConstants.GO_TO_PAGE}`,
      url: statusRoute,
      params: { paymentId: deposit.paymentPublicId }
    });
  }
}
