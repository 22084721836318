import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Container, Row, Col } from 'react-bootstrap';
import { formatCurrency } from '../../helpers/numeral';
import { TooltipInfo } from '../TooltipInfo/TooltipInfo';
import './OrderSummary.scss';
import { CardType } from '../../constants/cardType';

class OrderSummary extends PureComponent {
  render() {
    const { i18n, benefitsSummary, cardOrderSummary } = this.props;
    const taxableBaseValue =
      cardOrderSummary.duoCardsCosts +
      cardOrderSummary.transportCardsCosts +
      cardOrderSummary.transportCommissionAmount +
      cardOrderSummary.recreationalCommissionAmount +
      cardOrderSummary.lunchCommissionAmount;
    const balanceForBenefits =
      cardOrderSummary.orderTotalWithoutVat - taxableBaseValue;
    const cardType = CardType.plasticless;
    const showCardPrice =
      cardOrderSummary.duoCardsCosts > 0 && cardType !== CardType.plasticless;
    return (
      <div className="OrderSummary">
        <Container>
          <Row>
            <Col xs={12} sm={8}>
              <Row className="headers">
                <Col xs={2} className="padding-right">
                  {i18n.t(
                    'containers.card_order.summary_and_payment.order_summary.product'
                  )}
                </Col>
                <Col xs={10} className="padding-left">
                  <Row>
                    <Col xs={10}>
                      {i18n.t(
                        'containers.card_order.summary_and_payment.order_summary.details'
                      )}
                    </Col>
                    <Col xs={2} className="align-to-right">
                      {i18n.t(
                        'containers.card_order.summary_and_payment.order_summary.sum'
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              {benefitsSummary.lunch.totalAmount > 0 && (
                <Row className="body">
                  <Col xs={2} className="padding-right">
                    {i18n.t(
                      'containers.card_order.summary_and_payment.order_summary.lunch_benefit_balance'
                    )}
                  </Col>
                  <Col xs={10} className="padding-left">
                    <Row>
                      <Col xs={10}>
                        <span>
                          {i18n.t(
                            'containers.card_order.summary_and_payment.order_summary.lunch_estimated_benefit_balance'
                          )}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={10} className="bold">
                        {i18n.t(
                          'containers.card_order.summary_and_payment.order_summary.lunch_benefit_balance_details',
                          {
                            numberOfBeneficiaries:
                              benefitsSummary.lunch.beneficiaryNumber,
                            faceValue: formatCurrency(
                              benefitsSummary.lunch.amount
                            ),
                            workdays: benefitsSummary.lunch.workdays
                          }
                        )}
                      </Col>
                      <Col xs={2} className="align-to-right">
                        {formatCurrency(benefitsSummary.lunch.totalAmount)}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={10}>
                        {i18n.t(
                          'containers.card_order.summary_and_payment.order_summary.load_comission_for_lunch_benefit',
                          { vatPercentage: cardOrderSummary.vatPercentage }
                        )}
                      </Col>
                      <Col xs={2} className="align-to-right">
                        {formatCurrency(cardOrderSummary.lunchCommissionAmount)}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {benefitsSummary.recreational.totalAmount > 0 && (
                <Row className="body">
                  <Col xs={2} className="padding-right">
                    {i18n.t(
                      'containers.card_order.summary_and_payment.order_summary.recreational_benefit_balance'
                    )}
                  </Col>
                  <Col xs={10} className="padding-left">
                    <Row>
                      <Col xs={10}>
                        {i18n.t(
                          'containers.card_order.summary_and_payment.order_summary.recreational_estimated_benefit_balance_for_year'
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={10} className="bold">
                        {i18n.t(
                          'containers.card_order.summary_and_payment.order_summary.recreational_benefit_balance_details',
                          {
                            numberOfBeneficiaries:
                              benefitsSummary.recreational.beneficiaryNumber,
                            value: formatCurrency(
                              benefitsSummary.recreational.amount
                            )
                          }
                        )}
                      </Col>
                      <Col xs={2} className="align-to-right">
                        {formatCurrency(
                          benefitsSummary.recreational.totalAmount
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={10}>
                        {i18n.t(
                          'containers.card_order.summary_and_payment.order_summary.load_comission_for_recreational_benefit',
                          { vatPercentage: cardOrderSummary.vatPercentage }
                        )}
                      </Col>
                      <Col xs={2} className="align-to-right">
                        {formatCurrency(
                          cardOrderSummary.recreationalCommissionAmount
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              {benefitsSummary.transport.totalAmount > 0 && (
                <Row className="body">
                  <Col xs={2} className="padding-right word-break">
                    {i18n.t(
                      'containers.card_order.summary_and_payment.order_summary.commuting_benefit_balance'
                    )}
                  </Col>
                  <Col xs={10} className="padding-left">
                    <Row>
                      <Col xs={10}>
                        {i18n.t(
                          'containers.card_order.summary_and_payment.order_summary.transport_estimated_benefit_balance_for_year'
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={10} className="bold">
                        {i18n.t(
                          'containers.card_order.summary_and_payment.order_summary.commuting_benefit_balance_details',
                          {
                            numberOfBeneficiaries:
                              benefitsSummary.transport.beneficiaryNumber,
                            value: formatCurrency(
                              benefitsSummary.transport.amount
                            )
                          }
                        )}
                      </Col>
                      <Col xs={2} className="align-to-right">
                        {formatCurrency(benefitsSummary.transport.totalAmount)}
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={10}>
                        {i18n.t(
                          'containers.card_order.summary_and_payment.order_summary.load_comission_for_commuting_benefit',
                          { vatPercentage: cardOrderSummary.vatPercentage }
                        )}
                      </Col>
                      <Col xs={2} className="align-to-right">
                        {formatCurrency(
                          cardOrderSummary.transportCommissionAmount
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              )}
              <Row className="body">
                <Col xs={12}>
                  {showCardPrice && (
                    <Row>
                      <Col xs={12}>
                        <Row>
                          <Col xs={2} className="padding-right">
                            {i18n.t(
                              'containers.card_order.summary_and_payment.order_summary.edenred_card'
                            )}
                          </Col>
                          <Col xs={10} className="padding-left">
                            <Row>
                              <Col xs={10} className="bold">
                                {i18n.t(
                                  'containers.card_order.summary_and_payment.order_summary.edenred_card_details',
                                  {
                                    numberOfBeneficiaries:
                                      benefitsSummary.transport
                                        .beneficiaryNumber,
                                    cardCost: formatCurrency(
                                      cardOrderSummary.duoCardFee
                                    ),
                                    vatPercentage:
                                      cardOrderSummary.vatPercentage
                                  }
                                )}
                              </Col>
                              <Col xs={2} className="align-to-right">
                                {formatCurrency(cardOrderSummary.duoCardsCosts)}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12} className="description">
                            {i18n.t(
                              'containers.card_order.summary_and_payment.order_summary.edenred_card_description'
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  {cardOrderSummary.transportCardsCosts > 0 && (
                    <Row>
                      <Col xs={2} className="padding-right">
                        {i18n.t(
                          'containers.card_order.summary_and_payment.order_summary.transport_card'
                        )}
                      </Col>
                      <Col xs={10} className="padding-left">
                        <Row>
                          <Col xs={10} className="bold">
                            {i18n.t(
                              'containers.card_order.summary_and_payment.order_summary.transport_card_details',
                              {
                                numberOfBeneficiaries:
                                  benefitsSummary.transport.beneficiaryNumber,
                                cardCost: formatCurrency(
                                  cardOrderSummary.transportCardFee
                                ),
                                vatPercentage: cardOrderSummary.vatPercentage
                              }
                            )}
                          </Col>
                          <Col xs={2} className="align-to-right">
                            {formatCurrency(
                              cardOrderSummary.transportCardsCosts
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col xs={2}>
                          {i18n.t(
                            'containers.card_order.summary_and_payment.order_summary.handling_fee'
                          )}
                        </Col>
                        <Col xs={10}>
                          <Row>
                            <Col
                              xsOffset={10}
                              xs={2}
                              className="align-to-right"
                            >
                              {formatCurrency(
                                cardOrderSummary.totalDeliveryFee
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className="description">
                          <span
                            dangerouslySetInnerHTML={{
                              __html: i18n.t(
                                'containers.card_order.summary_and_payment.order_summary.handling_fee_description'
                              )
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="body">
                <Col xs={12}>
                  <Row>
                    <Col xs={10} className="padding-right">
                      {i18n.t(
                        'containers.card_order.summary_and_payment.order_summary.balance_for_benefits'
                      )}
                    </Col>
                    <Col xs={2} className="align-to-right">
                      {formatCurrency(balanceForBenefits)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={10} className="padding-right">
                      {i18n.t(
                        'containers.card_order.summary_and_payment.order_summary.taxable_base_value'
                      )}
                    </Col>
                    <Col xs={2} className="align-to-right">
                      {formatCurrency(taxableBaseValue)}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col xs={10} className="padding-right">
                          <span>
                            {i18n.t(
                              'containers.card_order.summary_and_payment.order_summary.vat',
                              { vat: cardOrderSummary.vatPercentage }
                            )}
                          </span>
                          <TooltipInfo
                            info={i18n.t(
                              'containers.card_order.summary_and_payment.order_summary.vat_tooltip',
                              { vat: cardOrderSummary.vatPercentage }
                            )}
                            placement="top"
                          />
                        </Col>
                        <Col xs={2} className="align-to-right">
                          {formatCurrency(cardOrderSummary.feesVat)}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="body bold">
                <Col xs={10} className="padding-right">
                  {i18n.t(
                    'containers.card_order.summary_and_payment.order_summary.order_total'
                  )}
                </Col>
                <Col xs={2} className="align-to-right">
                  {formatCurrency(cardOrderSummary.orderTotalWithVat)}
                </Col>
              </Row>
            </Col>
            <Col xs={4} />
          </Row>
        </Container>
      </div>
    );
  }
}

OrderSummary.propTypes = {
  i18n: PropTypes.object.isRequired,
  benefitsSummary: PropTypes.object.isRequired,
  cardOrderSummary: PropTypes.object.isRequired
};

const OrderSummaryWithI18n = withTranslation()(OrderSummary);
export { OrderSummaryWithI18n as OrderSummary };
