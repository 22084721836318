import { get, pick } from 'lodash';
import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/constants';
import { removeLocale, getLocale } from '../helpers/UrlHelpers';
import {
  getDefaultLanguage,
  findLanguageById
} from '../helpers/LanguageHelpers';
import storage from '../helpers/StorageHelper';
import i18n from '../helpers/I18n';
import {
  ApplicationConstants,
  NavigationConstants
} from '../constants/actions';
import { checkUsernameAvailability } from './api/getUsernameAvailability';
import history from '../routes/history';
import { getLoginState } from '../selectors/login';
import { getApplicationLanguageId } from '../selectors/application';
import { IsExternalUrl } from './navigation';

export function* applicationChangeLanguageSaga() {
  const location = get(history, 'location.pathname') || '';
  const locale = getLocale(location);
  const language = locale ? findLanguageById(locale) : getDefaultLanguage();

  yield put({
    type: `${ApplicationConstants.CHANGE_APP_LANGUAGE}`,
    languageId: language.id
  });
}

export function* changeLanguage(action) {
  const languageId = yield select(getApplicationLanguageId);

  if (action.languageId !== languageId) {
    return;
  }
  const pathname = get(history, 'location.pathname') || '';
  const search = get(history, 'location.search') || '';
  const url = removeLocale(pathname + search);
  yield put({
    type: `${NavigationConstants.GO_TO_PAGE}`,
    url: IsExternalUrl(url) ? `/${search}` : url // prvent redirect attack
  });

  i18n.changeLanguage(languageId);
}

export function* checkUsername(username) {
  try {
    return yield call(checkUsernameAvailability, username);
  } catch (ex) {
    return false;
  }
}

export function clearRememberMe() {
  storage.clear('rememberMe');
}

export function* saveRememberMe() {
  const authenticationData = pick(yield select(getLoginState), [
    'isAuthenticated',
    'accessToken',
    'tokenType',
    'refreshToken',
    'expiresIn',
    'validTo',
    'roles',
    'hasOrders'
  ]);
  storage.set('rememberMe', authenticationData);
}

export function* watchApplicationSaga() {
  yield all([
    takeLatest(REHYDRATE, applicationChangeLanguageSaga),
    takeLatest(ApplicationConstants.CHANGE_APP_LANGUAGE, changeLanguage),
    takeLatest(`${ApplicationConstants.REMEMBER_ME}_SAVE`, saveRememberMe),
    takeLatest(`${ApplicationConstants.REMEMBER_ME}_CLEAR`, clearRememberMe)
  ]);
}
