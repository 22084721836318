import { ApiConstants } from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  userCompany: {},
  isLoading: false,
  isLoaded: false,
  isProcessingUpdate: false,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_USER_COMPANY}_REQUEST`]: () => ({
    isLoading: true,
    isLoaded: false
  }),

  [`${ApiConstants.LOAD_USER_COMPANY}_SUCCESS`]: (
    state,
    { userCompany: { size, ...rest } }
  ) => ({
    userCompany: { ...rest, sizeId: size },
    isLoading: false,
    isLoaded: true
  }),

  [`${ApiConstants.LOAD_USER_COMPANY}_FAILURE`]: () => ({
    isLoading: false,
    isError: true
  }),

  [`${ApiConstants.UPDATE_USER_COMPANY}_REQUEST`]: () => ({
    isProcessingUpdate: true
  }),

  [`${ApiConstants.UPDATE_USER_COMPANY}_SUCCESS`]: (
    state,
    { address, sizeId, invoiceInformation }
  ) => ({
    userCompany: {
      ...state.userCompany,
      address,
      sizeId,
      invoiceInformation
    },
    isProcessingUpdate: false
  }),

  [`${ApiConstants.UPDATE_USER_COMPANY}_FAILURE`]: () => ({
    isProcessingUpdate: false
  }),

  [`${ApiConstants.LOGOUT_ACTION}_SUCCESS`]: () => ({
    isLoading: false,
    isLoaded: false,
    userCompany: {}
  }),

  [`${ApiConstants.LOAD_COMPANY_HAS_SIGNATORY}_SUCCESS`]: (
    state,
    { hasSignatory }
  ) => ({
    userCompany: { ...state.userCompany, hasSignatory }
  })
});
