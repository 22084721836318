import createReducer from '@epi-helpers/CreateReducer';
import { ApiConstants } from '@epi-constants/actions';

const initialState = {
  benefitGroupSettings: [],
  activeBenefits: [],
  isActiveBenefitsLoaded: false,
  isLoaded: false
};

export default createReducer(() => initialState, {
  [`${ApiConstants.BENEFIT_GROUP_SETTINGS}_SUCCESS`]: (_, { payload }) => ({
    benefitGroupSettings: payload,
    isLoaded: true
  }),
  [`${ApiConstants.GET_ACTIVE_BENEFITS}_SUCCESS`]: (state, { payload }) => ({
    ...state,
    activeBenefits: payload.map(benefit => benefit.toLowerCase()),
    isActiveBenefitsLoaded: true
  }),
  [`${ApiConstants.LOGOUT_ACTION}_SUCCESS`]: () => initialState,
  [`${ApiConstants.BENEFIT_GROUP_SETTINGS}_FAILURE`]: () => initialState
});
