import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { join, replaceParams } from '../helpers/UrlHelpers';
import { getDefaultLanguage } from '../helpers/LanguageHelpers';
import { Authorize } from '../constants/authorize';
import { routerPaths } from '../constants/routerPaths';
import { postRequest } from './ApiRepository';

export function* createNewPayment(action) {
  const lg =
    action.payload.languageId !== getDefaultLanguage()
      ? action.payload.languageId
      : '';
  const statusRoute = action.payload.isPaymentOnlyFlow
    ? `${routerPaths.orderPaymentStatus}`
    : action.payload.statusRoute;

  const url = join(`${window.location.origin}`, lg, statusRoute);
  const parameterizedUrl = replaceParams(url, {
    paymentId: action.payload.paymentPublicId
  });

  const request = {
    locale: action.payload.locale || 'fi',
    orderType: action.payload.orderType,
    paymentPublicId: action.payload.paymentPublicId,
    successUrl: parameterizedUrl,
    cancelUrl: parameterizedUrl,
    errorUrl: parameterizedUrl,
    delayedPaymentUrl: parameterizedUrl
  };

  return yield call(
    postRequest,
    `${config.apiHost}/api/payments`,
    Authorize.IfLogged,
    request
  );
}
