import { getVoucherReorder } from './voucherReorder';
import { isLoading, isLoaded } from './saga';
import { ApiConstants } from '../constants/actions';

export const addresses = state => state.apiAddresses.isLoading;

const isLoadingContacts = isLoading([ApiConstants.LOAD_CONTACTS]);
export const contacts = state => isLoadingContacts(state);

const isLoadingCountries = isLoading([ApiConstants.LOAD_COUNTRIES]);
export const countries = state => isLoadingCountries(state);

const isLoadingCompanyInfo = isLoading([
  ApiConstants.LOAD_COUNTRIES,
  ApiConstants.LOAD_USER_COMPANY
]);
export const companyInfo = state => isLoadingCompanyInfo(state);

export const reorderData = (state, orderId) => {
  const reorder = getVoucherReorder(state);
  const reorderIdWasNotLoaded =
    orderId && orderId.length > 0 && reorder && reorder.id !== orderId;

  const requiredDataLoading = !isLoaded([
    ApiConstants.LOAD_USER_COMPANY,
    ApiConstants.LOAD_VOUCHERS,
    ApiConstants.LOAD_ORDER_FEES,
    ApiConstants.LOAD_SETTINGS
  ])(state);

  const reorderLoading = isLoading([ApiConstants.LOAD_REORDER_DATA])(state);

  return reorderIdWasNotLoaded || requiredDataLoading || reorderLoading;
};

const isDisablePayment = isLoading([
  ApiConstants.CREATE_VOUCHER_REORDER,
  ApiConstants.CREATE_NEW_PAYMENT,
  ApiConstants.CREATE_VOUCHER_ORDER
]);
export const disablePayment = state => isDisablePayment(state);

const isLoadingOrderData = isLoading([
  ApiConstants.CHECK_USERNAME_AVAILABILITY,
  ApiConstants.LOAD_VOUCHERS,
  ApiConstants.LOAD_ORDER_FEES,
  ApiConstants.LOAD_SETTINGS
]);
export const orderData = state => isLoadingOrderData(state);

const isLoadingTransactionsHistory = isLoading([
  ApiConstants.LOAD_TRANSACTIONS_HISTORY,
  ApiConstants.LOAD_TRANSACTIONS
]);
export const transactionsHistory = state => isLoadingTransactionsHistory(state);

const isLoadingUsernameAvailability = isLoading([
  ApiConstants.CHECK_USERNAME_AVAILABILITY
]);
export const usernameAvailability = state =>
  isLoadingUsernameAvailability(state);

const isLoadingSignUp = isLoading([
  ApiConstants.SIGN_UP,
  ApiConstants.CHECK_USERNAME_AVAILABILITY,
  ApiConstants.LOGIN_ACTION
]);
export const signUp = state => isLoadingSignUp(state);

const isLoadingChangePassword = isLoading([ApiConstants.CHANGE_PASSWORD]);
export const changePassword = state => isLoadingChangePassword(state);

const isLoadingOrderPayment = isLoading([
  ApiConstants.ADD_NEW_PAYMENT_TO_VOUCHER
]);
export const orderPayment = state => isLoadingOrderPayment(state);

const isLoadingCreateVoucherOrder = isLoading([
  ApiConstants.CREATE_VOUCHER_ORDER
]);
export const createVoucherOrder = state => isLoadingCreateVoucherOrder(state);

const isLoadingCreateVoucherReorder = isLoading([
  ApiConstants.CREATE_VOUCHER_REORDER
]);
export const createVoucherReorder = state =>
  isLoadingCreateVoucherReorder(state);

const isLoadingCompanyAccounts = isLoading([
  ApiConstants.LOAD_COMPANY_ACCOUNTS
]);
export const loadCompanyAccounts = state => isLoadingCompanyAccounts(state);
