import { ApiConstants } from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  isLoading: [],
  noPersistance: true
});

const AddId = (state, id) => [...state.isLoading, id];

const RemoveId = (state, id) => [
  ...(state.isLoading || []).filter(value => value !== id)
];

export default createReducer(getInitialState, {
  [`${ApiConstants.DOWNLOAD_INVOICE}_REQUEST`]: (state, { id, idType }) => ({
    isLoading: AddId(state, `${idType}-${id}`)
  }),

  [`${ApiConstants.DOWNLOAD_INVOICE}_SUCCESS`]: (state, { id, idType }) => ({
    isLoading: RemoveId(state, `${idType}-${id}`)
  }),

  [`${ApiConstants.DOWNLOAD_INVOICE}_FAILURE`]: (state, { id, idType }) => ({
    isLoading: RemoveId(state, `${idType}-${id}`)
  })
});
