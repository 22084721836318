import React from 'react';
import { Box, TitleText, Text, Link, SimpleList } from 'edenred-ui';

const linksMapper = data =>
  data.map(elem => {
    if (elem?.links) {
      const links = elem.links.map(({ url, name }) => (
        <Link
          target="_blank"
          href={url}
          key={url}
          isSizeMedium
          isFontRegularBold
          id={`support-link_${name}`}
        >
          {name}
        </Link>
      ));
      return { ...elem, links };
    }
    return elem;
  });

export const SupportLinks = ({ data }) => {
  const mappedData = linksMapper(data);

  return mappedData.map(({ title, description, links }) => (
    <Box mt={6} mx={20} mb={2} key={title}>
      <TitleText>{title}</TitleText>
      {description && <Text paragraph>{description}</Text>}
      {links && <SimpleList data={links} />}
    </Box>
  ));
};
