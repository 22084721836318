import { put, call } from 'redux-saga/effects';
import { getAddresses } from '../../repositories/AddressesRepository';
import { ApiConstants } from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';

const { LOAD_ADDRESSES } = ApiConstants;

export default function* loadAddresses() {
  try {
    const addresses = yield call(getAddresses);
    yield put({
      type: `${LOAD_ADDRESSES}_SUCCESS`,
      addresses
    });
  } catch (ex) {
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    }
    yield put({
      type: `${LOAD_ADDRESSES}_FAILURE`,
      payload: ex,
      isError: true
    });
  }
}
