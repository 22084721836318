import styled from 'styled-components';

const Row = styled.div`
  width: ${props => (props.width ? props.width : '100%')};
  gap: 3rem;
  padding: 1.5rem 0;
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  justify-content: flex-start;
`;

export default Row;
