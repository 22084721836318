import { ApiConstants } from '@epi-constants/actions';
import createReducer from '@epi-helpers/CreateReducer';

export const getInitialState = () => ({
  estimation: null,
  originalTotalSuggestedTransfer: null,
  unfulfilledSum: 0
});

export default createReducer(getInitialState, {
  [`${ApiConstants.TRANSFER_MONEY_ESTIMATION}_SUCCESS`]: (
    state,
    { payload }
  ) => ({
    ...state,
    estimation: payload,
    originalTotalSuggestedTransfer: payload.totalSuggestedTransfer
  }),
  [`${ApiConstants.LOAD_COMPANY_ACCOUNTS}_SUCCESS`]: (state, { payload }) => {
    const [firstInfo] = payload;
    return {
      ...state,
      unfulfilledSum: !firstInfo ? 0 : payload[0].unfulfilledRequestSum
    };
  }
});
