import memoize from 'memoize-one';
import { createSelector } from 'reselect';
import { commonOrderSummary, getVoucher } from './voucherOrder';

export const getVoucherOrder = state =>
  state.apiVoucherOrderByPayment.voucherOrder;

export const getVoucherOrderDetails = createSelector(
  getVoucherOrder,
  state => state,
  (voucherOrder, state) => {
    const voucher = getVoucher(voucherOrder.voucherId)(state) || {};
    const faceValue = voucher.value || 0;
    return {
      faceValue,
      quantity: voucherOrder.totalQuantity,
      ...voucher,
      ...voucherOrder
    };
  }
);

export const getCompanyInformation = state =>
  state.apiVoucherOrderByPayment.voucherOrder.company;

export const getDelivery = state =>
  state.apiVoucherOrderByPayment.voucherOrder.delivery;

export const getVoucherTypeKey = state =>
  state.apiVoucherOrderByPayment.voucherOrder.voucherTypeKey;

export const getPaymentId = state => state.apiVoucherOrderByPayment.paymentId;

export const isVoucherOrderLoaded = state =>
  state.apiVoucherOrderByPayment.isLoaded;

export const isError = state => state.apiVoucherOrderByPayment.isError;

export const getOrderSummary = memoize(paymentMethod =>
  commonOrderSummary(paymentMethod, getVoucherOrderDetails)
);
