import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useDebouncedCallback } from 'use-debounce';
import {
  LoadingSpinner,
  PagePanel,
  Box,
  SetBenefitsIcon,
  AddEmployeesIcon,
  Button,
  ButtonMode
} from 'edenred-ui';

import { initializeBenefitSettingsContainer } from '@epi-actions/beneficiary';
import { showNotification } from '@epi-actions/overlays';
import withAjax from '@epi-decorators/withAjax';
import {
  companyBeneficiariesApiUrl,
  companyBeneficiaryApiUrl,
  checkBeneficiarySSNUrl
} from '@epi-repositories/CompanyBeneficiariesRepository';
import { resendEmailApiUrl } from '@epi-repositories/EmailRepository';
import {
  getDefinedBenefitGroupValues,
  isAnyBenefitGroupSettingsDefined,
  selectIsBenefitGroupSettingsLoaded
} from '@epi-selectors/benefitGroupSettings';
import { getReCaptchaSiteKey } from '@epi-selectors/settings';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { EmptyState, Pagination } from '@epi-shared/components';
import { getDetails } from '@epi-api/user';

import { BeneficiaryItemsPerPage } from '../../constants/beneficiary';
import { fetchPost as Post, fetchPut as Put } from '../../helpers/FetchHelpers';
import AddBeneficiarySlideout from './components/AddBeneficiary/AddBeneficiarySlideout';
import beneficiariesReducer, {
  getChangePageAction,
  getDataLoadedAction
} from '../../reducers/BeneficiariesReducer';
import BeneficiariesTable from './components/BeneficiariesTable/BeneficiariesTable';
import BeneficiaryDetailSlideout from './components/BeneficiaryDetail/BeneficiaryDetailSlideout';
import BeneficiariesTopSection from './components/BeneficiariesTopSection/BeneficiariesTopSection';

const sendEmailSuccessMessage = 'messages.successful_email_sent';
const sendEmailFailedMessage = 'messages.email_send_failed';
const editEmployeeSuccessMessage = 'messages.successful_edit_employee';
const addEmployeeSuccessMessage = 'messages.successful_add_employee';
const genericErrorMessage = 'messages.error';
const checkSSNErrorMessage = 'messages.check_ssn_failed';

function BeneficiariesList({
  fetchGet,
  fetchPost,
  showError,
  showSuccess,
  reCaptchaSiteKey,
  getBenefitGroupSettings,
  isBenefitDefined,
  currentlySetBenefitValues = null,
  isBenefitGroupSettingsLoaded,
  goToBenefits
}) {
  const { t } = useTranslation();
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showSlideout, setShowSlideout] = useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState(null);
  const [beneficiaryEdited, setBeneficiaryEdited] = useState(null);
  const [beneficiaryEditingMode, setBeneficiaryEditingMode] = useState(false);
  const [beneficiaryAdded, setBeneficiaryAdded] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [
    showBeneficiaryAddedNotification,
    setShowBeneficiaryAddedNotification
  ] = useState(false);
  const [isMoneyTransfered, setisMoneyTransfered] = useState(false);
  const [state, dispatch] = useReducer(beneficiariesReducer, {
    sortDirection: false,
    sortBy: 'lastName',
    isLoading: true,
    rows: [],
    page: 1,
    totalRows: 0
  });
  const paginationCount = Math.ceil(state.totalRows / BeneficiaryItemsPerPage);

  useEffect(() => {
    getBenefitGroupSettings();
  }, []);

  useEffect(() => {
    const fetchEmployees = () => {
      const abortController = new AbortController();
      fetchGet(companyBeneficiariesApiUrl, abortController.signal)
        .then(data => {
          const containsNewEmployees = data.beneficiaries.findIndex(
            b => b.isNew
          );
          if (isMoneyTransfered) {
            setShowBeneficiaryAddedNotification(containsNewEmployees !== -1);
          }
          dispatch(getDataLoadedAction(data.beneficiaries));
        })
        .catch(() => showError(genericErrorMessage))
        .finally(() => {
          abortController.abort();
        });
    };
    fetchEmployees();
  }, [beneficiaryEdited, beneficiaryAdded]);

  useEffect(() => {
    let isApiSubscribed = true;
    getDetails()
      .then(({ isMoneyTransfered }) => {
        if (isApiSubscribed) {
          setisMoneyTransfered(!isMoneyTransfered);
        }
      })
      .catch(() => showError(genericErrorMessage));
    return () => {
      isApiSubscribed = false;
    };
  }, [beneficiaryAdded]);

  const showDetail = personId => {
    const abortController = new AbortController();
    fetchGet(companyBeneficiaryApiUrl(personId), abortController.signal)
      .then(data => {
        setSelectedBeneficiary(data);
        setShowSlideout(true);
      })
      .catch(() => showError(genericErrorMessage))
      .finally(() => {
        abortController.abort();
      });
  };

  const resendActivationEmail = useDebouncedCallback(personId => {
    const abortController = new AbortController();
    fetchPost(`${resendEmailApiUrl}/${personId}`, null, abortController.signal)
      .then(() => showSuccess(sendEmailSuccessMessage))
      .catch(() => showError(sendEmailFailedMessage))
      .finally(() => {
        abortController.abort();
      });
  }, 1000);

  const editEmployeeAction = employee => {
    const onSuccess = () => {
      setBeneficiaryEdited(Symbol('beneficiary_edited'));
      showSuccess(editEmployeeSuccessMessage);
      showDetail(employee.personId);
    };
    const onFailure = () => showError(genericErrorMessage);
    return Put(companyBeneficiariesApiUrl, employee, onSuccess, onFailure);
  };

  const addEmployeeAction = (employee, addAnotherEmployee, resetForm) => {
    setIsProcessing(true);

    const onAddEmployeeSuccess = () => {
      setBeneficiaryAdded(Symbol('beneficiary_added'));
      showSuccess(addEmployeeSuccessMessage);
      setIsProcessing(false);

      if (addAnotherEmployee) {
        resetForm();
      } else {
        setShowAddEmployee(false);
      }
    };
    const onAddEmployeeFailure = () => {
      showError(genericErrorMessage);
      setShowAddEmployee(false);
      setIsProcessing(false);
    };

    const onSSNSuccess = () => {
      return Post(
        companyBeneficiariesApiUrl,
        employee,
        onAddEmployeeSuccess,
        onAddEmployeeFailure
      );
    };
    const onSSNFailure = () => {
      showError(checkSSNErrorMessage);
      setIsProcessing(false);
    };

    return Post(
      checkBeneficiarySSNUrl,
      { personalId: employee.personalId },
      onSSNSuccess,
      onSSNFailure
    );
  };

  function renderPagePanel() {
    if (!isBenefitGroupSettingsLoaded || state.isLoading) {
      return <LoadingSpinner />;
    }

    if (!isBenefitDefined) {
      return (
        <EmptyState
          icon={<SetBenefitsIcon />}
          title={t('employees.set_benefits_title')}
          description={t('employees.set_benefits_description')}
          btnText={t('employees.set_benefits_btn')}
          btnOnClick={goToBenefits}
        />
      );
    }

    if (state.totalRows === 0) {
      return (
        <EmptyState
          icon={<AddEmployeesIcon />}
          title={t('employees.no_employees_title')}
          description={t('employees.no_employees_description')}
          btnText={t('employees.no_employees_btn')}
          btnOnClick={() => setShowAddEmployee(true)}
        />
      );
    }

    return (
      <div>
        <BeneficiariesTopSection
          showBeneficiaryAddedNotification={showBeneficiaryAddedNotification}
          setShowBeneficiaryAddedNotification={
            setShowBeneficiaryAddedNotification
          }
          setShowAddEmployee={setShowAddEmployee}
        />
        <BeneficiariesTable
          beneficiaries={state.rows}
          isLoading={state.isLoading}
          showDetail={showDetail}
          resendActivationEmail={resendActivationEmail}
          setBeneficiaryEditingMode={setBeneficiaryEditingMode}
        />
        {state.totalRows > BeneficiaryItemsPerPage && (
          <Pagination
            page={state.page}
            count={paginationCount}
            defaultRowsPerPage={BeneficiaryItemsPerPage}
            onChangePage={page => dispatch(getChangePageAction(page))}
          />
        )}
      </div>
    );
  }

  return (
    <>
      <PagePanel
        relative
        title={t('containers.beneficiaries.title')}
        headerContent={
          isBenefitDefined && (
            <Button
              mode={ButtonMode.Secondary}
              onClick={() => {
                setShowAddEmployee(true);
              }}
              id="add-employee-btn"
            >
              {t('containers.beneficiaries.add_employee.trigger')}
            </Button>
          )
        }
      >
        <Box minHeight={600}>{renderPagePanel()}</Box>
      </PagePanel>
      {selectedBeneficiary !== null && (
        <BeneficiaryDetailSlideout
          beneficiary={selectedBeneficiary}
          expanded={showSlideout}
          setExpanded={setShowSlideout}
          editEmployeeAction={editEmployeeAction}
          resendActivationEmail={resendActivationEmail}
          currentlySetBenefitValues={currentlySetBenefitValues}
          reCaptchaSiteKey={reCaptchaSiteKey}
          setEditingMode={setBeneficiaryEditingMode}
          editingMode={beneficiaryEditingMode}
        />
      )}
      <AddBeneficiarySlideout
        expanded={showAddEmployee}
        setExpanded={setShowAddEmployee}
        addEmployeeAction={addEmployeeAction}
        currentlySetBenefitValues={currentlySetBenefitValues}
        reCaptchaSiteKey={reCaptchaSiteKey}
        isProcessing={isProcessing}
      />
    </>
  );
}

BeneficiariesList.propTypes = {
  fetchGet: PropTypes.func.isRequired,
  fetchPost: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired,
  reCaptchaSiteKey: PropTypes.string.isRequired,
  getBenefitGroupSettings: PropTypes.func.isRequired,
  isBenefitDefined: PropTypes.bool.isRequired,
  currentlySetBenefitValues: PropTypes.object.isRequired,
  isBenefitGroupSettingsLoaded: PropTypes.bool.isRequired,
  goToBenefits: PropTypes.func.isRequired
};

const msg = message => ({ message });
export default connect(
  state => ({
    reCaptchaSiteKey: getReCaptchaSiteKey(state),
    isBenefitDefined: isAnyBenefitGroupSettingsDefined(state),
    currentlySetBenefitValues: getDefinedBenefitGroupValues(state),
    isBenefitGroupSettingsLoaded: selectIsBenefitGroupSettingsLoaded(state)
  }),
  dispatch => ({
    showError: message => {
      dispatch(showNotification(msg(message), 'error'));
    },
    showSuccess: message => {
      dispatch(showNotification(msg(message), 'success'));
    },
    getBenefitGroupSettings: () =>
      dispatch(initializeBenefitSettingsContainer()),
    goToBenefits: () => dispatch(goToPage(routerPaths.manageBenefits))
  })
)(withTranslation()(withAjax()(BeneficiariesList)));
