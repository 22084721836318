import { put, call, select, all } from 'redux-saga/effects';
import { NotifyApiErrors } from '@epi-helpers/NotificationHelper';
import { getVoucherOrders } from '../../repositories/VoucherOrdersRepository';
import { getOrderHistoryQuery } from '../../selectors/orderHistory';
import { ApiConstants } from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';
import { getVoucherById } from './apiHelpers';

function* addVoucherFaceValueAndType(order) {
  const voucherData = yield getVoucherById(order.voucherId);
  return {
    ...order,
    faceValue: voucherData.value,
    voucherType: voucherData.type
  };
}

export function* setVoucherFaceValueAndType(orders) {
  return yield all(orders.map(item => addVoucherFaceValueAndType(item)));
}

export default function* loadVoucherOrdersHistory() {
  try {
    const query = yield select(getOrderHistoryQuery);
    const voucherOrdersResponse = yield call(getVoucherOrders, query);

    const voucherOrders = yield call(
      setVoucherFaceValueAndType,
      voucherOrdersResponse.voucherOrders
    );

    yield put({
      type: `${ApiConstants.LOAD_VOUCHER_ORDERS_HISTORY}_SUCCESS`,
      payload: {
        voucherOrdersResponse: {
          voucherOrders,
          totalPages: voucherOrdersResponse.totalPages
        }
      }
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.LOAD_VOUCHER_ORDERS_HISTORY}_FAILURE`,
      payload: ex
    });
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    } else {
      yield call(NotifyApiErrors, ex);
    }
  }
}
