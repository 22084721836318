import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { get } from '@epi-helpers/FetchHelpers';
import { Authorize } from '../constants/authorize';
import { getRequest, putRequest, postRequest } from './ApiRepository';

export function* getUserCompany() {
  return yield call(
    getRequest,
    `${config.apiHost}/api/companies`,
    Authorize.Always
  );
}

export function* getHasSignatory(companyId) {
  return yield call(
    getRequest,
    `${config.apiHost}/api/companies/${companyId}/has-signatory`,
    Authorize.Always
  );
}

export function* putUserCompany(companyData) {
  return yield call(
    putRequest,
    `${config.apiHost}/api/companies`,
    Authorize.Always,
    companyData
  );
}

export function* checkIfCompanyExists(payload) {
  const response = yield call(
    postRequest,
    `${config.apiHost}/api/companies/exists`,
    Authorize.Never,
    payload
  );
  return response.companyAlreadyExists;
}

export function getExistingCompanyData(data) {
  return get(`${config.apiHost}/api/companies/alreadyCustomer`, {
    ...data
  });
}
