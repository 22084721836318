import React from 'react';
import { connect } from 'react-redux';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { PagePanel } from 'edenred-ui';

import { ApiConstants } from '../../constants/actions';
import * as profileActions from '../../actions/profile';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { LoadingContainer } from '../../components/LoadingContainer/LoadingContainer';
import { UserInformation as UserInformationForm } from './UserInformation';
import { ChangePassword as ChangePasswordForm } from './ChangePassword';
import './Profile.scss';

const Profile = ({
  i18n,
  changeSelectedProfileTab,
  profile: { selectedProfileIndex },
  isUserImpersonate
}) => (
  <PagePanel title={i18n.t('profile.title')}>
    <ErrorPanel failedActionName={ApiConstants.UPDATE_USER_INFORMATION} />
    <Tabs
      className="Profile"
      defaultIndex={selectedProfileIndex}
      onSelect={tabIndex => changeSelectedProfileTab(tabIndex)}
    >
      <TabList>
        <Tab>{i18n.t('containers.user_profile.user_info_tab')}</Tab>
        {!isUserImpersonate && (
          <Tab>{i18n.t('containers.user_profile.change_password_tab')}</Tab>
        )}
      </TabList>
      <div className="col-lg-8 col-md-10 col-xs-12">
        <TabPanel>
          <LoadingContainer isLoading={[ApiConstants.GET_USER_INFORMATION]}>
            <UserInformationForm />
          </LoadingContainer>
        </TabPanel>
        {!isUserImpersonate && (
          <TabPanel>
            <ChangePasswordForm />
          </TabPanel>
        )}
      </div>
    </Tabs>
  </PagePanel>
);

Profile.propTypes = {
  profile: PropTypes.object.isRequired,
  changeSelectedProfileTab: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  isUserImpersonate: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  profile: state.profile,
  isUserImpersonate: state.login.isUserImpersonate
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(profileActions, dispatch);

const ConnectedProfile = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Profile));

export { ConnectedProfile as Profile };
