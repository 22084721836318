import React from 'react';
import { useSelector } from 'react-redux';
import { SmallWalletIcon } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { formatCurrencyDecimal } from '@epi-helpers/numeral';
import { getMainAvailableBalance } from '@epi-selectors/companyAccounts';

import {
  CurrentBalance,
  BalanceAmount,
  BalanceText
} from './TransferPage.styles';

export const TransferPageHeader = () => {
  const { t } = useTranslation();
  const balance = useSelector(getMainAvailableBalance);

  return (
    <CurrentBalance>
      <SmallWalletIcon />
      <BalanceText>
        {t('containers.deposit_money.current_balance_label')}
      </BalanceText>
      <BalanceAmount>{formatCurrencyDecimal(balance)}</BalanceAmount>
    </CurrentBalance>
  );
};
