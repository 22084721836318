import { put, select, take } from 'redux-saga/effects';
import { getVoucherReorderDetails } from '../../selectors/voucherReorder';
import { ApiConstants } from '../../constants/actions';
import { trackReorderComplete } from '../tracking';

export default function* createVoucherReorder({ values }) {
  // Select reordered quantity
  // eslint-disable-next-line camelcase
  const voucher_details = yield select(getVoucherReorderDetails);
  yield put({
    type: `${ApiConstants.CREATE_VOUCHER_ORDER}_REQUEST`,
    values: { ...values, voucher_details }
  });
  yield trackReorderComplete();
  const createVoucherOrderEndedRegex = new RegExp(
    `^${ApiConstants.CREATE_VOUCHER_ORDER}_(SUCCESS|FAILURE|ABORT)$`
  );
  const createVoucherOrderAction = yield take(action =>
    createVoucherOrderEndedRegex.test(action.type)
  );

  const success = /_SUCCESS$/.test(createVoucherOrderAction.type);
  yield put({
    type: `${ApiConstants.CREATE_VOUCHER_REORDER}_${
      success ? 'SUCCESS' : 'FAILURE'
    }`
  });
}
