import styled from 'styled-components';

export const BenefitTypeTileContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  width: 296px;
  margin-bottom: 24px;
  margin-right: 24px;
  padding: 24px;
  background-color: ${props =>
    props.active && !props.disabled
      ? props.theme.primaryBackColorLight10
      : props.theme.gray0};
  outline: 2px solid
    ${props =>
      props.disabled
        ? props.theme.altGray2
        : props.theme.primaryBackColorLight};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    background-color: ${props =>
      props.disabled ? props.theme.gray0 : props.theme.primaryBackColorLight10};
    box-shadow: ${props =>
      props.disabled
        ? ''
        : `1px 1px 30px 10px ${props.theme.primaryBackColorLight10}`};
  }
`;

export const BenefitTypeStatus = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 12px;
  top: 12px;
  padding: 4px 12px;
  background-color: ${props => props.theme.gray0};
  border-radius: 20px;
`;

export const BenefitTypeStatusText = styled.div`
  color: ${props => props.theme.gray50};
  font-size: ${props => props.theme.sizeExtraSmall};
  line-height: ${props => props.theme.sizeMedium};
  letter-spacing: 0;

  &::before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 8px;
    background-color: #42c446;
    border-radius: 100%;
  }
`;

export const BenefitTypeName = styled.div`
  color: ${props =>
    props.disabled ? props.theme.altGray1 : props.theme.primaryFrontColor};
  font-size: ${props => props.theme.sizeExtraExtraLarge};
  font-weight: ${props => props.theme.fontSemiBold};
  margin: 15px 0 35px;
`;

export const ComingSoonContainer = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  font-size: ${props => props.theme.sizeMedium};
  letter-spacing: 0;
  color: ${props => props.theme.gray50};
`;

export const ViewGroupsButtonContainer = styled.div`
  max-width: 180px;
  width: 100%;
`;
