import React, { useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FormFields } from '@epi-forms/helpers';

import { OrderType } from '@epi-constants/orderType';
import { PaymentMethods } from '@epi-constants/paymentMethods';
import { getDepositIsProcessing } from '@epi-selectors/deposit';
import { arePaymentMethodsLoaded as arePaymentMethodsLoadedSelector } from '@epi-selectors/api';
import { getApplicationLocale } from '@epi-selectors/application';
import { getMainCompanyAccount } from '@epi-selectors/companyAccounts';
import {
  createDepositOrder,
  loadPaymentMethods,
  loadTransferMoneyEstimation
} from '@epi-actions/api';
import { togglePrintInvoiceForPdf } from '@epi-actions/companyAccounts';
import {
  amountValidators,
  depositDebouncer
} from '@epi-constants/loadCompanyAccount';

import { TransferMoneyFormFields } from '../helpers';
import storage from '../../../helpers/StorageHelper';

export const useLoadCompanyAccount = updateFooter => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [TransferMoneyFormFields.Amount]: '',
      [TransferMoneyFormFields.Method]: ''
    }
  });

  const {
    watch,
    handleSubmit,
    getValues,
    formState: { errors }
  } = form;

  const amountValue = watch(TransferMoneyFormFields.Amount).replace(',', '.');
  const method = watch(FormFields.PaymentMethod);

  const mainCompanyAccount = useSelector(getMainCompanyAccount);
  const isPaymentProcessing: boolean = useSelector(getDepositIsProcessing);
  const locale: string = useSelector(getApplicationLocale);
  const arePaymentMethodsLoaded: boolean = useSelector(state =>
    arePaymentMethodsLoadedSelector(locale, amountValue)(state)
  );

  const isAmountValid = amountValidators.every(v => !v(Number(amountValue)));
  const disabled = isPaymentProcessing || !isAmountValid || !isEmpty(errors);

  const handleLoadingPaymentMethods = () => {
    if (isAmountValid && !arePaymentMethodsLoaded) {
      depositDebouncer(() => {
        dispatch(loadPaymentMethods(OrderType.digital, amountValue, locale));
      });
    }
  };

  const submitCompleteForm = () => {
    onSubmit(getValues());
  };

  const submitFooter = () => {
    dispatch(togglePrintInvoiceForPdf(true));
    storage.set('isPrintInvoicePdf', 'isPrintInvoicePdf');
    submitCompleteForm();
  };

  const onSubmit = values => {
    dispatch(
      createDepositOrder({
        ...values,
        paymentMethod:
          values.paymentMethod ===
          PaymentMethods.PrintInvoicePdfForTransferMoney
            ? PaymentMethods.PrintInvoicePdf
            : values.paymentMethod,
        ...mainCompanyAccount
      })
    );
  };

  const handleSubmitForm = () => handleSubmit(onSubmit);

  useEffect(() => {
    handleLoadingPaymentMethods();
  }, [amountValue]);

  useEffect(() => {
    dispatch(loadTransferMoneyEstimation());
    updateFooter(null);

    if (method === PaymentMethods.PrintInvoicePdfForTransferMoney) {
      updateFooter({
        submitText: t(
          'containers.deposit_money.review_requested_loads.submit_footer'
        ),
        onSubmit: () => submitFooter(),
        disableSubmit: disabled,
        submitButtonWidth: '230px'
      });
    }
  }, [method, disabled, t]);

  return {
    form,
    submitCompleteForm,
    disabled,
    isPaymentProcessing,
    handleSubmitForm
  };
};
