import config from '@epi-config';
import { downloadFile } from '../helpers/DownloadFileHelper';

export function downloadOrderDocument(accessToken, tokenType, voucherOrderId) {
  const headers = { Authorization: `${tokenType} ${accessToken}` };
  return downloadFile(
    `${config.apiHost}/api/order-documents/${voucherOrderId}`,
    headers,
    'invoice.pdf'
  );
}
export function downloadOrderDocumentAfterPayment(paymentId) {
  return downloadFile(
    `${config.apiHost}/api/order-documents?paymentId=${paymentId}`,
    null,
    'invoice.pdf'
  );
}
