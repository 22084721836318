import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, ButtonMode, Box, InfoIcon, Tooltip, Text } from 'edenred-ui';
import { useTranslation, Trans } from 'react-i18next';
import { useTheme } from 'styled-components';

import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { normalizeDecimalComma } from '@epi-containers/LoadCompanyAccount/helpers';
import { IReviewRequestedLoads } from '@epi-models/containers/LoadCompanyAccount';

export const ReviewRequestedLoads = ({ estimation }: IReviewRequestedLoads) => {
  const { gray10, primaryFrontColor } = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigateToRequestedLoadsPage = () => {
    dispatch(goToPage(routerPaths.requestedLoads));
  };

  return (
    <Box
      mt={5}
      mb={3}
      border={1}
      p={2}
      borderColor={gray10}
      minHeight={200}
      display="flex"
    >
      <Box px={8} py={4} display="flex" alignItems="center" width="100%">
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          width="100%"
        >
          <Box width={40} />
          <Box flex={1} fontSize={16}>
            <Trans
              id="transfer-money-review-request-text"
              defaults={t(
                'containers.deposit_money.review_requested_loads.summary',
                {
                  numberOfRequests: estimation?.requestedLoads.numberOfRequests,
                  valueOfRequests: normalizeDecimalComma(
                    estimation?.requestedLoads.value
                  )
                }
              )}
              components={{
                b: <b />
              }}
            />
          </Box>
          <Box flex={1} display="flex" alignItems="center">
            <Button
              id="navigate-to-request-loads-page-btn"
              onClick={navigateToRequestedLoadsPage}
              mode={ButtonMode.Primary}
              minWidth={156}
            >
              {t('containers.deposit_money.review_requested_loads.button')}
            </Button>
            <Tooltip
              arrow
              placement="top"
              title={
                <Box maxWidth={274} p={2}>
                  <Text small>
                    {t(
                      'containers.deposit_money.review_requested_loads.description'
                    )}
                  </Text>
                </Box>
              }
            >
              <Box pl={2}>
                <InfoIcon color={primaryFrontColor} />
              </Box>
            </Tooltip>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
