import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { TooltipInfo } from '../../../../components/TooltipInfo/TooltipInfo';

const ResendActivationEmailSection = ({
  editingMode,
  resendActivationEmail,
  personId
}) => {
  return (
    <div // eslint-disable-line
      className={`resendlink resendlink${
        editingMode ? '--disabled' : '--active'
      }`}
      data-test="resend_activation_email"
    >
      <button
        type="button"
        onClick={() => {
          if (!editingMode) {
            resendActivationEmail.callback(personId);
          }
        }}
      >
        {i18n.t('containers.beneficiaries.detail.resend_activation_email')}
      </button>
      <TooltipInfo
        info={i18n.t(
          'containers.beneficiaries.detail.resend_activation_email_tooltip'
        )}
        placement="top"
      />
    </div>
  );
};

ResendActivationEmailSection.propTypes = {
  editingMode: PropTypes.bool.isRequired,
  resendActivationEmail: PropTypes.object.isRequired,
  personId: PropTypes.string.isRequired
};

export default ResendActivationEmailSection;
