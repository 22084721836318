import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { PagePanel, Button, ButtonSize, EditIcon } from 'edenred-ui';

import * as navigationActions from '@epi-actions/navigation';
import DisplayAddress from '@epi-components/Address/DisplayAddress';
import config from '@epi-config';

import { businessId as businessIdFormatter } from '../../helpers/formHelpers/formatters';
import { RoundButton } from '../RoundButton/RoundButton';
import { LoadingContainer } from '../LoadingContainer/LoadingContainer';

import './Company.scss';

const { disableEditOptionsForOA } = config;

class Company extends PureComponent {
  render() {
    const {
      i18n,
      userCompany: { businessIdentityCode, name, address },
      editPage,
      goToPage,
      isLoading
    } = this.props;
    return (
      <div className="Company">
        <PagePanel
          title={i18n.t('containers.voucher_order.company_information_header')}
          className="px-10"
        >
          {editPage && (
            <span className="visible-xs-inline">
              <RoundButton
                id="company-edit-icon-btn"
                icon={<EditIcon />}
                className="pull-right"
                onClick={() => goToPage(editPage)}
              />
            </span>
          )}
          <LoadingContainer isLoading={isLoading}>
            <Row className="pl-60">
              <Col xs={10} sm={12}>
                <div className="header3 small">
                  {`${name} ${businessIdFormatter(businessIdentityCode)}`}
                </div>
                <DisplayAddress address={address} />
              </Col>
            </Row>
            {editPage && (
              <Row className="hidden-xs">
                <Col>
                  <Button
                    id="company-edit-btn"
                    className="btn-edit pull-right"
                    onClick={() => goToPage(editPage)}
                    size={ButtonSize.Medium}
                    disabled={Boolean(disableEditOptionsForOA)}
                  >
                    {i18n.t('components.benefit_summary.edit')}
                  </Button>
                </Col>
              </Row>
            )}
          </LoadingContainer>
        </PagePanel>
      </div>
    );
  }
}

Company.propTypes = {
  userCompany: PropTypes.object.isRequired,
  goToPage: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  editPage: PropTypes.string,
  isLoading: PropTypes.bool
};

Company.defaultProps = {
  editPage: '',
  isLoading: false
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const ConnectedCompany = connect(
  null,
  mapDispatchToProps
)(withTranslation()(Company));

export { ConnectedCompany as Company };
