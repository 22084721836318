import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getImpersonationToken } from '@epi-api/user';
import { getImpersonationLoginAction } from '@epi-actions/login';
import { setImpersonationRequest } from '@epi-helpers/ImpersonationHelper';

export const useImpersonation = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  const searchParams = new URLSearchParams(window.location.search);
  const isGuid = searchParams.has('guid');
  const guid = isGuid && searchParams.get('guid');

  useEffect(() => {
    if (guid) {
      setIsLoading(true);
      getImpersonationToken(guid)
        .then(res => {
          dispatch(getImpersonationLoginAction(setImpersonationRequest(res)));
        })
        .catch(() => setIsError(true));
    }
  }, []);

  return {
    isImpersonate: isGuid,
    isLoading,
    isError
  };
};
