import tokeys from 'tokeys';

import {
  getOnlinePaymentMinAmount,
  getOfflinePaymentMinAmount
} from '@epi-selectors/settings';

export const PaymentMethods = tokeys([
  'CreditCard',
  'LinkToOtherPerson',
  'PrintInvoicePdf',
  'Online',
  'PrintInvoicePdfForTransferMoney'
]);

export const getPaymentMinimalValue = paymentMethod => state => {
  if (
    paymentMethod === PaymentMethods.Online ||
    paymentMethod === PaymentMethods.CreditCard
  ) {
    return getOnlinePaymentMinAmount(state);
  }
  return getOfflinePaymentMinAmount(state);
};
