import { Button } from 'edenred-ui';
import styled from 'styled-components';

export const FieldContainer = styled.div`
  padding-bottom: 35px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: end;
  padding-right: 40px;
`;

export const StyledButton = styled(Button)`
  width: 135px;
`;
