import React from 'react';
import PropTypes from 'prop-types';
import { Table, LoadingSpinner, Box } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { BeneficiariesRow } from './BeneficiariesRow';

function BeneficiariesTable({
  beneficiaries,
  showDetail,
  isLoading,
  resendActivationEmail,
  setBeneficiaryEditingMode
}) {
  const { t } = useTranslation();

  return (
    <Box pt={2} pb={6}>
      <Table>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Table.Head>
              <Table.Row>
                <Table.Cell width="30%">
                  {t('containers.beneficiaries.table.employee')}
                </Table.Cell>
                <Table.Cell width="50%">
                  {t('containers.beneficiaries.table.email')}
                </Table.Cell>
                <Table.Cell width="15%">
                  {t('containers.beneficiaries.table.benefit_type')}
                </Table.Cell>
                <Table.Cell width="5%">
                  {t('containers.beneficiaries.table.actions')}
                </Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {beneficiaries.map(beneficiary => (
                <BeneficiariesRow
                  key={beneficiary.personId}
                  beneficiary={beneficiary}
                  showDetail={showDetail}
                  resendActivationEmail={resendActivationEmail}
                  setEditingMode={setBeneficiaryEditingMode}
                />
              ))}
            </Table.Body>
          </>
        )}
      </Table>
    </Box>
  );
}

BeneficiariesTable.propTypes = {
  beneficiaries: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  showDetail: PropTypes.func.isRequired,
  resendActivationEmail: PropTypes.object.isRequired,
  setBeneficiaryEditingMode: PropTypes.func.isRequired
};

export default BeneficiariesTable;
