import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  TransferIcon,
  Button,
  ButtonMode,
  ChevronRightIcon
} from 'edenred-ui';

import { getMainAvailableBalance } from '@epi-selectors/companyAccounts';
import { formatCurrency } from '@epi-helpers/numeral';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';

export const BalanceTile = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const amount = useSelector(getMainAvailableBalance);
  const dispatch = useDispatch();

  const goToTransferMoney = () =>
    dispatch(goToPage(routerPaths.loadCompanyAccount));

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width={400}
      height={320}
      p={3}
      bgcolor={theme.primaryFrontColor}
    >
      <Box position="relative">
        <Box
          fontSize={theme.sizeLarge}
          fontWeight={theme.fontSemiBold}
          color={theme.gray0}
        >
          {t('home.account.balance_title')}
        </Box>
        <Box
          mt={3}
          fontSize={40}
          fontWeight={theme.fontSemiBold}
          color={theme.gray0}
        >
          {formatCurrency(amount)}
        </Box>
      </Box>

      <Button
        id="go-to-transfer-money-btn"
        fullWidth
        mode={ButtonMode.Block}
        onClick={goToTransferMoney}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <TransferIcon />
            <Box ml={2}>{t('home.account.balance_go_to_transfer_money')}</Box>
          </Box>
          <ChevronRightIcon />
        </Box>
      </Button>
    </Box>
  );
};
