import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import tokenHandler from '@epi-helpers/refreshTokenHelper';
import { AppProvider } from './scripts/AppProvider';
import { reduxStore } from './scripts/store-configuration';
import { root as rootSaga } from './scripts/sagas/root';
import '@epi-helpers/I18n';

tokenHandler(5000);
//@ts-ignore
reduxStore.runSaga(rootSaga);

const render = RootElement => {
  ReactDOM.render(<RootElement />, document.getElementById('app'));
};

render(AppProvider);
