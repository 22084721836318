import { put, call } from 'redux-saga/effects';
import { getVoucherOrderByPayment } from '../../repositories/VoucherOrdersRepository';
import { ApiConstants } from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';
import { getVoucherById } from './apiHelpers';

export default function* loadVoucherOrderByPayment({ paymentId }) {
  try {
    yield put({
      type: `${ApiConstants.CREATE_VOUCHER_ORDER}_CLEAR`
    });
    const voucherOrder = yield call(getVoucherOrderByPayment, paymentId);

    const voucher = yield call(getVoucherById, voucherOrder.voucherId);

    yield put({
      type: `${ApiConstants.LOAD_VOUCHER_ORDER_BY_PAYMENT}_SUCCESS`,
      payload: {
        voucherOrder: {
          ...voucherOrder,
          voucherTypeKey: voucher.type
        },
        paymentId
      }
    });
  } catch (ex) {
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    }
    yield put({
      type: `${ApiConstants.LOAD_VOUCHER_ORDER_BY_PAYMENT}_FAILURE`,
      payload: ex
    });
  }
}
