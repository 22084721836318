import {
  take,
  takeLatest,
  takeEvery,
  call,
  all,
  fork,
  cancel,
  spawn
} from 'redux-saga/effects';
import { matchPath } from 'react-router-dom';
import performance, { MEASURES } from '../helpers/PerformanceHelper';
import { VoucherTypes, getVoucherTypeKey } from '../constants/voucherTypes';
import { removeLocale } from '../helpers/UrlHelpers';
import { routerPaths } from '../constants/routerPaths';
import {
  ApiConstants,
  NavigationConstants,
  VoucherReorderConstants
} from '../constants/actions';

const lunchType = getVoucherTypeKey(VoucherTypes.Lunch);
const recreationalType = getVoucherTypeKey(VoucherTypes.Recreational);
const pagesToMeasure = {
  [routerPaths.home]: MEASURES.HOME,
  [routerPaths.orderHistory]: MEASURES.ORDER_HISTORY,
  [routerPaths.signUp]: MEASURES.SIGN_UP,
  [routerPaths.voucherOrder[lunchType][0]]: MEASURES.VOUCHER_QUANTITY,
  [routerPaths.voucherOrder[recreationalType][0]]: MEASURES.VOUCHER_QUANTITY,
  [routerPaths.voucherOrder[lunchType][1]]: MEASURES.ORDER_SUMMARY,
  [routerPaths.voucherOrder[recreationalType][1]]: MEASURES.ORDER_SUMMARY,
  [routerPaths.voucherOrder[lunchType][2]]: MEASURES.PAYMENT_METHOD,
  [routerPaths.voucherOrder[recreationalType][2]]: MEASURES.PAYMENT_METHOD,
  [routerPaths.voucherReorder]: MEASURES.REORDER_SUMMARY
};

function* endMeasurement(actionsToAwait, actionName, measureName) {
  const task = yield take(a =>
    new RegExp(`${actionName}_SUCCESS$|_FAILURE$|_ABORT$`).test(a.type)
  );
  const index = actionsToAwait.indexOf(actionName);
  if (index > -1) {
    actionsToAwait.splice(index, 1);
  }
  if (actionsToAwait.length === 0) {
    if (/_FAILURE$/.test(task.type)) {
      return yield call(performance.errorOn, measureName);
    }
    return yield call(performance.stop, measureName);
  }
  return null;
}

function* waitForActionEnd(payload, action) {
  const actionNameBody = action.type.slice(0, -8);
  payload.actionsToAwait.push(actionNameBody);
  yield spawn(
    endMeasurement,
    payload.actionsToAwait,
    actionNameBody,
    payload.measureName
  );
}

function* performTakeEveryUntil(
  startActionType,
  stopActionType,
  sagaGenerator,
  measureName
) {
  const actionsToAwait = [];
  const takeEveryTask = yield takeEvery(startActionType, sagaGenerator, {
    actionsToAwait,
    measureName
  });
  yield take(stopActionType);
  yield cancel(takeEveryTask);
  if (actionsToAwait.length === 0) {
    yield call(performance.stop, measureName);
  }
}

function* takeEveryUntil(
  startActionType,
  stopActionType,
  sagaGenerator,
  measureName
) {
  return yield fork(
    performTakeEveryUntil,
    startActionType,
    stopActionType,
    sagaGenerator,
    measureName
  );
}

export function* startOpenPage(action) {
  const path = removeLocale(action.path);
  const route = Object.keys(pagesToMeasure).find(item =>
    matchPath(removeLocale(path), { path: item, exact: true })
  );
  if (route) {
    const measure = pagesToMeasure[route];
    yield call(performance.start, measure);
    yield takeEveryUntil(
      awaitedAction => /_REQUEST$/.test(awaitedAction.type),
      NavigationConstants.OPEN_PAGE_EVENT_END,
      waitForActionEnd,
      measure
    );
  }
}

export function* stopSubmitOrder() {
  const measure = performance.getActiveMeasure();
  if (
    [
      MEASURES.SUBMIT_ORDER,
      MEASURES.SUBMIT_REORDER,
      MEASURES.SUBMIT_ORDER_ONLINE_PAYMENT
    ].includes(measure)
  ) {
    try {
      yield call(performance.stop, measure);
    } catch (ex) {
      yield call(performance.errorOn, measure);
    }
  }
}

export function* startSubmitReorder() {
  yield call(performance.start, MEASURES.SUBMIT_REORDER);
}

export function* startSubmitOrderOnlinePayment() {
  yield call(performance.start, MEASURES.SUBMIT_ORDER_ONLINE_PAYMENT);
}

export default function* watchPerformance() {
  yield all([
    takeLatest(`${NavigationConstants.OPEN_PAGE_EVENT_START}`, startOpenPage),
    takeLatest(`${ApiConstants.GET_PAYMENT_STATUS}_FAILURE`, stopSubmitOrder),
    takeLatest(`${ApiConstants.GET_PAYMENT_STATUS}_SUCCESS`, stopSubmitOrder),
    takeLatest(`${VoucherReorderConstants.SUBMIT_REORDER}`, startSubmitReorder),
    takeLatest(
      `${ApiConstants.GET_PAYMENT_STATUS}_REQUEST`,
      startSubmitOrderOnlinePayment
    )
  ]);
}
