import React, { useEffect } from 'react';
import { Row, Card, Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonSize } from 'edenred-ui';

import { OrderType } from '@epi-constants/orderType';
import { getCompanyInvoicePFD } from '@epi-selectors/companyAccounts';

import {
  getApiPaymentStatus,
  isNewCustomer as isNewCustomerSelector
} from '../../selectors/api';
import { routerPaths } from '../../constants/routerPaths';
import { PaymentStatuses } from '../../constants/paymentStatuses';
import { PaymentMethods } from '../../constants/paymentMethods';
import { NotFound } from '../NotFound/NotFound';
import { LoadingContainer } from '../../components/LoadingContainer/LoadingContainer';
import * as apiActions from '../../actions/api';
import * as navigationActions from '../../actions/navigation';
import './OrderStatus.scss';
import { CardOrderSuccessfulScreen } from './CardOrderSuccessfulScreen';
import { VoucherSuccessfulScreen } from './VoucherSuccessfulScreen';
import { PDFInvoiceSuccess } from './PDFInvoiceSuccess';

function OrderStatus({
  isNewCustomer,
  goToPage,
  paymentOnlyFlow,
  orderType: propsOrderType,
  isDeposit,
  apiPaymentStatus: {
    status,
    forwardEmail,
    paymentMethod,
    isLoading,
    isUnauthorized,
    orderType: apiOrderType
  },
  match: {
    params: { paymentId }
  },
  getPaymentStatus
}) {
  const { t } = useTranslation();

  const orderType = apiOrderType || propsOrderType;
  const isPrintInvoicePdfForTransferMoney = useSelector(getCompanyInvoicePFD);

  useEffect(() => {
    getPaymentStatus(paymentId);
  }, []);

  const redirectionUrl = () => {
    if (orderType === OrderType.digital) {
      return isDeposit
        ? routerPaths.loadCompanyAccount
        : routerPaths.cardOrderPayment;
    }
    return routerPaths.voucherOrderPayment;
  };

  function getTranslationPath(value, paid = false) {
    let after = value ? `.${value}` : '';
    if (orderType === OrderType.digital) {
      const digiOrderType = isDeposit ? 'deposit_order' : 'card_order';
      after = paid && !isDeposit ? `${after}_payment` : after;
      const result = `containers.order_status.${orderType}.${digiOrderType}${after}`;
      return result;
    }
    return `containers.order_status.${orderType}${after}`;
  }

  const isPaidOnline = [
    PaymentMethods.CreditCard,
    PaymentMethods.Online
  ].includes(paymentMethod);

  const isPaymentSuccess =
    isPaidOnline &&
    [
      PaymentStatuses.Pending,
      PaymentStatuses.Paid,
      PaymentStatuses.Confirmed
    ].includes(status);

  const isSuccessful = !isPaidOnline || isPaymentSuccess;

  const isReceiptOnly = isPaidOnline && OrderType.voucher !== orderType;

  const successTemplate = () => {
    if (isPrintInvoicePdfForTransferMoney) {
      return <PDFInvoiceSuccess paymentId={paymentId} />;
    }

    if (orderType === OrderType.digital) {
      return (
        <CardOrderSuccessfulScreen
          isPaymentSuccess={isPaymentSuccess}
          getTranslationPath={getTranslationPath}
          paymentMethod={paymentMethod}
          paymentId={paymentId}
          isReceiptOnly={isReceiptOnly}
          goToPage={goToPage}
        />
      );
    }
    return (
      <VoucherSuccessfulScreen
        isPaymentSuccess={isPaymentSuccess}
        getTranslationPath={getTranslationPath}
        paymentMethod={paymentMethod}
        forwardEmail={forwardEmail}
        isReceiptOnly={isReceiptOnly}
        paymentId={paymentId}
        isNewCustomer={isNewCustomer}
        paymentOnlyFlow={paymentOnlyFlow}
        goToPage={goToPage}
      />
    );
  };

  const canceledTemplate = () => {
    const buttonText = orderType === OrderType.digital ? 'digital' : 'voucher';
    return (
      <Card>
        <Card.Body>
          <Card.Title as="span">
            <h1 className="text">{t(getTranslationPath('title_failed'))}</h1>
          </Card.Title>
          <Row>
            <Col xs={11}>
              <h2 className="small">{t(getTranslationPath('body_failed'))}</h2>
              {isNewCustomer && !paymentOnlyFlow ? (
                <div>
                  <h2 className="small">
                    {t('containers.order_status.new_customer_failed')}
                  </h2>
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 1, offset: 11 }}>
              <Button
                id="back-to-payment-method-btn"
                size={ButtonSize.Medium}
                className="float-right"
                onClick={() =>
                  goToPage(redirectionUrl(), {
                    params: { paymentId }
                  })
                }
              >
                {t(`containers.order_status.button_failed_${buttonText}`)}
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    );
  };

  return (
    <div className="OrderStatus">
      {isUnauthorized ? (
        <Route component={NotFound} />
      ) : (
        <div>
          <LoadingContainer isLoading={isLoading}>
            {isSuccessful && successTemplate()}
            {!isSuccessful && canceledTemplate()}
          </LoadingContainer>
        </div>
      )}
    </div>
  );
}

OrderStatus.propTypes = {
  isNewCustomer: PropTypes.bool.isRequired,
  apiPaymentStatus: PropTypes.any.isRequired,
  getPaymentStatus: PropTypes.func.isRequired,
  goToPage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  paymentOnlyFlow: PropTypes.bool,
  orderType: PropTypes.string,
  isDeposit: PropTypes.bool
};

OrderStatus.defaultProps = {
  paymentOnlyFlow: false,
  isDeposit: false,
  orderType: ''
};

const mapStateToProps = state => ({
  apiPaymentStatus: getApiPaymentStatus(state),
  isNewCustomer: isNewCustomerSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...apiActions, ...navigationActions }, dispatch);

const ConnectedOrderStatus = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderStatus);

export { ConnectedOrderStatus as OrderStatus };
