import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import React, { PureComponent } from 'react';
import { Route, Redirect, withRouter } from 'react-router-dom';
import {
  checkIfTokenExpires,
  userIsAuthorizedAndTokenIsValid
} from '../helpers/AuthorizeHelper';
import { hasRole as hasRoleSelector } from '../selectors/login';
import * as loginActions from '../actions/login';
import * as navigationActions from '../actions/navigation';

class TwoWays extends PureComponent {
  constructor(props) {
    super(props);
    this.authorizedComponent = this.authorizedComponent.bind(this);
  }

  componentDidMount() {
    const {
      login: { isAuthenticated, validTo },
      getLogoutAction
    } = this.props;
    if (isAuthenticated && checkIfTokenExpires(validTo)) {
      getLogoutAction(true);
    }
  }

  authorizedComponent = (props, redirect) => {
    const { login, authorized } = this.props;
    return userIsAuthorizedAndTokenIsValid(login) ? (
      React.cloneElement(authorized, props)
    ) : (
      <Redirect
        to={{
          pathname: redirect
        }}
      />
    );
  };

  render() {
    const {
      // eslint-disable-next-line no-unused-vars
      authorized,
      unauthorized,
      redirect,
      login: { isAuthenticated, validTo },
      ...rest
    } = this.props;
    if (!isAuthenticated) {
      checkIfTokenExpires(validTo);
    }

    return (
      <Route
        {...rest}
        render={props =>
          isAuthenticated
            ? this.authorizedComponent(props, redirect)
            : React.cloneElement(unauthorized, props)
        }
      />
    );
  }
}

TwoWays.propTypes = {
  login: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  unauthorized: PropTypes.node.isRequired,
  authorized: PropTypes.node.isRequired,
  redirect: PropTypes.string.isRequired,
  getLogoutAction: PropTypes.func.isRequired,
  withAnyRole: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  hasRole: PropTypes.bool.isRequired
};

TwoWays.defaultProps = {
  withAnyRole: []
};

const mapStateToProps = (state, props) => ({
  ...pick(state, ['login']),
  hasRole: hasRoleSelector(props.withAnyRole)(state)
});
const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions, ...loginActions }, dispatch);

const ConnectedTwoWays = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(TwoWays));

export { ConnectedTwoWays as TwoWays };
