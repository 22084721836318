import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Notification, NotificationType, Box } from 'edenred-ui';

import config from '@epi-config';
import { getIsAuthenticated } from '@epi-selectors/login';
import { useTheme } from 'styled-components';

const {
  notificationBannerStartDate,
  notificationBannerEndDate,
  showNotificationBanner
} = config;

export const NotificationInfoBanner = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [showNotification, setShowNotification] = useState(false);
  const isAuthenticated = useSelector(getIsAuthenticated);

  useEffect(() => {
    const todayTime = new Date().getTime();
    const validMessageTime = new Date('2024-06-14').getTime();
    setShowNotification(validMessageTime > todayTime);
  }, []);

  return isAuthenticated &&
    (Boolean(showNotificationBanner) || showNotification) ? (
    <Box m={4} mb={-1}>
      <Notification
        type={NotificationType.Important}
        text={
          showNotificationBanner ? (
            t('shared.banner.information', {
              startDate: notificationBannerStartDate,
              endDate: notificationBannerEndDate
            })
          ) : (
            <>
              <Box fontWeight={theme.fontBold}>
                {t('maintenance_notification.title')}
              </Box>
              <Box>{t('maintenance_notification.text')}</Box>
            </>
          )
        }
        onClose={() => setShowNotification(false)}
      />
    </Box>
  ) : null;
};
