import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useFormik } from 'formik';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import {
  InputComponent as Input,
  Notification,
  NotificationType,
  SimpleButton,
  SlideoutPanelComponent as Slideout,
  EditIcon,
  Box
} from 'edenred-ui';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';

import config from '@epi-config';
import { getLoginState } from '@epi-selectors/login';
import { LUNCH, RECREATIONAL } from '@epi-constants/benefitTypes';

import AsyncEmailField from '../../../../components/AsyncValidatedEmailField/AsyncEmailField';
import { FieldWrap } from '../../../../components/LayoutUtils/FieldWrap';
import Row from '../../../../components/LayoutUtils/Row';
import {
  getDifferenceInDays,
  hasDatePassed,
  parseStringToDate
} from '../../../../helpers/dateHelper';
import { isEmptyObj } from '../../../../helpers/isEmptyObj';
import { SectionHeader } from '../../BeneficiariesTableStyles';
import BenefitsSection from '../BenefitsSection/BenefitsSection';
import { EndOfBenefit } from '../EndOfBenefit/EndOfBenefit';
import EndOfBenefitNotification from '../EndOfBenefit/EndOfBenefitNotification';
import EndOfFundsNotification from '../EndOfBenefit/EndOfFundsNotification';
import ResendActivationEmailSection from '../ResendActivationEmailSection/ResendActivationEmailSection';

import beneficiaryDatailValidationSchema from './BeneficiaryDetailValidationSchema';
import CloseDetailDialog from './CloseDetailDialog';
import { getBeneficiaryPayload } from '../../helpers/getBeneficiaryPayload';
import BeneficiaryDetailActions from './BeneficiaryDetailActions';
import {
  fieldWidth,
  labelWidth,
  SlideoutStyles,
  slideoutWidth,
  EditButtonContainer,
  EditButtonRelativeContainer
} from './BeneficiaryDetailSlideoutStyles';

const BeneficiaryDetailSlideout = ({
  expanded,
  setExpanded,
  beneficiary,
  editEmployeeAction,
  resendActivationEmail,
  reCaptchaSiteKey,
  currentlySetBenefitValues,
  editingMode,
  setEditingMode
}) => {
  const {
    personId,
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    personalId,
    address,
    endOfBenefit,
    endOfFunds,
    createdAt
  } = beneficiary;

  const initialEoB = parseStringToDate(endOfBenefit);
  const initialEoF = parseStringToDate(endOfFunds);
  const eobPeriod = useMemo(
    () => getDifferenceInDays(initialEoB, initialEoF),
    [endOfBenefit, endOfFunds]
  );
  const endOfFundsDatePassed = useMemo(
    () => !!hasDatePassed(initialEoF),
    [endOfFunds]
  );

  const { isUserImpersonate } = useSelector(getLoginState);
  const employeeDetailRef = useRef();
  const emailFieldRef = useRef();
  const [validationNotification, setValidationNotification] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [emailDNSvalidated, setEmailDNSValidated] = useState(false);
  const {
    values,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    errors,
    resetForm,
    setTouched,
    touched
  } = useFormik({
    initialValues: {
      personId,
      firstName,
      lastName,
      emailAddress,
      phoneNumber,
      personalId,
      addressLine: address.addressLine,
      postCode: address.postCode,
      city: address.city,
      endOfBenefit,
      endOfFunds: initialEoF
    },
    enableReinitialize: true,
    validationSchema: beneficiaryDatailValidationSchema(isUserImpersonate)
  });

  const shouldDisplayEndOfBenefitNotification =
    !touched.endOfBenefit && eobPeriod && !endOfFundsDatePassed;
  const endOfBenefitDate = values.endOfBenefit
    ? format(new Date(values.endOfBenefit), 'yyyy-MM-dd')
    : '';

  useEffect(() => {
    if (isEmptyObj(errors)) {
      setValidationNotification(false);
    }
  }, [errors]);

  const handleClose = () => {
    if (isEmptyObj(errors) && !editingMode) {
      setExpanded(false);
    } else {
      setShowCloseConfirmation(true);
    }
  };

  const editEmployee = () => {
    setEditingMode(!editingMode);
    return editEmployeeAction(getBeneficiaryPayload(values));
  };

  const handleEditEmployee = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      const onSuccess = () => {
        setTouched({});
        resolve();
      };
      if (!isEmptyObj(errors)) {
        setValidationNotification(true);
        resolve();
        return;
      }
      const validateEmailDNS = 'emailAddress' in touched && !emailDNSvalidated;
      if (!validateEmailDNS) {
        editEmployee().then(() => onSuccess());
      } else {
        const validationResult = await emailFieldRef.current
          .forceEmailDNSValidation()
          .catch(() => {
            setValidationNotification(true);
            resolve();
          });
        if (validationResult === 'success') {
          editEmployee().then(() => onSuccess());
        }
        if (validationResult === 'validation_in_progress') {
          resolve();
        }
      }
    });
  };

  const handleSaveAction = () => {
    if (isEmptyObj(touched)) {
      setEditingMode(false);
    } else {
      handleEditEmployee();
    }
  };

  const confirmCloseWithoutSaving = () => {
    setShowCloseConfirmation(false);
    resetForm();
    setEditingMode(false);
    setExpanded(false);
  };

  const confirmSaving = () => {
    setShowCloseConfirmation(false);
    handleEditEmployee();
  };

  const handleFieldValueChange = (name, value) => {
    setFieldTouched(name, true);
    setFieldValue(name, value);
  };

  const usersBenefitValues = {
    cardLunch:
      beneficiary?.benefitGroupSettings
        ?.filter(group => group.benefitType === LUNCH)
        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))[0]
        ?.maxAmountPerPeriod || null,
    cardRecreational:
      beneficiary?.benefitGroupSettings
        ?.filter(group => group.benefitType === RECREATIONAL)
        .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))[0]
        ?.maxAmountPerPeriod || null
  };

  return (
    <div ref={employeeDetailRef}>
      <SlideoutStyles validationWarning={validationNotification}>
        <Slideout
          title={i18n.t('containers.beneficiaries.detail.title')}
          actions={
            editingMode && (
              <BeneficiaryDetailActions
                mainAction={handleSaveAction}
                mainActionText={i18n.t('containers.beneficiaries.detail.save')}
                disabled={endOfFundsDatePassed}
              />
            )
          }
          expanded={expanded}
          onClose={handleClose}
          width={slideoutWidth}
          closeButtonId="close-beneficiary-slideout"
        >
          <form onSubmit={handleSubmit}>
            {validationNotification && (
              <div
                className="fix_validation_warning"
                data-test="fix_validation_warning"
              >
                <Notification
                  type={NotificationType.Error}
                  text={i18n.t(
                    'containers.beneficiaries.detail.resolve_validation'
                  )}
                />
              </div>
            )}
            {shouldDisplayEndOfBenefitNotification && (
              <EndOfBenefitNotification
                endOfBenefit={values.endOfBenefit}
                eobPeriod={eobPeriod}
              />
            )}
            {!editingMode && !endOfFundsDatePassed && (
              <EditButtonRelativeContainer>
                <EditButtonContainer data-test="edit-employee-button">
                  <SimpleButton
                    text={i18n.t('containers.beneficiaries.detail.edit')}
                    icon={<EditIcon />}
                    disabled={endOfFundsDatePassed}
                    onClick={() => setEditingMode(true)}
                    id="beneficiary_edit"
                  />
                </EditButtonContainer>
              </EditButtonRelativeContainer>
            )}
            {endOfFundsDatePassed && <EndOfFundsNotification />}
            {config.enableVirikeBenefit ? (
              (usersBenefitValues.cardLunch ||
                usersBenefitValues.cardRecreational) && (
                <BenefitsSection
                  currentlySetBenefitValues={usersBenefitValues}
                />
              )
            ) : (
              <BenefitsSection
                currentlySetBenefitValues={currentlySetBenefitValues}
              />
            )}
            <SectionHeader>
              {i18n.t('containers.beneficiaries.detail.personal_information')}
            </SectionHeader>
            <Row>
              <FieldWrap width={fieldWidth}>
                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={i18n.t('containers.beneficiaries.table.first_name')}
                  onValueChange={v => handleFieldValueChange('firstName', v)}
                  value={values.firstName}
                  disabled={!editingMode}
                  errors={errors.firstName}
                  data-test="firstName"
                />
                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={i18n.t('containers.beneficiaries.table.last_name')}
                  onValueChange={v => handleFieldValueChange('lastName', v)}
                  value={values.lastName}
                  disabled={!editingMode}
                  errors={errors.lastName}
                  data-test="lastName"
                />
                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={i18n.t('containers.beneficiaries.detail.personal_id')}
                  value={values.personalId}
                  disabled
                  data-test="personalId"
                />
              </FieldWrap>
            </Row>
            <SectionHeader>
              {i18n.t('containers.beneficiaries.detail.contact_details')}
            </SectionHeader>
            {!endOfFundsDatePassed && (
              <ResendActivationEmailSection
                editingMode={editingMode}
                resendActivationEmail={resendActivationEmail}
                personId={personId}
              />
            )}
            <Row>
              <FieldWrap width={fieldWidth}>
                <AsyncEmailField
                  ref={emailFieldRef}
                  emailAddress={values.emailAddress}
                  disabled={!editingMode}
                  errors={errors}
                  onValueChange={v => {
                    setEmailDNSValidated(false);
                    handleFieldValueChange('emailAddress', v);
                  }}
                  labelWidth={labelWidth}
                  labelText={i18n.t('containers.beneficiaries.table.email')}
                  onValidationSuccess={() => {
                    setEmailDNSValidated(true);
                    setFieldTouched('emailAddress', false);
                  }}
                  onValidationFailure={() => {
                    setEmailDNSValidated(false);
                    setFieldError(
                      'emailAddress',
                      i18n.t(
                        'containers.beneficiaries.detail.email_doesnt_exist'
                      )
                    );
                  }}
                  reCaptchaSiteKey={reCaptchaSiteKey}
                />
                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={i18n.t('containers.beneficiaries.detail.phone')}
                  onValueChange={v => handleFieldValueChange('phoneNumber', v)}
                  value={values.phoneNumber}
                  disabled={!editingMode}
                  errors={errors.phoneNumber}
                  data-test="phoneNumber"
                />
                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={i18n.t('containers.beneficiaries.detail.address')}
                  onValueChange={v => handleFieldValueChange('addressLine', v)}
                  value={values.addressLine}
                  disabled={!editingMode}
                  errors={errors.addressLine}
                  data-test="addressLine"
                />

                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={i18n.t('containers.beneficiaries.detail.post_code')}
                  onValueChange={v => handleFieldValueChange('postCode', v)}
                  value={values.postCode}
                  disabled={!editingMode}
                  errors={errors.postCode}
                  data-test="postCode"
                />

                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={i18n.t('containers.beneficiaries.detail.city')}
                  onValueChange={v => handleFieldValueChange('city', v)}
                  value={values.city}
                  disabled={!editingMode}
                  errors={errors.city}
                  data-test="city"
                />
              </FieldWrap>
            </Row>
            <SectionHeader>
              {i18n.t(
                'containers.beneficiaries.detail.employee_settings_section.name'
              )}
            </SectionHeader>
            <FieldWrap width={fieldWidth}>
              <Input
                labelPositioning="side"
                labelWidth={labelWidth}
                label={i18n.t(
                  'containers.beneficiaries.detail.employee_settings_section.benefit_start_date'
                )}
                value={createdAt}
                id="benefit-start-date"
                disabled
              />
            </FieldWrap>
            {editingMode ? (
              <Row>
                <EndOfBenefit
                  endOfBenefitDate={
                    values.endOfBenefit ? new Date(values.endOfBenefit) : null
                  }
                  handleFieldValueChange={handleFieldValueChange}
                  disabled={!editingMode}
                  endOfBenefitEditable={!endOfFundsDatePassed}
                  initialEoB={initialEoB}
                  eobPeriod={eobPeriod}
                />
              </Row>
            ) : (
              <FieldWrap width={fieldWidth}>
                <Box pt={'20px'}>
                  <Input
                    labelPositioning="side"
                    labelWidth={labelWidth}
                    label={i18n.t(
                      'containers.beneficiaries.detail.end_of_benefit'
                    )}
                    value={endOfBenefitDate}
                    id="benefit-end-date"
                    disabled
                  />
                </Box>
              </FieldWrap>
            )}
          </form>
          <CloseDetailDialog
            isOpen={showCloseConfirmation}
            closeAction={() => setShowCloseConfirmation(false)}
            primaryAction={confirmCloseWithoutSaving}
            primaryActionLabel={i18n.t(
              'containers.beneficiaries.close_detail_dialog.close_without_saving'
            )}
            secondaryAction={confirmSaving}
            secondaryActionLabel={i18n.t(
              'containers.beneficiaries.close_detail_dialog.save'
            )}
            title={i18n.t('containers.beneficiaries.close_detail_dialog.title')}
            content={i18n.t(
              'containers.beneficiaries.close_detail_dialog.content'
            )}
            i18n={i18n}
          />
        </Slideout>
      </SlideoutStyles>
    </div>
  );
};

export default BeneficiaryDetailSlideout;

BeneficiaryDetailSlideout.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  beneficiary: PropTypes.object.isRequired,
  editEmployeeAction: PropTypes.func.isRequired,
  resendActivationEmail: PropTypes.object.isRequired,
  currentlySetBenefitValues: PropTypes.object,
  reCaptchaSiteKey: PropTypes.string,
  editingMode: PropTypes.bool.isRequired,
  setEditingMode: PropTypes.func.isRequired
};

BeneficiaryDetailSlideout.defaultProps = {
  currentlySetBenefitValues: null,
  reCaptchaSiteKey: null
};
