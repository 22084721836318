// @ts-nocheck
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Pagination as UiPagination, PaginationProps } from 'edenred-ui';

/* eslint-disable react/prop-types */

export function Pagination(props: PaginationProps) {
  const { rowsPerPageOptions } = props;
  const { t } = useTranslation();

  return (
    <UiPagination
      prevBtnText={t('shared.pagination.previous')}
      nextBtnText={t('shared.pagination.next')}
      rowsPerPageText={
        rowsPerPageOptions && t('shared.pagination.rows_per_page')
      }
      {...props}
    />
  );
}
