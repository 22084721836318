import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { postRequest, getRequest } from './ApiRepository';

export function* createOrder(orderData) {
  return yield call(
    postRequest,
    `${config.apiHost}/api/voucher-orders`,
    Authorize.IfLogged,
    orderData
  );
}

export function* getVoucherOrder(voucherOrderId) {
  return yield call(
    getRequest,
    `${config.apiHost}/api/voucher-orders/${voucherOrderId}`,
    Authorize.Always
  );
}

export function* getVoucherOrders(options) {
  return yield call(
    getRequest,
    `${config.apiHost}/api/voucher-orders`,
    Authorize.Always,
    { data: options }
  );
}

export function* getVoucherOrderByPayment(paymentId) {
  return yield call(
    getRequest,
    `${config.apiHost}/api/voucher-order-payments/${paymentId}`,
    Authorize.IfLogged
  );
}

export function* addNewPaymentToVoucher(paymentData) {
  return yield call(
    postRequest,
    `${config.apiHost}/api/voucher-order-payments`,
    Authorize.IfLogged,
    paymentData
  );
}
