import React from 'react';
import { useTheme } from 'styled-components';
import { Box, Text, Button, TitleText, TitleType } from 'edenred-ui';

import { IEmptyState } from '@epi-models/shared';

export function EmptyState({
  icon,
  title,
  description,
  btnText,
  btnOnClick,
  btnDisabled = false,
  children
}: IEmptyState) {
  const { fontSemiBold, gray85, sizeExtraExtraLarge } = useTheme();

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box mt={5.5}>{icon}</Box>
      <Box mt={6}>
        <TitleText
          type={TitleType.h1}
          fontWeight={fontSemiBold}
          noMargin
          color={gray85}
          fontSize={sizeExtraExtraLarge}
        >
          {title}
        </TitleText>
      </Box>
      {description && (
        <Box mt={2}>
          <Text>{description}</Text>
        </Box>
      )}
      {btnText && (
        <Box mt={5}>
          <Button
            id="empty-state-confirm-btn"
            onClick={btnOnClick}
            disabled={btnDisabled}
          >
            {btnText}
          </Button>
        </Box>
      )}
      {children}
    </Box>
  );
}
