import { createSelector } from 'reselect';

const transactionEstimationSelector = state => state.apiTransactionEstimation;

export const originalTotalSuggestedTransferSelector = createSelector(
  transactionEstimationSelector,
  state => state.originalTotalSuggestedTransfer
);

export const estimationSelector = createSelector(
  transactionEstimationSelector,
  state => state.estimation
);

export const isTransferSuggestedSelector = createSelector(
  estimationSelector,
  estimation => !!estimation && estimation.totalSuggestedTransfer > 0
);
