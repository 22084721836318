import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { FormControlLabel, Checkbox } from 'edenred-ui';

import { createRules, FormFields } from '@epi-forms/helpers';
import {
  required,
  termsAndConditionsRequired
} from '@epi-helpers/formHelpers/validators';

export function AcceptTermsCheckbox() {
  const name = FormFields.AcceptTerms;
  const { control } = useFormContext();
  const { t } = useTranslation();
  const validators = [required, termsAndConditionsRequired];
  const rules = createRules(validators);

  const {
    field: { value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={value}
            id={name}
            onChange={setValue}
            error={!!error}
          />
        }
        label={
          <Trans
            defaults={t('forms.terms_and_conditions.terms_label')}
            components={{
              terms: (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t('forms.terms_and_conditions.terms_link_url')}
                >
                  &nbsp;
                </a>
              ),
              price: (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={t('forms.terms_and_conditions.price_list_link_url')}
                >
                  &nbsp;
                </a>
              )
            }}
          />
        }
      />
      {error && <p className="error-message">{error?.message}</p>}
    </>
  );
}
