import { formatCurrencyDecimal } from '@epi-helpers/numeral';
import PropTypes from 'prop-types';
import React from 'react';
import { Checkbox } from 'edenred-ui';

function EstimationDetails({
  amount,
  text,
  message,
  hideIfZero,
  toggable = false,
  checked = false,
  setChecked,
  id,
  action,
  month
}) {
  if (amount <= 0 && hideIfZero) {
    return null;
  }
  const dataTestSuffix = month ? `-${month}` : '';
  return (
    <div
      className="estimation-details-row"
      onClick={() => {
        if (action !== null) {
          action();
        }
      }}
    >
      <div className="estimation-details-row-text">
        {toggable && (
          <Checkbox
            inputProps={{
              'data-test': `${text}-checkbox${dataTestSuffix}`
            }}
            checked={checked}
            onChange={() => setChecked(id)}
          />
        )}
        {text}
      </div>
      <div className="estimation-details-row-message">{message}</div>
      <div
        className={`estimation-details-row-amount ${
          toggable && !checked && 'estimation-details-row-amount--linethrough'
        }`}
      >
        {formatCurrencyDecimal(amount)}
      </div>
    </div>
  );
}

EstimationDetails.propTypes = {
  amount: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  message: PropTypes.string,
  hideIfZero: PropTypes.bool,
  toggable: PropTypes.bool,
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  id: PropTypes.string,
  action: PropTypes.func,
  month: PropTypes.string
};

EstimationDetails.defaultProps = {
  message: null,
  hideIfZero: false,
  toggable: false,
  checked: false,
  setChecked: () => {},
  id: null,
  action: null,
  month: null
};

export default EstimationDetails;
