import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { SortDownIcon, SortUpIcon } from 'edenred-ui';

function SortIndicator({ descending }) {
  return descending ? <SortDownIcon /> : <SortUpIcon />;
}

SortIndicator.propTypes = {
  descending: PropTypes.bool
};

SortIndicator.defaultProps = {
  descending: true
};

function SortingButtonComponent({
  children,
  sortBy,
  sortedBy,
  sortDirection,
  onSortingChange,
  ...otherProps
}) {
  return (
    <Button
      variant="link"
      onClick={() => {
        onSortingChange(sortBy, !sortDirection);
      }}
      {...otherProps}
    >
      {children}
      {sortBy === sortedBy ? (
        <SortIndicator descending={sortDirection} />
      ) : null}
    </Button>
  );
}
SortingButtonComponent.propTypes = {
  sortBy: PropTypes.string.isRequired,
  sortedBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.bool.isRequired,
  onSortingChange: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
};

SortingButtonComponent.defaultProps = { children: '' };

export default SortingButtonComponent;
