import * as React from 'react';
import { connect } from 'react-redux';
import { getLoginState } from '../selectors/login';
import { get, post, put } from '../helpers/FetchHelpers';

function getHeaders(store) {
  const loginState = getLoginState(store);
  console.log('loginState.isAuthenticated', loginState.isAuthenticated);
  if (loginState.isAuthenticated) {
    const header = {
      Authorization: `${loginState.tokenType} ${loginState.accessToken}`
    };

    return header;
  }

  return {};
}

const storeToState = connect(store => ({
  fetchGet: (url, abortController) => {
    const header = getHeaders(store);

    return get(url, null, header, abortController);
  },
  fetchPost: (url, data, abortController) => {
    const header = getHeaders(store);

    return post(url, data, header, abortController);
  },
  fetchPut: (url, data, abortController) => {
    const header = getHeaders(store);

    return put(url, data, header, abortController);
  }
}));

export default function withAjax() {
  return OriginalComponent => {
    return storeToState(props => {
      return <OriginalComponent {...props} />;
    });
  };
}
