import { format, isAfter, isSameDay, isToday } from 'date-fns';
import { DatePickerComponent, Box, Text, Tooltip, InfoIcon } from 'edenred-ui';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css, useTheme } from 'styled-components';

const today = new Date();

const EndOfBenefitDescription = styled.div`
  height: 150%;
  span:focus {
    outline: none;
  }
  p {
    margin: 1rem 0 10rem;
    height: 17px;
  }
  button {
    border: none;
    background-color: transparent;
    color: ${props => props.theme.primaryBackColorLight};
    font-weight: 500;
    margin-right: 1rem;
    margin-bottom: 1rem;
    outline: none;
    cursor: pointer;
  }
  ${props => {
    if (props.full) {
      return css`
        p {
          display: inline;
        }
      `;
    }
    return css`
      p {
        width: 200px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        margin: 0 0;
      }
      button {
        position: relative;
        bottom: 0.5rem;
        margin: 0 0;
        padding-left: 0px;
      }
    `;
  }}
`;

const DatePickerContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  .input-button {
    margin-left: -3.5rem;
  }
`;

export const EndOfBenefit = ({
  endOfBenefitDate,
  handleFieldValueChange,
  endOfBenefitEditable,
  disabled = false,
  initialEoB
}) => {
  const theme = useTheme();
  const disableDates = d => {
    return isAfter(today, d) && !isToday(d) && !isSameDay(d, initialEoB);
  };

  const [showFullDesc, setShowFullDesc] = useState(false);

  const handleShowDescription = () => {
    setShowFullDesc(!showFullDesc);
  };

  const description = showFullDesc
    ? i18n.t('containers.beneficiaries.detail.show_less_description')
    : i18n.t('containers.beneficiaries.detail.show_more_description');

  return (
    <div>
      <DatePickerContainer>
        <Box pt={1} display="flex" alignItems="center">
          <DatePickerComponent
            data-test="benefit-end-date"
            disableFunction={disableDates}
            placeholder={i18n.t('containers.beneficiaries.detail.choose_date')}
            date={endOfBenefitDate}
            onDateChanged={newDate => {
              handleFieldValueChange(
                'endOfBenefit',
                newDate ? format(newDate, 'yyyy-MM-dd') : null
              );
            }}
            label={i18n.t('containers.beneficiaries.detail.end_of_benefit')}
            labelPositioning="side"
            labelWidth="170px"
            inputWidth="200px"
            disabled={disabled}
          />
          {!endOfBenefitEditable && (
            <span className="secondaryInfo">
              {i18n.t('containers.beneficiaries.detail.date_not_editable')}
            </span>
          )}
          <Tooltip
            arrow
            boxShadow="strong"
            placement="top"
            title={
              <Box maxWidth={274} p={2}>
                <Text small>
                  {i18n.t(
                    'containers.beneficiaries.detail.end_of_benefit_info'
                  )}
                </Text>
              </Box>
            }
          >
            <Box>
              <InfoIcon color={theme.primaryFrontColor} />
            </Box>
          </Tooltip>
        </Box>
      </DatePickerContainer>
      <EndOfBenefitDescription
        full={showFullDesc}
        className="secondaryInfo labelWidthMargin"
      >
        <Trans
          defaults={i18n.t('containers.beneficiaries.detail.eob_description')}
        />
        <button type="button" onClick={handleShowDescription}>
          {description}
        </button>
      </EndOfBenefitDescription>
    </div>
  );
};

EndOfBenefit.propTypes = {
  endOfBenefitDate: PropTypes.instanceOf(Date),
  handleFieldValueChange: PropTypes.func.isRequired,
  endOfBenefitEditable: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  initialEoB: PropTypes.instanceOf(Date)
};

EndOfBenefit.defaultProps = {
  endOfBenefitDate: null,
  initialEoB: today,
  disabled: false
};
