import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';
import { DeliveryMethods } from '../../constants/deliveryMethods';

export const getInitialState = () => ({
  voucherOrder: {},
  paymentId: '',
  isLoaded: false,
  isError: false,
  noPersistance: true
});

const renameAddressProperty = voucherOrder => {
  const delivery = voucherOrder.delivery || {};
  if (delivery.method === DeliveryMethods.ToOtherAddress) {
    return {
      ...voucherOrder,
      delivery: {
        ...delivery,
        custom_address: delivery.address,
        address: null
      }
    };
  }
  return voucherOrder;
};

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_VOUCHER_ORDER_BY_PAYMENT}_REQUEST`]: () => ({
    voucherOrder: {},
    paymentId: '',
    isLoaded: false,
    isError: false
  }),

  [`${ApiConstants.LOAD_VOUCHER_ORDER_BY_PAYMENT}_SUCCESS`]: (
    state,
    { payload: { paymentId, voucherOrder } }
  ) => ({
    voucherOrder: renameAddressProperty(voucherOrder),
    paymentId,
    isLoaded: true,
    isError: false
  }),
  [`${ApiConstants.LOAD_VOUCHER_ORDER_BY_PAYMENT}_FAILURE`]: () => ({
    voucherOrder: {},
    paymentId: '',
    isLoaded: true,
    isError: true
  })
});
