import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, BenefitTileType } from 'edenred-ui';

import { BenefitsCategory } from './BenefitsCategory';
import { useBenefits } from '../../hooks';

export const Benefits = () => {
  const { t } = useTranslation();
  const { isActiveBenefitsLoaded, activeBenefits, inactiveBenefits } =
    useBenefits();

  return (
    <Box display="flex" gap={3} mt={8}>
      {isActiveBenefitsLoaded && activeBenefits.length > 0 && (
        <BenefitsCategory
          titleType={t('home.benefits_tile.active')}
          buttonText={t('home.benefits_tile.button_manage_benefit')}
          benefits={activeBenefits}
        />
      )}
      {inactiveBenefits.length > 0 && (
        <BenefitsCategory
          titleType={t('home.benefits_tile.inactive')}
          buttonText={t('home.benefits_tile.button_active')}
          benefits={inactiveBenefits}
          type={BenefitTileType.inactive}
        />
      )}
    </Box>
  );
};
