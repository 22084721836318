import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { PagePanel, Box, Button, ButtonMode } from 'edenred-ui';

import LunchPaperVouchers from '@epi-assets/images/LunchPaperVouchers.jpg';
import RecreationalPaperVouchers from '@epi-assets/images/RecreationalPaperVouchers.png';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { registeredVoucherOrderAction } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.slice';

const orderTileBoxStyle = url => ({
  display: 'flex',
  alignItems: 'flex-end',
  width: '100%',
  height: '100%',
  background: `url(${url})`,
  backgroundSize: 'cover',
  padding: 24
});

function OrderVouchersPanel({ dispatch }) {
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(registeredVoucherOrderAction.restoreInitialValues());
  }, []);

  return (
    <PagePanel title={t('paper_vouchers.order_panel.title')}>
      <Box display="flex" height={320} mt={1}>
        <Box style={orderTileBoxStyle(LunchPaperVouchers)} mr={1.5}>
          <Button
            id="go-to-voucher-order-lunch"
            fullWidth
            mode={ButtonMode.Block}
            onClick={() => dispatch(goToPage(routerPaths.voucherOrderLunch))}
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {t('paper_vouchers.order_panel.order_lunch')}
              <i className="mdi mdi-24px mdi-chevron-right" />
            </Box>
          </Button>
        </Box>
        <Box style={orderTileBoxStyle(RecreationalPaperVouchers)} ml={1.5}>
          <Button
            id="go-to-voucher-order-recreational"
            fullWidth
            mode={ButtonMode.Block}
            onClick={() =>
              dispatch(goToPage(routerPaths.voucherOrderRecreational))
            }
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {t('paper_vouchers.order_panel.order_recreational')}
              <i className="mdi mdi-24px mdi-chevron-right" />
            </Box>
          </Button>
        </Box>
      </Box>
    </PagePanel>
  );
}

OrderVouchersPanel.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const ConnectedOrderVouchersPanel = connect()(OrderVouchersPanel);

export { ConnectedOrderVouchersPanel as OrderVouchersPanel };
