import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { FormFields, createRules } from '@epi-forms/helpers';
import {
  required,
  username,
  minLength,
  maxLength
} from '@epi-helpers/formHelpers/validators';

export function ResetPasswordField() {
  const name = FormFields.UsernameOrEmail;
  const { t } = useTranslation();
  const { control } = useFormContext();
  const maxLength50 = maxLength(50);
  const minLength6 = minLength(6);
  const validators = [required, username, minLength6, maxLength50];
  const rules = createRules(validators);

  const {
    field: { ref, value, onBlur, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      name={name}
      id={name}
      label={t('forms.reset_password.email_or_username')}
      inputRef={ref}
      onBlur={onBlur}
      onChange={setValue}
      value={value}
      error={!!error}
      helperText={error?.message}
    />
  );
}
