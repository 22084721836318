import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import {
  Button,
  ButtonSize,
  Box,
  CalculatorIcon,
  Accordion,
  IconTooltip,
  InfoIcon
} from 'edenred-ui';

import { FormFields } from '@epi-forms/helpers';
import { FromMonthSelect } from '@epi-forms/controls/FromMonthSelect';
import { ToMonthSelect } from '@epi-forms/controls/ToMonthSelect';
import { BeneficiariesAmountField } from '@epi-forms/controls/BeneficiariesAmountField';

import { formatNumeric } from '../../helpers/numeral';
import Moment from '../../helpers/MomentExtensions';

import './BenefitCalculator.scss';

function BenefitCalculator({
  maxOrderTotalAmount,
  handleSetQuantityValue,
  i18n,
  locale,
  tooltip
}) {
  const [isCalculatorOpened, setIsCalculatorOpened] = useState(false);
  const calculatorForm = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.FromMonth]: '',
      [FormFields.ToMonth]: '',
      [FormFields.BeneficiariesAmount]: ''
    }
  });

  const { watch, trigger } = calculatorForm;
  const fromMonthValue = watch(FormFields.FromMonth);
  const toMonthValue = watch(FormFields.ToMonth);
  const beneficiariesAmountValue = watch(FormFields.BeneficiariesAmount);

  const toggleCalculator = () => {
    setIsCalculatorOpened(!isCalculatorOpened);
  };

  const getWorkingDays = () => {
    const start = parseOption(fromMonthValue).startOf('month');
    const end = parseOption(toMonthValue).endOf('month');
    return end.workingDaysDiff(start);
  };

  const calculate = () => {
    trigger();
    const employees = formatNumeric(beneficiariesAmountValue);

    if (beneficiariesAmountValue) {
      const qty = getWorkingDays() * employees;
      handleSetQuantityValue(qty);
    }
  };

  const parseOption = value => Moment(value, 'MM/YYYY');

  const workingDays = getWorkingDays();

  const toggleCalculatorButton = (
    <Box width="100%" display="flex" alignContent="space-between">
      <Box flex="0 0 100%">
        <Button
          aria-hidden
          type="button"
          onClick={toggleCalculator}
          size={ButtonSize.Medium}
        >
          <Box display="flex" alignItems="center">
            <CalculatorIcon color="white" />
            {isCalculatorOpened
              ? i18n.t('forms.benefit_calculator.close_calculator')
              : i18n.t('forms.benefit_calculator.open_calculator')}
          </Box>
        </Button>
      </Box>
      <Box>
        {tooltip && (
          <IconTooltip title={tooltip}>
            <InfoIcon />
          </IconTooltip>
        )}
      </Box>
    </Box>
  );

  const calculator = (
    <Box
      display="flex"
      width="100%"
      justifyContent="space-between"
      alignItems="flex-start"
      marginTop="10px"
    >
      <Box flex="0.25">
        <BeneficiariesAmountField maxOrderTotalAmount={maxOrderTotalAmount} />
      </Box>
      <Box display="flex" flex="0.40">
        <Box paddingRight="10px" flex="0.5">
          <FromMonthSelect locale={locale} />
        </Box>
        <Box paddingLeft="10px" flex="0.5">
          <ToMonthSelect locale={locale} startFrom={fromMonthValue} />
        </Box>
      </Box>
      <Box flex="0.15" paddingTop="20px">
        <p className="header3 small">
          {workingDays} {i18n.t('forms.benefit_calculator.working_days')}
        </p>
      </Box>
      <Box flex="0.10" paddingTop="5px">
        <Button
          id="benefit-calculate-btn"
          className="btn-block btn-calculate"
          onClick={calculate}
          type="button"
          size={ButtonSize.Medium}
        >
          {i18n.t('forms.benefit_calculator.calculate')}
        </Button>
      </Box>
    </Box>
  );

  return (
    <div className="BenefitCalculator">
      <FormProvider {...calculatorForm}>
        <Container fluid>
          <Box
            marginBottom="30px"
            sx={{
              '& .MuiAccordionSummary-expandIconWrapper': { display: 'none' }
            }}
          >
            <Accordion
              summaryChild={toggleCalculatorButton}
              expanded={isCalculatorOpened}
              detailsChild={calculator}
            />
          </Box>
        </Container>
      </FormProvider>
    </div>
  );
}

BenefitCalculator.propTypes = {
  tooltip: PropTypes.string,
  locale: PropTypes.string.isRequired,
  handleSetQuantityValue: PropTypes.func,
  i18n: PropTypes.object.isRequired,
  maxOrderTotalAmount: PropTypes.number.isRequired
};

BenefitCalculator.defaultProps = {
  handleSetQuantityValue: () => {},
  tooltip: null
};
const BenefitCalculatorWithI18n = withTranslation()(
  connect()(BenefitCalculator)
);

export { BenefitCalculatorWithI18n as BenefitCalculator };
