import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { Card } from 'react-bootstrap';
import i18n from 'i18next';
import PropTypes from 'prop-types';

import { showNotification } from '@epi-actions/overlays';
import { normalizeDecimalComma } from '@epi-containers/LoadCompanyAccount/helpers';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { fetchGet, fetchPost } from '../../helpers/FetchHelpers';
import {
  getRequestedLoadsUrl,
  postRejectRequestedLoadUrl
} from '../../repositories/RequestedLoadsRepository';

import RequestedLoadsSummary from './RequestedLoadsSummary';
import RequestedLoadsTable from './RequestedLoadsTable';
import { RequestedLoadsPageStyle } from './RequestedLoadsPageStyle';
import RejectRequestedLoadsDialog from './RejectRequestedLoadDialog';
import requestedLoadsReducer, {
  getDataLoadedAction,
  getDataFailedAction
} from './RequestedLoadsReducer';
import {
  rejectLoadSuccess,
  rejectDialogTitle,
  rejectDialogPrimaryLabel,
  rejectDialogSecondaryLabel,
  generateRejectDialogContent
} from './RejectRequestedLoadsHelpers';

const genericErrorMessage = 'messages.error';

const RequestedLoadsPage = ({ showError, showSuccess }) => {
  const [state, dispatch] = useReducer(requestedLoadsReducer, {
    sortDirection: false,
    sortBy: 'firstName',
    isLoading: true,
    rows: [],
    totalRequestedLoads: null,
    totalRequestedLoadsAmount: null,
    currentBalance: null,
    page: 1,
    totalRows: 0
  });

  const [requestIdToBeRejected, setRequestIdToBeRejected] = useState(null);

  const getRequestedLoads = () => {
    const onSuccess = res => dispatch(getDataLoadedAction(res));
    const onFailure = () => {
      dispatch(getDataFailedAction());
      showError(genericErrorMessage);
    };
    fetchGet(getRequestedLoadsUrl(), {}, onSuccess).catch(() => onFailure());
  };

  const rejectRequestedLoad = id => {
    const onSuccess = () => {
      showSuccess(rejectLoadSuccess);
      getRequestedLoads();
      setRequestIdToBeRejected(null);
    };
    const onFailure = () => {
      showError(genericErrorMessage);
      setRequestIdToBeRejected(null);
    };
    fetchPost(postRejectRequestedLoadUrl(id), null, onSuccess).catch(() =>
      onFailure()
    );
  };

  useEffect(() => {
    getRequestedLoads();
  }, []);

  const languageUrlPrefix = i18n.language === 'fi' ? '' : `${i18n.language}/`;

  return (
    <RequestedLoadsPageStyle>
      <Breadcrumb
        backIcon
        linkSegments={[
          {
            link: `/${languageUrlPrefix}load-account`,
            label: i18n.t(
              'containers.deposit_money.requested_loads.back_to_transfer_money'
            )
          }
        ]}
      />
      <Card>
        <Card.Body>
          <RequestedLoadsSummary
            totalRequestedLoads={state.totalRequestedLoads}
            totalRequestedLoadsAmount={normalizeDecimalComma(
              state.totalRequestedLoadsAmount
            )}
            currentBalance={normalizeDecimalComma(state.currentBalance)}
          />
          <div className="requested-loads__table">
            <RequestedLoadsTable
              requestedLoads={state.rows}
              setRequestIdToBeRejected={setRequestIdToBeRejected}
              isLoading={state.isLoading}
            />
          </div>
        </Card.Body>
      </Card>
      {requestIdToBeRejected && (
        <RejectRequestedLoadsDialog
          isOpen={requestIdToBeRejected !== null}
          closeAction={() => setRequestIdToBeRejected(null)}
          primaryAction={() => rejectRequestedLoad(requestIdToBeRejected)}
          primaryActionLabel={i18n.t(rejectDialogPrimaryLabel)}
          secondaryAction={() => setRequestIdToBeRejected(null)}
          secondaryActionLabel={i18n.t(rejectDialogSecondaryLabel)}
          title={i18n.t(rejectDialogTitle)}
          content={generateRejectDialogContent(requestIdToBeRejected, state)}
        />
      )}
    </RequestedLoadsPageStyle>
  );
};

RequestedLoadsPage.propTypes = {
  showError: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired
};

export default connect(null, dispatch => ({
  showError: message => {
    dispatch(showNotification({ message }, 'error'));
  },
  showSuccess: message => {
    dispatch(showNotification({ message }, 'success'));
  }
}))(RequestedLoadsPage);
