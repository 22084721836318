import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box, Link, Text } from 'edenred-ui';

export const DetailDailyLimitStatement = ({
  benefitType,
  maxAmountPerPeriodDisplay,
  isVirikeBenefit
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Box width={isVirikeBenefit ? 457 : 'inherit'} pb={isVirikeBenefit && 2}>
        <Text>
          {t(
            `components.manage_benefit_${benefitType}.detail_daily_limit_statement`,
            {
              limit: maxAmountPerPeriodDisplay
            }
          )}
        </Text>
      </Box>
      <Text>
        <Link
          target="_blank"
          href={t(
            `components.manage_benefit_${benefitType}.detail_read_more_about_limits_link`
          )}
        >
          {t(
            `components.manage_benefit_${benefitType}.detail_read_more_about_limits_text`
          )}
        </Link>
      </Text>
    </>
  );
};

DetailDailyLimitStatement.propTypes = {
  benefitType: PropTypes.string.isRequired,
  maxAmountPerPeriodDisplay: PropTypes.number.isRequired,
  isVirikeBenefit: PropTypes.bool.isRequired
};
