import { get } from 'lodash';
import { REHYDRATE } from 'redux-persist/constants';
import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';

export const getInitialState = () => ({
  lastPaymentPublicId: null,
  isError: false,
  isNewCustomer: false
});

export default createReducer(getInitialState, {
  [`${ApiConstants.CREATE_VOUCHER_ORDER}_CLEAR`]: () => ({
    lastPaymentPublicId: null,
    isError: false
  }),
  [`${ApiConstants.CREATE_VOUCHER_ORDER}_SUCCESS`]: (
    state,
    { payload, isNewCustomer }
  ) => ({
    lastPaymentPublicId: get(payload, 'paymentPublicId'),
    errors: [],
    isError: false,
    isNewCustomer
  }),
  [`${ApiConstants.CREATE_VOUCHER_ORDER}_FAILURE`]: () => ({
    lastPaymentPublicId: null,
    isError: true
  }),
  [`${REHYDRATE}`]: (state, action) => ({
    ...state,
    ...action.payload.apiVoucherOrder,
    rehydrated: true
  })
});
