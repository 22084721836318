import createReducer from '../../helpers/CreateReducer';
import {
  ApiConstants,
  TransactionsHistoryConstants
} from '../../constants/actions';
import { TransactionsHistorySorting } from '../../constants/transactionsHistorySorting';

export const getInitialState = () => ({
  transactions: [],
  totalPages: 1,
  queryParameters: {
    page: 1,
    rowsPerPage: 20,
    sortingBy: TransactionsHistorySorting.ByDate,
    descending: true
  }
});

export default createReducer(getInitialState, {
  [TransactionsHistoryConstants.SET_TRANSACTIONS_HISTORY_QUERY_PARAMETERS]: (
    state,
    { queryParameters }
  ) => {
    return {
      ...state,
      queryParameters
    };
  },

  [`${ApiConstants.LOAD_TRANSACTIONS_HISTORY}_SUCCESS`]: (
    state,
    { payload }
  ) => ({
    transactions: payload.transactionsHistoryResponse.transactions || [],
    totalPages: payload.transactionsHistoryResponse.totalPages
  }),

  [`${ApiConstants.LOAD_TRANSACTIONS_HISTORY}_FAILURE`]: () => ({
    transactions: [],
    isError: true
  })
});
