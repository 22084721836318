import styled from 'styled-components';

export const BenefitGroupTileActions = styled.div`
  display: none;
`;

export const BenefitGroupTileContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: space-between;
  width: 296px;
  height: 296px;
  margin: 0 24px 24px 0;
  padding: 24px 24px 40px 24px;
  background-color: ${props => props.theme.gray0};
  outline: 1px solid ${props => props.theme.altGray2};
  cursor: pointer;

  &:hover {
    outline: none;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.13);

    ${BenefitGroupTileActions} {
      display: block;
    }
  }
`;

export const BenefitGroupTileHeading = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  width: 100%;
`;

export const BenefitGroupTileTitle = styled.div`
  font-size: ${props => props.theme.sizeMedium};
  font-weight: ${props => props.theme.fontSemiBold};
  color: ${props => props.theme.gray85};
  word-break: break-word;
`;

export const BenefitGroupLimit = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  height: 56px;
  padding: 4px 24px 4px 0;
  border-right: solid 2px ${props => props.theme.primaryBackColorLight};
`;

export const BenefitGroupLimitValue = styled.div`
  font-weight: ${props => props.theme.fontSemiBold};
`;
