import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { Trans } from 'react-i18next';
import {
  Box,
  Heading1,
  Link,
  Text,
  LoadingSpinner,
  PagePanel,
  IEdenredTheme
} from 'edenred-ui';

import { EmailIcon } from '@epi-components/Icons/EmailIcon';
import { getReCaptchaSiteKey } from '@epi-selectors/settings';
import { resendSignUpMail } from '@epi-helpers/mailHelper';
import { IEmailSendConfirmation } from '@epi-models/shared';

function EmailSendConfirmation({
  heading,
  description,
  emailResendText,
  reCaptchaSiteKey,
  location
}: IEmailSendConfirmation) {
  const dispatch = useDispatch();
  const { gray85, fontRegularBold } = useContext<IEdenredTheme>(ThemeContext);
  const [isProcessingResendEmail, setIsProcessingResendEmail] = useState(false);

  const params = new URLSearchParams(location.search);
  const signToken = params.get('token');
  const contactRegistrationType = params.get('type');

  const resendEmail = async () => {
    if (signToken && !isProcessingResendEmail) {
      setIsProcessingResendEmail(true);

      const actions = await resendSignUpMail(
        signToken,
        contactRegistrationType,
        reCaptchaSiteKey,
        location.pathname
      );

      actions.forEach(action => dispatch(action));

      setIsProcessingResendEmail(false);
    }
  };

  return (
    <PagePanel>
      {isProcessingResendEmail && <LoadingSpinner />}
      <Box
        maxWidth={620}
        minHeight={550}
        mt={11.5}
        mx="auto"
        textAlign="center"
      >
        <EmailIcon />
        <Box mt={5} mb={4}>
          <Heading1>{heading}</Heading1>
        </Box>
        <Box>
          <Text>{description}</Text>
        </Box>
        <Box maxWidth={450} mt={4} mx="auto">
          {
            <Text>
              <Trans
                defaults={emailResendText}
                components={{
                  a: (
                    <Link
                      style={{
                        color: gray85,
                        cursor: 'pointer',
                        fontWeight: fontRegularBold
                      }}
                      onClick={resendEmail}
                    />
                  )
                }}
              />
            </Text>
          }
        </Box>
      </Box>
    </PagePanel>
  );
}

const mapStateToProps = state => ({
  reCaptchaSiteKey: getReCaptchaSiteKey(state)
});

const ConnectedEmailSendConfirmation = connect(mapStateToProps)(
  withRouter(EmailSendConfirmation)
);

export { ConnectedEmailSendConfirmation as EmailSendConfirmation };
