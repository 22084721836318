import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonMode,
  ButtonSize,
  NotificationType,
  Notification
} from 'edenred-ui';

import config from '@epi-config';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { selectActiveBenefits } from '@epi-selectors/benefitGroupSettings';

export function CreatedGroupNotifications({
  detail,
  isBenefitGroupsError,
  setDetail
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeBenefits = useSelector(selectActiveBenefits);

  return (
    <>
      {config.enableVirikeBenefit &&
        !isBenefitGroupsError &&
        activeBenefits.length === 1 &&
        detail.createdGroupSuggestion && (
          <>
            <Box mb={1}>
              <Notification
                type={
                  isBenefitGroupsError
                    ? NotificationType.Error
                    : NotificationType.Success
                }
                text={
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={1} mr={2}>
                      {isBenefitGroupsError
                        ? t('messages.error')
                        : t(
                            'components.manage_benefit_lunch.group_added_notification'
                          )}
                    </Box>
                  </Box>
                }
                onClose={() =>
                  setDetail({ ...detail, createdGroupNotification: false })
                }
              />
            </Box>
            <Box mb={5}>
              <Notification
                type={NotificationType.Info}
                text={
                  <Box display="flex" alignItems="center">
                    <Box flexGrow={1} mr={2}>
                      {t(
                        'components.manage_benefit_lunch.group_added_suggestion_a'
                      )}
                    </Box>
                    <Box mr="30px">
                      <Button
                        id="go-to-beneficiaries-btn"
                        mode={ButtonMode.Link}
                        size={ButtonSize.Medium}
                        onClick={() =>
                          dispatch(goToPage(routerPaths.manageBenefits))
                        }
                      >
                        {t(
                          'components.manage_benefit_lunch.group_added_suggestion_a_button'
                        )}
                      </Button>
                    </Box>
                    <Box flexGrow={1} mr={2}>
                      {t(
                        'components.manage_benefit_lunch.group_added_suggestion_b'
                      )}
                    </Box>
                    <Box>
                      <Button
                        id="go-to-beneficiaries-btn"
                        mode={ButtonMode.Link}
                        size={ButtonSize.Medium}
                        onClick={() =>
                          dispatch(goToPage(routerPaths.beneficiaries))
                        }
                      >
                        {t(
                          'components.manage_benefit_lunch.group_added_suggestion_b_button'
                        )}
                      </Button>
                    </Box>
                  </Box>
                }
                onClose={() =>
                  setDetail({ ...detail, createdGroupSuggestion: false })
                }
              />
            </Box>
          </>
        )}
      {((!config.enableVirikeBenefit && detail.createdGroupNotification) ||
        (config.enableVirikeBenefit &&
          detail.createdGroupNotification &&
          !isBenefitGroupsError &&
          activeBenefits.length === 2)) && (
        <Box mb={5}>
          <Notification
            type={
              isBenefitGroupsError
                ? NotificationType.Error
                : NotificationType.Success
            }
            text={
              <Box display="flex" alignItems="center">
                <Box flexGrow={1} mr={2}>
                  {isBenefitGroupsError
                    ? t('messages.error')
                    : t(
                        'components.manage_benefit_lunch.group_added_notification'
                      )}
                </Box>
                {!isBenefitGroupsError && (
                  <Box>
                    <Button
                      id="go-to-beneficiaries-btn"
                      mode={ButtonMode.Secondary}
                      size={ButtonSize.Medium}
                      onClick={() =>
                        dispatch(goToPage(routerPaths.beneficiaries))
                      }
                    >
                      {t(
                        'components.manage_benefit_lunch.group_added_suggestion_b_button'
                      )}
                    </Button>
                  </Box>
                )}
              </Box>
            }
            onClose={() =>
              setDetail({ ...detail, createdGroupNotification: false })
            }
          />
        </Box>
      )}
    </>
  );
}

CreatedGroupNotifications.propTypes = {
  detail: PropTypes.object.isRequired,
  isBenefitGroupsError: PropTypes.bool.isRequired,
  setDetail: PropTypes.func.isRequired
};
