import { put, take, call, select } from 'redux-saga/effects';
import { getErrorDataString } from '@epi-helpers/ErrorDataHelper';
import { routerPaths } from '../constants/routerPaths';
import {
  UnauthorizedErrorName,
  ForbiddenErrorName,
  ApiErrorName
} from '../constants/errorName';
import { HandleUnauthorizedError } from '../helpers/AuthorizeHelper';
import { OverlaysConstants, ApiConstants } from '../constants/actions';
import { getLoginState } from '../selectors/login';

export function* showErrorMessageOnConfirmationPasswordPopup(message) {
  yield put({
    type: `${OverlaysConstants.SET_ERROR_MESSAGE_ON_PASSWORD_POPUP}`,
    message
  });
}

export function* hideErrorMessageOnConfirmationPasswordPopup() {
  yield put({
    type: `${OverlaysConstants.SET_ERROR_MESSAGE_OFF_PASSWORD_POPUP}`,
    message: ''
  });
}

export function* setLoadingPasswordPopup(isLoading = false) {
  yield put({
    type: `${OverlaysConstants.SET_PASSWORD_POPUP_LOADING_STATE}`,
    isLoading
  });
}

function* SetPopupVisibility(show, message) {
  yield put({
    type: `${OverlaysConstants.SET_PASSWORD_POPUP_VISIBILITY}`,
    show,
    message
  });
}

function* ForgotPassword() {
  yield put({
    type: `${ApiConstants.LOGOUT_ACTION}_REQUEST`,
    gotoUrl: routerPaths.resetPassword
  });
}

export function* askConfirmationPassword(message = '') {
  // Display popup
  yield call(SetPopupVisibility, true, message);

  // Wait for confirm / cancel
  const buttonActionResult = yield take(
    `${OverlaysConstants.PASSWORD_POPUP_ACTION}`
  );

  switch (buttonActionResult.actionType) {
    case 'cancel':
      yield call(SetPopupVisibility, false);
      yield call(showErrorMessageOnConfirmationPasswordPopup);
      return null;
    case 'forgot_password':
      yield call(ForgotPassword, false);
      yield call(SetPopupVisibility, false);
      return null;
    default:
      return yield buttonActionResult.password;
  }
}

export function* hideConfirmationPasswordPopup() {
  yield call(SetPopupVisibility, false);
}

export function* handleErrors(error) {
  const message =
    error.name === ApiErrorName && error.content
      ? getErrorDataString(error)
      : error.message;

  if (error.name === ForbiddenErrorName || error.name === ApiErrorName) {
    yield call(showErrorMessageOnConfirmationPasswordPopup, message);
  }
  if (error.name === UnauthorizedErrorName) {
    const login = yield select(getLoginState);
    yield call(HandleUnauthorizedError, login);
    return true;
  }
  return false;
}

export function* callWithPassword(fn, params = {}, message = '') {
  while (true) {
    const password = yield call(askConfirmationPassword, message);
    if (!password) {
      yield call(hideConfirmationPasswordPopup);
      yield call(hideErrorMessageOnConfirmationPasswordPopup);
      return false;
    }
    yield call(setLoadingPasswordPopup, true);

    let isSuccess = false;
    let closePopup = false;
    try {
      yield call(fn, { ...params, password });
      isSuccess = true;
    } catch (error) {
      closePopup = yield call(handleErrors, error);
    }

    yield call(setLoadingPasswordPopup, false);
    if (isSuccess || closePopup) {
      yield call(hideConfirmationPasswordPopup);
      yield call(hideErrorMessageOnConfirmationPasswordPopup);
      return isSuccess;
    }
  }
}

export default { askConfirmationPassword, callWithPassword };
