import styled from 'styled-components';
import { Button } from 'edenred-ui';

export const NotFoundPanelContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em;
  margin-top: 40px;
`;

export const StyledButton = styled(Button)`
  margin: 10px 0;
`;
