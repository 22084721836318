import React from 'react';
import { connect } from 'react-redux';
import { Box, Popover } from 'edenred-ui';

import { getIsAuthenticated } from '@epi-selectors/login';
import { INavbarDropdown } from '@epi-models/core/Navbar';

import { NavbarDropdownActions } from '../NavbarDropdownActions';
import { NavbarLanguagesList } from '../NavbarLanguagesList';

function NavbarDropdown({
  anchorEl,
  isAuthenticated,
  onClose
}: INavbarDropdown) {
  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Box minWidth={260}>
        {isAuthenticated ? (
          <NavbarDropdownActions closeDropdown={onClose} />
        ) : (
          <NavbarLanguagesList onLanguageChanged={onClose} />
        )}
      </Box>
    </Popover>
  );
}

const mapStateToProps = state => ({
  isAuthenticated: getIsAuthenticated(state)
});

const ConnectedNavbarDropdown = connect(mapStateToProps)(NavbarDropdown);

export { ConnectedNavbarDropdown as NavbarDropdown };
