import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Table, Checkbox, SortDownIcon, SortUpIcon } from 'edenred-ui';
import { useFormContext } from 'react-hook-form';

import { Pagination } from '@epi-shared/components';
import { TopupSorting } from '@epi-constants/topupSorting';
import { formatCurrencyDecimal } from '@epi-helpers/numeral';

import { HeaderButton, Summary } from './EmployeesTable.styles';

export const EmployeesTable = ({
  visibleEmployees,
  toggleEmployee,
  handleChangePage,
  paginationCount,
  page,
  employeesPerPage,
  handleSort,
  descending,
  sortBy,
  checkedCount
}) => {
  const { t } = useTranslation();
  const { trigger } = useFormContext();

  const sortingIcon = descending ? <SortDownIcon /> : <SortUpIcon />;

  const handleToggleEmployee = employee => {
    toggleEmployee(employee);
    trigger();
  };

  return (
    <Box marginTop="50px">
      <Table id="employee-table">
        <Table.Head>
          <Table.Row>
            <Table.Cell width="35%">
              <HeaderButton
                onClick={() => handleSort(TopupSorting.ByFirstName)}
              >
                {sortBy === TopupSorting.ByFirstName && sortingIcon}
                {t('containers.deposit_money.topup_tab.form.table.name')}
              </HeaderButton>
            </Table.Cell>
            <Table.Cell width="25%">
              {t('containers.deposit_money.topup_tab.form.table.personal_id')}
            </Table.Cell>
            <Table.Cell width="25%">
              {t('containers.deposit_money.topup_tab.form.table.city')}
            </Table.Cell>
            <Table.Cell width="15%" align="right">
              <HeaderButton
                onClick={() =>
                  handleSort(TopupSorting.ByAvailableToTopupAmount)
                }
              >
                {sortBy === TopupSorting.ByAvailableToTopupAmount &&
                  sortingIcon}

                {t('containers.deposit_money.topup_tab.form.table.topup_left')}
              </HeaderButton>
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {visibleEmployees.map(employee => (
            <Table.Row
              key={employee.beneficiaryId}
              disabled={employee.availableToTopUpAmount <= 0}
              id={`employee-table-row-${employee.personalId}`}
            >
              <Table.Cell>
                <Box display="flex" alignItems="center">
                  <Box paddingRight="16px">
                    <Checkbox
                      id={`employee-table-checkbox-${employee.personalId}`}
                      noPadding
                      checked={employee.isChecked}
                      onChange={() => handleToggleEmployee(employee)}
                      disabled={employee.availableToTopUpAmount <= 0}
                    />
                  </Box>
                  {employee.firstName} {employee.lastName}
                </Box>
              </Table.Cell>
              <Table.Cell>{employee.personalId}</Table.Cell>
              <Table.Cell>{employee.city}</Table.Cell>
              <Table.Cell align="right">
                {formatCurrencyDecimal(employee.availableToTopUpAmount)}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <Summary>
        <span>
          {t('containers.deposit_money.topup_tab.form.table.selected')}
        </span>
        <span>{checkedCount}</span>
      </Summary>
      <Box mt={3}>
        <Pagination
          page={page}
          count={paginationCount}
          defaultRowsPerPage={employeesPerPage}
          onChangePage={handleChangePage}
        />
      </Box>
    </Box>
  );
};

EmployeesTable.propTypes = {
  visibleEmployees: PropTypes.array.isRequired,
  toggleEmployee: PropTypes.func.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  paginationCount: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  employeesPerPage: PropTypes.number.isRequired,
  handleSort: PropTypes.func.isRequired,
  descending: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  checkedCount: PropTypes.number.isRequired
};
