import React from 'react';
import { useTranslation } from 'react-i18next';

import { getOrderStatus } from './helpers';

export interface IOrderStatus {
  paymentStatus: string;
  voucherOrderStatus: string;
}

export function OrderStatus({
  paymentStatus,
  voucherOrderStatus
}: IOrderStatus) {
  const { t } = useTranslation();

  const orderStatus = getOrderStatus(paymentStatus, voucherOrderStatus);
  const orderStatusName = t(`shared.order_status.${orderStatus}`);

  return <>{orderStatusName}</>;
}
