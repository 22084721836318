import { put, call } from 'redux-saga/effects';
import {
  getSingleErrorDataString,
  getDefaultMessage
} from '@epi-helpers/ErrorDataHelper';
import { lowerFirst } from 'lodash';
import { ErrorSeverity } from '@epi-constants/errorSeverity';
import * as overlaysActions from '../actions/overlays';

export function* Notify(message, type = 'success', translated = false) {
  yield put(
    overlaysActions.showNotification(
      {
        message,
        translated
      },
      type
    )
  );
}

export function* NotifyApiErrors(apiError, translated = true) {
  if (!apiError || !apiError.content || !apiError.content.errors) {
    yield call(Notify, getDefaultMessage(), lowerFirst(ErrorSeverity.Error));
  } else {
    for (let error of apiError.content.errors) {
      yield call(
        Notify,
        error.value || getSingleErrorDataString(error),
        lowerFirst(error.severity),
        translated
      );
    }
  }
}
