import { values } from 'lodash';

export const getConstantValue = (constants, value) => {
  const foundType = values(constants).find(
    item => item.toLowerCase() === (value || '').toLowerCase()
  );
  return foundType || null;
};

export default { getConstantValue };
