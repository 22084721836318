import FacebookImg from '@epi-assets/images/Facebook.png';
import InstagramImg from '@epi-assets/images/Instagram.png';
import LinkedInImg from '@epi-assets/images/LinkedIn.png';
import YoutubeImg from '@epi-assets/images/Youtube.png';

export const socialsArr = [
  {
    key: 'linkedin',
    img: LinkedInImg,
    link: 'https://www.linkedin.com/company/edenred-finland/',
    alt: 'LinkedIn'
  },
  {
    key: 'instagram',
    img: InstagramImg,
    link: 'https://www.instagram.com/edenredfinland/',
    alt: 'Instagram'
  },
  {
    key: 'facebook',
    img: FacebookImg,
    link: 'https://www.facebook.com/edenredfinland/',
    alt: 'Facebook'
  },
  {
    key: 'youtube',
    img: YoutubeImg,
    link: 'https://www.youtube.com/@EdenredGroup/',
    alt: 'Youtube'
  }
];

export const PHONE_NUMBER = 'tel: 0975942848';
