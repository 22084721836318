import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { Table, LoadingSpinner, ButtonMode } from 'edenred-ui';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ApiConstants } from '@epi-constants/actions';
import { ErrorPanel } from '@epi-components/ErrorPanel/ErrorPanel';
import { getShowSlideout } from '@epi-selectors/company';
import { useMenuDimensions } from '@epi-shared/hooks';
import { contactsApiUrl } from '@epi-repositories/ContactsRepository';
import { fetchDelete as Delete } from '@epi-helpers/FetchHelpers';
import { showNotification } from '@epi-actions/overlays';
import { getUserInformation } from '@epi-actions/profile';

import {
  getOrderedContacts,
  getContactsOrder
} from '../../../../selectors/company';
import { contacts as contactsLoadingSelector } from '../../../../selectors/loading';
import * as apiActions from '../../../../actions/api';
import * as companyActions from '../../../../actions/company';
import { ContactRow } from '../ContactRow';
import { ContactsTableHead } from '../ContactsTableHead';
import { ContactPersonSlideout } from '../ContactPersonSlideout';
import { ContactDeletionModal } from '../ContactDeletionModal';
import { getCustomerNumber } from '@epi-selectors/api';
import { disableEditMode } from '@epi-actions/company';

function ContactsData({
  loadContacts,
  contacts,
  isLoading,
  setContactsOrder,
  contactsOrder: { sortingBy, ascending },
  showSlideout,
  openSlideout,
  closeSlideout,
  enableSlideoutEditMode,
  disableSlideoutEditMode,
  updateFooter,
  showNotification,
  getUserInformation
}) {
  const { t } = useTranslation();
  const { isMobile } = useMenuDimensions();
  const dispatch = useDispatch();
  const companyId = useSelector(getCustomerNumber);
  const closedModalState = {
    showModal: false,
    modalError: ''
  };
  const [showModalByError, setShowModalByError] = useState(closedModalState);
  const [contactToDelete, setContactToDelete] = useState({});
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      userName: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: ''
    }
  });

  const handleOpenSlideout = () => {
    openSlideout();
    enableSlideoutEditMode();
  };

  const handleCloseSlideout = () => {
    form.reset();
    closeSlideout();
  };

  const handleCloseModal = () => {
    setShowModalByError(closedModalState);
  };

  const onDeleteError = error => {
    setShowModalByError({
      showModal: true,
      modalError: error?.content?.errors[0]?.message || 'general_error'
    });
    loadContacts();
  };

  const handleDeleteContact = contact => {
    closeSlideout();
    setContactToDelete(contact);
    setShowModalByError({
      showModal: true,
      modalError: ''
    });
  };

  const deleteContact = () => {
    handleCloseModal();

    const onSuccess = () => {
      showNotification({ message: 'messages.company_info_updated' }, 'success');
      loadContacts();
    };

    const url = new URL(contactsApiUrl);

    if (contactToDelete.active) {
      url.searchParams.set('personIdToDelete', contactToDelete.personId);
      url.searchParams.set('companyId', companyId);
    } else {
      url.searchParams.set(
        'dataContactIdToDelete',
        contactToDelete.dataContactId
      );
    }

    return Delete(url, null, onSuccess).catch(onDeleteError);
  };

  useEffect(() => {
    getUserInformation();
    loadContacts();
    closeSlideout();
    disableSlideoutEditMode();
    dispatch(disableEditMode());
    if (isMobile)
      updateFooter({
        submitText: t('company_info.add_contact'),
        onSubmit: handleOpenSlideout,
        submitButtonMode: ButtonMode.Secondary
      });
  }, []);

  const orderBy = sortingBy => {
    setContactsOrder(sortingBy, !ascending);
  };

  const areData = contacts && contacts.length > 0;

  return (
    <div style={{ marginTop: 30 }}>
      <FormProvider {...form}>
        <ErrorPanel failedActionName={ApiConstants.ADD_NEW_CONTACT} />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Table responsive striped size="sm">
            <ContactsTableHead
              orderBy={orderBy}
              areData={areData}
              sortingBy={sortingBy}
              ascending={ascending}
            />
            <Table.Body>
              {contacts.map(row => (
                <ContactRow
                  key={`${row.username}-${row.dataContactId}`}
                  row={row}
                  handleDeleteContact={handleDeleteContact}
                />
              ))}
            </Table.Body>
          </Table>
        )}
        <ContactPersonSlideout
          isSlideoutExpanded={showSlideout}
          closeSlideout={handleCloseSlideout}
          handleDeleteContact={handleDeleteContact}
        />
      </FormProvider>
      <ContactDeletionModal
        showModalByError={showModalByError}
        contactToDelete={contactToDelete}
        deleteContact={deleteContact}
        handleCloseModal={handleCloseModal}
      />
    </div>
  );
}

ContactsData.propTypes = {
  contacts: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loadContacts: PropTypes.func.isRequired,
  setContactsOrder: PropTypes.func.isRequired,
  contactsOrder: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  showSlideout: PropTypes.bool.isRequired,
  closeSlideout: PropTypes.func.isRequired,
  updateFooter: PropTypes.func.isRequired,
  openSlideout: PropTypes.func.isRequired,
  enableSlideoutEditMode: PropTypes.func.isRequired,
  disableSlideoutEditMode: PropTypes.func.isRequired,
  showNotification: PropTypes.func.isRequired,
  getUserInformation: PropTypes.func.isRequired
};

ContactsData.defaultProps = {
  contactsOrder: {}
};

const mapStateToProps = state => ({
  contacts: getOrderedContacts(state),
  isLoading: contactsLoadingSelector(state),
  contactsOrder: getContactsOrder(state),
  showSlideout: getShowSlideout(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...apiActions, ...companyActions, showNotification, getUserInformation },
    dispatch
  );

const ConnectedContactsData = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ContactsData));

export { ConnectedContactsData as ContactsData };
