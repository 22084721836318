import React from 'react';
import { Box } from 'edenred-ui';

import { INavbarLanguageLabelWithChevron } from '@epi-models/core/Navbar';

import { NavbarLanguageLabel } from './NavbarLanguageLabel';

export function NavbarLanguageLabelWithChevron({
  languageId,
  open
}: INavbarLanguageLabelWithChevron) {
  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <NavbarLanguageLabel languageId={languageId} />
      </Box>
      <i className={`mdi mdi-24px ${open ? 'mdi-menu-up' : 'mdi-menu-down'}`} />
    </Box>
  );
}
