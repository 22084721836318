import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Checkbox } from 'edenred-ui';

import { FormFields } from '@epi-forms/helpers';

export function KeepLoggedInCheckbox() {
  const name = FormFields.KeepLoggedIn;
  const { control } = useFormContext();
  const { t } = useTranslation();

  const {
    field: { ref, value, onChange: setValue }
  } = useController({
    name,
    control
  });

  return (
    <FormControlLabel
      control={
        <Checkbox ref={ref} checked={value} id={name} onChange={setValue} />
      }
      label={t('controls.keep_logged_in_label')}
      id="keep-logged_checkbox"
    />
  );
}
