import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import fi from '../../locales/fi.yml';
import en from '../../locales/en.yml';

i18n.use(initReactI18next).init({
  lng: 'fi',
  fallbackLng: 'fi',
  ns: ['translations'],
  defaultNS: 'translations',
  resources: {
    en: { translations: en.en },
    fi: { translations: fi.fi }
  },
  interpolation: {
    escapeValue: false
  },
  react: {
    wait: true
  },
  parseMissingKeyHandler: () => undefined
});
i18n.on('languageChanged', lng => {
  window.OneTrust?.changeLanguage?.(lng);
});
export default i18n;
