import { put, call } from 'redux-saga/effects';
import { getContacts } from '../../repositories/ContactsRepository';
import { ApiConstants } from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';

const { LOAD_CONTACTS } = ApiConstants;

export default function* loadContacts() {
  try {
    const contacts = yield call(getContacts);
    yield put({
      type: `${LOAD_CONTACTS}_SUCCESS`,
      contacts
    });
  } catch (ex) {
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    }
    yield put({
      type: `${LOAD_CONTACTS}_FAILURE`,
      payload: ex,
      isError: true
    });
  }
}
