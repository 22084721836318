import config from '@epi-config';
/* eslint-disable no-undef */

export const isProduction = () => {
  return process.env.NODE_ENV === 'production';
};

export const isQa = () => {
  if (config.environment) {
    return false;
  }

  return config.environment.includes('QA');
};
