import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';

import storage from '../../helpers/StorageHelper';

const getIsPrintInvoicePdf = () => storage.get('isPrintInvoicePdf') || false;

export const getInitialState = () => ({
  accounts: [],
  isError: false,
  noPersistance: true,
  showNotification: true,
  isPrintInvoicePdfForTransferMoney: Boolean(getIsPrintInvoicePdf())
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_COMPANY_ACCOUNTS}_REQUEST`]: () => ({
    isError: false
  }),
  [`${ApiConstants.LOAD_COMPANY_ACCOUNTS}_SUCCESS`]: (state, { payload }) => ({
    accounts: payload
  }),
  [`${ApiConstants.LOAD_COMPANY_ACCOUNTS}_FAILURE`]: () => ({
    isError: true
  }),
  [`${ApiConstants.LOAD_COMPANY_ACCOUNTS}_CLOSE_NOTIFICATION`]: () => ({
    showNotification: false
  }),
  [`${ApiConstants.LOAD_COMPANY_ACCOUNTS}_TOGGLE_PRINT_INVOICE_FOR_PDF`]: (
    state,
    { payload }
  ) => ({
    isPrintInvoicePdfForTransferMoney: payload
  })
});
