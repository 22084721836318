import { Box } from 'edenred-ui';
import styled from 'styled-components';

export const ModalContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ModalTitle = styled(Box)`
  font-size: ${({ theme }) => theme.fontSize};
  font-weight: ${({ theme }) => theme.fontBold};
`;

export const ModalMessage = styled(Box)`
  font-size: ${({ theme }) => theme.fontSize};
  margin: 24px 0;
`;

export const Bold = styled.span`
  font-weight: ${({ theme }) => theme.fontBold};
`;
