import { takeLatest, call, put, select, all } from 'redux-saga/effects';
import { get } from 'lodash';
import history from '../routes/history';
import {
  getNavigationHistory,
  getLastSavedPath
} from '../selectors/navigation';
import { getApplicationLanguageId } from '../selectors/application';
import { removeLocale, addLocale, replaceParams } from '../helpers/UrlHelpers';
import { routerPaths } from '../constants/routerPaths';
import { NavigationConstants } from '../constants/actions';

export function IsExternalUrl(url) {
  const external = RegExp(`^((f|ht)tps?:)?//(?!${window.location.host})`);
  return external.test(url);
}

export function* replacePage({ url }) {
  const languageId = yield select(getApplicationLanguageId);
  const localizedUrl = yield call(addLocale, languageId, url);

  yield call(history.replace, localizedUrl);
}

export function* goToPage({ url, target, params = {} }) {
  if (target && IsExternalUrl(url)) {
    window.open(url, target);
    return;
  }

  if (IsExternalUrl(url)) {
    window.location = url;
    return;
  }

  const parameterizedUrl = replaceParams(url, params);
  // add language
  const languageId = yield select(getApplicationLanguageId);
  const localizedUrl = yield call(addLocale, languageId, parameterizedUrl);
  const currentUrl = get(history, 'location.pathname') || '';
  if (localizedUrl !== currentUrl) {
    yield call(history.push, localizedUrl);
  }
}

export function* goToReturnUrl({ sessionExpired = false } = {}) {
  const routesToSkip = [routerPaths.login, routerPaths.newPassword];
  const lastVisitedPages = yield select(getNavigationHistory);

  const index = [...lastVisitedPages]
    .splice(1)
    .findIndex(url => !routesToSkip.some(route => url.endsWith(route)));

  if (index !== -1) {
    yield put({
      type: `${NavigationConstants.DELETE_LAST_LOCATION_HISTORY}`,
      entries: index + 1
    });
  }

  const returnUrl = index === -1 ? '' : lastVisitedPages[index + 1];

  yield put({
    type: `${NavigationConstants.GO_TO_PAGE}`,
    url: sessionExpired ? routerPaths.login : returnUrl || '/'
  });
}

export function* saveLocation({ currentLocation }) {
  const lastSavedLocation = yield select(getLastSavedPath);
  const path = removeLocale((currentLocation || {}).pathname || '');

  if (!path || lastSavedLocation === path) {
    return;
  }

  yield put({
    type: `${NavigationConstants.ADD_LOCATION_HISTORY}`,
    path
  });
}

export function* watch() {
  yield all([
    takeLatest(`${NavigationConstants.REPLACE_PAGE}`, replacePage),
    takeLatest(`${NavigationConstants.GO_TO_PAGE}`, goToPage),
    takeLatest(`${NavigationConstants.GO_RETURN_URL}`, goToReturnUrl),
    takeLatest(`${NavigationConstants.SAVE_LOCATION_HISTORY}`, saveLocation)
  ]);
}
