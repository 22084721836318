import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'moment';
import { Table } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { LoadingSpinner } from 'edenred-ui';

import { DownloadReceiptButton } from '@epi-components/DownloadReceiptButton/DownloadReceiptButton';
import SortingButton from '@epi-components/SortingButton';
import { getApplicationLocale } from '@epi-selectors/application';
import { DocumentSourceTypes } from '@epi-constants/documentSourceTypes';

import { TransactionsHistoryDateFormat } from '../../constants/dateFormats';
import { formatCurrencyBalance } from '../../helpers/numeral';
import { TransactionsHistorySorting } from '../../constants/transactionsHistorySorting';

import './TransactionsHistoryTable.scss';

class TransactionsHistoryTable extends PureComponent {
  loadingContent = (
    <tr className="loading text-center">
      <td colSpan="7">
        <LoadingSpinner />
      </td>
    </tr>
  );

  noContent = i18n => (
    <tr className="no-content text-center">
      <td colSpan="7">{i18n.t('containers.transactions_history.no_data')}</td>
    </tr>
  );

  getHistoryContent = transactions => {
    const { i18n } = this.props;
    const transactionRows = transactions.map(row => [
      <tr className="standard-row" key={`standard${row.id}`}>
        <td>{Moment(row.date).format(TransactionsHistoryDateFormat)}</td>
        <td>
          {row.title}
          <br />
          <span className="hidden-xs subtitle">{row.description}</span>
        </td>
        <td>
          <div className="pull-right">
            {row.isDeposit && (
              <DownloadReceiptButton
                id="download-receipt-btn"
                sourceId={row.orderId || row.id}
                sourceType={
                  row.orderId !== null && row.orderId > 0
                    ? DocumentSourceTypes.Order
                    : DocumentSourceTypes.Transaction
                }
                className="btn"
              >
                <span>{i18n.t('containers.transactions_history.receipt')}</span>
              </DownloadReceiptButton>
            )}
          </div>
        </td>
        <td className="centered">{formatCurrencyBalance(row.balanceChange)}</td>
      </tr>
    ]);
    return transactionRows;
  };

  getContent = () => {
    const { transactions, isLoading, i18n } = this.props;

    if (isLoading) {
      return this.loadingContent;
    }

    if (!transactions || transactions.length === 0) {
      return this.noContent(i18n);
    }
    return this.getHistoryContent(transactions);
  };

  render() {
    const { i18n, isLoading, sortBy, sortDirection, onSortingChange } =
      this.props;

    return (
      <div className="TransactionsHistoryTable">
        <Table responsive size="sm" striped>
          <thead>
            <tr>
              <th>
                <SortingButton
                  id="transactions-history-sort-by-date-btn"
                  disabled={isLoading}
                  sortBy={TransactionsHistorySorting.ByDate}
                  sortedBy={sortBy}
                  sortDirection={sortDirection}
                  onSortingChange={onSortingChange}
                >
                  {i18n.t('containers.transactions_history.date_col')}
                </SortingButton>
              </th>
              <th>
                <SortingButton
                  id="transactions-history-sort-by-description-btn"
                  disabled={isLoading}
                  sortBy={TransactionsHistorySorting.ByDescription}
                  sortedBy={sortBy}
                  sortDirection={sortDirection}
                  onSortingChange={onSortingChange}
                >
                  {i18n.t('containers.transactions_history.transaction')}
                </SortingButton>
              </th>
              <th>&nbsp;</th>
              <th className="centered">
                <SortingButton
                  id="transactions-history-sort-by-amount-btn"
                  disabled={isLoading}
                  sortBy={TransactionsHistorySorting.ByAmount}
                  sortedBy={sortBy}
                  sortDirection={sortDirection}
                  onSortingChange={onSortingChange}
                >
                  {i18n.t('containers.transactions_history.amount')}
                </SortingButton>
              </th>
            </tr>
          </thead>
          <tbody>{this.getContent()}</tbody>
        </Table>
      </div>
    );
  }
}

TransactionsHistoryTable.propTypes = {
  i18n: PropTypes.object.isRequired,
  transactions: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortDirection: PropTypes.bool.isRequired,
  onSortingChange: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  locale: getApplicationLocale(state)
});

const TransactionsHistoryTableWithI18n = connect(mapStateToProps)(
  withTranslation()(TransactionsHistoryTable)
);

export { TransactionsHistoryTableWithI18n as TransactionsHistoryTable };
