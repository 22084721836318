import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { getRequest } from './ApiRepository';

export function* getPaymentStatus(paymentId) {
  return yield call(
    getRequest,
    `${config.apiHost}/api/payments/${paymentId}/status`,
    Authorize.IfLogged
  );
}
