import styled from 'styled-components';

export const CurrentBalance = styled.div`
  font-size: ${({ theme }) => theme.fontSize};
  display: flex;
  align-items: flex-end;
`;

export const BalanceText = styled.span`
  color: ${({ theme }) => theme.gray50};
  line-height: ${({ theme }) => theme.fontSize};
  padding: 0 25px 0 15px;
`;

export const BalanceAmount = styled.span`
  font-size: ${({ theme }) => theme.sizeExtraExtraLarge};
  line-height: ${({ theme }) => theme.sizeExtraExtraLarge};
  font-weight: ${({ theme }) => theme.fontSemiBold};
`;
