import { createReducer } from '@reduxjs/toolkit';
import { setReCaptchaAsLoaded } from '@epi-actions/reCaptchaActions';

export const initialReCaptchaState = {
  isLoaded: false
};

export const reCaptchaReducer = createReducer(initialReCaptchaState, {
  [setReCaptchaAsLoaded]: state => ({
    ...state,
    isLoaded: true
  })
});
