import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Notification, NotificationType } from 'edenred-ui';

function AccountCreatedNotification({ location }) {
  const { t } = useTranslation();
  const params = new URLSearchParams(location.search);
  const shouldShowNotification = params.get('created-account') === 'true';

  const [isVisible, setVisible] = useState(shouldShowNotification);

  return (
    isVisible && (
      <Notification
        text={t('login.created_account_notification')}
        type={NotificationType.Success}
        onClose={() => setVisible(false)}
      />
    )
  );
}

AccountCreatedNotification.propTypes = {
  location: PropTypes.object.isRequired
};

const ConnectedAccountCreatedNotification = withRouter(
  AccountCreatedNotification
);

export { ConnectedAccountCreatedNotification as AccountCreatedNotification };
