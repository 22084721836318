import { createReducer } from '@reduxjs/toolkit';

import { FormArrays, FormFields, InvoicingMethod } from '@epi-forms/helpers';

import { ContractStep, getContractSteps } from '../../helpers';
import { backToStep, submitStep, updateStepData } from '../actions';
import { signContract } from '../thunks';

export const initialContractStepData = {
  [ContractStep.CompanyInformation]: {
    [FormFields.CompanyName]: '',
    [FormFields.BusinessId]: '',
    [FormFields.NumberOfEmployees]: '',
    [FormFields.Address]: '',
    [FormFields.ZipCode]: '',
    [FormFields.City]: '',
    [FormFields.Country]: 'FI'
  },
  [ContractStep.InvoicingInformation]: {
    [FormFields.CopyCompanyAddress]: false,
    [FormFields.Address]: '',
    [FormFields.ZipCode]: '',
    [FormFields.City]: '',
    [FormFields.Country]: 'FI',
    [FormFields.InvoicingMethod]: InvoicingMethod.Email,
    [FormFields.EmailAddress]: '',
    [FormFields.EInvoiceAddress]: '',
    [FormFields.Operator]: ''
  },
  [ContractStep.ContactPerson]: {
    [FormArrays.ContactPersons]: [
      {
        [FormFields.FirstName]: '',
        [FormFields.LastName]: '',
        [FormFields.JobTitle]: '',
        [FormFields.EmailAddress]: '',
        [FormFields.PhoneNumber]: '+358'
      }
    ]
  },
  [ContractStep.ContractSignatory]: {
    [FormFields.CopyContactPerson]: false,
    [FormFields.FirstName]: '',
    [FormFields.LastName]: '',
    [FormFields.EmailAddress]: '',
    [FormFields.RightToSign]: false,
    [FormFields.AcceptTerms]: false
  }
};

export const initialContractState = {
  activeStep: ContractStep.CompanyInformation,
  stepsData: initialContractStepData,
  isProcessingSignContract: false
};

export const contractReducer = createReducer(initialContractState, {
  [submitStep]: (state, { payload }) => {
    const steps = getContractSteps();
    const currentStepIndex = steps
      .map(step => step.name)
      .indexOf(state.activeStep);
    const nextStepIndex = currentStepIndex + 1;

    return {
      ...state,
      activeStep:
        nextStepIndex < steps.length
          ? steps[nextStepIndex].name
          : state.activeStep,
      stepsData: {
        ...state.stepsData,
        [steps[currentStepIndex].name]: payload.data
      }
    };
  },
  [backToStep]: (state, { payload }) => ({
    ...state,
    activeStep: payload.step,
    stepsData: {
      ...state.stepsData,
      [state.activeStep]: payload.data
    }
  }),
  [updateStepData]: (state, { payload }) => ({
    ...state,
    stepsData: {
      ...state.stepsData,
      [state.activeStep]: {
        ...state.stepsData[state.activeStep],
        ...payload.data
      }
    }
  }),
  [signContract.pending]: state => ({
    ...state,
    isProcessingSignContract: true
  }),
  [signContract.fulfilled]: state => ({
    ...state,
    isProcessingSignContract: false
  }),
  [signContract.rejected]: state => ({
    ...state,
    isProcessingSignContract: false
  })
});
