import React from 'react';
import { useTheme } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Text } from 'edenred-ui';

import { ExternalPayment } from '@epi-forms/PaymentMethod/ExternalPayment/ExternalPayment';
import { PaymentMethod } from '@epi-forms/PaymentMethod/PaymentMethod';
import { BoxOval } from '@epi-shared/components';
import { FormFields } from '@epi-forms/helpers';
import { OrderType } from '@epi-constants/orderType';
import { PaymentMethods } from '@epi-constants/paymentMethods';
import { usePaymentMethodOptions } from '@epi-containers/LoadCompanyAccount/hooks';
import { IPaymentMethodOptions } from '@epi-models/containers/LoadCompanyAccount';
import {
  DISABLED_METHODS,
  HIDDEN_METHODS
} from '@epi-constants/loadCompanyAccount';

export const PaymentMethodOptions = ({
  submitCompleteForm,
  disabled,
  isPaymentProcessing
}: IPaymentMethodOptions) => {
  const { gray10, lightBlue10 } = useTheme();
  const { t } = useTranslation();
  const { watch } = useFormContext();
  const {
    calculateTotalAmount,
    apiPaymentMethods,
    isLessThanMinValue,
    onlinePaymentMethods,
    getErrorMessageKey
  } = usePaymentMethodOptions();

  const method = watch(FormFields.PaymentMethod);

  return (
    <Box p={2} border={1} borderColor={gray10}>
      <Box display="flex" alignItems="flex-start" px={8} pb={2} pt={8}>
        <BoxOval text="2" bgColor={lightBlue10} />
        <Box flex={1} pt={1}>
          <Text>{t('containers.deposit_money.payment_method_title')}</Text>
        </Box>
        <Box display="flex" alignItems="center" flex={1}>
          <PaymentMethod
            paymentMethods={apiPaymentMethods}
            onClickPaymentMethod={submitCompleteForm}
            disabled={disabled}
            creditCardFeePercent={0}
            amountValue={calculateTotalAmount()}
            disabledMethods={DISABLED_METHODS}
            hiddenMethods={HIDDEN_METHODS}
            isPaymentProcessing={isPaymentProcessing}
            orderType={OrderType.digital}
            isLoadCompanyAccountPage
          />
        </Box>
      </Box>
      <Box px={8}>
        <Box
          sx={{
            display: method === PaymentMethods.Online ? 'block' : 'none'
          }}
        >
          <ExternalPayment
            isError={apiPaymentMethods.isError || isLessThanMinValue()}
            errorMessageKey={getErrorMessageKey(
              'forms.payment_method.online_payment'
            )}
            isLoading={apiPaymentMethods.isLoading}
            disabled={disabled}
            methods={onlinePaymentMethods}
            onClickPaymentMethod={submitCompleteForm}
            isProcessing={isPaymentProcessing}
          />
        </Box>
      </Box>
    </Box>
  );
};
