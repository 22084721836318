import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { getRequest } from './ApiRepository';

export const companyBeneficiariesApiUrl = `${config.apiHost}/api/company-beneficiaries`;

export const companyBeneficiaryApiUrl = id =>
  `${companyBeneficiariesApiUrl}/${id}`;

export function* getAllCompanyBeneficiaries() {
  return yield call(getRequest, companyBeneficiariesApiUrl, Authorize.Always);
}

export const checkBeneficiarySSNUrl = `${companyBeneficiariesApiUrl}/check-SSN`;
