import React from 'react';
import { DownloadIcon, InProgressIcon } from 'edenred-ui';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { SingleClickButton } from '@epi-components/SingleClickButton/SingleClickButton';
import {
  isLoadingReceiptForDeposit,
  isLoadingReceiptForTransaction
} from '@epi-selectors/api';
import {
  downloadReceiptForDepositOrder,
  downloadReceiptForTransaction
} from '@epi-actions/api';
import { DocumentSourceTypes } from '@epi-constants/documentSourceTypes';

function downloadReceiptBySource(sourceType) {
  if (sourceType === DocumentSourceTypes.Transaction) {
    return downloadReceiptForTransaction;
  }
  return downloadReceiptForDepositOrder;
}

function isLoadingReceiptBySource(sourceType) {
  if (sourceType === DocumentSourceTypes.Transaction) {
    return isLoadingReceiptForTransaction;
  }
  return isLoadingReceiptForDeposit;
}

function DownloadReceiptButton({
  isLoading,
  downloadReceipt,
  sourceId,
  sourceType,
  children,
  ...xtraProps
}) {
  const showSpinner = isLoading(sourceId, sourceType);

  return (
    <div>
      <SingleClickButton
        {...xtraProps}
        disabled={showSpinner}
        onClick={() => {
          if (!showSpinner) {
            downloadReceipt(sourceId, sourceType);
          }

          return false;
        }}
      >
        {showSpinner ? <InProgressIcon /> : <DownloadIcon />}
        {children}
      </SingleClickButton>
    </div>
  );
}

DownloadReceiptButton.propTypes = {
  sourceId: PropTypes.number.isRequired,
  sourceType: PropTypes.string,
  downloadReceipt: PropTypes.func.isRequired,
  isLoading: PropTypes.func.isRequired,
  children: PropTypes.node
};

DownloadReceiptButton.defaultProps = {
  children: null,
  sourceType: DocumentSourceTypes.Order
};

const mapStateToProps = state => ({
  isLoading: (sourceId, sourceType) =>
    isLoadingReceiptBySource(sourceType)(sourceId)(state)
});

const mapDispatchToProps = dispatch => ({
  downloadReceipt: (sourceId, sourceType) =>
    dispatch(downloadReceiptBySource(sourceType)(sourceId))
});

const ConnectedDownloadReceiptButton = connect(
  mapStateToProps,
  mapDispatchToProps
)(DownloadReceiptButton);

export { ConnectedDownloadReceiptButton as DownloadReceiptButton };
