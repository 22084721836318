import memoize from 'memoize-one';
import { createSelector, createStructuredSelector } from 'reselect';
import { orderBy } from 'lodash';
import { getAvailableVouchersByType } from './api';
import { commonOrderSummary, getVoucher } from './voucherOrder';

export const isError = state => state.voucherReorder.isError;

export const getVoucherReorder = state => state.voucherReorder.voucherData;

export const getReorderVoucher = createSelector(
  getVoucherReorder,
  state => state,
  (voucherReorder, state) => {
    const voucher = getVoucher(voucherReorder.voucherId)(state) || {};
    const faceValue = voucher.value || 0;
    return {
      faceValue,
      ...voucher,
      ...voucherReorder
    };
  }
);

export const isVoucherFaceValueAvailable = createSelector(
  getReorderVoucher,
  voucher => voucher.available === true
);

export const isVoucherFaceValueUnavailable = createSelector(
  isVoucherFaceValueAvailable,
  available => !available
);

export const getVoucherTypeKey = createSelector(
  getReorderVoucher,
  voucher => voucher.type || ''
);

export const getEquivalentAvailableVoucher = originalVoucher => state => {
  const vouchers = getAvailableVouchersByType(originalVoucher.type)(state);
  const sortedVouchers = orderBy(vouchers, item => item.value, 'asc') || [];

  if (sortedVouchers.length === 0) {
    return {};
  }
  const higherFaceValueVouchers = sortedVouchers.filter(
    v => v.value >= originalVoucher.value
  );

  if (higherFaceValueVouchers.length !== 0) {
    return higherFaceValueVouchers[0];
  }

  const lowerFaceValueVouchers = sortedVouchers.filter(
    v => v.value <= originalVoucher.value
  );

  return lowerFaceValueVouchers.slice(-1)[0];
};

export const getFaceValueChangedData = createSelector(
  isVoucherFaceValueAvailable,
  getReorderVoucher,
  state => state,
  (faceAvailable, originalVoucher, state) => {
    if (faceAvailable) {
      return null;
    }

    return {
      oldValue: originalVoucher.value,
      newValue: getEquivalentAvailableVoucher(originalVoucher)(state).value
    };
  }
);

export const getReorderVoucherId = createSelector(
  getReorderVoucher,
  isVoucherFaceValueAvailable,
  state => state,
  (originalVoucher, voucherFaceValueAvailable, state) => {
    if (voucherFaceValueAvailable) {
      return originalVoucher.voucherId;
    }
    return getEquivalentAvailableVoucher(originalVoucher)(state).id;
  }
);

const reorderVoucherQtySelector = state =>
  state.voucherReorder.voucherData.quantity;

export const getVoucherReorderDetails = createStructuredSelector({
  voucherId: getReorderVoucherId,
  quantity: reorderVoucherQtySelector
});

export const orderSummary = memoize(formName =>
  commonOrderSummary(formName, getReorderVoucher)
);
