import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Menu,
  SimpleButton,
  EditIcon,
  DeleteIcon,
  MessageIcon,
  Box
} from 'edenred-ui';

import { enableSlideoutEditMode, enableEditMode } from '@epi-actions/company';
import config from '@epi-config';

const { disableEditOptionsForOA } = config;

const StyledButton = styled(SimpleButton)`
  flex: 1;
  display: flex;
  font-size: ${({ theme }) => theme.sizeMedium};
`;

function ContactActions({
  anchorEl,
  closeMenu,
  dispatch,
  userActive,
  openModalAndFillForm,
  resendActivationEmail,
  handleDeleteAction
}) {
  const { t } = useTranslation();

  const handleEditAction = () => {
    closeMenu();
    dispatch(enableSlideoutEditMode());
    dispatch(enableEditMode());
    openModalAndFillForm();
  };

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={closeMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Menu.Item onClick={null} disabled={Boolean(disableEditOptionsForOA)}>
        {userActive ? (
          <StyledButton
            onClick={handleEditAction}
            icon={
              <Box mr="10px">
                <EditIcon />
              </Box>
            }
            text={t('company_info.contacts.actions.edit')}
            sx={{ width: '100%' }}
            disabled={Boolean(disableEditOptionsForOA)}
            id="contact-action_edit"
          />
        ) : (
          <StyledButton
            onClick={resendActivationEmail}
            icon={
              <Box mr="10px">
                <MessageIcon />
              </Box>
            }
            text={t('company_info.contacts.actions.resend_email')}
            sx={{ width: '100%' }}
            id="contact-action_resend-email"
          />
        )}
      </Menu.Item>
      <Menu.Item onClick={null} disabled={Boolean(disableEditOptionsForOA)}>
        <StyledButton
          onClick={handleDeleteAction}
          icon={
            <Box mr="10px">
              <DeleteIcon />
            </Box>
          }
          text={t('company_info.contacts.actions.delete')}
          sx={{ width: '100%' }}
          disabled={Boolean(disableEditOptionsForOA)}
          id="contact-action_delete"
        />
      </Menu.Item>
    </Menu>
  );
}

ContactActions.propTypes = {
  voucherOrder: PropTypes.object,
  anchorEl: PropTypes.object,
  closeMenu: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  openModalAndFillForm: PropTypes.func.isRequired,
  userActive: PropTypes.bool.isRequired,
  resendActivationEmail: PropTypes.func,
  handleDeleteAction: PropTypes.func.isRequired
};

ContactActions.defaultProps = {
  voucherOrder: null,
  anchorEl: null
};

const ConnectedContactActions = connect()(ContactActions);

export { ConnectedContactActions as ContactActions };
