import {
  ApiConstants,
  OverlaysConstants,
  CompanyInfoConstants
} from '@epi-constants/actions';

export const checkIfCompanyExists = (company, callback) => ({
  type: `${ApiConstants.CHECK_IF_COMPANY_EXISTS}_REQUEST`,
  company,
  callback
});

export const closeCompanyExistsModal = () => ({
  type: `${OverlaysConstants.CLOSE_COMPANY_EXISTS_MODAL}`
});

export const checkIfCompanyExistsFinished = (cancel = false) => ({
  type: `${ApiConstants.CHECK_IF_COMPANY_EXISTS}_FINISHED`,
  cancel
});

export const setContactsOrder = (sortingBy, ascending) => ({
  type: `${CompanyInfoConstants.SET_CONTACTS_ORDER}`,
  sortingBy,
  ascending
});

export const openSlideout = () => ({
  type: `${CompanyInfoConstants.SHOW_CONTACT_SLIDEOUT}`
});

export const closeSlideout = () => ({
  type: `${CompanyInfoConstants.HIDE_CONTACT_SLIDEOUT}`
});

export const enableSlideoutEditMode = () => ({
  type: `${CompanyInfoConstants.ENABLE_SLIDEOUT_EDIT_MODE}`
});

export const disableSlideoutEditMode = () => ({
  type: `${CompanyInfoConstants.DISABLE_SLIDEOUT_EDIT_MODE}`
});

export const enableEditMode = () => ({
  type: `${CompanyInfoConstants.ENABLE_EDIT_MODE}`
});

export const disableEditMode = () => ({
  type: `${CompanyInfoConstants.DISABLE_EDIT_MODE}`
});

export const fillCompanyInfoForm = (form, values) => ({
  type: `${CompanyInfoConstants.FILL_COMPANY_FORM}`,
  values,
  form
});

export const unblockCompanyInfoForm = form => ({
  type: `${CompanyInfoConstants.UNBLOCK_COMPANY_FORM}`,
  form
});
