import React from 'react';
import { useFormContext } from 'react-hook-form';
import { get } from 'lodash';
import {
  InputAdornment,
  TextFieldSpinner,
  TextFieldSuccessIndicator
} from 'edenred-ui';
import {
  getUnverifiedErrorName,
  getInvalidErrorName,
  getProcessingErrorName
} from './asyncValidatorErrorNames';

export function useAsyncValidatorController({
  value,
  error,
  name,
  invalidMsg,
  processingMsg,
  isStatusSuccess = isSuccess => isSuccess
}) {
  const {
    formState: { errors },
    trigger,
    setError,
    clearErrors
  } = useFormContext();

  const isInvalidError = !!get(errors, getInvalidErrorName(name));
  const isProcessingError = !!get(errors, getProcessingErrorName(name));

  const isError = !!(error || isInvalidError);
  const isInfo = !isError && isProcessingError;

  const helperText =
    error?.message ||
    (isInvalidError && invalidMsg) ||
    (isProcessingError && processingMsg);

  const getInputProps = () => {
    if (isProcessingError) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <TextFieldSpinner id={`${name}-loading`} />
          </InputAdornment>
        )
      };
    }

    if (
      isStatusSuccess(
        !isError && value && !get(errors, getUnverifiedErrorName(name))
      )
    ) {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <TextFieldSuccessIndicator id={`${name}-success`} />
          </InputAdornment>
        )
      };
    }

    return null;
  };

  return {
    isError,
    isInfo,
    helperText,
    inputProps: getInputProps(),
    setAsyncUnverified() {
      setError(getUnverifiedErrorName(name));
    },
    clearAsyncUnverified() {
      const errorName = getUnverifiedErrorName(name);
      clearErrors(errorName);
      trigger(errorName);
    }
  };
}
