import { put, call } from 'redux-saga/effects';
import { createNewPayment as createNewPaymentRepo } from '../../repositories/PaymentsRepository';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';
import { routerPaths } from '../../constants/routerPaths';
import {
  ApiConstants,
  OverlaysConstants,
  NavigationConstants
} from '../../constants/actions';

export default function* createNewPayment(payload) {
  try {
    const createNewPaymentResponse = yield call(createNewPaymentRepo, payload);

    yield put({
      type: `${ApiConstants.CREATE_NEW_PAYMENT}_SUCCESS`,
      payload: createNewPaymentResponse
    });
    yield put({
      type: `${NavigationConstants.REPLACE_PAGE}`,
      url: `${routerPaths.maksuturvaPayment}?paymentId=${payload.payload.paymentPublicId}`
    });
  } catch (ex) {
    // change the loading status
    yield put({
      type: `${ApiConstants.CREATE_NEW_PAYMENT}_FAILURE`,
      payload: ex
    });
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    } else {
      // display the error message
      yield put({
        type: `${OverlaysConstants.SHOW_ERROR_POPUP}`,
        closable: false
      });
    }
  }
}
