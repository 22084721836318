import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PagePanel } from 'edenred-ui';

import { getApplicationLanguageId } from '@epi-selectors/application';
import { getSupportContent } from '@epi-repositories/SupportRepository';

import { SupportLinks } from './SupportLinks';
import { SupportContact } from './SupportContact';
import { SupportSocials } from './SupportSocials';

export const Support = () => {
  const language = useSelector(getApplicationLanguageId);
  const { t } = useTranslation();
  const content = getSupportContent().sections.filter(
    item => item.language === language
  );

  return (
    <PagePanel title={t('containers.help.title')}>
      <SupportLinks data={content} />
      <SupportContact />
      <SupportSocials />
    </PagePanel>
  );
};
