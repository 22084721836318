import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Box, Grid, LoadingSpinner, SimpleButton, EditIcon } from 'edenred-ui';

import {
  dismissApiErrors,
  getUserCompany,
  updateUserCompany
} from '@epi-actions/api';
import {
  getUserCompany as getUserCompanySelector,
  isCompanyLoaded as isCompanyLoadedSelector,
  isProcessingCompanyUpdateSelector
} from '@epi-selectors/api';
import { ApiConstants } from '@epi-constants/actions';
import { routerPaths } from '@epi-constants/routerPaths';
import {
  AddressField,
  BusinessIdField,
  CityField,
  CompanyNameField,
  CountryField,
  CustomerNumberField,
  NumberOfEmployeesField,
  ZipCodeField
} from '@epi-forms/controls';
import { FormFields } from '@epi-forms/helpers';
import { isSignatorySelector, getLoginState } from '@epi-selectors/login';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';
import config from '@epi-config';
import {
  addressLine,
  maxLength,
  required,
  poBoxNotAllowed
} from '@epi-helpers/formHelpers/validators';

import { ErrorPanel } from '../../../../components/ErrorPanel/ErrorPanel';
import { getCompanyDataFormValues, mapFormToCompanyData } from '../../helpers';
import { CompanyDataInvoicing } from '../CompanyDataInvoicing';

const { disableEditOptionsForOA } = config;

function CompanyData({
  location,
  autoRedirect,
  userCompany,
  isCompanyLoaded,
  isSignatory,
  dispatch,
  isProcessingCompanyUpdate,
  updateFooter
}) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = useState(false);
  const { isUserImpersonate } = useSelector(getLoginState);
  const pathName = location.pathName || '';
  const propagateAddress =
    pathName.includes(routerPaths.voucherOrderUrlPrefix) ||
    pathName.includes(routerPaths.voucherReorderUrlPrefix);

  const form = useForm({
    mode: 'onChange',
    shouldUnregister: true,
    defaultValues: getCompanyDataFormValues(isSignatory, null)
  });

  const {
    watch,
    reset,
    setValue,
    handleSubmit,
    formState: { errors }
  } = form;

  const selectedCountry = watch(FormFields.Country);

  function resetCompany() {
    if (isCompanyLoaded) {
      reset();

      setEditMode(false);
      Object.entries(
        getCompanyDataFormValues(isSignatory, userCompany)
      ).forEach(([field, value]) => setValue(field, value));
    }
  }

  useEffect(() => {
    dispatch(dismissApiErrors());
    dispatch(getUserCompany());
  }, []);

  useEffect(() => resetCompany(), [isCompanyLoaded, userCompany]);

  const onSubmit = values => {
    const companyData = mapFormToCompanyData(values, isSignatory, userCompany);
    dispatch(
      updateUserCompany(
        companyData,
        autoRedirect,
        propagateAddress,
        isUserImpersonate
      )
    );
  };

  useEffect(() => {
    updateFooter({
      hidden: !editMode,
      submitText: t('company_info.save_btn'),
      onSubmit: handleSubmit(onSubmit),
      disableSubmit: !isEmptyObj(errors) || isProcessingCompanyUpdate,
      cancelText: t('company_info.cancel_btn'),
      onCancel: () => resetCompany()
    });
  });

  useEffect(() => {
    return () =>
      updateFooter({
        hidden: true
      });
  }, []);

  return (
    <FormProvider {...form}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Box
          display="flex"
          justifyContent="flex-end"
          my={3}
          visibility={editMode ? 'hidden' : 'visible'}
        >
          <SimpleButton
            id="company-info-edit-btn"
            icon={<EditIcon />}
            text={t('company_info.edit_btn')}
            onClick={() => setEditMode(true)}
            disabled={Boolean(disableEditOptionsForOA)}
          />
        </Box>
        <Box
          maxWidth={936}
          minHeight={680}
          mx="auto"
          sx={{
            '& .MuiInputBase-input.Mui-disabled': {
              WebkitTextFillColor: 'rgb(37, 37, 37)'
            }
          }}
        >
          {!isCompanyLoaded && <LoadingSpinner />}
          <ErrorPanel failedActionName={ApiConstants.UPDATE_USER_COMPANY} />
          <Grid container spacing={3}>
            <Grid item xs={4}>
              <CompanyNameField disabled required={false} />
            </Grid>
            <Grid item xs={8} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={5}>
                  <BusinessIdField disabled required={false} />
                </Grid>
                <Grid item xs={6} md={7}>
                  <CustomerNumberField disabled required={false} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <NumberOfEmployeesField
                disabled={!editMode}
                required={editMode}
              />
            </Grid>
            <Grid item xs={8} md={4}>
              <AddressField
                disabled={!editMode}
                required={editMode}
                validate={[
                  required,
                  maxLength(50),
                  addressLine,
                  poBoxNotAllowed
                ]}
              />
            </Grid>
            <Grid item xs={8} md={4}>
              <Grid container spacing={3}>
                <Grid item xs={6} md={5}>
                  <ZipCodeField
                    disabled={!editMode}
                    selectedCountry={selectedCountry}
                    required={editMode}
                  />
                </Grid>
                <Grid item xs={6} md={7}>
                  <CityField disabled={!editMode} required={editMode} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4} md={4}>
              <CountryField disabled required={false} />
            </Grid>
          </Grid>
          {isSignatory && <CompanyDataInvoicing editMode={editMode} />}
        </Box>
      </form>
    </FormProvider>
  );
}

CompanyData.propTypes = {
  userCompany: PropTypes.object,
  isCompanyLoaded: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  autoRedirect: PropTypes.bool.isRequired,
  isSignatory: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  isProcessingCompanyUpdate: PropTypes.bool.isRequired,
  updateFooter: PropTypes.func.isRequired
};

CompanyData.defaultProps = {
  userCompany: null
};

const mapStateToProps = state => ({
  isCompanyLoaded: isCompanyLoadedSelector(state),
  userCompany: getUserCompanySelector(state),
  isSignatory: isSignatorySelector(state),
  isProcessingCompanyUpdate: isProcessingCompanyUpdateSelector(state)
});

const ConnectedCompanyData = connect(mapStateToProps)(withRouter(CompanyData));

export { ConnectedCompanyData as CompanyData };
