import React from 'react';
import PropTypes from 'prop-types';
import { Box } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { currencyCommaConvert } from '@epi-helpers/numeral';

import {
  DetailsBox,
  DetailsBoxHeader,
  StatusDot
} from '../../TopupReports.styles';
import { reportStatusesColors, formatDate } from '../../utils';

export const TopupReportsDetailsBox = ({ reportDetails }) => {
  const { t } = useTranslation();

  return (
    <DetailsBox>
      <Box>
        <DetailsBoxHeader>
          {t('containers.deposit_money.reports_tab.date')}
        </DetailsBoxHeader>
        {formatDate(reportDetails.date)}
      </Box>
      <Box>
        <DetailsBoxHeader>
          {t('containers.deposit_money.reports_tab.executed_by')}
        </DetailsBoxHeader>
        {reportDetails.firstName} {reportDetails.lastName}
      </Box>
      <Box>
        <DetailsBoxHeader>
          {t('containers.deposit_money.reports_tab.status')}
        </DetailsBoxHeader>
        <Box display="flex" alignItems="center">
          <StatusDot $dotColor={reportStatusesColors[reportDetails.status]} />
          {t(
            `containers.deposit_money.reports_tab.statuses.${reportDetails.status}`
          )}
        </Box>
      </Box>
      <Box>
        <DetailsBoxHeader>
          {t('containers.deposit_money.reports_tab.load')}
        </DetailsBoxHeader>
        {currencyCommaConvert(reportDetails.amount)}
      </Box>
      <Box>
        <DetailsBoxHeader>
          {t('containers.deposit_money.reports_tab.sum')}
        </DetailsBoxHeader>
        {currencyCommaConvert(reportDetails.totalSum)}
      </Box>
      <Box>
        <DetailsBoxHeader>
          {t('containers.deposit_money.reports_tab.total')}
        </DetailsBoxHeader>
        {currencyCommaConvert(reportDetails.totalSumWithFees)}
      </Box>
    </DetailsBox>
  );
};

TopupReportsDetailsBox.propTypes = {
  reportDetails: PropTypes.object.isRequired
};
