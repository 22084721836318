import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  Menu,
  SimpleButton,
  EditIcon,
  MessageIcon,
  Box,
  SearchIcon
} from 'edenred-ui';

import config from '@epi-config';

const { disableEditOptionsForOA } = config;

const StyledButton = styled(SimpleButton)`
  flex: 1;
  display: flex;
  font-size: ${({ theme }) => theme.sizeMedium};
  width: 100%;
`;

export function BeneficiaryActions({
  anchorEl,
  closeMenu,
  openModalAndFillForm,
  resendActivationEmail,
  endOfFundsDatePassed,
  openBeneficiary
}) {
  const { t } = useTranslation();

  const handleEditAction = () => {
    closeMenu();
    openModalAndFillForm();
  };

  const handleResendAction = () => {
    closeMenu();
    resendActivationEmail();
  };

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      {!endOfFundsDatePassed ? (
        <div>
          <Menu.Item onClick={null} disabled={Boolean(disableEditOptionsForOA)}>
            <StyledButton
              onClick={handleEditAction}
              icon={
                <Box mr="10px">
                  <EditIcon />
                </Box>
              }
              text={t('company_info.contacts.actions.edit')}
              disabled={Boolean(disableEditOptionsForOA)}
            />
          </Menu.Item>
          <Menu.Item>
            <StyledButton
              onClick={handleResendAction}
              icon={
                <Box mr="10px">
                  <MessageIcon />
                </Box>
              }
              text={t('containers.beneficiaries.table.resend')}
            />
          </Menu.Item>
        </div>
      ) : (
        <Menu.Item>
          <StyledButton
            onClick={openBeneficiary}
            icon={
              <Box mr="10px">
                <SearchIcon />
              </Box>
            }
            text={t('containers.beneficiaries.table.show_action')}
          />
        </Menu.Item>
      )}
    </Menu>
  );
}

BeneficiaryActions.propTypes = {
  anchorEl: PropTypes.object,
  closeMenu: PropTypes.func.isRequired,
  resendActivationEmail: PropTypes.func,
  openModalAndFillForm: PropTypes.func,
  endOfFundsDatePassed: PropTypes.bool,
  openBeneficiary: PropTypes.func
};
