import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { PagePanel, Button, ButtonSize, EditIcon } from 'edenred-ui';
import { useFormContext } from 'react-hook-form';

import { registeredVoucherOrderAction } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.slice';
import { getOrderData } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.selectors';
import { FormFields } from '@epi-forms/helpers';
import { getFinnishAddresses } from '@epi-selectors/api';

import { DeliveryMethods } from '../../constants/deliveryMethods';
import { LoadingContainer } from '../../components/LoadingContainer/LoadingContainer';
import { RoundButton } from '../../components/RoundButton/RoundButton';
import * as navigationActions from '../../actions/navigation';

import './DeliveryPicker.scss';

function DeliveryPicker({
  editPage,
  i18n,
  goToPage,
  isLoading,
  companyAdress
}) {
  const dispatch = useDispatch();
  const addresses = useSelector(getFinnishAddresses);
  const deliveryAddress = useSelector(getOrderData);
  const { setValue } = useFormContext();
  const isSavedAddressChosen =
    (deliveryAddress.deliveryMethod === DeliveryMethods.ToCompanyAddress ||
      deliveryAddress.deliveryMethod === DeliveryMethods.ToSavedAddress) &&
    addresses?.find(address => address.id === deliveryAddress.addressId);

  useEffect(() => {
    if (!deliveryAddress?.deliveryAddress && companyAdress?.addressLine) {
      dispatch(
        registeredVoucherOrderAction.addOrderInformation({
          addressId: companyAdress?.id,
          deliveryCompanyName: companyAdress?.companyName,
          deliveryAddress: companyAdress?.addressLine,
          deliveryZipCode: companyAdress?.postCode,
          deliveryCity: companyAdress?.city
        })
      );
    }
    setValue(FormFields.DeliveryMethod, DeliveryMethods.ToCompanyAddress);
    setValue(FormFields.AddressId, companyAdress?.id);
  }, [companyAdress?.addressLine]);

  const deliveryDetails = () => {
    if (isSavedAddressChosen) {
      return addresses.find(address => address.id === deliveryAddress.addressId)
        ?.label;
    }
    if (deliveryAddress.deliveryMethod === DeliveryMethods.ToPickupPoint) {
      return (
        <span>
          <a
            key="link"
            href={i18n.t(
              'forms.delivery_info.delivery_method.nearest_pickup_point.link_url'
            )}
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.t(
              'forms.delivery_info.delivery_method.nearest_pickup_point.link_text'
            )}
          </a>
        </span>
      );
    }
    return `${
      deliveryAddress.deliveryCompanyName
        ? deliveryAddress.deliveryCompanyName + ','
        : ''
    } 
        ${deliveryAddress.deliveryAddress}, 
        ${deliveryAddress.deliveryZipCode}, 
        ${deliveryAddress.deliveryCity}`;
  };

  return (
    <div className="DeliveryPicker">
      <PagePanel
        title={i18n.t('containers.voucher_order.delivery_info_header')}
        className="px-10"
      >
        {editPage && (
          <span className="visible-xs-inline">
            <RoundButton
              id="delivery-picker-edit-btn"
              icon={<EditIcon />}
              className="pull-right"
              onClick={() => goToPage(editPage)}
            />
          </span>
        )}
        <LoadingContainer isLoading={isLoading}>
          <Row className="pl-60">
            <Col xs={10} sm={12}>
              <div className="header3 small">
                {deliveryAddress.deliveryMethod ===
                DeliveryMethods.ToPickupPoint
                  ? i18n.t('containers.order_details.to_pickup_point')
                  : i18n.t('components.delivery.delivery_to_street')}
                <br />
                {deliveryDetails()}
              </div>
            </Col>
            {deliveryAddress.contactPersons ? (
              <Col xs={10} sm={12}>
                {`${i18n.t('components.delivery.contact_person')} ${
                  deliveryAddress.contactPersons
                }`}
              </Col>
            ) : null}
          </Row>
          {editPage && (
            <Row className="hidden-xs">
              <Col>
                <Button
                  className="btn-edit pull-right"
                  onClick={() => goToPage(editPage)}
                  size={ButtonSize.Medium}
                >
                  {i18n.t('components.benefit_summary.edit')}
                </Button>
              </Col>
            </Row>
          )}
        </LoadingContainer>
      </PagePanel>
    </div>
  );
}

DeliveryPicker.propTypes = {
  goToPage: PropTypes.func.isRequired,
  editPage: PropTypes.string,
  isLoading: PropTypes.bool,
  i18n: PropTypes.object.isRequired,
  companyAdress: PropTypes.object.isRequired
};

DeliveryPicker.defaultProps = {
  editPage: '',
  isLoading: false
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const DeliveryPickerWithI18n = withTranslation()(
  connect(null, mapDispatchToProps)(DeliveryPicker)
);

export { DeliveryPickerWithI18n as DeliveryPicker };
