import React from 'react';

export function EmailIcon() {
  return (
    <svg width="124" height="124" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path fill="#FFF" d="M0 0h124v124H0z" />
        <path d="M100 37v58H15V37h85Z" stroke="#0085CA" strokeWidth="2" />
        <path
          d="m15 37 37.1290415 33.6345435c3.0482544 2.7613599 7.6936626 2.7613599 10.741917 0L100 37"
          stroke="#0085CA"
          strokeWidth="2"
        />
        <circle fill="#162056" cx="100" cy="37" r="10" />
        <path stroke="#FFF" strokeWidth="2" d="m97 34.5 3-1V42" />
      </g>
    </svg>
  );
}
