import { createSelector } from 'reselect';

export const isLoading = actions =>
  createSelector(
    state => state.saga.loadingActions,
    loadingActions =>
      (loadingActions || []).some(item => actions.includes(item))
  );

export const isLoaded = actions =>
  createSelector(
    state => state.saga.loadedActions,
    loadedActions =>
      actions.every(
        action => loadedActions[action] && loadedActions[action].loaded
      )
  );

export const sagaDataLoaded = action => state =>
  state.saga.loadedActions[action];

export const getLoadedActions = state => state.saga.loadedActions;
