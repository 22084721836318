import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Heading3 } from 'edenred-ui';

import {
  AddressField,
  CityField,
  CountryField,
  EInvoice,
  EInvoiceAddressField,
  EmailAddressField,
  InvoicingMethodRadio,
  OperatorField,
  ZipCodeField
} from '@epi-forms/controls';
import { FormFields, InvoicingMethod } from '@epi-forms/helpers';
import { CompanyDataFormFields } from '../../helpers';

export function CompanyDataInvoicing({ editMode }) {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const selectedInvoicingMethod = watch(FormFields.InvoicingMethod);
  const selectedCountry = watch(CompanyDataFormFields.InvoicingCountry);

  return (
    <Box mt={5}>
      <Box mb={3}>
        <Heading3>{t('company_info.invoicing_address_heading')}</Heading3>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={8} md={4}>
          <AddressField
            name={CompanyDataFormFields.InvoicingAddress}
            disabled={!editMode}
            required={editMode}
          />
        </Grid>
        <Grid item xs={8} md={4}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={5}>
              <ZipCodeField
                name={CompanyDataFormFields.InvoicingZipCode}
                disabled={!editMode}
                selectedCountry={selectedCountry}
                required={editMode}
              />
            </Grid>
            <Grid item xs={6} md={7}>
              <CityField
                name={CompanyDataFormFields.InvoicingCity}
                disabled={!editMode}
                required={editMode}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} md={4}>
          <CountryField
            name={CompanyDataFormFields.InvoicingCountry}
            disabled={!editMode}
            required={editMode}
          />
        </Grid>
      </Grid>
      <Box mt={5} mb={3}>
        <Heading3>{t('company_info.invoicing_method_heading')}</Heading3>
      </Box>
      <Box display="flex">
        <InvoicingMethodRadio readOnly={!editMode} />
      </Box>
      <Box mt={3}>
        {
          {
            [InvoicingMethod.Email]: (
              <Box display="flex">
                <Box flex="1">
                  <EmailAddressField
                    readOnly={!editMode}
                    label="controls.email_address_label"
                  />
                </Box>
              </Box>
            ),
            [InvoicingMethod.EInvoice]: (
              <EInvoice>
                <Grid container spacing={3}>
                  <Grid item xs={8} md={7}>
                    <EInvoiceAddressField readOnly={!editMode} />
                  </Grid>
                  <Grid item xs={4} md={3}>
                    <OperatorField readOnly={!editMode} />
                  </Grid>
                </Grid>
              </EInvoice>
            )
          }[selectedInvoicingMethod]
        }
      </Box>
    </Box>
  );
}

CompanyDataInvoicing.propTypes = {
  editMode: PropTypes.bool.isRequired
};
