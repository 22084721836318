import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Row, Col, Collapse } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';

import { FormFields } from '@epi-forms/helpers';

import { formatNumeric, formatCurrency } from '../../helpers/numeral';
import { VoucherTypes } from '../../constants/voucherTypes';
import { TooltipInfo } from '../TooltipInfo/TooltipInfo';
import * as navigationActions from '../../actions/navigation';

import './BenefitSummaryContent.scss';

const LOCALE_PREFIX = 'components.benefit_summary';
function BenefitSummaryContent({
  i18n,
  showCreditCardFee,
  voucherTypeKey,
  orderSummary: {
    creditCardFeePercent,
    creditCardFee,
    withoutReference,
    vatFeePercent,
    vatFeeAmount,
    handlingFee
  },
  voucherOrderPayment
}) {
  const { watch } = useFormContext();

  const quantityValue =
    watch(FormFields.VoucherQuantity) || voucherOrderPayment?.totalQuantity;
  const voucherWorthValue =
    watch(FormFields.VoucherWorth) ||
    voucherOrderPayment?.faceValue ||
    voucherOrderPayment?.totalAmount / voucherOrderPayment?.totalQuantity;
  const sumValue = quantityValue * voucherWorthValue;
  const orderTotalWithoutVat = sumValue + handlingFee + creditCardFee;
  const totalAmountWithFees = orderTotalWithoutVat + vatFeeAmount;
  const formattedQuantity = `${formatNumeric(quantityValue, '0,0')}`;
  const formattedFaceValue = `${formatCurrency(voucherWorthValue)}`;
  const formattedSumVouchers = `${formatCurrency(sumValue)}`;

  return (
    <div
      className="BenefitSummaryContent benefit-summary-content"
      id="BenefitSummaryContent"
    >
      <Row>
        <Col xs={8}>
          <div className="header3 small m-0">
            {i18n.t(
              `${LOCALE_PREFIX}.vouchers_total[${voucherTypeKey.toLowerCase()}]`,
              { quantity: formattedQuantity, faceValue: formattedFaceValue }
            )}
          </div>
        </Col>
        <Col className="text-right" xs={2}>
          <div className="header3 small m-0">
            {i18n.t(`${LOCALE_PREFIX}.vat`, { vatFeePercent: 0 })}
          </div>
        </Col>
        <Col className="text-right" xs={2}>
          <div className="header3 small m-0">{formattedSumVouchers}</div>
        </Col>
      </Row>
      <Row>
        <Col xs={8}>
          <div
            className={`${
              withoutReference ? 'blue-text' : ''
            } header3 small m-0`}
          >
            {i18n.t(`${LOCALE_PREFIX}.handling_fee`)}{' '}
          </div>
          <div
            className="small"
            dangerouslySetInnerHTML={{
              __html: i18n.t(
                `${LOCALE_PREFIX}.${
                  VoucherTypes[voucherTypeKey] === VoucherTypes.Lunch
                    ? 'lunch_price_list_and_fee'
                    : 'recreational_price_list_and_fee'
                }`
              )
            }}
          />
        </Col>
        <Col className="text-right" xs={2}>
          <div className="header3 small m-0">
            {i18n.t(`${LOCALE_PREFIX}.vat`, { vatFeePercent })}
          </div>
        </Col>
        <Col className="text-right" xs={2}>
          <div
            className={`${
              withoutReference ? 'blue-text' : ''
            } header3 small internal-subheader m-0`}
          >
            {handlingFee || '-'} €
          </div>
        </Col>
      </Row>
      <hr />

      <Row>
        <Col xs={6} sm={7}>
          <div className="header3 small m-0">
            {i18n.t(`${LOCALE_PREFIX}.total_without_vat`)}
          </div>
        </Col>
        <Col className="text-right" xs={6} sm={5}>
          <div className="header3 small m-0">
            {formatCurrency(orderTotalWithoutVat)}
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={6} sm={7}>
          <div className="header3 small m-0">
            {i18n.t(`${LOCALE_PREFIX}.total_vat`, { vatFeePercent })}
          </div>
        </Col>
        <Col className="text-right" xs={6} sm={5}>
          <div className="header3 small m-0">
            {formatCurrency(vatFeeAmount)}
          </div>
        </Col>
      </Row>

      <Collapse unmountOnExit in={showCreditCardFee}>
        <div>
          <hr />
          <Row>
            <Col xs={7} sm={8}>
              <div className="blue-text header3 small internal-subheader m-0">
                {i18n.t(`${LOCALE_PREFIX}.credit_card_fee`)}{' '}
              </div>
              {creditCardFeePercent > 0 && (
                <TooltipInfo
                  info={i18n.t(`${LOCALE_PREFIX}.credit_card_fee_desc`, {
                    value: formatNumeric(creditCardFeePercent, '0.00')
                  })}
                  placement="right"
                />
              )}
            </Col>
            <Col className="text-right" xs={5} sm={4}>
              <div className="blue-text header3 small internal-subheader m-0">
                {totalAmountWithFees ? formatCurrency(creditCardFee) : '?'}
              </div>
            </Col>
          </Row>
        </div>
      </Collapse>
      <hr className="gray" />
      <Row>
        <Col xs={7} sm={8}>
          <div className="header2 small internal-subheader m-0">
            {i18n.t(`${LOCALE_PREFIX}.order_total`)}
          </div>
        </Col>
        <Col className="text-right total" xs={5} sm={4}>
          <div className="header2 small internal-subheader m-0">
            {formatCurrency(totalAmountWithFees)}
          </div>
        </Col>
      </Row>
    </div>
  );
}

BenefitSummaryContent.propTypes = {
  voucherTypeKey: PropTypes.string.isRequired,
  orderSummary: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  showCreditCardFee: PropTypes.bool,
  voucherOrderPayment: PropTypes.object
};

BenefitSummaryContent.defaultProps = {
  showCreditCardFee: false
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions }, dispatch);

const ConnectedBenefitSummaryContent = connect(
  null,
  mapDispatchToProps
)(withTranslation()(BenefitSummaryContent));

export {
  ConnectedBenefitSummaryContent as BenefitSummaryContent,
  BenefitSummaryContent as BenefitSummaryContentTest
};
