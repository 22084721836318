import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Row, Card, Col } from 'react-bootstrap';
import { Button, ButtonSize, SuccessCheckIcon } from 'edenred-ui';

import { PaymentMethods } from '../../constants/paymentMethods';
import { routerPaths } from '../../constants/routerPaths';
import { DownloadInvoiceButton } from '../../components/DownloadInvoiceButton/DownloadInvoiceButton';

export function CardOrderSuccessfulScreen({
  isPaymentSuccess,
  getTranslationPath,
  paymentMethod,
  paymentId,
  isReceiptOnly,
  goToPage
}) {
  const { t } = useTranslation();
  return (
    <Card>
      <Card.Body>
        <Card.Title>
          <Row className="no-wrap">
            <Col xs={9} sm={10} lg={12}>
              <h1>
                {t(getTranslationPath('title_success', isPaymentSuccess))}
              </h1>
            </Col>
            <Col xs={3} sm={2} className="d-lg-none">
              <h1>
                <SuccessCheckIcon className="check-icon" />
              </h1>
            </Col>
          </Row>
        </Card.Title>
        <Row className="pl-15 mb-10">
          <Col xs={11}>
            <h2
              className="small"
              dangerouslySetInnerHTML={{
                __html: t(getTranslationPath('body_success', isPaymentSuccess))
              }}
            />
            {paymentMethod === PaymentMethods.PrintInvoicePdf && (
              <h2 className="small">
                {t('containers.order_status.body_reference_number_info')}
              </h2>
            )}
            <div>
              <DownloadInvoiceButton
                id={paymentId}
                idType="payment"
                className="btn-download"
              >
                <span>
                  {isReceiptOnly
                    ? t('containers.order_status.download_receipt')
                    : t('containers.order_status.download_invoice')}
                </span>
              </DownloadInvoiceButton>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 11, offset: 1 }}>
            <Button
              id="go-to-transactions-history-btn"
              size={ButtonSize.Medium}
              className="pull-right"
              onClick={() => goToPage(`${routerPaths.transactionsHistory}`)}
            >
              {t(getTranslationPath('button_success'))}
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

CardOrderSuccessfulScreen.propTypes = {
  isPaymentSuccess: PropTypes.bool,
  paymentMethod: PropTypes.string,
  paymentId: PropTypes.number,
  isReceiptOnly: PropTypes.bool,
  getTranslationPath: PropTypes.func,
  goToPage: PropTypes.func
};
