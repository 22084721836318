import tokeys from 'tokeys';

export const FormConstants = tokeys([
  'LOGIN_FORM',
  'DELIVERY_INFO_FORM',
  'EDIT_DELIVERY_INFO_FORM',
  'USER_INFO_FORM',
  'CHANGE_PASSWORD_FORM',
  'PASSWORD_POPUP_FORM',
  'RESET_PASSWORD_FORM',
  'SET_NEW_PASSWORD_FORM',
  'SIGN_UP_FORM',
  'EDIT_VOUCHER_QUANTITY_FORM',
  'REORDER_FORM',
  'ORDER_PAYMENT_FORM',
  'LUNCH_VOUCHER_ORDER_FORM',
  'RECREATIONAL_VOUCHER_ORDER_FORM',
  'CARD_ORDER_FORM',
  'LOAD_COMPANY_ACCOUNT_FORM',
  'BENEFIT_SETTINGS',
  'CARD_ORDER_PAYMENT_FORM',
  'DEPOSIT_ORDER_PAYMENT_FORM',
  'CHECK_EXISTING_COMPANY'
]);

// Form persistance configuration
export const FormPersistance = {
  forms: [
    FormConstants.DELIVERY_INFO_FORM,
    FormConstants.EDIT_DELIVERY_INFO_FORM,
    FormConstants.SIGN_UP_FORM,
    FormConstants.LUNCH_VOUCHER_ORDER_FORM,
    FormConstants.RECREATIONAL_VOUCHER_ORDER_FORM,
    FormConstants.EDIT_VOUCHER_QUANTITY_FORM,
    FormConstants.REORDER_FORM,
    FormConstants.CARD_ORDER_FORM,
    FormConstants.CARD_ORDER_PAYMENT_FORM
  ],
  forget_values: {
    [FormConstants.SIGN_UP_FORM]: [
      'username_password.password',
      'username_password.confirmPassword'
    ],
    [FormConstants.LUNCH_VOUCHER_ORDER_FORM]: [
      'username_password.password',
      'username_password.confirmPassword'
    ],
    [FormConstants.RECREATIONAL_VOUCHER_ORDER_FORM]: [
      'username_password.password',
      'username_password.confirmPassword'
    ],
    [FormConstants.CARD_ORDER_FORM]: [
      'username_password.password',
      'username_password.confirmPassword',
      'beneficiaries',
      'signing_right'
    ]
  },
  forget_fields: {
    [FormConstants.CARD_ORDER_FORM]: ['beneficiaries', 'signing_right']
  }
};
