import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  page: 1,
  order: {}
};

const registeredVoucherOrderSlice = createSlice({
  name: 'registeredVoucherOrder',
  initialState,
  reducers: {
    addOrderInformation: (state, { payload }) => {
      state.order = { ...state.order, ...payload };
    },
    changeOrderPage: (state, { payload }) => {
      state.page = payload;
    },
    restoreInitialValues: () => initialState
  }
});

export const { reducer, actions: registeredVoucherOrderAction } =
  registeredVoucherOrderSlice;
