import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  Box,
  Notification,
  NotificationType,
  Button,
  ButtonMode
} from 'edenred-ui';
import styled from 'styled-components';

const StyledButton = styled(Button)`
  text-decoration: underline;

  span {
    overflow: initial;
  }
`;

export const TransferMoneyReminder = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Box pt={5} width={457}>
      <Notification
        type={NotificationType.Important}
        text={
          <Trans
            defaults={t(
              'components.manage_benefit_recreational.transfer_money_reminder'
            )}
            components={{
              a: (
                <StyledButton
                  mode={ButtonMode.None}
                  onClick={onClick}
                  type="button"
                />
              )
            }}
          />
        }
      />
    </Box>
  );
};

TransferMoneyReminder.propTypes = {
  onClick: PropTypes.func.isRequired
};
