import { SuccessCheckIcon } from 'edenred-ui';
import {
  ButtonComponent as Button,
  ButtonMode,
  CheckboxComponent as Checkbox
} from 'edenred-ui';
import i18n from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { slideoutWidth } from '../BeneficiaryDetail/BeneficiaryDetailSlideoutStyles';

const AddBeneficiaryActionsStyle = styled.div`
  width: ${slideoutWidth};
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 2rem;
  button {
    margin-right: 40px;
  }

  .add-employee-checkbox {
    -webkit-box-sizing: unset;
    box-sizing: unset;
    * {
      -webkit-box-sizing: unset;
      box-sizing: unset;
    }
    label svg {
      vertical-align: baseline;
    }
  }
`;

const AddBeneficiaryActions = ({
  isSuccess,
  mainAction,
  addAnotherEmployee,
  setAddAnotherEmployee,
  setActionButtonSuccess,
  disabled,
  isProcessing
}) => {
  const [icon, setIcon] = useState(null);
  useEffect(() => {
    if (isSuccess) {
      setIcon(
        <span style={{ marginRight: '1rem' }}>
          <SuccessCheckIcon />
        </span>
      );
      setTimeout(() => {
        setIcon(null);
        setActionButtonSuccess(false);
      }, 5000);
    }
  }, [isSuccess]);

  return (
    <AddBeneficiaryActionsStyle>
      <div className="add-employee-checkbox" id="add-employee_checkbox">
        <Checkbox
          label={i18n.t('containers.beneficiaries.add_employee.add_another')}
          checked={addAnotherEmployee}
          onChanged={() => setAddAnotherEmployee(!addAnotherEmployee)}
        />
      </div>
      <Button
        id="add-beneficiary-submit-btn"
        className="trigger-email-DNS-validation"
        onClick={mainAction}
        mode={ButtonMode.Primary}
        icon={icon}
        disabled={disabled}
        isProcessing={isProcessing}
      >
        {i18n.t('containers.beneficiaries.add_employee.main_action')}
      </Button>
    </AddBeneficiaryActionsStyle>
  );
};

AddBeneficiaryActions.propTypes = {
  mainAction: PropTypes.func.isRequired,
  addAnotherEmployee: PropTypes.bool.isRequired,
  setAddAnotherEmployee: PropTypes.func.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  setActionButtonSuccess: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  isProcessing: PropTypes.bool.isRequired
};

export default AddBeneficiaryActions;
