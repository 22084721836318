import React from 'react';
import PropTypes from 'prop-types';
import { SimpleButton, EditIcon } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import * as Style from './BenefitGroupTile.styles';

export function BenefitGroupTile({
  groupName,
  maxAmountPerPeriod,
  index,
  onTileClick,
  onEditClick,
  benefitType
}) {
  const { t } = useTranslation();
  const maxAmountPerPeriodDisplay = maxAmountPerPeriod.toFixed(2);

  return (
    <Style.BenefitGroupTileContainer
      id={`benefit-group-${index}`}
      onClick={onTileClick}
    >
      <Style.BenefitGroupTileHeading>
        <Style.BenefitGroupTileTitle>{groupName}</Style.BenefitGroupTileTitle>
        <Style.BenefitGroupTileActions>
          <SimpleButton
            id={`benefit-group-edit-${index}`}
            icon={<EditIcon />}
            onClick={e => {
              e.stopPropagation();
              onEditClick();
            }}
          />
        </Style.BenefitGroupTileActions>
      </Style.BenefitGroupTileHeading>
      <Style.BenefitGroupLimit>
        <span>
          {t(`components.manage_benefit_${benefitType}.detail_limit_label`)}
        </span>
        <Style.BenefitGroupLimitValue
          id={`benefit-group-daily-limit-${index}`}
        >{`${maxAmountPerPeriodDisplay} €`}</Style.BenefitGroupLimitValue>
      </Style.BenefitGroupLimit>
    </Style.BenefitGroupTileContainer>
  );
}

BenefitGroupTile.propTypes = {
  groupName: PropTypes.string.isRequired,
  maxAmountPerPeriod: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  onTileClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  benefitType: PropTypes.string.isRequired
};
