import styled from 'styled-components';

export const DateRangeDialogStyles = styled.div`
  .header {
    display: relative;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 25px;
    padding-left: 40px;
    .close-icon {
      position: absolute;
      top: 16px;
      right: 16px;
      font-size: 20px;
      cursor: pointer;
      .mdi-close {
        margin: 0 0;
      }
    }

    .title {
      padding-top: 40px;
      width: 100%;
      font-size: 24px;
      font-weight: 600;
      color: #252525;
    }
  }

  .content {
    width: 696px;
    height: 500px;
    .InputFromTo {
      padding: 40px 40px;
    }
  }

  .actions {
    height: 74px;
    border-top: 1px solid #e6e6e6;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;
    button {
      height: 40px;
      margin-right: 40px;
      width: auto;
    }
  }
`;
