import React from 'react';
import { useTheme } from 'styled-components';
import { Box } from 'edenred-ui';

import { IBoxOval } from '@epi-models/shared';

export const BoxOval = ({ text, bgColor }: IBoxOval) => {
  const { primaryFrontColor, primaryBackgroundColor } = useTheme();

  return (
    <Box
      width={40}
      height={40}
      bgcolor={bgColor || primaryBackgroundColor}
      borderRadius="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      mr={2}
      fontSize={16}
      color={primaryFrontColor}
      pt={0.5}
    >
      {text}
    </Box>
  );
};
