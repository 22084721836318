import { ApiConstants } from '../constants/actions';

export const closeNotification = () => ({
  type: `${ApiConstants.LOAD_COMPANY_ACCOUNTS}_CLOSE_NOTIFICATION`
});

export const togglePrintInvoiceForPdf = payload => ({
  type: `${ApiConstants.LOAD_COMPANY_ACCOUNTS}_TOGGLE_PRINT_INVOICE_FOR_PDF`,
  payload
});
