import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import NotificationSystem from 'react-notification-system';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { getNotifications } from '../../selectors/overlays';
import * as overlaysActions from '../../actions/overlays';

class Notifier extends PureComponent {
  style = {
    Containers: {
      DefaultStyle: {
        zIndex: 1010
      },
      tl: {
        top: 73
      },
      tr: {
        top: 73,
        right: 17
      },
      tc: {
        top: 73
      }
    }
  };

  componentDidUpdate() {
    const { i18n, notifications, hideNotification } = this.props;
    const notificationIds = notifications.map(notification => notification.uid);
    const systemNotifications =
      this.notificationSystem.state.notifications || [];

    if (notifications.length > 0) {
      // Get all active notifications from react-notification-system
      // / and remove all where uid is not found in the reducer
      systemNotifications.forEach(notification => {
        if (notificationIds.indexOf(notification.uid) < 0) {
          this.notificationSystem.removeNotification(notification.uid);
        }
      });

      notifications.forEach(notification => {
        const message = notification.translated
          ? notification.message
          : i18n.t(notification.message);
        this.notificationSystem.addNotification({
          ...notification,
          message,
          onRemove: () => {
            hideNotification(notification.uid);
            if (notification.onRemove) {
              notification.onRemove();
            }
          }
        });
      });
    }
  }

  render() {
    return (
      <div>
        <NotificationSystem
          ref={r => {
            this.notificationSystem = r;
          }}
          style={this.style}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  notifications: getNotifications(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(overlaysActions, dispatch);

Notifier.propTypes = {
  hideNotification: PropTypes.func, // eslint-disable-line
  notifications: PropTypes.array.isRequired, // eslint-disable-line
  i18n: PropTypes.object.isRequired // eslint-disable-line
};

Notifier.defaultProps = {
  hideNotification: () => {}
};

const ConnectedNotifier = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(Notifier));

export { ConnectedNotifier as Notifier };
