import { ApiConstants } from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  contacts: [],
  isLoading: false,
  isLoaded: false
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_CONTACTS}_REQUEST`]: () => ({
    isLoading: true,
    isLoaded: false
  }),

  [`${ApiConstants.LOAD_CONTACTS}_SUCCESS`]: (state, { contacts }) => ({
    contacts,
    isLoading: false,
    isLoaded: true
  }),

  [`${ApiConstants.LOAD_CONTACTS}_FAILURE`]: () => ({
    contacts: [],
    isLoading: false,
    isError: true
  }),

  [`${ApiConstants.LOGOUT_ACTION}_SUCCESS`]: () => getInitialState()
});
