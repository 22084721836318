import { createSelector } from 'reselect';

export const getCompanyAccounts = state => state.apiCompanyAccounts.accounts;

export const getCompanyNotification = state =>
  state.apiCompanyAccounts.showNotification;

export const getCompanyInvoicePFD = state =>
  state.apiCompanyAccounts.isPrintInvoicePdfForTransferMoney;

export const getMainCompanyAccount = createSelector(
  getCompanyAccounts,
  accounts => {
    return (accounts || []).find(v => !v.parentAccountId) || {};
  }
);

export const getMainAvailableBalance = createSelector(
  getMainCompanyAccount,
  account => {
    return account.availableBalance || 0;
  }
);

export const getTransactionsHistoryQueryPageNumber = state =>
  state.apiCompanyAccounts.transactionsHistory.queryParameters.pageNumber;

export const getTransactionsHistoryQueryPageSize = state =>
  state.apiCompanyAccounts.transactionsHistory.queryParameters.pageSize;

export const getTransactionsHistoryQuery = state =>
  state.apiCompanyAccounts.transactionsHistory.queryParameters;
