import {
  ButtonComponent as Button,
  ButtonMode,
  DialogComponent,
  PopupPanelComponent as Panel
} from 'edenred-ui';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import Pad from '../../../../components/LayoutUtils/Pad';

const CloseDetailDialog = ({
  isOpen,
  closeAction,
  primaryAction,
  primaryActionLabel,
  secondaryAction,
  secondaryActionLabel,
  title,
  content
}) => {
  const modalRootElement = document.getElementById('modal-root');
  if (!modalRootElement) return null;

  return ReactDOM.createPortal(
    <DialogComponent open={isOpen}>
      <Panel
        closeButtonId="dialog-close-button"
        onClose={() => {
          closeAction();
        }}
        title={title}
        primaryActionButton={
          <Button
            id="dialog-close-without-saving"
            onClick={primaryAction}
            mode={ButtonMode.Primary}
            minWidth={200}
          >
            <span>{primaryActionLabel}</span>
          </Button>
        }
        secondaryActionButton={
          <Button
            id="dialog-save-first"
            onClick={secondaryAction}
            mode={ButtonMode.Secondary}
            minWidth={200}
          >
            <span>{secondaryActionLabel}</span>
          </Button>
        }
      >
        <Pad horizontal="L" vertical="XL">
          <span>{content}</span>
        </Pad>
      </Panel>
    </DialogComponent>,
    modalRootElement
  );
};

CloseDetailDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeAction: PropTypes.func.isRequired,
  primaryAction: PropTypes.func.isRequired,
  primaryActionLabel: PropTypes.string.isRequired,
  secondaryAction: PropTypes.func.isRequired,
  secondaryActionLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired
};

export default CloseDetailDialog;
