import { put, call } from 'redux-saga/effects';
import { getPaymentStatus as getPaymentStatusRepo } from '../../repositories/PaymentStatusesRepository';
import {
  ApiConstants,
  OverlaysConstants,
  ApplicationConstants
} from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';

export default function* getPaymentStatus({ paymentId }) {
  try {
    yield put({
      type: `${ApiConstants.CREATE_VOUCHER_ORDER}_CLEAR`
    });
    yield put({
      type: `${ApplicationConstants.CLEAR_FORMS}`
    });

    const paymentStatusResponse = yield call(getPaymentStatusRepo, paymentId);

    yield put({ type: `${ApiConstants.GET_HAS_ORDERS}_REQUEST` });
    yield put({
      type: `${ApiConstants.GET_PAYMENT_STATUS}_SUCCESS`,
      payload: paymentStatusResponse
    });
  } catch (error) {
    if (error.name === UnauthorizedErrorName) {
      yield put({
        type: `${ApiConstants.GET_PAYMENT_STATUS}_FAILURE`,
        isUnauthorized: true,
        payload: error
      });
    } else {
      yield put({
        type: `${ApiConstants.GET_PAYMENT_STATUS}_FAILURE`,
        payload: error
      });
      yield put({
        type: `${OverlaysConstants.SHOW_ERROR_POPUP}`,
        closable: false
      });
    }
  }
}
