import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { FormArrays, createRules } from '@epi-forms/helpers';
import { maxLength } from '@epi-helpers/formHelpers/validators';

export function ContactPersonField() {
  const name = FormArrays.ContactPersons;
  const { control } = useFormContext();
  const { t } = useTranslation();
  const validators = [maxLength(50)];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      label={t('forms.delivery_info.contact_person')}
      value={value}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={setValue}
    />
  );
}
