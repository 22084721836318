import React from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { Box } from 'edenred-ui';
import PropTypes from 'prop-types';

import { OrderType } from '@epi-constants/orderType';
import { FormFields } from '@epi-forms/helpers';

import { EmailAddressField } from '@epi-forms/controls';
import { AdditionalMessageField } from '@epi-forms/controls/AdditionalMessageField';

function ForwardInvoice({ i18n, orderType }) {
  return (
    <Card className="forward-invoice">
      <Card.Body>
        <Container fluid>
          <Row>
            <Col xs={12}>
              {i18n.t('forms.payment_method.forward_instructions')}
              <br />
              {orderType === OrderType.voucher && (
                <div className="blue-text mb-10">
                  {i18n.t('forms.payment_method.order_shipped_after_payment')}
                </div>
              )}
            </Col>
          </Row>
          <Box margin="10px 0 20px 0">
            <Row>
              <Col xs={12}>{i18n.t('forms.payment_method.email_address')}</Col>
            </Row>
            <Row>
              <Col xs={12} md={6}>
                <EmailAddressField
                  fieldName={FormFields.InvoicingEmailAddress}
                />
              </Col>
            </Row>
          </Box>
          <Box marginBottom="10px">
            <Row>
              <Col xs={9}>{i18n.t('forms.payment_method.message')}</Col>
            </Row>
            <Row>
              <Col xs={12}>
                <AdditionalMessageField />
              </Col>
            </Row>
          </Box>
        </Container>
      </Card.Body>
    </Card>
  );
}

ForwardInvoice.propTypes = {
  i18n: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  orderType: PropTypes.string.isRequired
};

ForwardInvoice.defaultProps = {
  disabled: false
};

const ForwardInvoiceWithI18n = withTranslation()(ForwardInvoice);

export { ForwardInvoiceWithI18n as ForwardInvoice };
