import { put, call } from 'redux-saga/effects';
import { resetPassword as resetPasswordRepo } from '../../repositories/ProfileRepository';
import { ApiConstants } from '../../constants/actions';
import { routerPaths } from '../../constants/routerPaths';
import * as navigationActions from '../../actions/navigation';

export default function* resetPassword({ usernameOrEmail }) {
  try {
    yield call(resetPasswordRepo, usernameOrEmail);

    yield put({
      type: `${ApiConstants.RESET_PASSWORD}_SUCCESS`
    });

    yield put(navigationActions.goToPage(routerPaths.resetPasswordRequested));
  } catch (error) {
    yield put({
      type: `${ApiConstants.RESET_PASSWORD}_FAILURE`,
      payload: error
    });
  }
}
