/** Additional flags dependent on environmental variables can be added here
 *  The property name is the name of the added flag.
 *  In the value of the property is expected to pass down an anonymous
 *  function which has access to environmental variables and returns
 *  the value of the additional flag
 *
 *  example: {
 *    isProduction: (envVariables) => envVariables.environment === 'production'
 *  }
 *
 */
export const additionalFlags = {};
