import { fork, all } from 'redux-saga/effects';
import watchSaga from './saga';
import { watchApplicationSaga } from './application';
import { watch as watchNavigationSaga } from './navigation';
import { watchApiAction } from './api/watchApiAction';
import watchOrderHistorySaga from './orderHistory';
import watchBeneficiarySaga from './beneficiary';
import watchVoucherReorderSaga from './voucherReorder';
import watchMaintenanceSaga from './maintenance';
import watchPerformance from './Performance';
import watchReCaptcha from './reCaptcha';
import { watchTrackingSaga } from './tracking';

export function* root() {
  yield all([
    fork(watchApplicationSaga),
    fork(watchNavigationSaga),
    fork(watchApiAction),
    fork(watchOrderHistorySaga),
    fork(watchBeneficiarySaga),
    fork(watchVoucherReorderSaga),
    fork(watchSaga),
    fork(watchMaintenanceSaga),
    fork(watchPerformance),
    fork(watchTrackingSaga),
    fork(watchReCaptcha)
  ]);
}
