import { ApiConstants } from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  isProcessing: false,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ApiConstants.DEPOSIT_COMPANY_ACCOUNT}_REQUEST`]: () => ({
    isProcessing: true
  }),

  [`${ApiConstants.DEPOSIT_COMPANY_ACCOUNT}_FAILURE`]: () => ({
    isProcessing: false
  }),

  [`${ApiConstants.CREATE_NEW_PAYMENT}_SUCCESS`]: () => ({
    isProcessing: false
  })
});
