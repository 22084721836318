import createReducer from '../../helpers/CreateReducer';
import { ProfileConstants, ApiConstants } from '../../constants/actions';

export const getInitialState = () => ({
  selectedProfileIndex: 0,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ProfileConstants.CHANGE_SELECTED_PROFILE_TAB}`]: (state, action) => ({
    selectedProfileIndex: action.index
  }),
  [`${ApiConstants.GET_USER_INFORMATION}_SUCCESS`]: (state, { userInfo }) => ({
    ...userInfo
  }),
  [`${ApiConstants.UPDATE_USER_INFORMATION}_SUCCESS`]: (
    state,
    { payload }
  ) => ({
    ...payload
  })
});
