import styled from 'styled-components';

export const FieldWrap = styled.div`
  flex: 1 1 auto;
  max-width: ${props => props.width};
  & > div {
    margin-top: 3px;
  }
`;

export default FieldWrap;
