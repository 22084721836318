import React from 'react';
import PropTypes from 'prop-types';

import { FormStep, FormStepMode } from '../FormStep';
import { FormStepLabel } from '../FormStepLabel';
import { FormStepStyles } from '../FormStep/FormStep.styles';

export const SimpleFormStep = ({ mode, stepNumber, stepTitle, children }) => {
  return (
    <FormStep mode={mode}>
      <FormStepStyles.Content>
        <FormStepLabel
          mode={mode}
          stepNumber={stepNumber}
          stepTitle={stepTitle}
        />
        {children}
      </FormStepStyles.Content>
    </FormStep>
  );
};

SimpleFormStep.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  stepTitle: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  mode: PropTypes.string.isRequired
};

SimpleFormStep.defaultProps = {
  mode: FormStepMode.Contained
};
