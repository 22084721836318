import { ApplicationConstants } from '../constants/actions';

export const switchLanguage = languageId => ({
  type: `${ApplicationConstants.CHANGE_APP_LANGUAGE}`,
  languageId
});

export const copyValueToField = (form, field, newValue) => ({
  type: ApplicationConstants.COPY_VALUE_TO_FIELD,
  form,
  field,
  newValue
});

export const setIsGuid = isUserImpersonate => ({
  type: ApplicationConstants.SET_GUID,
  isUserImpersonate
});

export const checkUsernameOnMount = (form, field) => ({
  type: ApplicationConstants.CHECK_USERNAME_ON_MOUNT,
  form,
  field
});
