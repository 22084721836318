import { put, call } from 'redux-saga/effects';

import { getCompanyAccounts as getCompanyAccountsRepository } from '../../repositories/CompanyAccountsRepository';
import { ApiConstants } from '../../constants/actions';
import * as overlaysActions from '../../actions/overlays';
import { UnauthorizedErrorName } from '../../constants/errorName';

export default function* loadCompanyAccounts() {
  try {
    const accounts = yield call(getCompanyAccountsRepository);
    yield put({
      type: `${ApiConstants.LOAD_COMPANY_ACCOUNTS}_SUCCESS`,
      payload: accounts
    });
  } catch (error) {
    yield put({
      type: `${ApiConstants.LOAD_COMPANY_ACCOUNTS}_FAILURE`,
      isUnauthorized: error.name === UnauthorizedErrorName,
      payload: error
    });
    if (error.name !== UnauthorizedErrorName) {
      yield put(
        overlaysActions.showNotification({ message: 'messages.error' }, 'error')
      );
    }
  }
}
