import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, LoadingSpinner } from 'edenred-ui';

import { getCompanyMetadata } from '@epi-store/companyMetadata/companyMetadata.selectors';
import { routerPaths } from '@epi-constants/routerPaths';
import { goToPage } from '@epi-actions/navigation';
import { getUserRoles } from '@epi-selectors/login';
import { HomeNotification } from '@epi-pages/Home/components';
import {
  isSignatory,
  isVoucherManager
} from '@epi-helpers/Menu/MenuItemsFactoryHelper';

import {
  VoucherOrderHistory,
  OrderVouchersPanel,
  RequestContact,
  PaperVoucherError
} from './components';

function PaperVouchers() {
  const companyMetadata = useSelector(getCompanyMetadata);
  const roles = useSelector(getUserRoles);
  const dispatch = useDispatch();

  const hideVoucherOrder = companyMetadata.data?.hideVoucherOrder ?? true;
  const voucherManagerOnly =
    !isSignatory({ roles }) && isVoucherManager({ roles });
  const isError = hideVoucherOrder && voucherManagerOnly;

  useEffect(() => {
    if (hideVoucherOrder && !isError && !companyMetadata?.isLoading) {
      dispatch(goToPage(routerPaths.home));
    }
  }, [hideVoucherOrder]);

  if (companyMetadata?.isLoading) return <LoadingSpinner />;

  if (isError) return <PaperVoucherError />;

  return (
    <Box pb={5}>
      {voucherManagerOnly && <HomeNotification />}
      <RequestContact />
      <OrderVouchersPanel />
      <VoucherOrderHistory />
    </Box>
  );
}

export default PaperVouchers;
