import config from '@epi-config';
import { post } from '@epi-helpers/FetchHelpers';
import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import { getReCaptchaSiteKey } from '@epi-selectors/settings';

import { reduxStore } from '../../store-configuration';

const url = `${config.apiHost}/api/sign-up`;

export function signUpRequest(payload) {
  return post(url, payload);
}

export function isSignUpTokenExpiredRequest(token, contactRegistrationType) {
  return post(`${url}/token/is-expired`, { token, contactRegistrationType });
}

export function isSignUpCompletedRequest(token, contactRegistrationType) {
  return post(`${url}/is-completed`, { token, contactRegistrationType });
}

export function resendSignUpEmailRequest(payload) {
  return post(`${url}/resend-email`, payload);
}

export async function isSignupTokenValidRequest(
  token,
  contactRegistrationType
) {
  const state = reduxStore.getState();
  const reCaptchaSiteKey = getReCaptchaSiteKey(state);

  const reCaptchaToken = await getRecaptchaToken(
    reCaptchaSiteKey,
    'CHECK_SIGNUP_TOKEN_VALIDITY'
  );

  return post(`${url}/validate-token`, {
    contactRegistrationType,
    token,
    reCaptchaToken
  });
}
