export const addressLine = addressObject => {
  if (!addressObject) {
    return '';
  }
  return [
    addressObject.companyName,
    addressObject.addressLine,
    addressObject.postCode,
    addressObject.city
  ]
    .filter(elt => !!elt)
    .join(', ');
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
