import { BeneficiaryItemsPerPage } from '../constants/beneficiary';

function sortRows(rows, descending, sortBy) {
  const sortByFunc = x => x[sortBy];
  const direction = descending ? -1 : 1;

  const sorted = [
    ...rows.sort((a, b) => {
      const sortA = sortByFunc(a);
      const sortB = sortByFunc(b);

      const stringA = sortA instanceof Array ? sortA.join(',') : sortA;
      const stringB = sortB instanceof Array ? sortB.join(',') : sortB;

      return stringA.toLowerCase() > stringB.toLowerCase()
        ? direction
        : -direction;
    })
  ];

  const sortByLatest = (a, b) => {
    if (a.isNew && b.isNew) {
      return 0;
    }
    if (a.isNew) {
      return -1;
    }
    if (b.isNew) {
      return 1;
    }
  };

  const newOnTopRows = [...sorted].sort(sortByLatest);

  return [...newOnTopRows];
}

function sliceRows(rows, page) {
  return [
    ...rows.slice(
      BeneficiaryItemsPerPage * (page - 1),
      BeneficiaryItemsPerPage * page
    )
  ];
}

const DATA_LOADED = 'DATA_LOADED';
const CHANGE_SORT = 'CHANGE_SORT';
const CHANGE_PAGE = 'CHANGE_PAGE';

export function getDataLoadedAction(beneficiaries) {
  return {
    type: DATA_LOADED,
    data: beneficiaries
  };
}

export function getChangeSortAction(sortBy, sortDirection) {
  return {
    type: CHANGE_SORT,
    data: { sortBy, sortDirection }
  };
}

export function getChangePageAction(page) {
  return {
    type: CHANGE_PAGE,
    data: { page }
  };
}

export default function beneficiariesReducer(state, action) {
  switch (action.type) {
    case DATA_LOADED:
      return {
        ...state,
        isLoading: false,
        original: action.data,
        rows: sliceRows(
          sortRows(action.data, state.descending, state.sortBy),
          1
        ),
        totalRows: action.data.length
      };
    case CHANGE_SORT:
      return {
        ...state,
        sortDirection: action.data.sortDirection,
        sortBy: action.data.sortBy,
        page: 1,
        rows: sliceRows(
          sortRows(
            [...state.original],
            action.data.sortDirection,
            action.data.sortBy
          ),
          1
        )
      };
    case CHANGE_PAGE:
      return {
        ...state,
        page: action.data.page,
        rows: sliceRows(
          sortRows([...state.original], state.sortDirection, state.sortBy),
          action.data.page
        )
      };
    default:
      return state;
  }
}
