import { goToPage } from '@epi-actions/navigation';
import { showNotification } from '@epi-actions/overlays';
import { resendSignUpEmailRequest } from '@epi-api/signUp';
import { routerPaths } from '@epi-constants/routerPaths';
import { SignupTokenErrors } from '@epi-constants/signupTokenErrors';

import { getRecaptchaToken } from './ReCaptchaHelper';

export async function resendSignUpMail(
  signToken,
  contactRegistrationType,
  reCaptchaSiteKey,
  resultPage
) {
  const reCaptchaToken = await getRecaptchaToken(
    reCaptchaSiteKey,
    'resend_email'
  );

  try {
    const payload = {
      token: signToken,
      contactRegistrationType,
      reCaptchaToken
    };
    const response = await resendSignUpEmailRequest(payload);

    return [
      goToPage(
        `${resultPage}?token=${response.token}&type=${response.contactRegistrationType}`
      ),
      showNotification({ message: 'messages.successful_email_sent' }, 'success')
    ];
  } catch (e) {
    if (e.content?.errors[0]?.message === SignupTokenErrors.CreationCompleted) {
      return [goToPage(routerPaths.resendMailCompleted)];
    }
    if (e.content?.errors[0]?.message === SignupTokenErrors.IncorrectToken) {
      return [goToPage(routerPaths.resendMailInvalid)];
    }
    if (e.content?.errors[0]?.message === SignupTokenErrors.ContactDeleted) {
      return [goToPage(routerPaths.resendMailContactDeleted)];
    }
    return [showNotification({ message: 'messages.error' }, 'error')];
  }
}
