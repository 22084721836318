import React, { useState, useEffect, useMemo } from 'react';
import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { originalTotalSuggestedTransferSelector } from '@epi-selectors/transactionEstimationSelectors';
import {
  calculateComission,
  calculateSuggestedTransferAmount
} from '@epi-helpers/loadAccountEstimationHelper';

import { getMonthNameForLocale } from '../../helpers/dateHelper';

import EstimationDetailsRow from './EstimationDetailsRow';
import './EstimationDetails.scss';

function EstimationDetails({
  estimation,
  i18n,
  setSuggestedTranserAmount,
  navigateToRequestedLoadsPage
}) {
  const suggestedTransfer = useSelector(originalTotalSuggestedTransferSelector);
  const [benefitDetails, setBenefitDetails] = useState(
    estimation.benefits.map(b => ({ ...b, checked: true, id: nanoid() }))
  );
  const toggleCheckbox = id => {
    setBenefitDetails(previous => {
      return previous.map(b => {
        return b.id === id
          ? {
              ...b,
              checked: !b.checked
            }
          : b;
      });
    });
  };

  const totalComission = useMemo(
    () => calculateComission(benefitDetails, estimation),
    [benefitDetails, estimation]
  );

  const totalSuggestedTransfer = useMemo(
    () =>
      calculateSuggestedTransferAmount(
        benefitDetails,
        estimation,
        totalComission
      ),
    [(benefitDetails, estimation, totalComission)]
  );

  useEffect(() => {
    setSuggestedTranserAmount(
      totalSuggestedTransfer > 0 ? totalSuggestedTransfer : 0
    );
  }, [totalSuggestedTransfer]);

  const currentMonth = getMonthNameForLocale(i18n.language, 0);
  const nextMonth = getMonthNameForLocale(i18n.language, 1);

  const benefitDetailRows = benefitDetails
    ? benefitDetails.map(b => (
        <EstimationDetailsRow
          text={i18n.t(`containers.deposit_money.${b.type}.text`)}
          message={
            b.month === 'current'
              ? i18n.t(`containers.deposit_money.${b.type}.message.current`, {
                  month: currentMonth.toUpperCase()
                })
              : i18n.t(`containers.deposit_money.${b.type}.message.next`, {
                  month: nextMonth.toUpperCase(),
                  numberOfEmployees: b.numberOfEmployees
                })
          }
          amount={b.value}
          toggable
          checked={b.checked}
          setChecked={toggleCheckbox}
          id={b.id}
          key={b.id}
          month={b.month || null}
        />
      ))
    : null;

  return (
    <div className="estimation-details">
      <div>
        <div className="estimation-details__header">
          <div className="header__benefits">
            {i18n.t('containers.deposit_money.requested_loads.benefit')}
          </div>
          <div className="header__details">
            {i18n.t('containers.deposit_money.requested_loads.details')}
          </div>
          <div className="header__sum">
            {i18n.t('containers.deposit_money.requested_loads.sum')}
          </div>
        </div>
        <div className="requested-loads">
          <EstimationDetailsRow
            text={i18n.t('containers.deposit_money.requested_loads.text')}
            message={i18n.t(
              'containers.deposit_money.requested_loads.message',
              {
                noOfRequests: estimation.requestedLoads.numberOfRequests
              }
            )}
            amount={estimation.requestedLoads.value}
            hideIfZero
            action={navigateToRequestedLoadsPage}
          />
        </div>
        {benefitDetailRows}
        <EstimationDetailsRow
          text={i18n.t('containers.deposit_money.commission.text')}
          message={i18n.t('containers.deposit_money.commission.message')}
          amount={totalComission}
          hideIfZero
        />
        <div className="estimation-details-row total-line-item">
          <EstimationDetailsRow
            text={i18n.t('containers.deposit_money.current_balance_label')}
            amount={estimation.currentBalance}
          />
        </div>
        <div className="strong estimation-details-row total-line-item">
          <EstimationDetailsRow
            text={i18n.t('containers.deposit_money.suggested_transfer_amount')}
            amount={suggestedTransfer}
          />
        </div>
      </div>
    </div>
  );
}

EstimationDetails.propTypes = {
  estimation: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  setSuggestedTranserAmount: PropTypes.func.isRequired,
  navigateToRequestedLoadsPage: PropTypes.func.isRequired
};

export default withTranslation()(EstimationDetails);
