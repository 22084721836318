import config from '@epi-config';

export const resendEmailApiUrl = `${config.apiHost}/api/email/resend`;

export const validateEmailApiUrl = `${config.apiHost}/api/email/validate/`;

export const confirmEmailApiUrl = `${config.apiHost}/api/email/confirm-email`;

export const confirmEmailChangeApiUrl = `${config.apiHost}/api/email/confirm-change-email`;

export const requestConfirmationLinkApiUrl = `${config.apiHost}/api/email/request-new-confirmation-link`;
