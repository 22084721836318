import { TransactionsHistoryConstants } from '../constants/actions';

export const setTransactionsHistoryParameters = queryParameters => ({
  type: TransactionsHistoryConstants.SET_TRANSACTIONS_HISTORY_QUERY_PARAMETERS,
  queryParameters
});

export const updateTransactionsHistoryParameters = queryParameters => ({
  type: TransactionsHistoryConstants.UPDATE_TRANSACTIONS_HISTORY_QUERY_PARAMETERS,
  queryParameters
});

export const resetOrderHistoryParameters = () => ({
  type: TransactionsHistoryConstants.RESET_TRANSACTIONS_HISTORY_QUERY_PARAMETERS
});
