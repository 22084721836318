import React from 'react';
import PropTypes from 'prop-types';

export const CommutingIcon = ({ fill }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="124" height="124">
    <path
      d="M99.918 33a8 8 0 016.816 3.813l.147.248 12.131 21.444a9 9 0 011.164 4.22l.002.343-.173 11.863a6 6 0 01-1.08 3.349l-.165.223-.874 1.136a7.071 7.071 0 01-5.517 2.758l-.283-.002-1.995-.055A9.498 9.498 0 01106.6 88H119v2H8v-2h15.939a9.484 9.484 0 01-3.66-7.075l-4.831-.537a5 5 0 01-4.443-4.745L11 75.42l.003-36.497c.304-3.936 2.577-5.801 6.712-5.918L18 33h81.918zm-8.636 48H39.256a9.485 9.485 0 01-3.656 7.001h59.339a9.487 9.487 0 01-3.634-6.674L91.282 81zm9.487-8a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm-71 0a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm71 2.5a5 5 0 110 10 5 5 0 010-10zm-71 0a5 5 0 110 10 5 5 0 010-10zm71 2a3 3 0 100 6 3 3 0 000-6zm-71 0a3 3 0 100 6 3 3 0 000-6zM18.01 35c-3.243.029-4.705 1.106-4.988 3.768L13 39v36.42a3 3 0 002.495 2.957l.174.025 4.73.525c.75-4.498 4.66-7.927 9.37-7.927 4.737 0 8.663 3.466 9.382 8h52.236a9.5 9.5 0 0118.878 1.23l.002.113 1.875.053a5.071 5.071 0 003.998-1.778l.161-.199.874-1.136a4 4 0 00.818-2.123l.012-.258.174-11.863a7.01 7.01 0 00-.76-3.276l-.148-.273-12.131-21.444a6 6 0 00-4.974-3.04L99.918 35h-81.91zM99 38l.001.071a7.05 7.05 0 014.963 3.222l.147.243 8.749 15.222 3.102 5.22a2 2 0 01-1.57 3.017l-.15.005h-3.972a2 2 0 01-.851-.19l-.156-.082-10.278-5.996c-.344-.2-.65-.447-.914-.73L19 58a3 3 0 01-2.995-2.824L16 55V41a3 3 0 012.824-2.995L19 38h80zm0 2.101v15.176a2 2 0 00.855 1.64l.137.088L110.27 63h3.972l-3.094-5.207-8.771-15.26a5.049 5.049 0 00-2.974-2.334l-.25-.066-.153-.032zM61 60v2H35v-2h26zM43 40H19a1 1 0 00-.993.883L18 41v14a1 1 0 00.883.993L19 56h24V40zm27 0H45v16h25V40zm27 0H72v16l25.066.001a3.997 3.997 0 01-.06-.5L97 55.277V40z"
      fill={fill}
      fillRule="nonzero"
    />
  </svg>
);

CommutingIcon.propTypes = {
  fill: PropTypes.string.isRequired
};
