import styled from 'styled-components';

export const Cell = styled.div`
  font-size: 14px;
  min-width: 100px;
  word-break: break-word;
`;

export const BenefitTableStyle = styled.div`
  font-size: 15px;
`;

export const BenefitTypeCell = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  max-width: 200px;
  @media (max-width: 1200px) {
    min-width: 100px;
  }
  li {
    font-size: 11px;
  }
`;

export const ActionCell = styled.div`
  color: ${props => props.theme.primaryBackColorLight};
  font-weight: bold;
  font-size: 15px;
  width: 100%;
  text-align: left;
  margin: auto auto;
  cursor: pointer;
`;

export const SectionHeader = styled.div`
  margin-top: 1rem;
  font-family: inherit;
  font-size: ${props => props.theme.sizeSmall};
  font-weight: 800;
`;
