import { getErrorsList } from '@epi-helpers/ErrorDataHelper';
import { ApiConstants } from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  errors: [],
  message: '',
  actionName: '',
  isError: false,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [ApiConstants.SAVE_API_ERROR]: (
    state,
    { actionName, payload: { content, message } }
  ) => {
    const errors = content && getErrorsList(content);

    return {
      isError: true,
      errors,
      message,
      actionName
    };
  },

  [ApiConstants.CLEAR_API_ERROR]: () => ({
    isError: false,
    errors: [],
    message: '',
    actionName: ''
  })
});
