import createReducer from '../../helpers/CreateReducer';
import { ApiConstants, PaymentsConstants } from '../../constants/actions';

export const getInitialState = () => ({
  paymentMethods: [],
  locale: '',
  amount: 0,
  isLoading: false,
  loaded: false,
  noPersistance: true,
  isError: false
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_PAYMENT_METHODS}_REQUEST`]: (
    state,
    { locale, totalAmount }
  ) => ({
    paymentMethods: [],
    locale,
    amount: totalAmount,
    isLoading: true,
    loaded: false
  }),
  [`${ApiConstants.LOAD_PAYMENT_METHODS}_SUCCESS`]: (state, { methods }) => ({
    paymentMethods: methods,
    isLoading: false,
    loaded: true
  }),

  [`${ApiConstants.LOAD_PAYMENT_METHODS}_FAILURE`]: () => ({
    ...getInitialState(),
    isLoading: false,
    loaded: true,
    isError: true
  }),

  [`${PaymentsConstants.RESET_PAYMENT_METHODS_QUERY_PARAMETERS}`]: () => ({
    ...getInitialState()
  })
});
