import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Notification, NotificationType, Box, Link } from 'edenred-ui';

import config from '@epi-config';

const { disableAddingContactPersonOnCompanyInformation } = config;

export const CompanyNotificationBanner = () => {
  const { t } = useTranslation();

  return disableAddingContactPersonOnCompanyInformation ? (
    <Box m={4}>
      <Notification
        type={NotificationType.Important}
        text={
          <Trans
            defaults={t('company_info.banner')}
            components={{
              a: <Link target="_blank" href={t('company_info.banner_link')} />
            }}
          />
        }
      />
    </Box>
  ) : null;
};
