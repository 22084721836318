import { REHYDRATE } from 'redux-persist/constants';
import createReducer from '../helpers/CreateReducer';
import { CompanyInfoConstants } from '../constants/actions';
import { contactsOrderBy } from '../constants/contactsOrderBy';

export const getInitialState = () => ({
  contactsOrder: {
    sortingBy: contactsOrderBy.Active,
    ascending: true
  },
  editMode: false,
  showSlideout: false,
  slideoutEditMode: false
});

export default createReducer(getInitialState, {
  [`${CompanyInfoConstants.SET_CONTACTS_ORDER}`]: (
    state,
    { sortingBy, ascending }
  ) => ({
    contactsOrder: { sortingBy, ascending }
  }),
  [`${CompanyInfoConstants.SHOW_CONTACT_SLIDEOUT}`]: () => ({
    showSlideout: true
  }),
  [`${CompanyInfoConstants.HIDE_CONTACT_SLIDEOUT}`]: () => ({
    showSlideout: false
  }),
  [`${CompanyInfoConstants.ENABLE_SLIDEOUT_EDIT_MODE}`]: () => ({
    slideoutEditMode: true
  }),
  [`${CompanyInfoConstants.DISABLE_SLIDEOUT_EDIT_MODE}`]: () => ({
    slideoutEditMode: false
  }),
  [`${CompanyInfoConstants.ENABLE_EDIT_MODE}`]: () => ({
    editMode: true
  }),
  [`${CompanyInfoConstants.DISABLE_EDIT_MODE}`]: () => ({
    editMode: false
  }),
  [`${REHYDRATE}`]: (state, { payload }) => ({
    ...state,
    ...payload.companyInfo,
    rehydrated: true
  })
});
