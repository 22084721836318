import { OrderHistoryConstants } from '../constants/actions';

export const loadVoucherOrdersHistory = queryParameters => ({
  type: `${OrderHistoryConstants.SET_VOUCHER_ORDERS_HISTORY_QUERY_PARAMETERS}`,
  queryParameters
});

export const resetOrderHistoryParameters = () => ({
  type: `${OrderHistoryConstants.RESET_VOUCHER_ORDERS_HISTORY_QUERY_PARAMETERS}`
});
