import { put, call } from 'redux-saga/effects';
import { getAllCountries } from '../../repositories/CountriesRepository';
import { ApiConstants, OverlaysConstants } from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';

export default function* loadCountries() {
  try {
    const countriesList = yield call(getAllCountries);
    yield put({
      type: `${ApiConstants.LOAD_COUNTRIES}_SUCCESS`,
      countries: countriesList.countries
    });
  } catch (ex) {
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    }
    yield put({
      type: `${ApiConstants.LOAD_COUNTRIES}_FAILURE`,
      payload: ex,
      isError: true
    });
    yield put({
      type: `${OverlaysConstants.SHOW_ERROR_POPUP}`,
      closable: false,
      error: ex
    });
  }
}
