import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Authorize } from './Authorize';
import { saveLocationHistory as saveLocationHistoryAction } from '../actions/navigation';
import { getIsAuthenticated, getUserRoles } from '../selectors/login';

const mapStoreToProps = connect(
  store => ({
    userRoles: getUserRoles(store),
    isAuthenticated: getIsAuthenticated(store)
  }),
  dispatch =>
    bindActionCreators(
      { saveLocationHistory: saveLocationHistoryAction },
      dispatch
    )
);

export const AuthorizeRole = mapStoreToProps(props => {
  const { roles, userRoles, redirect, saveLocationHistory, location } = props;
  const missingRoles = roles.reduce((alreadyMissing, role) => {
    if (!userRoles.find(r => r === role)) {
      return [...alreadyMissing, role];
    }

    return alreadyMissing;
  }, []);

  if (missingRoles.length > 0) {
    saveLocationHistory(location);
    return (
      <Redirect
        to={{
          pathname: redirect
        }}
      />
    );
  }

  return <Authorize {...props} />;
});
