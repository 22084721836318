import React from 'react';
import PropTypes from 'prop-types';
import { LoadingData } from 'src/scripts/routes/LoadingData';
import { reorderData as reorderDataLoadingSelector } from '@epi-selectors/loading';
import { isError as isErrorSelector } from '@epi-selectors/voucherReorder';
import * as voucherReorderActions from '@epi-actions/voucherReorder';
import { OrderAmount } from './OrderAmount';

export function OrderAmountLoadingData(props) {
  return (
    <LoadingData
      {...props}
      loadAction={() =>
        voucherReorderActions.loadReorderData(props.match.params.orderId)
      }
      loadingSelector={state =>
        reorderDataLoadingSelector(state, props.match.params.orderId)
      }
      errorSelector={state => isErrorSelector(state)}
    >
      <OrderAmount />
    </LoadingData>
  );
}

OrderAmountLoadingData.propTypes = {
  match: PropTypes.object
};
