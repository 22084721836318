import { ApiConstants } from '@epi-constants/actions';
import createReducer from '@epi-helpers/CreateReducer';

export const getInitialState = () => ({
  isLoading: [],
  noPersistance: true
});

const AddId = (state, id) => [...state.isLoading, id];

const RemoveId = (state, id) => [
  ...(state.isLoading || []).filter(value => value !== id)
];

export default createReducer(getInitialState, {
  [`${ApiConstants.GET_RECEIPT_FOR_TRANSACTION}_REQUEST`]: (state, { id }) => ({
    isLoading: AddId(state, `transaction-${id}`)
  }),

  [`${ApiConstants.GET_RECEIPT_FOR_TRANSACTION}_SUCCESS`]: (state, { id }) => ({
    isLoading: RemoveId(state, `transaction-${id}`)
  }),

  [`${ApiConstants.GET_RECEIPT_FOR_TRANSACTION}_FAILURE`]: (state, { id }) => ({
    isLoading: RemoveId(state, `transaction-${id}`)
  })
});
