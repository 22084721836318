import { PaymentStatuses } from '@epi-constants/paymentStatuses';
import { VoucherOrderStatuses } from '@epi-constants/voucherOrderStatuses';

export const OrderStatuses = {
  WaitingPayment: 'waiting_payment',
  Processing: 'processing',
  Failed: 'failed',
  PaymentCanceled: 'payment_canceled',
  InProgress: 'in_progress',
  Expired: 'expired',
  Sent: 'sent',
  OrderCanceled: 'order_canceled'
};

export const statusDefinitions = [
  {
    voucherOrderStatus: VoucherOrderStatuses.Created,
    paymentStatus: PaymentStatuses.Created,
    status: OrderStatuses.WaitingPayment
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Created,
    paymentStatus: PaymentStatuses.Pending,
    status: OrderStatuses.WaitingPayment
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Created,
    paymentStatus: PaymentStatuses.Paid,
    status: OrderStatuses.Processing
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Created,
    paymentStatus: PaymentStatuses.Error,
    status: OrderStatuses.Failed
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Created,
    paymentStatus: PaymentStatuses.Canceled,
    status: OrderStatuses.PaymentCanceled
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Created,
    paymentStatus: PaymentStatuses.Confirmed,
    status: OrderStatuses.InProgress
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Created,
    paymentStatus: PaymentStatuses.Expired,
    status: OrderStatuses.Expired
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Submitted,
    paymentStatus: PaymentStatuses.Paid,
    status: OrderStatuses.InProgress
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Submitted,
    paymentStatus: PaymentStatuses.Confirmed,
    status: OrderStatuses.InProgress
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Submitted,
    paymentStatus: PaymentStatuses.Error,
    status: OrderStatuses.Failed
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.InProgress,
    paymentStatus: PaymentStatuses.Paid,
    status: OrderStatuses.InProgress
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.InProgress,
    paymentStatus: PaymentStatuses.Confirmed,
    status: OrderStatuses.InProgress
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.InProgress,
    paymentStatus: PaymentStatuses.Error,
    status: OrderStatuses.Failed
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Sent,
    paymentStatus: PaymentStatuses.Paid,
    status: OrderStatuses.Sent
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Sent,
    paymentStatus: PaymentStatuses.Confirmed,
    status: OrderStatuses.Sent
  },
  {
    voucherOrderStatus: VoucherOrderStatuses.Error,
    paymentStatus: PaymentStatuses.Error,
    status: OrderStatuses.Failed
  }
];

export function getOrderStatus(
  paymentStatus: string,
  voucherOrderStatus: string
) {
  const statusDefinition = statusDefinitions.find(
    sd =>
      sd.paymentStatus === paymentStatus &&
      sd.voucherOrderStatus === voucherOrderStatus
  );

  if (statusDefinition) {
    return statusDefinition.status;
  }

  if (voucherOrderStatus === VoucherOrderStatuses.Canceled) {
    return OrderStatuses.OrderCanceled;
  }

  return OrderStatuses.Processing;
}
