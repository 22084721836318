import React from 'react';

import ExternalReportsTabs from './ExternalReportsTabs';
import { ReportsTabProvider } from './Reports/ReportsTabContext';

const ExternalReports = () => {
  return (
    <ReportsTabProvider>
      <ExternalReportsTabs />
    </ReportsTabProvider>
  );
};

export default ExternalReports;
