import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box } from 'edenred-ui';

import { INavbarLanguageID } from '@epi-models/core/Navbar';

import { NavbarLanguageFlag } from '../NavbarLanguageFlag';

const LanguageText = styled.div`
  margin-left: 8px;
  color: ${({ theme }) => theme.gray70};
  font-size: ${({ theme }) => theme.sizeExtraSmall};
`;

export function NavbarLanguageLabel({ languageId }: INavbarLanguageID) {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center">
      <NavbarLanguageFlag languageId={languageId} />
      <LanguageText>{t(`navbar.language_short_${languageId}`)}</LanguageText>
    </Box>
  );
}
