import i18n from 'i18next';

export const ContractStep = {
  CompanyInformation: 'companyInformation',
  InvoicingInformation: 'invoicingInformation',
  ContactPerson: 'contactPerson',
  ContractSignatory: 'contractSignatory'
};

export function getContractSteps() {
  return [
    {
      label: i18n.t('contract.company_information_step'),
      name: ContractStep.CompanyInformation
    },
    {
      label: i18n.t('contract.invoicing_information_step'),
      name: ContractStep.InvoicingInformation
    },
    {
      label: i18n.t('contract.contact_person_step'),
      name: ContractStep.ContactPerson
    },
    {
      label: i18n.t('contract.contract_signatory_step'),
      name: ContractStep.ContractSignatory
    }
  ];
}
