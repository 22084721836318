import styled from 'styled-components';

export const DataColumnsSectionStyle = styled.div`
  display: flex;
  flex-flow: column;
  border-bottom: 1px solid lightgrey;

  .column-pills {
    padding: 40px 0;
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
  }

  .benefit-types {
    padding-bottom: 40px;
    color: #252525;
    font-size: 16px;
    font-weight: 600;
    display: ${props => (props.showBenefitTypeCheckboxes ? 'flex' : 'none')};
    flex-flow: row nowrap;
    align-items: center;
    gap: 2rem;

    &__title {
      width: 150px;
      position: relative;
    }

    &__checkboxes {
      -webkit-box-sizing: unset;
      box-sizing: unset;
      * {
        -webkit-box-sizing: unset;
        box-sizing: unset;
      }
      margin-left: 0;
      position: relative;
      left: -1.5rem;

      svg {
        margin-bottom: 6px;
      }

      input {
        visibility: hidden;
      }
    }
  }
`;
