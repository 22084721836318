import React, { createContext, useMemo, useReducer } from 'react';
import PropTypes from 'prop-types';
import reportsTabReducer, {
  reportsInitialDataLoaded,
  requestDownloadReport,
  reportDownloaded,
  reportDownloadFailed,
  setColumns,
  setDataScope,
  setDownloadAs,
  setDateRangeMode,
  setPickerDateRange,
  toggleBenefitType,
  dateRangeModes
} from './ReportsTabReducer';
import {
  setColumnInitialValue,
  setStartDate,
  setEndDate
} from './ReportsTabHelpers';

const ReportsTabContext = createContext();

const ReportsTabProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reportsTabReducer, {
    isLoading: true,
    activeBenefits: null,
    startDate: setStartDate(dateRangeModes.currentMonth),
    endDate: setEndDate(dateRangeModes.currentMonth),
    pickerStartDate: null,
    pickerEndDate: null,
    loadStatuses: 'Fulfilled',
    columns: [
      {
        id: 'firstName',
        group: 'employeeData',
        active: setColumnInitialValue('firstName')
      },
      {
        id: 'lastName',
        group: 'employeeData',
        active: setColumnInitialValue('lastName')
      },
      {
        id: 'SSN',
        group: 'employeeData',
        active: setColumnInitialValue('SSN')
      },
      {
        id: 'benefitType',
        group: 'benefits',
        active: setColumnInitialValue('benefitType')
      },
      {
        id: 'benefitStart',
        group: 'benefits',
        active: setColumnInitialValue('benefitStart')
      },
      {
        id: 'benefitEnd',
        group: 'benefits',
        active: setColumnInitialValue('benefitEnd')
      },
      {
        id: 'loadAmount',
        group: 'loads',
        active: setColumnInitialValue('loadAmount')
      },
      {
        id: 'loadDate',
        group: 'loads',
        active: setColumnInitialValue('loadDate')
      },
      {
        id: 'loadStatus',
        group: 'loads',
        active: setColumnInitialValue('loadStatus')
      }
    ],
    dataScope: {
      employeeData: false,
      benefits: false,
      loads: false
    },
    requestDownloadReport: false,
    report: null,
    downloadAs: 'csv',
    dateRangeMode: dateRangeModes.currentMonth
  });

  const actions = {
    requestDownloadReport: () => dispatch(requestDownloadReport()),
    reportsInitialDataLoaded: data => dispatch(reportsInitialDataLoaded(data)),
    reportDownloaded: report => dispatch(reportDownloaded(report)),
    reportDownloadFailed: () => dispatch(reportDownloadFailed()),
    setColumns: columnId => dispatch(setColumns(columnId)),
    setDataScope: columnGroupName => dispatch(setDataScope(columnGroupName)),
    toggleBenefitType: benefitName => dispatch(toggleBenefitType(benefitName)),
    setDateRangeMode: mode => dispatch(setDateRangeMode(mode)),
    setPickerDateRange: (from, to) => dispatch(setPickerDateRange(from, to)),
    setDownloadAs: format => dispatch(setDownloadAs(format))
  };

  const contextValue = useMemo(
    () => ({ reportsTabState: state, reportsTabActions: actions }),
    [state, actions]
  );

  return (
    <ReportsTabContext.Provider value={contextValue}>
      {children}
    </ReportsTabContext.Provider>
  );
};

ReportsTabProvider.propTypes = {
  children: PropTypes.any.isRequired
};

export { ReportsTabProvider, ReportsTabContext };
