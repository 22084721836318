import { NotificationType } from 'edenred-ui';
import { ReactElement, ReactNode, useState } from 'react';

export const useToast = () => {
  const [content, setContent] = useState<ReactNode | undefined>();
  const [button, setButton] = useState<ReactElement | undefined>();
  const [type, setType] = useState<NotificationType | undefined>();

  const showToast = (
    type: NotificationType,
    content: ReactNode,
    button?: ReactElement
  ) => {
    setContent(content);
    setButton(button);
    setType(type);
  };

  const closeToast = () => {
    setContent(undefined);
    setButton(undefined);
    setType(undefined);
  };

  return {
    showToast,
    closeToast,
    type,
    content,
    button
  };
};
