import { put, call } from 'redux-saga/effects';
import { getPaymentMethods } from '../../repositories/PaymentMethodsRepository';
import { ApiConstants } from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';

const { LOAD_PAYMENT_METHODS } = ApiConstants;

export default function* loadPaymentMethods({
  orderType,
  totalAmount,
  locale
}) {
  try {
    const methods = yield call(
      getPaymentMethods,
      orderType,
      totalAmount,
      locale
    );
    yield put({
      type: `${LOAD_PAYMENT_METHODS}_SUCCESS`,
      methods
    });
  } catch (ex) {
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    }
    yield put({
      type: `${LOAD_PAYMENT_METHODS}_FAILURE`,
      payload: ex
    });
  }
}
