import React from 'react';
import { useTranslation } from 'react-i18next';

import { Products } from '@epi-constants/products';
import { IProductName } from '@epi-models/core/Navbar';

export function ProductName({ product }: IProductName) {
  const { t } = useTranslation();

  const generateText = () => {
    switch (product) {
      case Products.VoucherRestaurant:
        return t('products.voucher-restaurant');
      case Products.VoucherRecreational:
        return t('products.voucher-recreational');
      default:
        return '';
    }
  };

  return <>{generateText()}</>;
}
