import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import {
  createRules,
  FormFields,
  useAsyncValidatorController
} from '@epi-forms/helpers';
import * as Validators from '@epi-helpers/formHelpers/validators';
import { EInvoiceContext } from '../EInvoiceContext';

export function EInvoiceAddressField({ readOnly }) {
  const name = FormFields.EInvoiceAddress;
  const {
    control,
    formState: { errors },
    getValues
  } = useFormContext();
  const { t } = useTranslation();
  const validators = [Validators.required];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const { validateEInvoice } = useContext(EInvoiceContext);

  const {
    isError,
    isInfo,
    helperText,
    inputProps,
    setAsyncUnverified,
    clearAsyncUnverified
  } = useAsyncValidatorController({
    value,
    error,
    name: FormFields.EInvoice,
    invalidMsg: t('validation_messages.invalid_einvoice'),
    processingMsg: t('validation_messages.processing_einvoice'),
    isStatusSuccess: isDefaultSuccess =>
      isDefaultSuccess &&
      getValues(FormFields.Operator) &&
      !errors[FormFields.Operator]
  });

  const onChange = event => {
    setAsyncUnverified();
    setValue(event);
  };

  const onBlurFormat = () => {
    const formattedValue = value?.trim().toUpperCase();

    if (value !== formattedValue) {
      setValue(formattedValue);
    }
  };

  const onBlur = async () => {
    onBlurFormat();
    await validateEInvoice();
    clearAsyncUnverified();
  };

  return (
    <TextField
      label={t('controls.einvoice_address_label')}
      required
      readOnly={readOnly}
      value={value}
      id={name}
      error={isError}
      info={isInfo}
      helperText={
        readOnly ? '' : helperText || t('controls.einvoice_address_helper_text')
      }
      InputProps={readOnly ? null : inputProps}
      inputRef={ref}
      onBlur={onBlur}
      onChange={onChange}
    />
  );
}

EInvoiceAddressField.propTypes = {
  readOnly: PropTypes.bool
};

EInvoiceAddressField.defaultProps = {
  readOnly: false
};
