import XLSX from 'xlsx';
import { format } from 'date-fns';
import {
  getFirstDayOfCurrentMonth,
  getFirstDayOfLastMonth,
  getLastDayOfCurrentMonth,
  getLastDayOfLastMonth
} from '../../../helpers/dateHelper';
import { defaultDateFormat } from '../../../constants/dateFormats';
import storage from '../../../helpers/StorageHelper';
import { dateRangeModes } from './ReportsTabReducer';

export const setStartDate = (dateRangeMode, startDateValue = null) => {
  if (dateRangeMode === dateRangeModes.lastMonth) {
    return getFirstDayOfLastMonth();
  }
  if (dateRangeMode === dateRangeModes.datePicker) {
    return startDateValue;
  }
  return getFirstDayOfCurrentMonth();
};

export const setEndDate = (dateRangeMode, endDateValue = null) => {
  if (dateRangeMode === dateRangeModes.lastMonth) {
    return getLastDayOfLastMonth();
  }

  if (dateRangeMode === dateRangeModes.datePicker) {
    return endDateValue;
  }
  return getLastDayOfCurrentMonth();
};

export const formatReportRequestPayload = state => {
  const isColumnActive = columnId => {
    const column = state.columns.find(c => c.id === columnId);
    return column.active;
  };
  const isColumnGroupActive = columnId => {
    const column = state.columns.find(c => c.id === columnId);
    return state.dataScope[column.group];
  };

  const isColumnSelected = columnId =>
    isColumnGroupActive(columnId) && isColumnActive(columnId);

  const getStartDate = () =>
    state.dateRangeMode === dateRangeModes.datePicker
      ? state.pickerStartDate
      : state.startDate;
  const getEndDate = () =>
    state.dateRangeMode === dateRangeModes.datePicker
      ? state.pickerEndDate
      : state.endDate;

  return {
    startDate: getStartDate(),
    endDate: getEndDate(),
    benefits: state.activeBenefits.filter(b => b.checked).map(b => b.name),
    loadStatuses: ['fulfilled'],
    columns: {
      firstName: isColumnSelected('firstName'),
      lastName: isColumnSelected('lastName'),
      SSN: isColumnSelected('SSN'),
      benefitType: isColumnSelected('benefitType'),
      benefitStart: isColumnSelected('benefitStart'),
      benefitEnd: isColumnSelected('benefitEnd'),
      loadAmount: isColumnSelected('loadAmount'),
      loadDate: isColumnSelected('loadDate'),
      loadStatus: isColumnSelected('loadStatus')
    }
  };
};

export const setColumnInitialValue = columnId => {
  const selectedByDefault = [
    'firstName',
    'lastName',
    'benefitType',
    'loadAmount'
  ];
  const fromStorage = storage.get(`report${columnId}`);
  if (typeof fromStorage === 'boolean') {
    return fromStorage;
  }
  return selectedByDefault.includes(columnId);
};

export const downloadReport = (state, report) => {
  const from =
    state.dateRangeMode === dateRangeModes.datePicker
      ? state.pickerStartDate
      : state.startDate;
  const to =
    state.dateRangeMode === dateRangeModes.datePicker
      ? state.pickerEndDate
      : state.endDate;
  const fromDate = format(new Date(from), defaultDateFormat);
  const toDate = format(new Date(to), defaultDateFormat);
  const fileName = `Report-${fromDate}-${toDate}`;
  const keys = Object.keys(report.data);
  const entries = Object.entries(report.data);
  const output = [];

  entries.forEach(entry => {
    const columnName = entry[0];
    const columnValues = entry[1];
    if (columnValues.length > 0) {
      columnValues.map((val, index) => {
        if (typeof output[index] === 'undefined') {
          output[index] = { [columnName]: val };
        } else if (typeof output[index] === 'object') {
          output[index][columnName] = val;
        }
        return null;
      });
    } else {
      keys.forEach(val => output.push({ [val]: '' }));
    }
  });

  const wb = XLSX.utils.book_new();
  wb.SheetNames.push('Report');
  const ws = XLSX.utils.json_to_sheet(output);
  wb.Sheets.Report = ws;
  XLSX.writeFile(wb, `${fileName}.${state.downloadAs}`, {
    bookType: state.downloadAs
  });
};
