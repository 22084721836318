import { put, select } from 'redux-saga/effects';
import { getApiErrors } from '../../selectors/api';
import { ApiConstants } from '../../constants/actions';

export function* getApiError(action) {
  yield put({
    type: `${ApiConstants.SAVE_API_ERROR}`,
    actionName: action.type,
    payload: action.payload
  });
}

export function* clearActionApiError(action) {
  const apiError = (yield select(getApiErrors)).actionName || '';
  if (
    apiError.replace(/_FAILURE$/, '') === action.type.replace(/_REQUEST$/, '')
  ) {
    yield put({
      type: `${ApiConstants.CLEAR_API_ERROR}`
    });
  }
}

export function* clearApiError() {
  yield put({
    type: `${ApiConstants.CLEAR_API_ERROR}`
  });
}
