import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from 'edenred-ui';
import PropTypes from 'prop-types';

import { FormFields } from '@epi-forms/helpers';
import { createRules } from '@epi-forms/helpers';
import {
  minValue,
  required,
  integer,
  maxLength
} from '@epi-helpers/formHelpers/validators';

export function VoucherQuantityField({
  label,
  onFocus,
  onBlur,
  validate,
  name
}) {
  const minValue50 = minValue(50);
  const maxLength6 = maxLength(6);
  const { control } = useFormContext();
  const validators = [minValue50, required, integer, maxLength6, ...validate];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      name={name}
      id={name}
      label={label}
      inputRef={ref}
      onFocus={onFocus}
      onBlur={onBlur}
      onChange={setValue}
      value={value}
      error={!!error}
      helperText={error?.message}
    />
  );
}

VoucherQuantityField.propTypes = {
  label: PropTypes.string.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  validate: PropTypes.array,
  name: PropTypes.string
};

VoucherQuantityField.defaultProps = {
  validate: [],
  name: FormFields.VoucherQuantity
};
