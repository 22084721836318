import React from 'react';
import { put, call } from 'redux-saga/effects';
import { getAvailability } from '../../repositories/UsernameRepository';
import { ApiConstants } from '../../constants/actions';
import * as overlaysActions from '../../actions/overlays';
import { TranslationResource } from '../../helpers/TranslationResource';

export function* checkUsernameAvailability(username) {
  const result = yield call(getAvailability, username);
  return result;
}

export default function* getUsernameAvailability({
  username,
  resolve,
  reject
}) {
  try {
    const result = yield call(checkUsernameAvailability, username);
    if (result === true) {
      yield call(resolve);
    } else {
      yield call(reject, {
        username: (
          <TranslationResource message="validation_messages.username_taken" />
        )
      });
    }
    yield put({
      type: `${ApiConstants.CHECK_USERNAME_AVAILABILITY}_SUCCESS`
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.CHECK_USERNAME_AVAILABILITY}_FAILURE`,
      payload: ex,
      isError: true
    });
    yield put(
      overlaysActions.showErrorNotification('messages.cannot_check_username')
    );
    yield call(reject, ex);
  }
}
