import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingSpinner } from 'edenred-ui';

import { isSignupTokenValidRequest } from '@epi-api/signUp';
import { showNotification } from '@epi-actions/overlays';
import { isReCaptchaLoadedSelector } from '@epi-selectors/reCaptchaSelectors';

import {
  CreateAccountForm,
  ContractCompleted,
  TokenExpired,
  TokenInvalid
} from './components';
import { SignupTokenErrors } from '@epi-constants/signupTokenErrors';
import { ContactDeleted } from './components/ContactDeleted';

function CreateAccount({ location, dispatch, isReCaptchaLoaded }) {
  const [tokenValidityState, setTokenValidityState] = useState({
    isValid: false,
    errorCode: null
  });

  const params = new URLSearchParams(location.search);
  const signToken = params.get('token');
  const contactRegistrationType = params.get('type');

  async function fetchIsSignUpTokenValid() {
    try {
      const tokenValidity = await isSignupTokenValidRequest(
        signToken,
        contactRegistrationType
      );
      setTokenValidityState(tokenValidity);
    } catch (e) {
      dispatch(showNotification({ message: 'messages.error' }, 'error'));
    }
  }

  useEffect(() => {
    if (isReCaptchaLoaded) {
      fetchIsSignUpTokenValid();
    }
  }, [isReCaptchaLoaded]);

  function render() {
    if (tokenValidityState.isValid) {
      return <CreateAccountForm />;
    }
    switch (tokenValidityState.errorCode) {
      case SignupTokenErrors.IncorrectToken:
        return <TokenInvalid />;
      case SignupTokenErrors.CreationCompleted:
        return <ContractCompleted />;
      case SignupTokenErrors.ExpiredToken:
        return <TokenExpired />;
      case SignupTokenErrors.ContactDeleted:
        return <ContactDeleted />;
    }
    return <LoadingSpinner />;
  }

  return render();
}

CreateAccount.propTypes = {
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  isReCaptchaLoaded: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isReCaptchaLoaded: isReCaptchaLoadedSelector(state)
});

export default connect(mapStateToProps)(CreateAccount);
