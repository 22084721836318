/* eslint-disable no-restricted-syntax */
export function sendFormData(url, formData, method = 'POST') {
  const form = document.createElement('form');
  form.method = method;
  form.action = url;

  for (const item of formData) {
    const hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.name = item.key;
    hiddenField.value = item.value;

    form.appendChild(hiddenField);
  }

  document.body.appendChild(form);
  form.submit();
}
