import React from 'react';
import PropTypes from 'prop-types';
import { Box, BenefitTile, TitleText, TitleType } from 'edenred-ui';

export const BenefitsCategory = ({ titleType, buttonText, benefits, type }) => {
  return (
    <Box display="flex" flexDirection="column">
      <TitleText type={TitleType.h1}>{titleType}</TitleText>
      <Box display="flex" gap={3} height="100%">
        {benefits.map(({ title, imgSrc, infoElementsArr, handleClick }) => (
          <BenefitTile
            key={title}
            title={title}
            imgSrc={imgSrc}
            infoElementsArr={infoElementsArr}
            buttonText={buttonText}
            labelText={titleType}
            onClick={handleClick}
            type={type}
          />
        ))}
      </Box>
    </Box>
  );
};

BenefitsCategory.propTypes = {
  titleType: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  benefits: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired
};
