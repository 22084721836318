import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { connect, useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { NavSidebar, Box, LoadingSpinner } from 'edenred-ui';

import {
  getUserRoles,
  getHasOrders,
  getIsAuthenticated,
  getLoginState
} from '@epi-selectors/login';
import { generateUrlWithLocale } from '@epi-selectors/navigation';
import { createMenuItems } from '@epi-helpers/Menu/MenuItemsFactoryHelper';
import { isLocationWithMenu } from '@epi-helpers/Menu/MenuLocationHelper';
import { Navbar } from '@epi-core/components/Navbar';
import { useMenuDimensions } from '@epi-shared/hooks';
import storage from '@epi-helpers/StorageHelper';
import { getCompanyMetadata } from '@epi-store/companyMetadata/companyMetadata.selectors';
import { loadCompanyMetadata } from '@epi-actions/api';
import { ILayout } from '@epi-models/core/Layout';

import { Toast } from '../Toast';

const navbarHeight = 73;

const clearLocalStorage = () => storage.clear();

function Layout({
  children,
  isAuthenticated,
  roles,
  hasOrders,
  urlWithLocale,
  location
}: ILayout) {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { isMobile, isDesktop } = useMenuDimensions();
  const [menuOpen, setMenuOpen] = useState(isDesktop);
  const [overlayVisible, setOverlayVisible] = useState(isMobile && menuOpen);
  const { isUserImpersonate } = useSelector(getLoginState);
  const companyMetadata = useSelector(getCompanyMetadata);

  const showVoucherOrder = !companyMetadata.data?.hideVoucherOrder;

  const menuItems = createMenuItems({
    roles,
    hasOrders,
    urlWithLocale,
    showVoucherOrder
  });
  const locationIncludesMenu = isLocationWithMenu(location);

  const heightDifference = navbarHeight;
  const layoutHeight = `calc(100% - ${heightDifference}px)`;
  useEffect(() => {
    setMenuOpen(isDesktop);
  }, [isDesktop]);

  useEffect(() => {
    setOverlayVisible(isMobile && menuOpen);
  }, [menuOpen, isMobile]);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(loadCompanyMetadata());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isUserImpersonate) {
      window.addEventListener('beforeunload', clearLocalStorage);
      return () => {
        window.removeEventListener('beforeunload', clearLocalStorage);
      };
    }
  }, [isUserImpersonate]);

  if (companyMetadata?.isLoading && isAuthenticated) return <LoadingSpinner />;

  return (
    <Box height="100vh" max-height="100vh" overflow="hidden">
      <Navbar sideMenuOpen={menuOpen} toggleSideMenu={setMenuOpen} />
      <Box display="flex" height={layoutHeight} position="relative">
        {locationIncludesMenu && isAuthenticated && (
          <Box>
            <NavSidebar
              showToggle={!isMobile}
              temporary={isMobile}
              open={menuOpen}
              onToggle={setMenuOpen}
            >
              {menuItems.map(menuItem => {
                return (
                  <NavSidebar.Item
                    key={menuItem.name}
                    name={menuItem.name}
                    link={menuItem.link}
                    icon={menuItem.icon}
                    id={menuItem.id}
                    exact
                  />
                );
              })}
            </NavSidebar>
          </Box>
        )}
        <Box
          width="100%"
          overflow="auto"
          bgcolor={theme.gray5}
          position="relative"
        >
          {overlayVisible && (
            <Box
              zIndex={90}
              width="100%"
              height="100%"
              bgcolor="rgba(0,0,0,0.7)"
              position="fixed"
            />
          )}
          <Toast />
          {children}
        </Box>
      </Box>
    </Box>
  );
}

const connectToStore = connect(state => ({
  hasOrders: getHasOrders(state),
  roles: getUserRoles(state),
  isAuthenticated: getIsAuthenticated(state),
  urlWithLocale: url => generateUrlWithLocale(url)(state)
}));

const ConnectedLayout = withRouter(connectToStore(Layout));

export { ConnectedLayout as Layout };
