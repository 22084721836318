import Moment from 'moment';
import finishHolidays from 'finnish-holidays-js';
import { map, some } from 'lodash';

class MomentExtensions {
  constructor() {
    this.cache = null;
  }

  workingDaysDiff(param) {
    const d1 = this.clone();
    const d2 = param.clone();
    const start = d1 < d2 ? d1 : d2;
    const end = d2 > d1 ? d2 : d1;

    let daysBetween = 0;

    if (start === end) {
      return daysBetween;
    }

    // NOSONAR
    while (start < end) {
      if (start.isBusinessDay()) {
        daysBetween += 1;
      }
      start.add(1, 'd');
    }

    return daysBetween;
  }

  isHoliday() {
    const thisMoment = this.clone();
    const thisYear = thisMoment.year();

    const holidays = this.getYearsHolidays(thisYear);

    if (holidays && holidays.length > 0) {
      if (some(holidays, holiday => holiday.isSame(thisMoment, 'day'))) {
        return true;
      }
    }

    return false;
  }

  isBusinessDay() {
    const defaultWorkingWeekdays = [1, 2, 3, 4, 5];
    const workingWeekdays = defaultWorkingWeekdays;

    return this.isHoliday() ? false : workingWeekdays.indexOf(this.day()) >= 0;
  }

  getYearsHolidays(year) {
    if (!this.cache || this.cache.year !== year) {
      this.cache = {
        year,
        holidays: map(finishHolidays.year(year, true), holiday =>
          Moment([holiday.year, holiday.month - 1, holiday.day])
        )
      };
    }

    return this.cache.holidays;
  }
}

const ext = new MomentExtensions();

Moment.fn.isHoliday = ext.isHoliday;
Moment.fn.isBusinessDay = ext.isBusinessDay;
Moment.fn.workingDaysDiff = ext.workingDaysDiff;
Moment.fn.getYearsHolidays = ext.getYearsHolidays;

export default Moment;
