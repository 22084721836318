import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { postRequest } from './ApiRepository';

export function* addNewPaymentToDepositOrder(paymentData) {
  return yield call(
    postRequest,
    `${config.apiHost}/api/deposit-order-payments`,
    Authorize.IfLogged,
    paymentData
  );
}
