import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { TableComponent, LoadingSpinner } from 'edenred-ui';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import { normalizeDecimalComma } from '@epi-containers/LoadCompanyAccount/helpers';

import { defaultDateFormat } from '../../constants/dateFormats';
import { capitalizeFirstLetter } from '../../helpers/Formatters';
import { getMonthNameForLocale } from '../../helpers/dateHelper';

const Cell = styled.div`
  font-size: 14px;
  word-break: initial;
  min-width: 50px;
  flex-grow: initial;
`;

const formattedRequestDate = requestDate =>
  format(new Date(requestDate), defaultDateFormat);
const requestMonthName = requestDate =>
  capitalizeFirstLetter(
    getMonthNameForLocale(i18n.language, 0, new Date(requestDate))
  );

function RequestedLoadsTable({
  requestedLoads,
  isLoading,
  setRequestIdToBeRejected
}) {
  const rows =
    typeof requestedLoads !== 'undefined'
      ? requestedLoads.map(rl => {
          const row = [];

          row.push(<span>{formattedRequestDate(rl.requestDate)}</span>);
          row.push(<span>{rl.firstName}</span>);
          row.push(<span>{rl.lastName}</span>);
          row.push(<span>{rl.personalId}</span>);
          row.push(
            <span>
              {i18n.t('containers.deposit_money.requested_loads.table.lunch')}
            </span>
          );
          row.push(<span>{requestMonthName(rl.requestDate)}</span>);
          row.push(<span>{`${normalizeDecimalComma(rl.amount)} €`}</span>);
          return { row, id: rl.requestId };
        })
      : [];
  const columns = [
    {
      render: ({ row }) => <Cell>{row[0]}</Cell>, // eslint-disable-line
      renderHeader: () => (
        <Cell>
          {i18n.t(
            'containers.deposit_money.requested_loads.table.request_date'
          )}
        </Cell>
      ),
      sortByKey: 'requestDate'
    },
    {
      render: ({ row }) => <Cell>{row[1]}</Cell>, // eslint-disable-line
      renderHeader: () => (
        <Cell>
          {i18n.t('containers.deposit_money.requested_loads.table.first_name')}
        </Cell>
      ),
      sortByKey: 'firstName'
    },
    {
      render: ({ row }) => <Cell>{row[2]}</Cell>, // eslint-disable-line
      renderHeader: () => (
        <Cell>
          {i18n.t('containers.deposit_money.requested_loads.table.last_name')}
        </Cell>
      ),
      sortByKey: 'lastName'
    },
    {
      render: ({ row }) => <Cell>{row[3]}</Cell>, // eslint-disable-line
      renderHeader: () => (
        <Cell>
          {i18n.t('containers.deposit_money.requested_loads.table.personal_id')}
        </Cell>
      ),
      sortByKey: 'personalId'
    },
    {
      render: ({ row }) => <Cell>{row[4]}</Cell>, // eslint-disable-line
      renderHeader: () => (
        <Cell>
          {i18n.t('containers.deposit_money.requested_loads.table.benefit')}
        </Cell>
      ),
      sortByKey: 'benefit'
    },
    {
      render: ({ row }) => <Cell>{row[5]}</Cell>, // eslint-disable-line
      renderHeader: () => (
        <Cell>
          {i18n.t(
            'containers.deposit_money.requested_loads.table.request_month'
          )}
        </Cell>
      ),
      sortByKey: ''
    },
    {
      render: ({ row }) => <Cell>{row[6]}</Cell>, // eslint-disable-line
      renderHeader: () => (
        <Cell>
          {i18n.t('containers.deposit_money.requested_loads.table.amount')}
        </Cell>
      ),
      sortByKey: 'amount'
    },
    {
      render: (
        { id } // eslint-disable-line
      ) => (
        <Cell
          onClick={() => {
            setRequestIdToBeRejected(id);
          }}
        >
          <span className="requested-loads__action">
            {i18n.t('containers.deposit_money.requested_loads.table.reject')}
          </span>
        </Cell>
      ), // eslint-disable-line
      renderHeader: () => (
        <Cell>
          {i18n.t('containers.deposit_money.requested_loads.table.actions')}
        </Cell>
      )
    }
  ];

  if (!columns || !rows) {
    return null;
  }

  return (
    <TableComponent
      isLoading={isLoading}
      spinner={<LoadingSpinner />}
      rows={rows}
      columns={columns}
      noData={i18n.t('containers.deposit_money.requested_loads.table.no_data')}
    />
  );
}

RequestedLoadsTable.propTypes = {
  requestedLoads: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setRequestIdToBeRejected: PropTypes.func.isRequired
};

export default RequestedLoadsTable;
