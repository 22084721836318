export const homeBanner = (paymentLimitsImg, delicardImg, taxationImg) => [
  {
    content: {
      locale: 'en',
      header: 'Tax-free recognition on special occasions',
      imageAlt: 'A gift card that you can pay for something',
      imageSrc: delicardImg,
      link: 'https://www.delicard.fi/en/buy-gift-cards/?utm_source=emp&utm_medium=banner&utm_campaign=dc_high_season',
      linkKind: 'Relative',
      linkText: 'Buy Gift Cards',
      text: `Christmas gifts, retirement, birthdays, summer gift... Delicard's differently priced gift cards offer a tax-free option for every occasion and celebration!`,
      linkTwo:
        'https://www.delicard.fi/en/request-offer?utm_source=emp&utm_medium=banner&utm_campaign=dc_high_season',
      linkTextTwo: 'Request an Offer'
    },
    id: 1,
    name: 'Delicard',
    priority: 1,
    templateName: 'Banner'
  },
  {
    content: {
      locale: 'en',
      header: 'Employee benefit payment limits for 2024',
      imageAlt: 'A women eating dinner',
      imageSrc: paymentLimitsImg,
      link: 'https://edenred.fi/en/blog/employee-benefit-payment-limits',
      linkKind: 'Relative',
      linkText: 'Read more',
      text: "Check the current year's tax-free payment limits"
    },
    id: 2,
    name: 'Payment Limits',
    priority: 2,
    templateName: 'Banner'
  },
  {
    content: {
      locale: 'en',
      header: 'Want to learn about employee benefit taxation?',
      imageAlt: 'A book with employee benefit taxation information',
      imageSrc: taxationImg,
      link: 'https://edenred.fi/en/employer/employee-benefit-taxation',
      linkKind: 'Relative',
      linkText: 'Learn about taxation',
      text: 'Clarify the taxation of employee benefits and download our taxation guide'
    },
    id: 3,
    name: 'Employee benefit',
    priority: 3,
    templateName: 'Banner'
  },
  {
    content: {
      locale: 'fi',
      header: 'Verovapaata muistamista merkkipäivinä',
      imageAlt: 'Lahjakortti, jolla voit maksaa jotain',
      imageSrc: delicardImg,
      link: 'https://www.delicard.fi/osta-lahjakortteja?utm_source=emp&utm_medium=banner&utm_campaign=dc_high_season',
      linkKind: 'Relative',
      linkText: 'Osta lahjakortteja',
      text: 'Joululahja, eläkkeelle siirtyminen, syntymäpäivät, kesälahja... Delicardin erihintaiset lahjakortit tarjoavat verovapaan vaihtoehdon joka tilanteeseen ja juhlan aiheeseen!',
      linkTwo:
        'https://www.delicard.fi/pyyda-tarjous?utm_source=emp&utm_medium=banner&utm_campaign=dc_high_season',
      linkTextTwo: 'Kysy tarjous'
    },
    id: 5,
    name: 'Delicard',
    priority: 1,
    templateName: 'Banner'
  },
  {
    content: {
      locale: 'fi',
      header: 'Työsuhde-etujen ajankohtaiset maksurajat',
      imageAlt: 'Edenred-kauppiaiden sijainnit kartalla',
      imageSrc: paymentLimitsImg,
      link: 'https://edenred.fi/fi/blog/tyosuhde-etujen-maksurajat',
      linkKind: 'Relative',
      linkText: 'Lue lisää',
      text: 'Tarkista vuoden 2024 verovapaat maksurajat'
    },
    id: 4,
    name: 'Payment Limits',
    priority: 2,
    templateName: 'Banner'
  },
  {
    content: {
      locale: 'fi',
      header: 'Haluatko oppia lisää työsuhde-etujen verotuksesta?',
      imageAlt:
        'Kirja, joka sisältää tietoa työntekijöiden etuuksien verotuksesta',
      imageSrc: taxationImg,
      link: 'https://edenred.fi/fi/tyonantaja/tyosuhde-etujen-verotus',
      linkKind: 'Relative',
      linkText: 'Tutustu verotukseen',
      text: 'Selvennä työsuhde-etujen verotuksen kiemuroita ja lataa vero-oppaamme'
    },
    id: 6,
    name: 'Employee benefit',
    priority: 3,
    templateName: 'Banner'
  }
];
