import React from 'react';
import PropTypes from 'prop-types';
import { Box, Tooltip, Text, InfoIcon } from 'edenred-ui';
import { useTheme } from 'styled-components';

export const TableHeaderTooltip = ({ tooltipText }) => {
  const theme = useTheme();

  return (
    <Tooltip
      arrow
      boxShadow="strong"
      placement="top"
      title={
        <Box maxWidth={274} p={2}>
          <Text small>{tooltipText}</Text>
        </Box>
      }
    >
      <Box paddingLeft="4px">
        <InfoIcon color={theme.primaryFrontColor} />
      </Box>
    </Tooltip>
  );
};

TableHeaderTooltip.propTypes = {
  tooltipText: PropTypes.string.isRequired
};
