import styled from 'styled-components';

export const HeaderButton = styled.div`
  cursor: pointer;
`;

export const Summary = styled.div`
  margin: 12px 18px;
  display: flex;
  justify-content: space-between;

  font-size: ${props => props.theme.fontSize};
  font-weight: ${props => props.theme.fontSemiBold};
`;
