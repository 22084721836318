import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Table, Box, SimpleButton, MoreIcon } from 'edenred-ui';

import { loadVoucherOrdersHistory } from '@epi-actions/orderHistory';
import { formatDate } from '@epi-helpers/dateHelper';
import { formatCurrency } from '@epi-helpers/numeral';
import { ProductName, OrderStatus } from '@epi-shared/components';
import {
  getOrderHistoryQueryDescending,
  getOrderHistoryQuerySortingBy
} from '@epi-selectors/orderHistory';
import { OrderHistorySorting } from '@epi-constants/orderHistorySorting';
import { VoucherOrderHistoryActions } from '../VoucherOrderHistoryActions';

const HeaderButton = styled.button.attrs({ type: 'button' })`
  background: none;
  border: none;
  margin-left: -6px;
`;

function VoucherOrderHistoryTable({
  voucherOrders,
  sortingBy,
  descending,
  dispatch
}) {
  const { t } = useTranslation();
  const theme = useTheme();

  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [currentVoucherOrder, setCurrentVoucherOrder] = useState(null);

  const openMenu = voucherOrder => event => {
    setCurrentVoucherOrder(voucherOrder);
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
    setCurrentVoucherOrder(null);
  };

  const sortingIcon = descending ? (
    <i className="mdi mdi-menu-down" />
  ) : (
    <i className="mdi mdi-menu-up" />
  );

  const sortBy = orderBy => () =>
    dispatch(loadVoucherOrdersHistory({ sortingBy: orderBy }));

  return (
    <Box
      position="relative"
      sx={{
        '& .MuiBackdrop-root.MuiBackdrop-invisible': {
          backgroundColor: 'transparent'
        }
      }}
    >
      <Table>
        <Table.Head>
          <Table.Row>
            <Table.Cell>
              <HeaderButton
                onClick={sortBy(OrderHistorySorting.ByDate)}
                id="sort-by_date"
              >
                {sortingBy === OrderHistorySorting.ByDate && sortingIcon}
                {t('paper_vouchers.order_history.header_date')}
              </HeaderButton>
            </Table.Cell>
            <Table.Cell>
              <HeaderButton
                onClick={sortBy(OrderHistorySorting.ByVoucherType)}
                id="sort-by_type"
              >
                {sortingBy === OrderHistorySorting.ByVoucherType && sortingIcon}
                {t('paper_vouchers.order_history.header_product')}
              </HeaderButton>
            </Table.Cell>
            <Table.Cell>
              {t('paper_vouchers.order_history.header_face_value')}
            </Table.Cell>
            <Table.Cell>
              <HeaderButton
                onClick={sortBy(OrderHistorySorting.ByAmount)}
                id="sort-by_amount"
              >
                {sortingBy === OrderHistorySorting.ByAmount && sortingIcon}
                {t('paper_vouchers.order_history.header_amount')}
              </HeaderButton>
            </Table.Cell>
            <Table.Cell>
              {t('paper_vouchers.order_history.header_total')}
            </Table.Cell>
            <Table.Cell>
              {t('paper_vouchers.order_history.header_order_status')}
            </Table.Cell>
            <Table.Cell>
              {t('paper_vouchers.order_history.header_action')}
            </Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {voucherOrders.map(voucherOrder => (
            <Table.Row key={voucherOrder.id}>
              <Table.Cell>{formatDate(voucherOrder.date)}</Table.Cell>
              <Table.Cell>
                <ProductName product={voucherOrder.product} />
              </Table.Cell>
              <Table.Cell>{formatCurrency(voucherOrder.faceValue)}</Table.Cell>
              <Table.Cell>{voucherOrder.totalQuantity}</Table.Cell>
              <Table.Cell>
                <Box fontWeight={theme.fontSemiBold}>
                  {formatCurrency(
                    voucherOrder.faceValue * voucherOrder.totalQuantity
                  )}
                </Box>
              </Table.Cell>
              <Table.Cell>
                <OrderStatus
                  paymentStatus={voucherOrder.paymentStatus}
                  voucherOrderStatus={voucherOrder.voucherOrderStatus}
                />
              </Table.Cell>
              <Table.Cell width={56}>
                <SimpleButton
                  icon={<MoreIcon />}
                  onClick={openMenu(voucherOrder)}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <VoucherOrderHistoryActions
        voucherOrder={currentVoucherOrder}
        anchorEl={menuAnchorEl}
        closeMenu={closeMenu}
      />
    </Box>
  );
}

VoucherOrderHistoryTable.propTypes = {
  voucherOrders: PropTypes.array.isRequired,
  sortingBy: PropTypes.string.isRequired,
  descending: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  voucherOrders: state.apiVoucherOrdersHistory.voucherOrders,
  sortingBy: getOrderHistoryQuerySortingBy(state),
  descending: getOrderHistoryQueryDescending(state)
});

const ConnectedVoucherOrderHistoryTable = connect(mapStateToProps)(
  VoucherOrderHistoryTable
);

export { ConnectedVoucherOrderHistoryTable as VoucherOrderHistoryTable };
