import { ButtonComponent as Button, ButtonMode } from 'edenred-ui';
import PropTypes from 'prop-types';
import React from 'react';

const BeneficiaryDetailActions = ({ mainAction, mainActionText, disabled }) => (
  <div className="actions-section">
    <div data-test="beneficiary-detail-action">
      <Button
        id="beneficiary-detail-submit-btn"
        className="trigger-email-DNS-validation"
        onClick={mainAction}
        mode={ButtonMode.Primary}
        disabled={disabled}
      >
        {mainActionText}
      </Button>
    </div>
  </div>
);

BeneficiaryDetailActions.propTypes = {
  mainAction: PropTypes.func.isRequired,
  mainActionText: PropTypes.string.isRequired,
  disabled: PropTypes.bool
};

BeneficiaryDetailActions.defaultProps = {
  disabled: false
};

export default BeneficiaryDetailActions;
