import { ApiConstants } from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  countries: [],
  isLoading: false,
  loaded: false,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_COUNTRIES}_REQUEST`]: () => ({
    countries: [],
    isLoading: true,
    loaded: false
  }),

  [`${ApiConstants.LOAD_COUNTRIES}_SUCCESS`]: (state, { countries }) => {
    const mappedCountries = countries.map(item => ({
      Code: item.id,
      EnglishName: item.en,
      FinnishName: item.fi
    }));
    return {
      countries: mappedCountries,
      isLoading: false,
      loaded: true
    };
  },

  [`${ApiConstants.LOAD_COUNTRIES}_FAILURE`]: state =>
    !state.isError
      ? {
          countries: [],
          isLoading: false,
          loaded: true,
          isError: true
        }
      : state
});
