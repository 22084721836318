import styled from 'styled-components';
import { Box } from 'edenred-ui';

export const StatusDot = styled(Box)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;

  background-color: ${({ $dotColor, theme }) => {
    switch ($dotColor) {
      case 'green':
        return theme.positiveColor;
      case 'orange':
        return theme.warningColorSecondary;
      case 'grey':
        return theme.gray25;
      case 'red':
        return theme.warningColor;
    }
  }};
`;

export const DetailsBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  padding: 16px;

  background-color: ${({ theme }) => theme.gray5};
`;

export const DetailsBoxHeader = styled(Box)`
  margin-bottom: 8px;

  font-weight: ${({ theme }) => theme.fontSemiBold};
`;

export const ButtonContainer = styled(Box)`
  cursor: pointer;
`;
