import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  checkIfTokenExpires,
  userIsAuthorizedAndTokenIsValid
} from '../helpers/AuthorizeHelper';
import * as loginActions from '../actions/login';
import * as navigationActions from '../actions/navigation';
import { hasRole as hasRoleSelector } from '../selectors/login';

class Authorize extends PureComponent {
  componentDidMount() {
    const {
      saveLocationHistory,
      getLogoutAction,
      location,
      login: { isAuthenticated, validTo }
    } = this.props;
    if (isAuthenticated && checkIfTokenExpires(validTo)) {
      getLogoutAction(true);
    }
    if (!isAuthenticated) {
      saveLocationHistory(location);
    }
  }

  render() {
    const {
      children,
      redirect,
      // eslint-disable-next-line no-unused-vars
      location,
      login,
      hasRole,
      ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          userIsAuthorizedAndTokenIsValid(login) && hasRole ? (
            React.cloneElement(children, {
              ...props
            })
          ) : (
            <Redirect
              to={{
                pathname: redirect
              }}
            />
          )
        }
      />
    );
  }
}

Authorize.propTypes = {
  login: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  redirect: PropTypes.string.isRequired,
  getLogoutAction: PropTypes.func.isRequired,
  saveLocationHistory: PropTypes.func.isRequired,
  withAnyRole: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  hasRole: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

Authorize.defaultProps = {
  withAnyRole: []
};

const mapStateToProps = (state, props) => ({
  ...pick(state, ['login']),
  hasRole: hasRoleSelector(props.withAnyRole)(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...navigationActions, ...loginActions }, dispatch);

const ConnectedAuthorize = connect(
  mapStateToProps,
  mapDispatchToProps
)(Authorize);

export { ConnectedAuthorize as Authorize };
