import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { BenefitType } from '@epi-constants/benefitTypes';
import { CommutingIcon } from '@epi-components/Icons/CommutingIcon';
import { LunchIcon } from '@epi-components/Icons/LunchIcon';
import { MassageIcon } from '@epi-components/Icons/MassageIcon';
import { RecreationalIcon } from '@epi-components/Icons/RecreationalIcon';

function BenefitTypeIcon({ benefit, disabled, theme }) {
  const iconFill = disabled ? theme.altGray1 : theme.primaryBackColorLight;

  switch (benefit) {
    case BenefitType.Lunch:
      return <LunchIcon fill={iconFill} />;
    case BenefitType.Recreational:
      return <RecreationalIcon fill={iconFill} />;
    case BenefitType.Transport:
      return <CommutingIcon fill={iconFill} />;
    case BenefitType.Massage:
      return <MassageIcon fill={iconFill} />;
    default:
      return null;
  }
}

BenefitTypeIcon.propTypes = {
  benefit: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired
};

export default withTheme(BenefitTypeIcon);
