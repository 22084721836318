import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import { Button, ButtonSize } from 'edenred-ui';

import withAjax from '@epi-decorators/withAjax';
import { ApiConstants } from '@epi-constants/actions';
import { ErrorPanel } from '@epi-components/ErrorPanel/ErrorPanel';
import Panel from '@epi-components/Panel';
import { Company } from '@epi-components/Company/Company';
import { OrderSummary } from '@epi-components/OrderSummary/OrderSummary';
import { NavigationHeader } from '@epi-components/NavigationHeader/NavigationHeader';
import { LoadingContainer } from '@epi-components/LoadingContainer/LoadingContainer';
import DisplayBillingInformation from '@epi-components/BillingInformation/DisplayBillingInformation';
import { PaymentMethod } from '@epi-forms/PaymentMethod/PaymentMethod';
import { PaymentMethods } from '@epi-constants/paymentMethods';
import { loadPaymentMethods, addNewPaymentToCardOrder } from '@epi-actions/api';
import { getPaymentMethods } from '@epi-selectors/api';
import { OrderType } from '@epi-constants/orderType';
import { getCardPaymentDetailsUrl } from '@epi-repositories/CardOrdersRepository';

import './CardOrderPayment.scss';

function CardOrderPayment({
  i18n,
  fetchGet,
  match,
  fetchPaymentMethods,
  createPayment,
  paymentMethods,
  locale,
  hideConfirmButton
}) {
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      method: ''
    }
  });
  const { getValues, handleSubmit } = form;

  const [paymentData, updatePaymentData] = useState({
    paymentId: match.params.paymentId,
    isLoading: true,
    isProcessing: false,
    billingAddress: null,
    company: null,
    cardOrderSummary: null,
    benefitGroup: null
  });

  const onSubmit = values => {
    createPayment({
      payment: {
        method: values.paymentMethod,
        methodCode: values.paymentMethodCode
      },
      paymentId: paymentData.paymentId
    });
  };

  const handleExternalPayment = () => {
    onSubmit(getValues());
  };

  useEffect(() => {
    const abortController = new AbortController();
    updatePaymentData({
      ...paymentData,
      isLoading: true
    });
    fetchGet(
      getCardPaymentDetailsUrl(match.params.paymentId),
      abortController.signal
    ).then(response => {
      updatePaymentData({
        ...paymentData,
        company: response.company,
        billingAddress: response.billingAddress,
        cardOrderSummary: response.cardOrderSummary,
        benefitGroup: response.benefitGroup,
        isLoading: false
      });

      fetchPaymentMethods(response.cardOrderSummary.orderTotalWithVat, locale);
    });

    return () => abortController.abort();
  }, [match.params.paymentId]);

  if (paymentData.isLoading) {
    return <LoadingContainer isLoading />;
  }
  const { billingAddress, cardOrderSummary, benefitGroup, isProcessing } =
    paymentData;
  const { address, name, businessIdentityCode } = paymentData.company;
  const translationPrefix = 'containers.card_order.summary_and_payment.';

  return (
    <div className="CardOrderPayment">
      <NavigationHeader title={i18n.t('containers.card_payment.title')}>
        <FormProvider {...form}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <Company
              userCompany={{
                businessIdentityCode,
                name,
                address
              }}
            />
            <DisplayBillingInformation address={billingAddress} />
            <Panel
              title={i18n.t(`${translationPrefix}order_summary_header`)}
              subtitle={i18n.t(`${translationPrefix}order_summary_subheader`)}
            >
              <OrderSummary
                benefitsSummary={{
                  lunch: {
                    totalAmount: benefitGroup.lunchTotalAmount,
                    beneficiaryNumber: benefitGroup.noOfBeneficiaries,
                    workdays: benefitGroup.workdays,
                    amount: benefitGroup.lunchAmount
                  },
                  transport: {
                    totalAmount: benefitGroup.transportTotalAmount,
                    beneficiaryNumber: benefitGroup.noOfBeneficiaries,
                    amount: benefitGroup.transportAmount
                  },
                  recreational: {
                    totalAmount: benefitGroup.recreationalTotalAmount,
                    beneficiaryNumber: benefitGroup.noOfBeneficiaries,
                    amount: benefitGroup.recreationalAmount
                  }
                }}
                cardOrderSummary={cardOrderSummary}
              />
            </Panel>
            <Panel
              title={i18n.t(
                'containers.card_order.summary_and_payment.payment_method_header'
              )}
              subtitle={i18n.t(
                'containers.card_order.summary_and_payment.payment_method_subheader'
              )}
            >
              <PaymentMethod
                paymentMethods={{
                  paymentMethods,
                  isLoading: !paymentMethods,
                  isError: false
                }}
                locale={locale}
                creditCardFeePercent={0}
                amountValue={cardOrderSummary.orderTotalWithVat}
                onClickPaymentMethod={handleExternalPayment}
                isPaymentProcessing={isProcessing}
                disabled={isProcessing}
                disabledMethods={[PaymentMethods.LinkToOtherPerson]}
                hiddenMethods={[
                  PaymentMethods.LinkToOtherPerson,
                  PaymentMethods.PrintInvoicePdf
                ]}
                orderType={OrderType.digital}
              />
              <ErrorPanel
                failedActionName={ApiConstants.CARD_ORDER_PAYMENT_FORM}
              />
            </Panel>
            {!hideConfirmButton && (
              <Button
                type="submit"
                className="pull-right"
                size={ButtonSize.Medium}
              >
                {i18n.t('containers.voucher_order.confirm')}
              </Button>
            )}
          </form>
        </FormProvider>
      </NavigationHeader>
    </div>
  );
}

CardOrderPayment.propTypes = {
  hideConfirmButton: PropTypes.bool.isRequired,
  createPayment: PropTypes.func.isRequired,
  paymentMethods: PropTypes.array.isRequired,
  locale: PropTypes.string.isRequired,
  fetchPaymentMethods: PropTypes.func.isRequired,
  match: PropTypes.any.isRequired,
  fetchGet: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired
};

const connectToStore = connect(
  state => ({
    paymentMethods: getPaymentMethods(state)
  }),
  dispatch => ({
    fetchPaymentMethods: (totalAmount, locale) =>
      dispatch(loadPaymentMethods(OrderType.digital, totalAmount, locale)),
    createPayment: values => dispatch(addNewPaymentToCardOrder(values))
  })
);

export default withTranslation()(connectToStore(withAjax()(CardOrderPayment)));
