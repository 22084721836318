import React from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import {
  createRules,
  FormFields,
  useOnBlurTrim,
  withFormNameContext
} from '@epi-forms/helpers';
import {
  lastName,
  maxLength,
  required
} from '@epi-helpers/formHelpers/validators';

export function LastNameField({ disabled, context, readOnly }) {
  const name = withFormNameContext(context, FormFields.LastName);
  const { control } = useFormContext();
  const { t } = useTranslation();
  const onBlurTrim = useOnBlurTrim(name);
  const validators = [required, maxLength(31), lastName];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      label={t('controls.last_name_label')}
      required
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      fullWidth
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onBlur={onBlurTrim}
      onChange={setValue}
    />
  );
}

LastNameField.propTypes = {
  context: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool
};

LastNameField.defaultProps = {
  context: null,
  disabled: false,
  readOnly: false
};
