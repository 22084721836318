import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Checkbox } from 'edenred-ui';

import { FormFields } from '@epi-forms/helpers';

export function WithoutReferenceNumberCheckbox() {
  const name = FormFields.WithoutReferenceNumber;
  const { control } = useFormContext();
  const { t } = useTranslation();

  const {
    field: { value, onChange: setValue }
  } = useController({
    name,
    control
  });

  return (
    <FormControlLabel
      control={<Checkbox checked={value} id={name} onChange={setValue} />}
      label={t('forms.payment_method.foreign_bank_or_missing_reference')}
    />
  );
}
