import React from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { TextField } from 'edenred-ui';
import { ManageBenefitLunchFormFields } from '../../helpers';

export function DailyLimitField({ readOnly, options, label }) {
  const name = ManageBenefitLunchFormFields.DailyLimit;
  const { control } = useFormContext();

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control
  });

  return (
    <TextField
      label={label}
      select
      value={value}
      readOnly={readOnly}
      id="daily-limit-select"
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={setValue}
      SelectProps={{
        MenuProps: {
          BackdropProps: {
            sx: {
              backgroundColor: 'transparent'
            }
          }
        }
      }}
    >
      {options
        .filter(option => option.upToDate)
        .map(option => (
          <TextField.Item key={option.id} value={option.id}>
            {option.display}
          </TextField.Item>
        ))}
    </TextField>
  );
}

DailyLimitField.propTypes = {
  readOnly: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      display: PropTypes.string.isRequired,
      upToDate: PropTypes.bool.isRequired
    })
  ).isRequired
};

DailyLimitField.defaultProps = {
  readOnly: false,
  label: ''
};
