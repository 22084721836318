import React from 'react';
import PropTypes from 'prop-types';
import { Box, Table, LoadingSpinner, ChevronRightIcon } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { routerPaths } from '@epi-constants/routerPaths';
import { goToPage } from '@epi-actions/navigation';
import { currencyCommaConvert } from '@epi-helpers/numeral';

import { StatusDot, ButtonContainer } from '../TopupReports.styles';
import { reportStatusesColors, formatDate } from '../utils';

import { TableHeaderTooltip } from './TableHeaderTooltip';
import { TableNoContent } from './TableNoContent';

export const TopupReportsTable = ({ visibleReports, isLoading }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const goToDetails = reportId => {
    dispatch(goToPage(`${routerPaths.topupDetails}/${reportId}`));
  };

  if (isLoading) return <LoadingSpinner />;

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Cell width="15%">
            {t('containers.deposit_money.reports_tab.date')}
          </Table.Cell>
          <Table.Cell width="23%">
            {t('containers.deposit_money.reports_tab.executed_by')}
          </Table.Cell>
          <Table.Cell width="20%">
            {t('containers.deposit_money.reports_tab.status')}
          </Table.Cell>
          <Table.Cell width="9%" align="right">
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {t('containers.deposit_money.reports_tab.load')}
              <TableHeaderTooltip
                tooltipText={t(
                  'containers.deposit_money.reports_tab.load_info'
                )}
              />
            </Box>
          </Table.Cell>
          <Table.Cell width="14%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              textAlign="right"
            >
              {t('containers.deposit_money.reports_tab.sum')}
              <TableHeaderTooltip
                tooltipText={t('containers.deposit_money.reports_tab.sum_info')}
              />
            </Box>
          </Table.Cell>
          <Table.Cell width="14%" align="right">
            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {t('containers.deposit_money.reports_tab.total')}
              <TableHeaderTooltip
                tooltipText={t(
                  'containers.deposit_money.reports_tab.total_info'
                )}
              />
            </Box>
          </Table.Cell>
          <Table.Cell width="5%" align="right">
            {t('containers.deposit_money.reports_tab.actions')}
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {visibleReports.length ? (
          visibleReports.map(report => (
            <Table.Row key={report.id}>
              <Table.Cell>{formatDate(report.date)}</Table.Cell>
              <Table.Cell>
                {report.firstName} {report.lastName}
              </Table.Cell>
              <Table.Cell>
                <Box display="flex" alignItems="center">
                  <StatusDot $dotColor={reportStatusesColors[report.status]} />
                  {t(
                    `containers.deposit_money.reports_tab.statuses.${report.status}`
                  )}
                </Box>
              </Table.Cell>
              <Table.Cell align="right">
                {currencyCommaConvert(report.amount)}
              </Table.Cell>
              <Table.Cell align="right">
                {currencyCommaConvert(report.totalSum)}
              </Table.Cell>
              <Table.Cell align="right">
                {currencyCommaConvert(report.totalSumWithFees)}
              </Table.Cell>
              <Table.Cell align="right">
                <Box onClick={() => goToDetails(report.id)}>
                  <ButtonContainer>
                    <ChevronRightIcon />
                  </ButtonContainer>
                </Box>
              </Table.Cell>
            </Table.Row>
          ))
        ) : (
          <TableNoContent />
        )}
      </Table.Body>
    </Table>
  );
};

TopupReportsTable.propTypes = {
  visibleReports: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired
};
