import { format } from 'date-fns';
import {
  InputComponent as Input,
  Notification,
  NotificationType,
  SlideoutPanelComponent as Slideout
} from 'edenred-ui';
import { useFormik } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { routerPaths } from '@epi-constants/routerPaths';

import AsyncEmailField from '../../../../components/AsyncValidatedEmailField/AsyncEmailField';
import { FieldWrap } from '../../../../components/LayoutUtils/FieldWrap';
import Row from '../../../../components/LayoutUtils/Row';
import { isEmptyObj } from '../../../../helpers/isEmptyObj';
import { SectionHeader } from '../../BeneficiariesTableStyles';
import {
  fieldWidth,
  labelWidth,
  SlideoutStyles,
  slideoutWidth
} from '../BeneficiaryDetail/BeneficiaryDetailSlideoutStyles';
import beneficiaryDatailValidationSchema from '../BeneficiaryDetail/BeneficiaryDetailValidationSchema';
import CloseDetailDialog from '../BeneficiaryDetail/CloseDetailDialog';
import BenefitsSection from '../BenefitsSection/BenefitsSection';
import AddBeneficiaryActions from './AddBeneficiaryActions';
import { getBeneficiaryPayload } from '../../helpers/getBeneficiaryPayload';
import { defaultDateFormat } from '../../../../constants/dateFormats';

const today = new Date();

const AddBeneficiarySlideout = ({
  expanded,
  setExpanded,
  addEmployeeAction,
  currentlySetBenefitValues = null,
  reCaptchaSiteKey,
  isProcessing
}) => {
  const { t } = useTranslation();
  const addEmployeeRef = useRef();
  const emailFieldRef = useRef();
  const [actionButtonSuccess, setActionButtonSuccess] = useState(false);
  const [addAnotherEmployee, setAddAnotherEmployee] = useState(false);
  const [validationNotification, setValidationNotification] = useState(false);
  const [showCloseConfirmation, setShowCloseConfirmation] = useState(false);
  const [emailDNSvalidated, setEmailDNSValidated] = useState(false);
  const {
    values,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    errors,
    resetForm,
    touched,
    dirty,
    submitForm
  } = useFormik({
    initialValues: {
      personId: '',
      firstName: '',
      lastName: '',
      emailAddress: '',
      phoneNumber: '',
      personalId: '',
      addressLine: '',
      postCode: '',
      city: '',
      benefitStartDate: format(today, defaultDateFormat),
      endOfBenefit: null
    },
    enableReinitialize: true,
    validationSchema: beneficiaryDatailValidationSchema()
  });

  useEffect(() => {
    if (!isProcessing && !expanded) {
      resetForm();
    }
  }, [isProcessing, expanded]);

  useEffect(() => {
    if (isEmptyObj(errors)) {
      setValidationNotification(false);
    }
  }, [errors]);

  const handleClose = () => {
    if (!dirty) {
      setExpanded(false);
    } else {
      setShowCloseConfirmation(true);
    }
  };

  const addEmployee = () => {
    return addEmployeeAction(
      getBeneficiaryPayload(values),
      addAnotherEmployee,
      resetForm
    );
  };

  const onAddEmployeeSuccess = resolve => {
    if (addAnotherEmployee) {
      setActionButtonSuccess(true);
    }
    setFieldValue('endOfBenefit', null);
    resolve();
  };

  const addValidEmployee = async resolve => {
    const validateEmailDNS = 'emailAddress' in touched && !emailDNSvalidated;
    if (!validateEmailDNS) {
      addEmployee()
        .then(() => onAddEmployeeSuccess(resolve))
        .catch(() => resolve());
    } else {
      const validationResult = await emailFieldRef.current
        .forceEmailDNSValidation()
        .catch(() => {
          setValidationNotification(true);
          resolve();
        });
      if (validationResult === 'success') {
        addEmployee()
          .then(() => onAddEmployeeSuccess(resolve))
          .catch(() => resolve());
      }
      if (validationResult === 'validation_in_progress') {
        resolve();
      }
    }
  };

  const handleAddEmployee = () => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async resolve => {
      if (isEmptyObj(errors) && touched) {
        addValidEmployee(resolve);
      } else {
        submitForm();
        setValidationNotification(true);
        resolve();
      }
    });
  };

  const confirmCloseWithoutSaving = () => {
    setShowCloseConfirmation(false);
    resetForm();
    setExpanded(false);
  };

  const backToAddingEmployee = () => {
    setExpanded(true);
    setShowCloseConfirmation(false);
  };

  const handleFieldValueChange = (name, value) => {
    setFieldTouched(name, true);
    setFieldValue(name, value);
  };

  return (
    <div ref={addEmployeeRef}>
      <SlideoutStyles validationWarning={validationNotification}>
        <Slideout
          title={t('containers.beneficiaries.add_employee.title')}
          subtitle={
            <Trans
              defaults={t('containers.beneficiaries.add_employee.subtitle')}
              components={{ a: <Link to={routerPaths.manageBenefits} /> }}
            />
          }
          actions={
            <AddBeneficiaryActions
              isSuccess={actionButtonSuccess}
              mainAction={handleAddEmployee}
              addAnotherEmployee={addAnotherEmployee}
              setAddAnotherEmployee={setAddAnotherEmployee}
              setActionButtonSuccess={setActionButtonSuccess}
              disabled={!dirty}
              isProcessing={isProcessing}
            />
          }
          expanded={expanded}
          onClose={handleClose}
          width={slideoutWidth}
          closeButtonId="close-beneficiary-slideout"
        >
          <form onSubmit={handleSubmit}>
            {validationNotification && (
              <div
                className="fix_validation_warning"
                data-test="fix_validation_warning"
              >
                <Notification
                  type={NotificationType.Error}
                  text={t('containers.beneficiaries.detail.resolve_validation')}
                />
              </div>
            )}
            {currentlySetBenefitValues && (
              <BenefitsSection
                currentlySetBenefitValues={currentlySetBenefitValues}
              />
            )}
            <SectionHeader>
              {t('containers.beneficiaries.detail.personal_information')}
            </SectionHeader>
            <Row>
              <FieldWrap width={fieldWidth}>
                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={t('containers.beneficiaries.table.first_name')}
                  onValueChange={v => handleFieldValueChange('firstName', v)}
                  value={values.firstName}
                  errors={touched.firstName && errors.firstName}
                  data-test="firstName"
                  required
                />
                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={t('containers.beneficiaries.table.last_name')}
                  onValueChange={v => handleFieldValueChange('lastName', v)}
                  value={values.lastName}
                  errors={touched.lastName && errors.lastName}
                  data-test="lastName"
                  required
                />
                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={t('containers.beneficiaries.detail.personal_id')}
                  onValueChange={v => handleFieldValueChange('personalId', v)}
                  value={values.personalId}
                  errors={touched.personalId && errors.personalId}
                  data-test="personalId"
                  required
                />
              </FieldWrap>
            </Row>
            <SectionHeader>
              {t('containers.beneficiaries.detail.contact_details')}
            </SectionHeader>
            <Row>
              <FieldWrap width={fieldWidth}>
                <AsyncEmailField
                  ref={emailFieldRef}
                  emailAddress={values.emailAddress}
                  disabled={false}
                  errors={touched.emailAddress ? errors : {}}
                  onValueChange={v => {
                    setEmailDNSValidated(false);
                    handleFieldValueChange('emailAddress', v);
                  }}
                  labelWidth={labelWidth}
                  labelText={t('containers.beneficiaries.table.email')}
                  onValidationSuccess={() => {
                    setEmailDNSValidated(true);
                    setFieldTouched('emailAddress', false);
                  }}
                  onValidationFailure={() => {
                    setEmailDNSValidated(false);
                    setFieldError(
                      'emailAddress',
                      t('containers.beneficiaries.detail.email_doesnt_exist')
                    );
                  }}
                  reCaptchaSiteKey={reCaptchaSiteKey}
                />
                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={t('containers.beneficiaries.detail.phone')}
                  onValueChange={v => handleFieldValueChange('phoneNumber', v)}
                  value={values.phoneNumber}
                  errors={touched.phoneNumber && errors.phoneNumber}
                  data-test="phoneNumber"
                  required
                />
                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={t('containers.beneficiaries.detail.address')}
                  onValueChange={v => handleFieldValueChange('addressLine', v)}
                  value={values.addressLine}
                  errors={touched.addressLine && errors.addressLine}
                  data-test="addressLine"
                  required
                />

                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={t('containers.beneficiaries.detail.post_code')}
                  onValueChange={v => handleFieldValueChange('postCode', v)}
                  value={values.postCode}
                  errors={touched.postCode && errors.postCode}
                  data-test="postCode"
                  required
                />

                <Input
                  labelPositioning="side"
                  labelWidth={labelWidth}
                  label={t('containers.beneficiaries.detail.city')}
                  onValueChange={v => handleFieldValueChange('city', v)}
                  value={values.city}
                  errors={touched.city && errors.city}
                  data-test="city"
                  required
                />
              </FieldWrap>
            </Row>
          </form>
          <CloseDetailDialog
            isOpen={showCloseConfirmation}
            closeAction={() => setShowCloseConfirmation(false)}
            primaryAction={confirmCloseWithoutSaving}
            primaryActionLabel={t(
              'containers.beneficiaries.close_add_employee_dialog.close'
            )}
            secondaryAction={backToAddingEmployee}
            secondaryActionLabel={t(
              'containers.beneficiaries.close_add_employee_dialog.back'
            )}
            title={t(
              'containers.beneficiaries.close_add_employee_dialog.title'
            )}
            content={t(
              'containers.beneficiaries.close_add_employee_dialog.text'
            )}
          />
        </Slideout>
      </SlideoutStyles>
    </div>
  );
};

AddBeneficiarySlideout.propTypes = {
  expanded: PropTypes.bool.isRequired,
  setExpanded: PropTypes.func.isRequired,
  addEmployeeAction: PropTypes.func.isRequired,
  currentlySetBenefitValues: PropTypes.object,
  reCaptchaSiteKey: PropTypes.string,
  isProcessing: PropTypes.bool.isRequired
};

AddBeneficiarySlideout.defaultProps = {
  currentlySetBenefitValues: null,
  reCaptchaSiteKey: null
};

export default AddBeneficiarySlideout;
