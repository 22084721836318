import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { TextField } from 'edenred-ui';
import memoize from 'memoize-one';

import { getTranslatedCountries } from '@epi-selectors/api';
import { createRules, FormFields } from '@epi-forms/helpers';
import { required } from '@epi-helpers/formHelpers/validators';
import Moment from '@epi-helpers/MomentExtensions';

function FromMonthSelect({ disabled, readOnly, name, locale }) {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [fromPeriods, setFromPeriods] = useState();
  const validators = [required];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const getNextFullMonth = memoize(increment => {
    const today = Moment();
    if (today.date() === 1) {
      return today.format('MM/YYYY');
    }
    return today.add(increment || 1, 'months').format('MM/YYYY');
  });

  const getFromPeriods = memoize(() =>
    generateMonthsOptionsHelper(locale, getNextFullMonth())
  );

  const getLastMonthOfNextYear = () => {
    const endOfNextYear = Moment().endOf('year').add(1, 'year');
    return endOfNextYear.format('MM/YYYY');
  };

  const generateMonthsOptionsHelper = (locale, from, to) => {
    Moment.locale(locale);
    const start = Moment(from, 'MM/YYYY');
    const end = to || getLastMonthOfNextYear();
    const totalMonths = Moment(end, 'MM/YYYY').diff(start, 'months') + 1;
    const options = [...Array(totalMonths).keys()].map(() => {
      const option = {
        id: start.toISOString(),
        label: start.format('MMMM YYYY'),
        value: start.format('MM/YYYY')
      };
      start.add(1, 'months');
      return option;
    });
    return options;
  };

  useEffect(() => {
    const newPeriods = getFromPeriods();
    setFromPeriods(newPeriods);
    setValue(newPeriods[0].value);
  }, [locale]);

  return (
    <TextField
      label={t('forms.benefit_calculator.period_from')}
      select
      required
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={setValue}
      sx={{ '& .MuiSelect-select:focus': { backgroundColor: 'transparent' } }}
    >
      {(fromPeriods?.length > 0 ? fromPeriods : []).map(period => (
        <TextField.Item key={period.id} value={period.value}>
          {period.label}
        </TextField.Item>
      ))}
    </TextField>
  );
}

FromMonthSelect.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({ Code: PropTypes.string, Name: PropTypes.string })
  ).isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  locale: PropTypes.string.isRequired
};

FromMonthSelect.defaultProps = {
  disabled: false,
  readOnly: false,
  name: FormFields.FromMonth
};

const mapStateToProps = state => ({
  countries: getTranslatedCountries(state)
});

const ConnectedFromMonthSelect = connect(mapStateToProps)(FromMonthSelect);

export { ConnectedFromMonthSelect as FromMonthSelect };
