import React from 'react';
import { useTranslation } from 'react-i18next';
import { EmailSendConfirmation } from '@epi-shared/components';

export function ResendMailSummary() {
  const { t } = useTranslation();
  return (
    <EmailSendConfirmation
      heading={t('contract.email_send_heading')}
      description={t('contract.email_send_description')}
      emailResendText={t('contract.email_send_click_here')}
    />
  );
}
