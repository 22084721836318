import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { showNotification } from '@epi-actions/overlays';
import { loadCompanyAccounts, getActiveBenefits } from '@epi-actions/api';
import { getDetails } from '@epi-api/user';
import { isSignatorySelector } from '@epi-selectors/login';
import { getMainCompanyAccount } from '@epi-selectors/companyAccounts';
import { useEmailConfirmation } from '@epi-shared/hooks/useEmailConfirmation';

export const useHome = () => {
  useEmailConfirmation();
  const { companyAccountId } = useSelector(getMainCompanyAccount);
  const [customerData, setCustomerData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const isSignatory = useSelector(isSignatorySelector);
  const isNewCustomer =
    Object.values(customerData).find(elem => !elem) !== undefined;

  useEffect(() => {
    if (!isSignatory) {
      dispatch(goToPage(routerPaths.orderHistory));
      return;
    }
  }, []);

  useEffect(() => {
    dispatch(loadCompanyAccounts());
    dispatch(getActiveBenefits());
  }, []);

  useEffect(() => {
    let isApiSubscribed = true;
    getDetails()
      .then(res => {
        if (isApiSubscribed) {
          setCustomerData(res);
          setIsLoading(false);
        }
      })
      .catch(() => {
        dispatch(showNotification({ message: 'messages.error' }, 'error'));
        setIsLoading(false);
      });
    return () => {
      isApiSubscribed = false;
    };
  }, []);

  return {
    isLoading: isLoading || Boolean(!companyAccountId),
    isNewCustomer,
    customerData
  };
};
