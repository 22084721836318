import i18n from 'i18next';

export const rejectLoadSuccess =
  'containers.deposit_money.requested_loads.reject_successful';
export const rejectDialogTitle =
  'containers.deposit_money.requested_loads.reject_confirmation_dialog.title';
export const rejectDialogPrimaryLabel =
  'containers.deposit_money.requested_loads.reject_confirmation_dialog.primary_label';
export const rejectDialogSecondaryLabel =
  'containers.deposit_money.requested_loads.reject_confirmation_dialog.secondary_label';
const rejectDialogMessage =
  'containers.deposit_money.requested_loads.reject_confirmation_dialog.message';
const rejectDialogSummary =
  'containers.deposit_money.requested_loads.reject_confirmation_dialog.summary';

export const generateRejectDialogContent = (requestId, state) => {
  const employeeToReject = state.rows.filter(e => e.requestId === requestId);
  return {
    message: i18n.t(rejectDialogMessage),
    summary: i18n.t(rejectDialogSummary, {
      employeeName:
        employeeToReject &&
        `${employeeToReject[0].firstName} ${employeeToReject[0].lastName}`,
      requestedLoadAmount: employeeToReject && employeeToReject[0].amount
    })
  };
};
