import styled from 'styled-components';

export const Header = styled.span`
  font-size: ${({ theme }) => theme.fontSize};
`;

export const Sum = styled.span`
  font-size: ${({ theme }) => theme.fontSize};
  font-weight: ${({ theme }) => theme.fontSemiBold};
`;

export const Remainder = styled.span`
  margin-left: 10px;

  font-size: ${({ theme }) => theme.sizeExtraSmall};
  color: ${({ hasError, theme }) => hasError && theme.warningColor};
`;
