import React, { PureComponent } from 'react';
import { Modal, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import { ErrorDetails } from '../../containers/ErrorData/ErrorDetails';
import { getErrorPopup } from '../../selectors/overlays';
import * as profileActions from '../../actions/profile';

class ErrorPopup extends PureComponent {
  render() {
    const { i18n, errorPopup } = this.props;
    return (
      <Modal show={errorPopup.display || false}>
        <Modal.Header closeButton={errorPopup.closable}>
          <Modal.Title>{i18n.t('components.error_popup.title')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {i18n.t('components.error_popup.message')}
          {errorPopup.showDetails && errorPopup.error && (
            <ErrorDetails error={errorPopup.error} />
          )}
        </Modal.Body>
        {errorPopup.closable ? (
          <Modal.Footer>
            <Button id="error-modal-close-btn" onClick={this.close}>
              {i18n.t('components.error_popup.close_button')}
            </Button>
          </Modal.Footer>
        ) : null}
      </Modal>
    );
  }
}

ErrorPopup.propTypes = {
  errorPopup: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  errorPopup: getErrorPopup(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(profileActions, dispatch);

const ConnectedErrorPopup = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(ErrorPopup));

export { ConnectedErrorPopup as ErrorPopup };
