/* global grecaptcha */
/**  grecaptcha is assigned to the global window variable by the script
 * in the loadRecaptcha function
 */
import config from '@epi-config';
const scriptId = 'reCaptchaScript';

const getScript = () => document.getElementById(scriptId);

const isScriptLoaded = () =>
  getScript() && getScript().getElementsByClassName('isLoaded').length === 1;

const loadRecaptcha = (key, callback) => {
  const existingScript = getScript();

  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${key}`;
    script.id = scriptId;
    document.body.appendChild(script);

    script.onload = () => {
      getScript().classList.add('isLoaded');
      if (callback) {
        callback();
      }
    };
  }

  if (existingScript && callback) callback();
};

export const loadRecaptchaScript = key =>
  new Promise(resolve => {
    if (isScriptLoaded()) {
      resolve();
    }
    loadRecaptcha(key, resolve);
  });

export const getRecaptchaToken = (key, action) => {
  if (config.disableReCaptcha) {
    return Promise.resolve('');
  }

  return new Promise(resolve => {
    grecaptcha.ready(() => {
      grecaptcha
        .execute(`${key}`, {
          action
        })
        .then(resolve);
    });
  });
};
