import { put, call } from 'redux-saga/effects';
import { getCardFees } from '../../repositories/CardFeesRepository';
import { ApiConstants, OverlaysConstants } from '../../constants/actions';

export default function* loadCardFees() {
  try {
    const contract = yield call(getCardFees);
    yield put({
      type: `${ApiConstants.LOAD_CARD_FEES}_SUCCESS`,
      payload: contract
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.LOAD_CARD_FEES}_FAILURE`,
      payload: ex
    });
    yield put({
      type: `${OverlaysConstants.SHOW_ERROR_POPUP}`,
      closable: false
    });
  }
}
