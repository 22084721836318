import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoadingSpinner } from 'edenred-ui';

import { resendSignUpMail } from '@epi-helpers/mailHelper';
import { getReCaptchaSiteKey } from '@epi-selectors/settings';
import { isReCaptchaLoadedSelector } from '@epi-selectors/reCaptchaSelectors';
import { routerPaths } from '@epi-constants/routerPaths';

function ResendMail({
  reCaptchaSiteKey,
  isReCaptchaLoaded,
  location,
  dispatch
}) {
  useEffect(() => {
    if (isReCaptchaLoaded) {
      (async () => {
        const params = new URLSearchParams(location.search);
        const signToken = params.get('token');
        const contactRegistrationType = params.get('type');

        if (signToken) {
          const actions = await resendSignUpMail(
            signToken,
            contactRegistrationType,
            reCaptchaSiteKey,
            routerPaths.resendMailSummary
          );
          actions.forEach(action => dispatch(action));
        }
      })();
    }
  }, [isReCaptchaLoaded]);

  return <LoadingSpinner />;
}

ResendMail.propTypes = {
  isReCaptchaLoaded: PropTypes.bool.isRequired,
  reCaptchaSiteKey: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  reCaptchaSiteKey: getReCaptchaSiteKey(state),
  isReCaptchaLoaded: isReCaptchaLoadedSelector(state)
});

const ConnectedResendMail = connect(mapStateToProps)(ResendMail);

export { ConnectedResendMail as ResendMail };
