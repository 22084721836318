import React, { useImperativeHandle, useRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Box, TextField, InputAdornment } from 'edenred-ui';

import { createRules } from '@epi-forms/helpers';
import { maxAmount, minAmount } from '@epi-helpers/formHelpers/validators';
import { IAmountField } from '@epi-models/containers/LoadCompanyAccount';

import { TransferMoneyFormFields } from '../../helpers';

const StyledTextField = styled(TextField)`
  && {
    .MuiFormHelperText-root {
      position: absolute;
      bottom: -3px;
      transform: translateY(100%);
    }
  }
`;

const AmountField = ({ ref }: IAmountField) => {
  const name = TransferMoneyFormFields.Amount;
  const { control } = useFormContext();
  const inputRef = useRef<HTMLInputElement>();
  const { t } = useTranslation();
  const theme = useTheme();
  const validators = [minAmount, maxAmount];
  const rules = createRules(validators);

  const {
    field: { ref: fieldRef, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const onChange = event => {
    const formattedValue = (event.target.value || '').replace('.', ',');
    // eslint-disable-next-line no-useless-escape
    const decimalRegex = /^\d*\,?\d{0,2}$/;

    if (formattedValue === '' || decimalRegex.test(formattedValue)) {
      setValue(formattedValue);
    }
  };

  useImperativeHandle(ref, () => ({
    focus: () => inputRef?.current?.focus()
  }));

  return (
    <StyledTextField
      label={t('transfer_money.amount_field_label')}
      value={value}
      id={name}
      error={!!error}
      helperText={error?.message}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box fontSize={theme.sizeMedium}>&euro;</Box>
          </InputAdornment>
        )
      }}
      inputRef={el => {
        fieldRef(el);
        inputRef.current = el;
      }}
      onChange={onChange}
    />
  );
};

const ForwardRefAmountField = React.forwardRef(AmountField);

export { ForwardRefAmountField as AmountField };
