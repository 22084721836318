import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { getTopupEmployeesRequest } from '@epi-api/topupLoad';
import { setFeePercentage } from '@epi-actions/api';

import { useTopupRequest } from './useTopupRequest';
import { useSortEmployees } from './useSortEmployees';

export const useTopupEmployees = (
  page,
  benefitType,
  showTable,
  setShowConfirmationModal
) => {
  const dispatch = useDispatch();
  const [checkableEmployees, setCheckableEmployees] = useState([]);
  const [employeesToTopup, setEmployeesToTopup] = useState([]);
  const [visibleEmployees, setVisibileEmployees] = useState([]);

  const checkedCount = checkableEmployees.filter(e => e.isChecked).length;
  const lowestAvailable =
    showTable && employeesToTopup.length
      ? Math.min(
          ...employeesToTopup.map(employee => employee.availableToTopUpAmount)
        )
      : Math.min(
          ...checkableEmployees
            .filter(employee => employee.availableToTopUpAmount > 0)
            .map(employee => employee.availableToTopUpAmount)
        );
  const topupBeneficiariesIds =
    showTable && employeesToTopup.length
      ? employeesToTopup.map(employee => employee.beneficiaryId)
      : checkableEmployees
          .filter(employee => employee.availableToTopUpAmount > 0)
          .map(employee => employee.beneficiaryId);
  const employeesCount = topupBeneficiariesIds.length;

  const toggleEmployee = employee => {
    setCheckableEmployees(
      checkableEmployees.map(emp =>
        emp.beneficiaryId === employee.beneficiaryId
          ? { ...employee, isChecked: !employee.isChecked }
          : emp
      )
    );
  };

  const loadEmployeesToTopup = async () => {
    const response = await getTopupEmployeesRequest(benefitType);
    dispatch(
      setFeePercentage(response.availableBenefitSettings[0]?.feePercentage)
    );
    setCheckableEmployees(
      response.beneficiaries.map(employee => ({
        ...employee,
        availableToTopUpAmount: employee.availableToTopUpAmount / 100,
        isChecked: false
      }))
    );
  };

  const {
    employeesPerPage,
    postTopupRequest,
    isTopupPending,
    isTopupProcessing,
    summaryId
  } = useTopupRequest(setShowConfirmationModal, loadEmployeesToTopup);

  const { handleSort, descending, sortBy } = useSortEmployees(
    checkableEmployees,
    setCheckableEmployees
  );

  const paginationCount = Math.ceil(
    checkableEmployees.length / employeesPerPage
  );

  useEffect(() => {
    if (benefitType) {
      loadEmployeesToTopup();
    }
  }, [benefitType]);

  useEffect(() => {
    setEmployeesToTopup(
      checkableEmployees.filter(employee => employee.isChecked)
    );
  }, [checkableEmployees]);

  useEffect(() => {
    setVisibileEmployees(
      checkableEmployees.slice(
        page * employeesPerPage - employeesPerPage,
        page * employeesPerPage
      )
    );
  }, [checkableEmployees, page]);

  return {
    paginationCount,
    toggleEmployee,
    visibleEmployees,
    handleSort,
    descending,
    sortBy,
    checkedCount,
    employeesPerPage,
    employeesToTopup,
    employeesCount,
    lowestAvailable,
    topupBeneficiariesIds,
    postTopupRequest,
    isTopupPending,
    isTopupProcessing,
    summaryId
  };
};
