import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { postRequest } from './ApiRepository';

export function* addNewPaymentToCardOrder(paymentData) {
  return yield call(
    postRequest,
    `${config.apiHost}/api/card-order-payments`,
    Authorize.IfLogged,
    paymentData
  );
}

export const getCardPaymentDetailsUrl = paymentId =>
  `${config.apiHost}/api/card-order-payments/${paymentId}`;
