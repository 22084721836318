import React from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { createRules, FormFields } from '@epi-forms/helpers';
import { required } from '@epi-helpers/formHelpers/validators';

export function BenefitSelect({ disabled, readOnly, name, options }) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const validators = [required];
  const rules = disabled ? [] : createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      label={t('containers.deposit_money.topup_tab.form.benefit.input')}
      select
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onChange={setValue}
      inputProps={{ id: name }}
    >
      {options?.map(benefit => (
        <TextField.Item key={benefit} value={benefit}>
          {t(`components.manage_benefits.new.benefits.${benefit}`)}
        </TextField.Item>
      ))}
    </TextField>
  );
}

BenefitSelect.propTypes = {
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string
};

BenefitSelect.defaultProps = {
  disabled: false,
  readOnly: false,
  name: FormFields.Benefit
};
