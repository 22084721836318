import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { throttle } from 'lodash';
import { InfoIcon, Box } from 'edenred-ui';

import './TooltipInfo.scss';

export class TooltipInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.handleToggle = this.handleToggle.bind(this);
    this.getTrigger = this.getTrigger.bind(this);
    this.state = {
      show: false
    };

    const onResize = () => {
      // HACK: to make open popover move when resizing the window
      const { show } = this.state;
      if (show) {
        this.handleToggle();
        this.handleToggle();
      }
    };
    this.throttledOnResize = throttle(onResize, 100);
  }

  componentDidMount() {
    window.addEventListener('resize', this.throttledOnResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledOnResize);
  }

  getToggleHandler(shouldAddHandler) {
    return shouldAddHandler ? this.handleToggle : undefined;
  }

  getTrigger = clickable => (clickable ? ['click'] : ['hover', 'focus']);

  handleToggle() {
    const { show } = this.state;
    this.setState({ show: !show });
  }

  render() {
    const { info, placement, className, clickable } = this.props;
    const tooltip = info && (
      <Tooltip
        className="TooltipInfo tooltip-info"
        id="tooltip"
        placement={placement}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: info
          }}
        />
      </Tooltip>
    );

    return tooltip ? (
      <span className="icon">
        <OverlayTrigger
          shouldUpdatePosition
          container={document.getElementsByClassName('sidebar-content')[0]}
          placement={placement}
          trigger={this.getTrigger(clickable)}
          overlay={tooltip}
        >
          <Box
            onClick={this.getToggleHandler(clickable)}
            onFocus={this.getToggleHandler(!clickable)}
            onBlur={this.getToggleHandler(!clickable)}
            onMouseOut={this.getToggleHandler(!clickable)}
            onMouseOver={this.getToggleHandler(!clickable)}
          >
            <InfoIcon
              className={`TooltipInfo ${className}`}
              icon="information-outline"
            />
          </Box>
        </OverlayTrigger>
      </span>
    ) : (
      <div />
    );
  }
}

TooltipInfo.propTypes = {
  info: PropTypes.string,
  placement: PropTypes.oneOf(['left', 'right', 'bottom', 'top']),
  className: PropTypes.string,
  clickable: PropTypes.bool
};

TooltipInfo.defaultProps = {
  info: undefined,
  placement: 'right',
  className: '',
  clickable: false
};
