import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PagePanel, Tabs, Button, ButtonMode } from 'edenred-ui';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { pick } from 'lodash';

import { openSlideout, enableSlideoutEditMode } from '@epi-actions/company';
import { getShowSlideout } from '@epi-selectors/company';
import { useMenuDimensions } from '@epi-shared/hooks';
import config from '@epi-config';

import { CompanyData } from './components/CompanyData';
import { ContactsData } from './components/ContactsData';
import { CompanyNotificationBanner } from './components/CompanyNotificationBanner';

const AddContactButton = styled(Button)`
  visibility: ${props => (props.isContactsTabActive ? 'visible' : 'hidden')};
`;

const {
  disableEditOptionsForOA,
  disableAddingContactPersonOnCompanyInformation
} = config;

function CompanyInfo({ autoRedirect, dispatch }) {
  const { t } = useTranslation();
  const { isMobile } = useMenuDimensions();
  const [isContactsTabActive, setIsContactsTabActive] = useState(false);
  const [footer, setFooter] = useState();

  const updateFooter = newFooter => {
    setFooter(newFooter);
  };

  const handleButtonVisibility = tabName => {
    setIsContactsTabActive(tabName === 'contactsDataTab');
  };

  const handleOpenSlideout = () => {
    dispatch(openSlideout());
    dispatch(enableSlideoutEditMode());
  };

  const tabs = [
    {
      name: 'companyDataTab',
      label: t('company_info.company_info_tab'),
      id: 'tab-company-info',
      body: (
        <CompanyData autoRedirect={autoRedirect} updateFooter={updateFooter} />
      )
    },
    {
      name: 'contactsDataTab',
      label: t('company_info.contact_persons_tab'),
      id: 'tab-contact-persons',
      body: <ContactsData updateFooter={updateFooter} />
    }
  ];

  return (
    <>
      <CompanyNotificationBanner />
      <PagePanel
        title={t('company_info.title')}
        footer={footer}
        headerContent={
          !isMobile && (
            <AddContactButton
              isContactsTabActive={isContactsTabActive}
              mode={ButtonMode.Secondary}
              onClick={handleOpenSlideout}
              disabled={
                Boolean(disableEditOptionsForOA) ||
                Boolean(disableAddingContactPersonOnCompanyInformation)
              }
              id="company-info_add-contact"
            >
              {t('company_info.add_contact')}
            </AddContactButton>
          )
        }
      >
        <Tabs tabs={tabs} onTabChange={handleButtonVisibility} />
      </PagePanel>
    </>
  );
}

CompanyInfo.propTypes = {
  autoRedirect: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
  showSlideout: PropTypes.bool.isRequired
};

CompanyInfo.defaultProps = {
  autoRedirect: false
};

const mapStateToProps = state => ({
  ...pick(state, ['apiCompany']),
  showSlideout: getShowSlideout(state)
});

export default connect(mapStateToProps)(CompanyInfo);
