import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import { SelectComponent as Select } from 'edenred-ui';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { ReportsTabContext } from './ReportsTabContext';
import DateRangeDialog from './DateRangeDialog';
import { apiToDefaultDateFormat } from '../../../helpers/dateHelper';
import { dateRangeModes } from './ReportsTabReducer';

const ReportDateRangeStyles = styled.div`
  color: #252525;
  font-size: 16px;
  font-weight: 600;
  opacity: 1;
  padding: 40px 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: 1rem;

  .title {
    width: 150px;
  }

  input[type='text'] {
    border: none;
    color: black;
    font-weight: 400;
    z-index: 1;
  }
`;

export const DateRangeSection = ({ dateRangeMode }) => {
  const { reportsTabState, reportsTabActions } = useContext(ReportsTabContext);
  const [daterangeDialog, setDaterangeDialog] = useState(true);
  const datepickerDropdownText =
    reportsTabState.pickerStartDate && reportsTabState.pickerEndDate
      ? `${apiToDefaultDateFormat(
          reportsTabState.pickerStartDate
        )} - ${apiToDefaultDateFormat(reportsTabState.pickerEndDate)}`
      : i18n.t('containers.reports.date_range.select_from_picker');

  const options = [
    {
      display: i18n.t('containers.reports.date_range.current_month'),
      id: dateRangeModes.currentMonth
    },
    {
      display: i18n.t('containers.reports.date_range.previous_month'),
      id: dateRangeModes.lastMonth
    },
    {
      display: datepickerDropdownText,
      id: dateRangeModes.datePicker
    }
  ];

  return (
    <ReportDateRangeStyles>
      <div className="title">
        {i18n.t('containers.reports.date_range.title')}
      </div>
      <Select
        id="report-date-range-select"
        selectedId={dateRangeMode}
        options={options}
        onChange={v => {
          reportsTabActions.setDateRangeMode(v.id);
          if (v.id === dateRangeModes.datePicker) {
            setDaterangeDialog(true);
          }
        }}
      />
      {reportsTabState.dateRangeMode === dateRangeModes.datePicker && (
        <DateRangeDialog
          isOpen={daterangeDialog}
          closeAction={() => setDaterangeDialog(false)}
        />
      )}
    </ReportDateRangeStyles>
  );
};

DateRangeSection.propTypes = {
  dateRangeMode: PropTypes.string.isRequired
};
