import { SagaConstants, ApiConstants } from '../constants/actions';

export const loadCountries = () => ({
  type: `${SagaConstants.SAGA_ENSURE_LOADED}`,
  action: `${ApiConstants.LOAD_COUNTRIES}`
});

export const loadPaymentMethods = (orderType, totalAmount, locale) => ({
  type: `${ApiConstants.LOAD_PAYMENT_METHODS}_REQUEST`,
  orderType,
  totalAmount,
  locale
});

export const createVoucherOrder = values => ({
  type: `${ApiConstants.CREATE_VOUCHER_ORDER}_REQUEST`,
  values
});

export const createVoucherReorder = values => ({
  type: `${ApiConstants.CREATE_VOUCHER_REORDER}_REQUEST`,
  values
});

export const getPaymentStatus = paymentId => ({
  type: `${ApiConstants.GET_PAYMENT_STATUS}_REQUEST`,
  paymentId
});

export const loadVoucherOrderDetails = voucherOrderId => ({
  type: `${ApiConstants.LOAD_VOUCHER_ORDER_DETAILS}_REQUEST`,
  voucherOrderId
});

export const loadVoucherOrderByPayment = paymentId => ({
  type: `${ApiConstants.LOAD_VOUCHER_ORDER_BY_PAYMENT}_REQUEST`,
  paymentId
});

export const addNewPaymentToCardOrder = ({
  paymentId,
  payment: { ...paymentData }
}) => ({
  type: `${ApiConstants.ADD_NEW_PAYMENT_TO_CARD}_REQUEST`,
  values: { paymentId, ...paymentData }
});

export const addNewPaymentToDepositOrder = ({
  paymentId,
  payment: { ...paymentData }
}) => ({
  type: `${ApiConstants.ADD_NEW_PAYMENT_TO_DEPOSIT}_REQUEST`,
  values: { paymentId, ...paymentData }
});

export const addNewPaymentToVoucher = ({
  paymentId,
  payment: { ...paymentData }
}) => ({
  type: `${ApiConstants.ADD_NEW_PAYMENT_TO_VOUCHER}_REQUEST`,
  values: { paymentId, ...paymentData }
});

export const checkUsernameAvailability = (username, resolve, reject) => ({
  type: `${ApiConstants.CHECK_USERNAME_AVAILABILITY}_REQUEST`,
  username,
  resolve,
  reject
});

export const getUserCompany = () => ({
  type: `${ApiConstants.LOAD_USER_COMPANY}_REQUEST`,
  reloadAfterLogout: true
});

export const loadVouchers = () => ({
  type: `${SagaConstants.SAGA_ENSURE_LOADED}`,
  action: `${ApiConstants.LOAD_VOUCHERS}`
});

export const loadOrderFees = () => ({
  type: `${SagaConstants.SAGA_ENSURE_LOADED}`,
  action: `${ApiConstants.LOAD_ORDER_FEES}`
});

export const updateUserCompany = (
  companyData,
  autoRedirect,
  propagateAddress,
  isUserImpersonate
) => ({
  type: `${ApiConstants.UPDATE_USER_COMPANY}_REQUEST`,
  companyData,
  autoRedirect,
  propagateAddress,
  isUserImpersonate
});

export const dismissApiErrors = () => ({
  type: `${ApiConstants.CLEAR_API_ERROR}`
});

export const getAddresses = () => ({
  type: `${ApiConstants.LOAD_ADDRESSES}_REQUEST`
});

export const loadContacts = () => ({
  type: `${ApiConstants.LOAD_CONTACTS}_REQUEST`
});

export const clearSavedOrderHistory = () => ({
  type: `${ApiConstants.CLEAR_SAVED_HISTORY}`
});

export const downloadInvoice = ({ voucherOrderId, paymentId }) => ({
  type: `${ApiConstants.DOWNLOAD_INVOICE}_REQUEST`,
  idType: voucherOrderId ? 'order' : 'payment',
  id: voucherOrderId || paymentId
});

export const resetPassword = usernameOrEmail => ({
  type: `${ApiConstants.RESET_PASSWORD}_REQUEST`,
  usernameOrEmail
});

export const addSignatoryRoleToUser = page => ({
  type: `${ApiConstants.ADD_SIGNATORY_ROLE_TO_USER}_REQUEST`,
  page
});

export const loadSettings = () => ({
  type: `${SagaConstants.SAGA_ENSURE_LOADED}`,
  action: `${ApiConstants.LOAD_SETTINGS}`
});

export const setFeePercentage = feePercentage => ({
  type: `${ApiConstants.GET_FEE}`,
  feePercentage
});

export const loadCardFees = () => ({
  type: `${SagaConstants.SAGA_ENSURE_LOADED}`,
  action: `${ApiConstants.LOAD_CARD_FEES}`,
  reloadAfterLogout: true
});

export const loadCompanyAccounts = () => ({
  type: `${ApiConstants.LOAD_COMPANY_ACCOUNTS}_REQUEST`
});

export const loadCompanyMetadata = () => ({
  type: `${ApiConstants.LOAD_COMPANY_METADATA}_REQUEST`
});

export const createDepositOrder = values => ({
  type: `${ApiConstants.DEPOSIT_COMPANY_ACCOUNT}_REQUEST`,
  values
});

export const successDepositOrderAfterPDFInvoice = () => ({
  type: `${ApiConstants.CREATE_NEW_PAYMENT}_SUCCESS`
});

export const downloadReceiptForDepositOrder = depositId => ({
  type: `${ApiConstants.GET_RECEIPT_FOR_DEPOSIT}_REQUEST`,
  id: depositId
});

export const downloadReceiptForTransaction = transactionId => ({
  type: `${ApiConstants.GET_RECEIPT_FOR_TRANSACTION}_REQUEST`,
  id: transactionId
});

export const getBenefitGroupSettings = () => ({
  type: `${ApiConstants.BENEFIT_GROUP_SETTINGS}_REQUEST`
});

export function loadTransferMoneyEstimation() {
  return {
    type: `${ApiConstants.TRANSFER_MONEY_ESTIMATION}_REQUEST`
  };
}

export const getHasOrders = () => ({
  type: `${SagaConstants.SAGA_ENSURE_LOADED}`,
  action: `${ApiConstants.GET_HAS_ORDERS}`
});

export const getActiveBenefits = () => ({
  type: `${ApiConstants.GET_ACTIVE_BENEFITS}_REQUEST`
});

export const getBenefitGroups = benefitType => ({
  type: `${ApiConstants.GET_BENEFIT_GROUPS}_REQUEST`,
  benefitType
});

export const updateBenefitGroup = (payload, benefitType) => ({
  type: `${ApiConstants.UPDATE_BENEFIT_GROUP}_REQUEST`,
  payload,
  benefitType
});

export const createBenefitGroup = (payload, benefitType) => ({
  type: `${ApiConstants.CREATE_BENEFIT_GROUP}_REQUEST`,
  payload,
  benefitType
});
