import React, { PureComponent } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

class TranslationResource extends PureComponent {
  render() {
    const { i18n, message, parameters = {} } = this.props;
    return <span>{i18n.t(message, parameters)}</span>;
  }
}

TranslationResource.propTypes = {
  i18n: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  parameters: PropTypes.object
};

TranslationResource.defaultProps = {
  parameters: {}
};

const TranslationResourceWithI18n = withTranslation()(TranslationResource);

export { TranslationResourceWithI18n as TranslationResource };
