import styled from 'styled-components';

export const AddBenefitGroupTileContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  width: 296px;
  height: 296px;
  margin: 0 24px 24px 0;
  background-color: ${props => props.theme.gray0};
  outline: 2px dashed ${props => props.theme.primaryBackColorLight};
  cursor: pointer;
`;

export const AddBenefitGroup = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  width: 120px;
  text-align: center;
  font-size: ${props => props.theme.sizeMedium};
  font-weight: ${props => props.theme.fontSemiBold};
  color: ${props => props.theme.primaryFrontColor};
`;
