export default function createReducer(getInitialState, handlers) {
  return (state = getInitialState(), action = null) => {
    if (!handlers[action.type]) {
      return state;
    }
    const result = handlers[action.type](state, action);
    if (result === state) {
      return state;
    }
    return { ...state, ...result };
  };
}
