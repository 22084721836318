import React from 'react';
import { useTranslation } from 'react-i18next';
import { InProgressIcon } from 'edenred-ui';
import { useFormContext } from 'react-hook-form';

import { SingleClickButton } from '@epi-components/SingleClickButton';
import { FormFields } from '@epi-forms/helpers';

import './ExternalPayment.scss';
import { IExternalPayment } from '@epi-models/forms/PaymentMethod';

export const ExternalPayment = ({
  errorMessageKey,
  isLoading,
  methods,
  isError,
  disabled,
  isProcessing,
  onClickPaymentMethod = () => {}
}: IExternalPayment) => {
  const { t } = useTranslation();
  const { setValue } = useFormContext();
  const getErrorMessage = () => {
    return t(errorMessageKey.value, errorMessageKey.format);
  };

  const createPayment = paymentCode => {
    if (!disabled) {
      setValue(FormFields.PaymentMethodCode, paymentCode);
      setTimeout(() => onClickPaymentMethod(), 0);
    }
  };

  return (
    <div className="ExternalPayment external-payment">
      {isLoading && !isError && <InProgressIcon />}{' '}
      {isLoading && !isError && t('application.loading')}
      {isError && (
        <div className="payments-methods-not-loaded">{getErrorMessage()}</div>
      )}
      {!isLoading &&
        !isError &&
        methods.map(item => (
          <span key={item.code}>
            <SingleClickButton
              id="create-external-payment-btn"
              disabled={disabled || isProcessing}
              className="external-payment"
              type="button"
              onClick={e => {
                e.preventDefault();

                if (!isProcessing) {
                  createPayment(item.code);
                }
              }}
            >
              <img
                className="payment-type"
                alt={item.name}
                width={item.image_width}
                height={item.image_height}
                src={item.image_url}
              />
            </SingleClickButton>
          </span>
        ))}
    </div>
  );
};
