import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { createRules, FormFields, useOnBlurTrim } from '@epi-forms/helpers';
import {
  addressLine,
  maxLength,
  required as requiredValidation
} from '@epi-helpers/formHelpers/validators';

export function AddressField({ disabled, readOnly, name, validate, required }) {
  const { control, unregister } = useFormContext();
  const { t } = useTranslation();
  const onBlurTrim = useOnBlurTrim(name);
  const validators = validate || [
    requiredValidation,
    maxLength(50),
    addressLine
  ];
  const rules = disabled || readOnly ? [] : createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  useEffect(() => {
    if (readOnly) {
      unregister(name);
    }
  }, [readOnly]);

  return (
    <TextField
      label={t('controls.address_label')}
      required={required}
      disabled={disabled}
      value={value}
      readOnly={readOnly}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onBlur={onBlurTrim}
      onChange={setValue}
    />
  );
}

AddressField.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  validate: PropTypes.array,
  required: PropTypes.bool
};

AddressField.defaultProps = {
  disabled: false,
  readOnly: false,
  name: FormFields.Address,
  required: true
};
