import { format } from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Row from '../../../../components/LayoutUtils/Row';
import { defaultDateFormat } from '../../../../constants/dateFormats';

const EndOfBenefitNotification = ({ endOfBenefit, eobPeriod }) => {
  const { t } = useTranslation();

  return (
    <Row>
      <p className="secondaryInfo">
        {t('containers.beneficiaries.detail.benefit_end_date_notifiation', {
          endOfBenefit: format(new Date(endOfBenefit), defaultDateFormat),
          eobPeriod
        })}
      </p>
    </Row>
  );
};

EndOfBenefitNotification.propTypes = {
  eobPeriod: PropTypes.number.isRequired,
  endOfBenefit: PropTypes.string
};

export default EndOfBenefitNotification;
