import { takeLatest, call, all, select, put } from 'redux-saga/effects';
import { setReCaptchaAsLoaded } from '@epi-actions/reCaptchaActions';
import { ApiConstants } from '../constants/actions';
import { getReCaptchaSiteKey } from '../selectors/settings';
import { loadRecaptchaScript } from '../helpers/ReCaptchaHelper';

export function* initializeRecaptcha() {
  const key = yield select(getReCaptchaSiteKey);
  yield call(loadRecaptchaScript, key);
  yield put(setReCaptchaAsLoaded());
}

export default function* watchRecaptcha() {
  yield all([
    takeLatest(`${ApiConstants.LOAD_SETTINGS}_SUCCESS`, initializeRecaptcha)
  ]);
}
