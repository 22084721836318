import { createSelector } from 'reselect';
import { getApplicationLocale } from './application';
import { addLocale } from '../helpers/UrlHelpers';

export const getLastSavedPath = state => state.navigation.locationHistory[0];

export const getNavigationHistory = state => state.navigation.locationHistory;

export const generateUrlWithLocale = url =>
  createSelector(getApplicationLocale, locale => addLocale(locale, url));
