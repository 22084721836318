import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { postRequest } from './ApiRepository';

export function* createUser(payload) {
  return yield call(
    postRequest,
    `${config.apiHost}/api/user`,
    Authorize.Never,
    payload
  );
}
