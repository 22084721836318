import React, { useState, forwardRef, useImperativeHandle } from 'react';
import PropTypes from 'prop-types';
import { InputComponent as Input, InProgressIcon } from 'edenred-ui';
import { validateEmailApiUrl } from '@epi-repositories/EmailRepository';
import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import { fetchPost as Post } from '../../helpers/FetchHelpers';
import { AsyncEmailFieldStyles } from './AsyncEmailField.style';

const AsyncEmailField = forwardRef(
  (
    {
      emailAddress,
      disabled,
      errors,
      onValueChange,
      onValidationSuccess,
      onValidationFailure,
      onRecaptchaFailure,
      reCaptchaSiteKey,
      labelText,
      labelWidth = '100%',
      labelPositioning = 'side'
    },
    ref
  ) => {
    const [validationInProgress, setValidationInProgress] = useState(false);
    const onSuccess = () => {
      setValidationInProgress(false);
      if (onValidationSuccess !== null) {
        onValidationSuccess();
      }
    };

    const onFailure = err => {
      setValidationInProgress(false);
      if (
        err.content &&
        err.content.emailAddress &&
        err.content.emailAddress[0] === 'email domain does not exist' &&
        onValidationFailure
      ) {
        onValidationFailure();
      }
    };

    const generateRecaptcha = async () => {
      return getRecaptchaToken(reCaptchaSiteKey, 'async_email_validation');
    };

    const validateEmail = (email, token) => {
      return Post(
        validateEmailApiUrl,
        { emailAddress: email, reCaptchaToken: token },
        onSuccess,
        onFailure
      );
    };

    const validateEmailWithRecaptcha = async email => {
      const token = await generateRecaptcha().catch(err => {
        if (onRecaptchaFailure) {
          onRecaptchaFailure(err);
        }
      });

      if (token && !validationInProgress) {
        setValidationInProgress(true);
        return validateEmail(email, token);
      }

      return null;
    };

    useImperativeHandle(ref, () => ({
      forceEmailDNSValidation() {
        if (!validationInProgress) {
          return validateEmailWithRecaptcha(emailAddress);
        }
        return Promise.resolve('validation_in_progress');
      }
    }));

    const onBlur = e => {
      const email = e.target.value;
      const newFocusedElementClasses = e.relatedTarget
        ? e.relatedTarget.classList
        : null;
      if (
        !errors.emailAddress &&
        !validationInProgress &&
        (!newFocusedElementClasses ||
          !newFocusedElementClasses.contains('trigger-email-DNS-validation'))
      ) {
        validateEmailWithRecaptcha(email).catch(err => console.error(err)); // eslint-disable-line
      }
    };

    return (
      <AsyncEmailFieldStyles>
        <Input
          className="email-address-input"
          labelPositioning={labelPositioning}
          labelWidth={labelWidth}
          label={
            <>
              {labelText}
              {validationInProgress && <InProgressIcon />}
            </>
          }
          onValueChange={onValueChange}
          value={emailAddress}
          disabled={disabled}
          errors={errors.emailAddress}
          name="emailAddress"
          data-test="emailAddress"
          onBlur={onBlur}
          required
        />
      </AsyncEmailFieldStyles>
    );
  }
);

AsyncEmailField.propTypes = {
  emailAddress: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  onValueChange: PropTypes.func.isRequired,
  labelText: PropTypes.string.isRequired,
  labelWidth: PropTypes.string,
  labelPositioning: PropTypes.string,
  onValidationSuccess: PropTypes.func,
  onValidationFailure: PropTypes.func,
  onRecaptchaFailure: PropTypes.func,
  reCaptchaSiteKey: PropTypes.string,
  required: PropTypes.string
};

AsyncEmailField.defaultProps = {
  disabled: false,
  labelWidth: '100%',
  labelPositioning: 'side',
  onValidationSuccess: null,
  onValidationFailure: null,
  onRecaptchaFailure: null,
  reCaptchaSiteKey: null
};

AsyncEmailField.displayName = 'AsyncEmailField';

export default AsyncEmailField;
