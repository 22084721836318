import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import { AcceptTermsCheckbox } from '@epi-forms/controls';

import './TermsAndConditions.scss';

export function TermsAndConditions() {
  return (
    <Container className="TermsAndConditions" id="TermsAndConditions" fluid>
      <Row>
        <Col xs={12} className="pl-25">
          <AcceptTermsCheckbox />
        </Col>
      </Row>
    </Container>
  );
}
