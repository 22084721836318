import { FormFields } from '@epi-forms/helpers';

export const CompanyDataFormFields = {
  InvoicingAddress: 'invoicingAddress',
  InvoicingZipCode: 'invoicingZipCode',
  InvoicingCity: 'invoicingCity',
  InvoicingCountry: 'invoicingCountry'
};

export function mapFormToCompanyData(form, isSignatory, userCompany) {
  return {
    company_info: {
      address: {
        addressLine: form[FormFields.Address],
        postCode: form[FormFields.ZipCode],
        city: form[FormFields.City],
        country: userCompany.address.country,
        companyName: userCompany.name
      },
      sizeId: form[FormFields.NumberOfEmployees],
      ...(isSignatory
        ? {
            invoiceInformation: {
              address: form[CompanyDataFormFields.InvoicingAddress],
              postCode: form[CompanyDataFormFields.InvoicingZipCode],
              city: form[CompanyDataFormFields.InvoicingCity],
              country: form[CompanyDataFormFields.InvoicingCountry],
              invoiceMethod: form[FormFields.InvoicingMethod],
              emailAddress: form[FormFields.EmailAddress],
              eInvoiceAddress: form[FormFields.EInvoiceAddress],
              operator: form[FormFields.Operator]
            }
          }
        : null)
    }
  };
}

function getInvoicingFormValues(userCompany) {
  return {
    [CompanyDataFormFields.InvoicingAddress]:
      userCompany?.invoiceInformation?.address || '',
    [CompanyDataFormFields.InvoicingZipCode]:
      userCompany?.invoiceInformation?.postCode || '',
    [CompanyDataFormFields.InvoicingCity]:
      userCompany?.invoiceInformation?.city || '',
    [CompanyDataFormFields.InvoicingCountry]:
      userCompany?.invoiceInformation?.country || '',
    [FormFields.InvoicingMethod]:
      userCompany?.invoiceInformation?.invoiceMethod || '',
    [FormFields.EmailAddress]:
      userCompany?.invoiceInformation?.emailAddress || '',
    [FormFields.EInvoiceAddress]:
      userCompany?.invoiceInformation?.eInvoiceAddress || '',
    [FormFields.Operator]: userCompany?.invoiceInformation?.operator || ''
  };
}

export function getCompanyDataFormValues(isSignatory, userCompany) {
  return {
    [FormFields.CompanyName]: userCompany?.name || '',
    [FormFields.BusinessId]: userCompany?.businessIdentityCode || '',
    [FormFields.CustomerNumber]: userCompany?.customerNumber || '',
    [FormFields.NumberOfEmployees]: userCompany?.sizeId || '',
    [FormFields.Address]: userCompany?.address.addressLine || '',
    [FormFields.ZipCode]: userCompany?.address.postCode || '',
    [FormFields.City]: userCompany?.address.city || '',
    [FormFields.Country]: userCompany?.address.country || '',
    ...(isSignatory ? getInvoicingFormValues(userCompany) : null)
  };
}
