import config from '@epi-config';
import { post } from '@epi-helpers/FetchHelpers';
import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import { getReCaptchaSiteKey } from '@epi-selectors/settings';
import { reduxStore } from '../../store-configuration';

const url = `${config.apiHost}/api/username`;

export async function isUsernameAvailableRequest(userName, abortController) {
  const state = reduxStore.getState();
  const reCaptchaSiteKey = getReCaptchaSiteKey(state);

  const reCaptchaToken = await getRecaptchaToken(
    reCaptchaSiteKey,
    'CHECK_USER_AVAILABILITY'
  );

  return post(
    `${url}/availability`,
    { userName, reCaptchaToken },
    null,
    abortController.signal
  );
}
