import React from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { createRules, FormFields } from '@epi-forms/helpers';
import {
  businessId,
  required as requiredValidation
} from '@epi-helpers/formHelpers/validators';

export function BusinessIdField({ disabled, readOnly, required }) {
  const name = FormFields.BusinessId;
  const { t } = useTranslation();
  const { control, trigger } = useFormContext();
  const validators = [requiredValidation, businessId];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const onBlurFormatWithDash = () => {
    if ((value || '').match(/^\d{8}$/)) {
      const valueWithDash = `${value.substr(0, 7)}-${value.substr(7, 1)}`;
      setValue(valueWithDash);
      trigger(FormFields.BusinessId);
    }
  };

  return (
    <TextField
      label={t('controls.business_id_label')}
      required={required}
      value={value}
      disabled={disabled}
      readOnly={readOnly}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onBlur={onBlurFormatWithDash}
      onChange={setValue}
    />
  );
}

BusinessIdField.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  required: PropTypes.bool
};

BusinessIdField.defaultProps = {
  disabled: false,
  readOnly: false,
  required: true
};
