import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { getRequest } from './ApiRepository';

export function* getPaymentMethods(orderType, amount, locale = 'fi') {
  const formattedAmount = (Number(amount) * 100).toFixed(0);

  return yield call(
    getRequest,
    `${config.apiHost}/api/payment-methods/${orderType}/${formattedAmount}?locale=${locale}`,
    Authorize.IfLogged
  );
}
