import React from 'react';
import PropTypes from 'prop-types';

import { FormStepStyles } from './FormStep.styles';

export const FormStep = ({ mode, children }) => {
  return (
    <FormStepStyles.Container mode={mode}>{children}</FormStepStyles.Container>
  );
};

export const FormStepMode = { Contained: 'contained', Outlined: 'outlined' };

FormStep.propTypes = {
  children: PropTypes.node.isRequired,
  mode: PropTypes.string.isRequired
};

FormStep.defaultProps = {
  mode: FormStepMode.Contained
};
