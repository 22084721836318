import { takeLatest, put, call, select, all } from 'redux-saga/effects';
import { ensureDataLoaded } from './saga';
import { ApiConstants, VoucherReorderConstants } from '../constants/actions';
import { getVoucherReorder } from '../selectors/voucherReorder';
import { DeliveryMethods } from '../constants/deliveryMethods';
import { UnauthorizedErrorName } from '../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../helpers/AuthorizeHelper';
import { NotificationMethods } from '../constants/notificationMethods';
import { getVoucherOrderDetails } from './api/loadVoucherOrderDetails';
import { trackReorderStart } from './tracking';

import { addressPickerAction } from '@epi-store/addressPicker/addressPicker.slice';

export function* setDeliveryAddress(delivery) {
  if (delivery.method === DeliveryMethods.ToPickupPoint) {
    const method = delivery.pickupEmail
      ? NotificationMethods.SendEmail
      : NotificationMethods.SendSms;

    yield put(
      addressPickerAction.setDeliveryAddress({
        ...delivery,
        method: DeliveryMethods.ToPickupPoint,
        pickup_point_details: {
          confirmation_method: method,
          emailAddress: delivery.pickupEmail,
          phoneNumber: delivery.pickupPhoneNumber
        }
      })
    );
  } else {
    yield put(
      addressPickerAction.setDeliveryAddress({
        ...delivery,
        method: DeliveryMethods.ToSavedAddress
      })
    );
  }
}

export function* loadReorderData({ orderId }) {
  try {
    const currentReorder = yield select(getVoucherReorder);
    const reloadReorderData = currentReorder.id !== orderId;

    // Load data in parallel
    const { voucherDetails } = yield all({
      vouchers: call(ensureDataLoaded, ApiConstants.LOAD_VOUCHERS),
      fees: call(ensureDataLoaded, ApiConstants.LOAD_ORDER_FEES),
      settings: call(ensureDataLoaded, ApiConstants.LOAD_SETTINGS),
      company: call(ensureDataLoaded, ApiConstants.LOAD_USER_COMPANY, true),
      voucherDetails: reloadReorderData && call(getVoucherOrderDetails, orderId)
    });
    if (reloadReorderData) {
      yield call(setDeliveryAddress, voucherDetails.delivery);
      yield put({
        type: `${VoucherReorderConstants.LOAD_REORDER_DATA}_SUCCESS`,
        voucherDetails
      });
    } else {
      yield trackReorderStart();
      yield put({
        type: `${VoucherReorderConstants.LOAD_REORDER_DATA}_ABORT`
      });
    }
  } catch (ex) {
    yield put({
      type: `${VoucherReorderConstants.LOAD_REORDER_DATA}_FAILURE`,
      payload: ex
    });
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    }
  }
}

export default function* watchVoucherReorderSaga() {
  yield all([
    takeLatest(
      `${VoucherReorderConstants.LOAD_REORDER_DATA}_REQUEST`,
      loadReorderData
    )
  ]);
}
