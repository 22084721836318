import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import {
  Box,
  PagePanel,
  ImagePanel,
  Heading1,
  Button,
  Text,
  Link
} from 'edenred-ui';

import { getUserRoles } from '@epi-selectors/login';
import RequestContactImg from '@epi-assets/images/RequestContact.jpg';
import { useEmailConfirmation } from '@epi-shared/hooks/useEmailConfirmation';

export const RequestContact = () => {
  useEmailConfirmation();
  const { t } = useTranslation();
  const theme = useTheme();
  const loginData = useSelector(getUserRoles);
  const isClient =
    loginData.includes('voucherManager') && loginData.length === 1;

  return isClient ? (
    <PagePanel>
      <Box height={360} bgcolor={theme.primaryBackColorLight10}>
        <ImagePanel imageSrc={RequestContactImg} reverse>
          <Box p={5}>
            <Heading1>{t('paper_vouchers.request_contact.title')}</Heading1>
            <Box mt={4}>
              <Text>{t('paper_vouchers.request_contact.text')}</Text>
            </Box>
            <Box mt={6}>
              <Button id="paper-vouchers-btn-anchor" minWidth={200}>
                <Link
                  target="_blank"
                  href={t('paper_vouchers.request_contact.link')}
                >
                  {t('paper_vouchers.request_contact.button')}
                </Link>
              </Button>
            </Box>
          </Box>
        </ImagePanel>
      </Box>
    </PagePanel>
  ) : (
    <Box mt={5} />
  );
};
