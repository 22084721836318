import styled from 'styled-components';

export const TopSection = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin: 0 0;
  position: relative;

  .employee-added-notification {
    flex: 1 1 auto;
    padding-right: 2rem;
  }
`;
