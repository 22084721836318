import {
  differenceInDays,
  isAfter,
  isSameDay,
  isValid,
  parseISO,
  addMonths,
  startOfMonth,
  endOfMonth,
  subMonths,
  format
} from 'date-fns';
import { apiDateFormat, defaultDateFormat } from '../constants/dateFormats';

export function formatDate(date, dateFormat = defaultDateFormat) {
  return format(new Date(date), dateFormat);
}

export const parseStringToDate = dateString => {
  return isValid(parseISO(dateString)) ? parseISO(dateString) : null;
};

export const getDifferenceInDays = (dateOne, dateTwo) => {
  return isValid(dateOne) && isValid(dateTwo)
    ? Math.abs(
        // eslint-disable-line
        differenceInDays(dateOne, dateTwo)
      )
    : null;
};

export const hasDatePassed = date => {
  const today = new Date();
  return date !== null && isValid(date)
    ? !isSameDay(today, date) && isAfter(today, date)
    : null;
};

export const getMonthNameForLocale = (
  locale,
  offsetFromCurrentMonth,
  date = null
) => {
  const startingMonth = !date ? new Date() : date;
  const targetMonth = addMonths(startingMonth, offsetFromCurrentMonth);
  const options = { month: 'long' };
  return targetMonth.toLocaleDateString(locale, options);
};

export const dateToApiFormat = date => {
  return format(date, apiDateFormat);
};

export const apiToDefaultDateFormat = apiDate => {
  const date = new Date(apiDate);
  return format(date, defaultDateFormat);
};

export const getFirstDayOfCurrentMonth = () => {
  const date = new Date();
  return format(startOfMonth(date), apiDateFormat);
};

export const getFirstDayOfLastMonth = () => {
  const date = new Date();
  const yearAgo = subMonths(date, 1);
  return format(startOfMonth(yearAgo), apiDateFormat);
};

export const getLastDayOfCurrentMonth = () => {
  const date = new Date();
  return format(endOfMonth(date), apiDateFormat);
};

export const getLastDayOfLastMonth = () => {
  const date = new Date();
  const yearAgo = subMonths(date, 1);
  return format(endOfMonth(yearAgo), apiDateFormat);
};
