import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { getRequest } from './ApiRepository';

export const companyBenefitGroupSettingsUrl = () =>
  `${config.apiHost}/api/benefit-group-settings`;

export const companyBenefitGroupSettingsActiveBenefitsUrl = () =>
  `${config.apiHost}/api/benefit-group-settings/active/benefit-types`;

export function* fetchCompanyBenefitGroupSettings() {
  return yield call(
    getRequest,
    companyBenefitGroupSettingsUrl(),
    Authorize.Always
  );
}

export function* fetchActiveBenefits() {
  return yield call(
    getRequest,
    companyBenefitGroupSettingsActiveBenefitsUrl(),
    Authorize.Always
  );
}
