import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useFormContext } from 'react-hook-form';

import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { estimationSelector } from '@epi-selectors/transactionEstimationSelectors';
import EstimationDetails from './EstimationDetails';

function TransferUnfulfilledRequestSummary({ estimation, dispatch }) {
  const { t } = useTranslation();
  const { setValue } = useFormContext();

  const setSuggestedTranserAmount = amountValue =>
    setValue('amount', amountValue.toFixed(2).replace('.', ','));

  const navigateToRequestedLoadsPage = () =>
    dispatch(goToPage(routerPaths.requestedLoads));

  return (
    estimation && (
      <>
        <p className="estimation-disclaimer">
          {t('containers.deposit_money.estimation_disclaimer')}
        </p>
        <EstimationDetails
          estimation={estimation}
          setSuggestedTranserAmount={setSuggestedTranserAmount}
          navigateToRequestedLoadsPage={navigateToRequestedLoadsPage}
        />
      </>
    )
  );
}

TransferUnfulfilledRequestSummary.propTypes = {
  estimation: PropTypes.object,
  dispatch: PropTypes.func.isRequired
};

TransferUnfulfilledRequestSummary.defaultProps = {
  estimation: null
};

const mapStateToProps = state => ({
  estimation: estimationSelector(state)
});

export default connect(mapStateToProps)(TransferUnfulfilledRequestSummary);
