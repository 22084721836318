import React from 'react';
import { Box, Text, Tooltip, InfoIcon } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';

import {
  Min10CharsRegex,
  Min1LowercaseRegex,
  Min1NumberRegex,
  isPasswordMin1Symbol,
  isPasswordMin1Uppercase
} from '@epi-helpers/formHelpers/validators';
import { PasswordValidation } from '@epi-shared/components';
import { IPasswordValidationContainer } from '@epi-models/core/Navbar';

export const PasswordValidationContainer = ({
  password
}: IPasswordValidationContainer) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Box mt={2}>
      <PasswordValidation
        isValid={Min10CharsRegex.test(password)}
        text={t('validation_messages.password_min_characters')}
      />
      <PasswordValidation
        isValid={Min1LowercaseRegex.test(password)}
        text={t('validation_messages.password_min_lowercase')}
      />
      <Box display="flex">
        <PasswordValidation
          isValid={isPasswordMin1Symbol(password)}
          text={t('validation_messages.password_min_symbol')}
        />
        <Box pl={1}>
          <Tooltip
            arrow
            placement="top"
            title={
              <Box>
                <Text small>{t('validation_messages.special_char_info')}</Text>
                <br />
                <Text small>{'@!#$%&\'*-+=?^_`"][{|}~:().,/<>;'}</Text>
              </Box>
            }
          >
            <Box>
              <InfoIcon color={theme.primaryFrontColor} />
            </Box>
          </Tooltip>
        </Box>
      </Box>
      <PasswordValidation
        isValid={Min1NumberRegex.test(password)}
        text={t('validation_messages.password_min_number')}
      />
      <PasswordValidation
        isValid={isPasswordMin1Uppercase(password)}
        text={t('validation_messages.password_min_uppercase')}
      />
    </Box>
  );
};
