import { put, call, select } from 'redux-saga/effects';
import { isCompanyLoaded, getUserCompany } from '@epi-selectors/api';
import { addSignatoryRoleToUser as addSignatoryRoleToUserRepo } from '@epi-repositories/UserRepository';
import { ApiConstants, NavigationConstants } from '@epi-constants/actions';
import { routerPaths } from '@epi-constants/routerPaths';
import * as overlaysActions from '@epi-actions/overlays';
import loadUserCompany, { doesCompanyHasSignatories } from './loadUserCompany';

export function* addSignatoryRoleToUser({ page }) {
  try {
    yield call(addSignatoryRoleToUserRepo);
    yield put({
      type: `${ApiConstants.ADD_SIGNATORY_ROLE_TO_USER}_SUCCESS`
    });

    const companyLoaded = yield select(isCompanyLoaded);
    if (!companyLoaded) {
      yield call(loadUserCompany);
    }

    const userCompany = yield select(getUserCompany);
    yield call(doesCompanyHasSignatories, userCompany.customerNumber);
    yield put({
      type: `${NavigationConstants.GO_TO_PAGE}`,
      url: `${routerPaths.cardOrder[page]}`
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.ADD_SIGNATORY_ROLE_TO_USER}_FAILURE`,
      payload: ex
    });
    yield put(
      overlaysActions.showNotification(
        { message: 'messages.add_signatory_failed' },
        'error'
      )
    );
  }
}
