import history from '../routes/history';
import {
  ApiConstants,
  NavigationConstants,
  OverlaysConstants
} from '../constants/actions';

export const getLoginAction = (
  username,
  password,
  { isAutoLogin = false, autoRedirect = true, rememberMe = undefined } = {}
) => ({
  type: `${ApiConstants.LOGIN_ACTION}_REQUEST`,
  payload: { username, password, rememberMe },
  isAutoLogin,
  autoRedirect
});

export const getImpersonationLoginAction = payload => ({
  type: `${ApiConstants.LOGIN_ACTION}_SUCCESS`,
  payload
});

export const goToLoginPage = returnUrl => ({
  type: NavigationConstants.GO_TO_LOGIN_PAGE,
  returnUrl: returnUrl || history.location.pathname
});

export const showLoginPopup = form => ({
  type: OverlaysConstants.SHOW_LOGIN_POPUP,
  form
});

export const loginPopupAction = actionType => ({
  type: `${OverlaysConstants.LOGIN_POPUP_ACTION}`,
  actionType
});

export const getLogoutAction = (sessionExpired = false) => ({
  type: `${ApiConstants.LOGOUT_ACTION}_REQUEST`,
  sessionExpired: !!sessionExpired
});
