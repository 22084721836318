import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';

export const getInitialState = () => ({
  newPaymentParams: null,
  isError: false,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ApiConstants.CREATE_NEW_PAYMENT}_REQUEST`]: () => ({
    newPaymentParams: null,
    isError: false
  }),

  [`${ApiConstants.CREATE_NEW_PAYMENT}_SUCCESS`]: (state, { payload }) => ({
    newPaymentParams: payload
  }),

  [`${ApiConstants.CREATE_NEW_PAYMENT}_FAILURE`]: () => ({
    newPaymentParams: null,
    isError: true
  })
});
