import React from 'react';
import { connect } from 'react-redux';
import { Container, Card, Row, Col } from 'react-bootstrap';
import { Box } from 'edenred-ui';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { OrderType } from '@epi-constants/orderType';
import { InvoicingContactPersonField } from '@epi-forms/controls/InvoicingContactPersonField';
import { InvoicingCostCenter } from '@epi-forms/controls/InvoicingCostCenter';
import { WithoutReferenceNumberCheckbox } from '@epi-forms/controls/WithoutReferenceNumberCheckbox';

import './DownloadInvoice.scss';
function DownloadInvoice({ i18n, orderType }) {
  return (
    <Card className="DownloadInvoice download-invoice">
      <Card.Body>
        <Container fluid>
          {orderType === OrderType.voucher && (
            <Row>
              <Col xs={12} className="blue-text mb-10">
                {i18n.t('forms.payment_method.order_shipped_after_payment')}
              </Col>
            </Row>
          )}
          <Row>
            <Col xs={12}>
              {i18n.t('forms.payment_method.invoice_additional_fields')}
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <InvoicingCostCenter />
            </Col>
            <Col xs={12} md={6}>
              <InvoicingContactPersonField />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Box marginTop="20px">
                <WithoutReferenceNumberCheckbox />
              </Box>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

DownloadInvoice.propTypes = {
  i18n: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  orderType: PropTypes.string.isRequired
};

DownloadInvoice.defaultProps = {
  disabled: false
};

const DownloadInvoiceWithI18n = withTranslation()(connect()(DownloadInvoice));

export { DownloadInvoiceWithI18n as DownloadInvoice };
