import { developEnvVariables } from './developVariables';
import { addFlags, processEnvVariables } from './envVariablesHelpers';
import { envVariablesSchema } from './envVariablesSchema';

const { envVariables: rawEnvVariables } = window;

const envVariables =
  process.env.NODE_ENV === 'development'
    ? developEnvVariables
    : processEnvVariables(rawEnvVariables, { ...envVariablesSchema });

export default addFlags(envVariables);
