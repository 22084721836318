import { useEffect, useState } from 'react';
import { orderBy } from 'lodash';

import { TopupSorting } from '@epi-constants/topupSorting';

export const useSortEmployees = (checkableEmployees, setCheckableEmployees) => {
  const [sortBy, setSortBy] = useState(TopupSorting.ByFirstName);
  const [descending, setDescending] = useState(true);

  const handleSort = newSortBy => {
    if (newSortBy === sortBy) {
      setDescending(!descending);
    } else {
      setSortBy(newSortBy);
    }
  };

  const sortEmployees = () => {
    setCheckableEmployees(
      orderBy(checkableEmployees, sortBy, descending ? 'desc' : 'asc')
    );
  };

  useEffect(() => {
    if (checkableEmployees.length) {
      sortEmployees();
    }
  }, [sortBy, descending]);

  return { handleSort, descending, sortBy };
};
