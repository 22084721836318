import {
  ButtonComponent as Button,
  ButtonMode,
  DialogComponent,
  PopupPanelComponent as Panel
} from 'edenred-ui';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';

import Pad from '../../components/LayoutUtils/Pad';

const RejectRequestedLoadDialogStyle = styled.div`
  .content {
    &__summary {
      font-weight: 600;
    }
  }
`;

const RejectRequestedLoadDialog = ({
  isOpen,
  closeAction,
  primaryAction,
  primaryActionLabel,
  secondaryAction,
  secondaryActionLabel,
  title,
  content
}) => {
  const modalRootElement = document.getElementById('modal-root');
  if (!modalRootElement) return null;
  return ReactDOM.createPortal(
    <RejectRequestedLoadDialogStyle>
      <DialogComponent open={isOpen} data-test="close_confirmation_dialog">
        <Panel
          onClose={() => {
            closeAction();
          }}
          title={title}
          primaryActionButton={
            <Button
              id="reject-requested-load-dialog-submit-btn"
              onClick={primaryAction}
              mode={ButtonMode.Primary}
              style={{ width: '200px' }}
            >
              <span data-test="cancel-request-action">
                {primaryActionLabel}
              </span>
            </Button>
          }
          secondaryActionButton={
            <Button
              id="reject-requested-load-dialog-cancel-btn"
              onClick={secondaryAction}
              mode={ButtonMode.Secondary}
              style={{ width: '200px' }}
            >
              <span data-test="go-back-action">{secondaryActionLabel}</span>
            </Button>
          }
        >
          <Pad horizontal="L" vertical="XL">
            <span data-test="close_confirmation_dialog">
              {content && content.message}
            </span>
            <p className="content__summary">{content && content.summary}</p>
          </Pad>
        </Panel>
      </DialogComponent>
    </RejectRequestedLoadDialogStyle>,
    modalRootElement
  );
};

RejectRequestedLoadDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeAction: PropTypes.func.isRequired,
  primaryAction: PropTypes.func.isRequired,
  primaryActionLabel: PropTypes.string.isRequired,
  secondaryAction: PropTypes.func.isRequired,
  secondaryActionLabel: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.object.isRequired
};

export default RejectRequestedLoadDialog;
