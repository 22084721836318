import { put, call, select } from 'redux-saga/effects';
import { addNewPaymentToCardOrder as addNewPaymentRepo } from '../../repositories/CardOrdersRepository';
import {
  getApplicationLocale,
  getApplicationLanguageId
} from '../../selectors/application';
import { PaymentMethods } from '../../constants/paymentMethods';
import { OrderType } from '../../constants/orderType';
import { routerPaths } from '../../constants/routerPaths';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';
import {
  ApiConstants,
  NavigationConstants,
  ApplicationConstants
} from '../../constants/actions';
import { getPaymentData } from './getPaymentData';

const isOnlineOrCreditCardPayment = paymentMethod =>
  paymentMethod === PaymentMethods.Online ||
  paymentMethod === PaymentMethods.CreditCard;

export default function* addNewPaymentToCard({ values }) {
  try {
    const data = getPaymentData({ payment: values });
    const newPaymentId = yield call(addNewPaymentRepo, data);

    if (isOnlineOrCreditCardPayment(values.method)) {
      const locale = yield select(getApplicationLocale);
      const languageId = yield select(getApplicationLanguageId);
      yield put({
        type: `${ApiConstants.CREATE_NEW_PAYMENT}_REQUEST`,
        payload: {
          paymentPublicId: newPaymentId,
          orderType: OrderType.digital,
          locale,
          languageId,
          isPaymentOnlyFlow: true
        }
      });
    } else {
      yield put({
        type: `${NavigationConstants.GO_TO_PAGE}`,
        url: `${routerPaths.orderPaymentStatus}`,
        params: { paymentId: newPaymentId }
      });
    }
    yield put({
      type: `${ApiConstants.ADD_NEW_PAYMENT_TO_CARD}_SUCCESS`
    });
    yield put({
      type: `${ApplicationConstants.CLEAR_FORMS}`
    });
  } catch (ex) {
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    }
    yield put({
      type: `${ApiConstants.ADD_NEW_PAYMENT_TO_CARD}_FAILURE`,
      payload: ex
    });
  }
}
