import React from 'react';
import { Box, OutlineWarningIcon, PagePanel } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { goToPage } from '@epi-actions/navigation';
import { EmptyState } from '@epi-shared/components';
import { routerPaths } from '@epi-constants/routerPaths';

export const PaperVoucherError = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const navigateToSupportPage = () => dispatch(goToPage(routerPaths.help));

  return (
    <PagePanel>
      <Box
        mt={4.5}
        minHeight={600}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <EmptyState
          icon={<OutlineWarningIcon />}
          title={t('paper_vouchers.errors.voucher_manager_hide_order_title')}
          description={t(
            'paper_vouchers.errors.voucher_manager_hide_order_subTitle'
          )}
          btnText={t('paper_vouchers.errors.voucher_manager_hide_order_button')}
          btnOnClick={navigateToSupportPage}
        />
      </Box>
    </PagePanel>
  );
};
