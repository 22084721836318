import { OverlaysConstants } from '../constants/actions';

export const showNotification = (options = {}, level = 'info') => ({
  type: `${OverlaysConstants.SHOW_NOTIFICATION}`,
  uid: options.uid || Date.now(),
  ...options,
  level
});

export const showErrorNotification = message => ({
  type: `${OverlaysConstants.SHOW_ERROR_NOTIFICATION}`,
  uid: Date.now(),
  message,
  level: 'error'
});

export const showErrorPopup = (closable, error = {}) => ({
  type: `${OverlaysConstants.SHOW_ERROR_POPUP}`,
  closable,
  error
});

export const hideNotification = uid => ({
  type: `${OverlaysConstants.HIDE_NOTIFICATION}`,
  uid
});

export const removeAllNotifications = () => ({
  type: `${OverlaysConstants.REMOVE_ALL_NOTIFICATIONS}`
});

export const passwordPopupAction = (actionType, password) => ({
  type: `${OverlaysConstants.PASSWORD_POPUP_ACTION}`,
  actionType,
  password
});
