import {
  take,
  race,
  takeEvery,
  call,
  fork,
  all,
  takeLatest,
  put,
  select
} from 'redux-saga/effects';
import { omit } from 'lodash';
import { SagaConstants, ApiConstants } from '../constants/actions';
import { sagaDataLoaded, getLoadedActions } from '../selectors/saga';

export function* takeFirst(pattern, saga, ...args) {
  // eslint-disable-next-line func-names
  const task = yield fork(function* () {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const action = yield take(pattern);
      yield call(saga, ...args.concat(action));
    }
  });
  return task;
}

function* waitForActionSuccess(action, reloadAfterLogout) {
  yield put({
    type: `${action}_REQUEST`
  });
  yield race([take(`${action}_SUCCESS`), take(`${action}_FAILURE`)]);
  yield put({
    type: `${SagaConstants.SAGA_DATA_LOADED}`,
    action,
    reloadAfterLogout
  });
}

export function* ensureDataLoaded(action, reloadAfterLogout = false) {
  const dataLoaded = yield select(sagaDataLoaded(action));
  if (dataLoaded && dataLoaded.loaded) {
    return;
  }
  yield call(waitForActionSuccess, action, reloadAfterLogout);
}

function* setResourcesToBeReloadedAfterLogout() {
  const loadedActions = yield select(getLoadedActions);
  const propertiesToDelete = Object.keys(loadedActions).filter(
    key => loadedActions[key].reloadAfterLogout
  );
  yield put({
    type: `${SagaConstants.RESET_DATA_LOADED}`,
    loadedActions: omit(loadedActions, propertiesToDelete)
  });
}

export function* setResourcesToBeReloaded(propertiesToDelete) {
  const loadedActions = yield select(getLoadedActions);
  yield put({
    type: `${SagaConstants.RESET_DATA_LOADED}`,
    loadedActions: omit(loadedActions, propertiesToDelete)
  });
}

export default function* watch() {
  yield all([
    takeEvery(
      `${SagaConstants.SAGA_ENSURE_LOADED}`,
      ({ action, reloadAfterLogout }) =>
        ensureDataLoaded(action, reloadAfterLogout)
    ),
    takeLatest(
      `${ApiConstants.LOGOUT_ACTION}_SUCCESS`,
      setResourcesToBeReloadedAfterLogout
    ),
    takeLatest(
      `${ApiConstants.LOGIN_ACTION}_REQUEST`,
      setResourcesToBeReloadedAfterLogout
    )
  ]);
}
