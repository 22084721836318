import { put, call } from 'redux-saga/effects';
import {
  fetchCompanyBenefitGroupSettings,
  fetchActiveBenefits
} from '@epi-repositories/BenefitGroupSettingsRepository';
import { ApiConstants, OverlaysConstants } from '@epi-constants/actions';

export function* getBenefitGroupSettings() {
  try {
    const benefitGroupSettingsResponse = yield call(
      fetchCompanyBenefitGroupSettings
    );

    yield put({
      type: `${ApiConstants.BENEFIT_GROUP_SETTINGS}_SUCCESS`,
      payload: benefitGroupSettingsResponse.benefitGroupSettings
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.BENEFIT_GROUP_SETTINGS}_FAILURE`,
      payload: ex
    });
    yield put({
      type: `${OverlaysConstants.SHOW_ERROR_POPUP}`,
      closable: false
    });
  }
}

export function* getActiveBenefits() {
  try {
    const response = yield call(fetchActiveBenefits);

    // Remove when endpoint returns benefit types with first letter upper case
    const activeBenefitTypes = response.map(
      type => type.charAt(0).toUpperCase() + type.slice(1)
    );

    yield put({
      type: `${ApiConstants.GET_ACTIVE_BENEFITS}_SUCCESS`,
      payload: activeBenefitTypes
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.GET_ACTIVE_BENEFITS}_FAILURE`,
      payload: ex
    });
  }
}
