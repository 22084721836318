import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pick } from 'lodash';
import React, { PureComponent } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { userIsAuthorizedAndTokenIsValid } from '@epi-helpers/AuthorizeHelper';

class AnonymousOnly extends PureComponent {
  render() {
    const { children, redirect, login, ...rest } = this.props;
    return (
      <Route
        {...rest}
        render={props =>
          !userIsAuthorizedAndTokenIsValid(login) ? (
            React.cloneElement(children, {
              ...props
            })
          ) : (
            <Redirect
              to={{
                pathname: redirect
              }}
            />
          )
        }
      />
    );
  }
}

AnonymousOnly.propTypes = {
  login: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  redirect: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  ...pick(state, ['login'])
});

const ConnectedAnonymousOnly = connect(mapStateToProps)(AnonymousOnly);

export { ConnectedAnonymousOnly as AnonymousOnly };
