import createSagaMiddleware, { END } from 'redux-saga';
import thunkMiddleware from 'redux-thunk';
import { configureStore as createStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers';

export function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [thunkMiddleware, sagaMiddleware];

  // No possibility to use default redux toolkit middlewares until we remove redux form
  const store = createStore({
    reducer: rootReducer,
    middleware: middlewares,
    devTools: { trace: true }
  });

  store.runSaga = sagaMiddleware.run;
  store.close = () => store.dispatch(END);

  return store;
}

export const reduxStore = configureStore();
