import React from 'react';
import { Box, FailureIndicatorIcon, SuccessIndicatorIcon } from 'edenred-ui';

import { IPasswordValidation } from '@epi-models/core/Navbar';

export function PasswordValidation({ isValid, text }: IPasswordValidation) {
  return (
    <Box display="flex" alignItems="center" my={0.5}>
      {isValid ? <SuccessIndicatorIcon /> : <FailureIndicatorIcon />}
      <Box ml={1}>{text}</Box>
    </Box>
  );
}
