import {
  ApiConstants as apiActions,
  ProfileConstants as profileActions
} from '../constants/actions';

export const updateUserInformation = payload => ({
  type: apiActions.UPDATE_USER_INFORMATION,
  payload
});

export const getUserInformation = payload => ({
  type: `${apiActions.GET_USER_INFORMATION}_REQUEST`,
  payload
});

export const changePassword = payload => ({
  type: `${apiActions.CHANGE_PASSWORD}_REQUEST`,
  payload
});

export const changeSelectedProfileTab = index => ({
  type: profileActions.CHANGE_SELECTED_PROFILE_TAB,
  index
});
