import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Table, SimpleButton, MoreIcon } from 'edenred-ui';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { fetchPut as Put } from '@epi-helpers/FetchHelpers';
import { useMenuDimensions } from '@epi-shared/hooks';
import {
  openSlideout,
  disableSlideoutEditMode,
  disableEditMode
} from '@epi-actions/company';
import { contactsApiUrl } from '@epi-repositories/ContactsRepository';
import { showNotification } from '@epi-actions/overlays';
import { SignupTokenErrors } from '@epi-constants/signupTokenErrors';
import { loadContacts } from '@epi-actions/api';

import { ContactActions } from '../ContactActions';
import { formatPhoneNumber } from '../../../../helpers/formHelpers/formatters';

const TableRow = styled(Table.Row)`
  cursor: pointer;
`;

function ContactRow({
  row,
  showSlideout,
  showSuccess,
  showError,
  disableEditMode,
  loadContacts,
  handleDeleteContact
}) {
  const { t } = useTranslation();
  const { isMobile } = useMenuDimensions();
  const { setValue } = useFormContext();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const openMenu = event => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const openModalAndFillForm = () => {
    setValue('firstName', row.firstName);
    setValue('lastName', row.lastName);
    setValue('emailAddress', row.emailAddress);
    setValue('phoneNumber', row.phoneNumber);
    setValue('userName', row.userName);
    if (row.personId) setValue('personId', row.personId);
    if (row.dataContactId) setValue('dataContactId', row.dataContactId);
    showSlideout();
  };

  const onResendError = error => {
    const errorMessage = error.content?.errors[0]?.message;

    if (errorMessage === SignupTokenErrors.CreationCompleted) {
      loadContacts();
      return showError('messages.account_activated');
    }
    if (errorMessage === SignupTokenErrors.ContactDeleted) {
      loadContacts();
      return showError('messages.contact_already_deleted');
    }
    return showError('messages.error');
  };

  const resendActivationEmail = () => {
    closeMenu();
    return Put(
      `${contactsApiUrl}/resend-email`,
      { dataContactId: row.dataContactId },
      showSuccess
    ).catch(onResendError);
  };

  const handleRowClick = () => {
    openModalAndFillForm();
    disableEditMode();
  };

  const handleDeleteAction = () => {
    handleDeleteContact(row);
    closeMenu();
  };

  return (
    <>
      {isMobile ? (
        <TableRow onClick={handleRowClick}>
          <Table.Cell>
            {row.firstName} {row.lastName}
          </Table.Cell>
          <Table.Cell align="center">
            <SimpleButton icon={<MoreIcon />} onClick={openMenu} />
          </Table.Cell>
        </TableRow>
      ) : (
        <TableRow onClick={handleRowClick}>
          <Table.Cell>{row.firstName}</Table.Cell>
          <Table.Cell>{row.lastName}</Table.Cell>
          <Table.Cell>{row.emailAddress}</Table.Cell>
          <Table.Cell>{formatPhoneNumber(row.phoneNumber)}</Table.Cell>
          <Table.Cell>
            {row.active
              ? t('company_info.contacts.status.active')
              : t('company_info.contacts.status.inactive')}
          </Table.Cell>
          <Table.Cell align="center">
            <SimpleButton icon={<MoreIcon />} onClick={openMenu} />
          </Table.Cell>
        </TableRow>
      )}
      <ContactActions
        anchorEl={menuAnchorEl}
        closeMenu={closeMenu}
        userActive={row.active}
        openModalAndFillForm={openModalAndFillForm}
        resendActivationEmail={resendActivationEmail}
        handleDeleteAction={handleDeleteAction}
      />
    </>
  );
}

ContactRow.propTypes = {
  showSlideout: PropTypes.func.isRequired,
  row: PropTypes.object.isRequired,
  disableEditMode: PropTypes.func.isRequired,
  loadContacts: PropTypes.func.isRequired,
  showSuccess: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  handleDeleteContact: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
  showError: errorKey => {
    dispatch(showNotification({ message: errorKey }, 'error'));
  },
  showSuccess: () => {
    dispatch(
      showNotification(
        { message: 'messages.resend_activation_successful' },
        'success'
      )
    );
  },
  showSlideout: () => {
    dispatch(openSlideout());
  },
  disableEditMode: () => {
    dispatch(disableSlideoutEditMode());
    dispatch(disableEditMode());
  },
  loadContacts: () => dispatch(loadContacts())
});

const ConnectedContactRow = connect(null, mapDispatchToProps)(ContactRow);

export { ConnectedContactRow as ContactRow };
