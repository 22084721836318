import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Box, LoadingSpinner } from 'edenred-ui';

import LoginImg from '@epi-assets/images/Login.jpg';
import { isFetching } from '@epi-selectors/login';
import { LOGIN } from '@epi-constants/login';
import { changeUrlWithoutReloadPage } from '@epi-helpers/UrlHelpers';
import { useWindowDimensions, useImpersonation } from '@epi-shared/hooks';
import {
  AccountCreatedNotification,
  LoginForm,
  RecaptchaTerms,
  LoginImpersonationError
} from './components';
import { TowRedirect } from './components/TowRedirect';

const pageBoxStyle = {
  position: 'relative',
  width: '100%',
  py: { xs: '16px', md: '40px' },
  mx: 2
};

function Login({ isProcessingLogin }) {
  const { width } = useWindowDimensions();
  const { isImpersonate, isLoading, isError } = useImpersonation();
  const isMobile = width < 800;
  const url = window.location.href;

  if (!url.includes(LOGIN) && !isImpersonate)
    changeUrlWithoutReloadPage(url, LOGIN);

  if (isError) return <LoginImpersonationError />;

  if (isLoading) return <LoadingSpinner />;

  return (
    <Box display="flex" justifyContent="center">
      <Box sx={pageBoxStyle} maxWidth={isMobile ? 600 : 1056}>
        <Box py={2}>
          <AccountCreatedNotification />
        </Box>
        {isProcessingLogin && <LoadingSpinner />}
        <Box
          display="flex"
          flexDirection={isMobile ? 'column' : 'row'}
          boxShadow="0 16px 64px 0 rgba(0, 0, 0, 0.1)"
        >
          <Box flex={1} position="relative">
            <img
              alt="Login page"
              src={LoginImg}
              style={{
                width: '100%',
                height: isMobile ? '160px' : '100%',
                objectFit: 'cover'
              }}
            />
          </Box>
          <Box bgcolor="white" flex={1}>
            <Box maxWidth={400} px={3} py={isMobile ? 5 : 3} mx="auto">
              <LoginForm />
              <TowRedirect />
            </Box>
          </Box>
        </Box>
        <RecaptchaTerms />
      </Box>
    </Box>
  );
}

Login.propTypes = {
  isProcessingLogin: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  isProcessingLogin: isFetching(state)
});

export default connect(mapStateToProps)(Login);
