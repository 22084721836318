import { get } from 'lodash';

export const getApplicationLocale = state =>
  get(state, 'application.language.locale');

export const getApplicationState = state => state.application;

export const getApplicationLanguageId = state =>
  get(state, 'application.language.id');

export const getApplicationLanguage = state =>
  get(state, 'application.language');

export const getApplicationLanguageCode = state =>
  get(state, 'application.language.cultureCode', 'fi-FI');
