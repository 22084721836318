import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';

export const getInitialState = () => ({
  voucherOrders: [],
  totalPages: 1,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_VOUCHER_ORDERS_HISTORY}_SUCCESS`]: (
    state,
    { payload }
  ) => ({
    voucherOrders: payload.voucherOrdersResponse.voucherOrders || [],
    totalPages: payload.voucherOrdersResponse.totalPages
  }),

  [`${ApiConstants.LOAD_VOUCHER_ORDERS_HISTORY}_FAILURE`]: () => ({
    voucherOrders: [],
    isError: true
  }),

  [`${ApiConstants.CLEAR_SAVED_HISTORY}`]: () => ({
    voucherOrders: [],
    isError: false
  })
});
