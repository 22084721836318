import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { createRules, FormFields, useOnBlurTrim } from '@epi-forms/helpers';
import {
  required as requiredValidation,
  zipCodeBasedOnCountry
} from '@epi-helpers/formHelpers/validators';

export function ZipCodeField({ disabled, readOnly, name, required }) {
  const { control, trigger, getValues, unregister } = useFormContext();
  const { t } = useTranslation();
  const country = getValues(FormFields.Country) || 'FI';
  const onBlurTrim = useOnBlurTrim(name);
  const validators = [requiredValidation, zipCodeBasedOnCountry(country)];
  const rules = disabled || readOnly ? [] : createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  useEffect(() => {
    if (country && value) {
      trigger(FormFields.ZipCode);
    }
  }, [country]);

  useEffect(() => {
    if (readOnly) {
      unregister(name);
    }
  }, [readOnly]);

  return (
    <TextField
      label={t('controls.zip_code_label')}
      required={required}
      disabled={disabled}
      value={value}
      readOnly={readOnly}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onBlur={onBlurTrim}
      onChange={setValue}
    />
  );
}

ZipCodeField.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool
};

ZipCodeField.defaultProps = {
  disabled: false,
  readOnly: false,
  name: FormFields.ZipCode,
  required: true
};
