import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { postRequest } from './ApiRepository';

export function* userLogin({ username, password }) {
  const payload = {
    username,
    password
  };

  return yield call(
    postRequest,
    `${config.apiHost}/api/login`,
    Authorize.Never,
    payload
  );
}
