import { put, call } from 'redux-saga/effects';
import {
  getUserCompany,
  getHasSignatory
} from '../../repositories/CompaniesRepository';
import { ApiConstants } from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';
import * as overlaysActions from '../../actions/overlays';

export function* doesCompanyHasSignatories(companyId) {
  try {
    const hasSignatory = yield call(getHasSignatory, companyId);

    yield put({
      type: `${ApiConstants.LOAD_COMPANY_HAS_SIGNATORY}_SUCCESS`,
      hasSignatory
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.LOAD_COMPANY_HAS_SIGNATORY}_FAILURE`,
      payload: ex
    });
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    } else {
      yield put(
        overlaysActions.showNotification({ message: 'messages.error' }, 'error')
      );
    }
  }
}

export default function* loadUserCompany() {
  try {
    const loadedUserCompany = yield call(getUserCompany);

    yield put({
      type: `${ApiConstants.LOAD_USER_COMPANY}_SUCCESS`,
      userCompany: loadedUserCompany
    });

    yield call(doesCompanyHasSignatories, loadedUserCompany.customerNumber);
  } catch (ex) {
    // change the loading status
    yield put({
      type: `${ApiConstants.LOAD_USER_COMPANY}_FAILURE`,
      payload: ex
    });
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    } else {
      // display the error message
      yield put(
        overlaysActions.showNotification({ message: 'messages.error' }, 'error')
      );
    }
  }
}
