import React, { createContext, PropsWithChildren } from 'react';

import { useToast } from '@epi-shared/hooks/useToast';
import { IToastContext } from '@epi-models/core/Toast';

export const ToastContext = createContext<IToastContext | null>(null);

export const ToastProvider = ({ children }: PropsWithChildren) => {
  const value = useToast();

  return (
    <ToastContext.Provider value={value}>{children}</ToastContext.Provider>
  );
};

export default ToastProvider;
