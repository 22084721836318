import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { OrderType } from '@epi-constants/orderType';
import { PagePanel } from 'edenred-ui';

import { ApiConstants } from '../../constants/actions';
import { PaymentMethods } from '../../constants/paymentMethods';
import { TermsAndConditions } from '../../forms/TermsAndConditions/TermsAndConditions';
import { PaymentMethod } from '../../forms/PaymentMethod/PaymentMethod';
import { DeliveryPicker } from '../../forms/DeliveryPicker/DeliveryPicker';
import { Company } from '../Company/Company';
import { BenefitSummary } from '../BenefitSummary/BenefitSummary';
import { LoadingPanel } from '../LoadingPanel/LoadingPanel';

function DeliveryAndPayment({
  i18n,
  voucherTypeKey,
  orderSummary,
  paymentMethod,
  apiPaymentMethods,
  locale,
  onClickPaymentMethod,
  userCompany,
  isLoading,
  disabled,
  goToEditPage,
  editDeliveryPage,
  editCompanyPage,
  voucherReorderDetails
}) {
  return (
    <div>
      <Company
        voucherTypeKey={voucherTypeKey}
        userCompany={userCompany}
        isLoading={isLoading}
        editPage={editCompanyPage}
      />
      <DeliveryPicker
        editPage={editDeliveryPage}
        isLoading={isLoading}
        companyAdress={userCompany.address}
      />
      <BenefitSummary
        goToEditPage={goToEditPage}
        voucherTypeKey={voucherTypeKey}
        orderSummary={orderSummary}
        isLoading={isLoading}
        showCreditCardFee={paymentMethod === PaymentMethods.CreditCard}
        showTitle
        voucherOrderPayment={voucherReorderDetails}
      />
      <PagePanel
        title={i18n.t('containers.voucher_order.terms_and_conditions_header')}
        className="px-10"
      >
        <TermsAndConditions />
      </PagePanel>
      <LoadingPanel
        title={i18n.t('containers.voucher_order.payment_methods_header')}
        className="px-10 payment-method"
        isLoading={[
          ApiConstants.CREATE_VOUCHER_ORDER,
          ApiConstants.CREATE_NEW_PAYMENT
        ]}
      >
        <PaymentMethod
          paymentMethods={apiPaymentMethods}
          locale={locale}
          onClickPaymentMethod={() => onClickPaymentMethod()}
          disabled={disabled}
          creditCardFeePercent={orderSummary.creditCardFeePercent}
          amountValue={orderSummary.totalAmountWithFees}
          orderType={OrderType.voucher}
          hiddenMethods={[PaymentMethods.PrintInvoicePdfForTransferMoney]}
        />
      </LoadingPanel>
    </div>
  );
}

DeliveryAndPayment.propTypes = {
  voucherTypeKey: PropTypes.string.isRequired,
  locale: PropTypes.string.isRequired,
  apiPaymentMethods: PropTypes.object.isRequired,
  orderSummary: PropTypes.object.isRequired,
  onClickPaymentMethod: PropTypes.func.isRequired,
  userCompany: PropTypes.object.isRequired,
  goToEditPage: PropTypes.func.isRequired,
  editDeliveryPage: PropTypes.string.isRequired,
  editCompanyPage: PropTypes.string.isRequired,
  i18n: PropTypes.object.isRequired,
  paymentMethod: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  voucherReorderDetails: PropTypes.object
};

DeliveryAndPayment.defaultProps = {
  isLoading: false,
  disabled: false,
  paymentMethod: ''
};

const DeliveryAndPaymentWithI18n = withTranslation()(DeliveryAndPayment);

export { DeliveryAndPaymentWithI18n as DeliveryAndPayment };
