export const getOrderHistoryQueryPage = state =>
  state.orderHistory.queryParameters.page;

export const getOrderHistoryQueryRowsPerPage = state =>
  state.orderHistory.queryParameters.rowsPerPage;

export const getOrderHistoryQuerySortingBy = state =>
  state.orderHistory.queryParameters.sortingBy;

export const getOrderHistoryQueryDescending = state =>
  state.orderHistory.queryParameters.descending;

export const getOrderHistoryQuery = state => state.orderHistory.queryParameters;
