import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import { Authorize } from '../constants/authorize';
import { postRequest } from './ApiRepository';
import { reduxStore } from '../store-configuration';
import { getReCaptchaSiteKey } from '@epi-selectors/settings';

export function* getAvailability(userName) {
  const state = reduxStore.getState();
  const reCaptchaSiteKey = getReCaptchaSiteKey(state);

  const reCaptchaToken = yield getRecaptchaToken(
    reCaptchaSiteKey,
    'CHECK_USER_AVAILABILITY'
  );

  return yield call(
    postRequest,
    `${config.apiHost}/api/username/availability`,
    Authorize.Never,
    { userName, reCaptchaToken }
  );
}
