import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { PagePanel } from 'edenred-ui';

import { BenefitType } from '@epi-constants/benefitTypes';
import { getActiveBenefits } from '@epi-actions/api';
import {
  selectActiveBenefits,
  selectIsActiveBenefitsLoaded
} from '@epi-selectors/benefitGroupSettings';
import { isSupportedBenefit } from '@epi-helpers/benefitsHelper';

import * as Style from './ManageBenefits.styles';
import BenefitTypeTile from './components/BenefitTypeTile/BenefitTypeTile';

function ManageBenefits({ dispatch, activeBenefits, isActiveBenefitsLoaded }) {
  useEffect(() => {
    dispatch(getActiveBenefits());
  }, []);

  const benefitTiles =
    isActiveBenefitsLoaded &&
    Object.values(BenefitType).map(type => (
      <BenefitTypeTile
        key={type}
        benefit={type}
        active={activeBenefits
          .map(activeBenefit => activeBenefit.toLowerCase())
          .includes(type)}
        disabled={!isSupportedBenefit(type)}
      />
    ));

  return (
    <PagePanel title={i18n.t('components.manage_benefits.new.title')}>
      <Style.ManageBenefitsTiles>{benefitTiles}</Style.ManageBenefitsTiles>
    </PagePanel>
  );
}

ManageBenefits.propTypes = {
  dispatch: PropTypes.func.isRequired,
  activeBenefits: PropTypes.arrayOf(PropTypes.string),
  isActiveBenefitsLoaded: PropTypes.bool.isRequired
};

ManageBenefits.defaultProps = {
  activeBenefits: []
};

const connectToStore = connect(state => ({
  activeBenefits: selectActiveBenefits(state),
  isActiveBenefitsLoaded: selectIsActiveBenefitsLoaded(state)
}));

export default connectToStore(ManageBenefits);
