import React from 'react';
import { FormProvider } from 'react-hook-form';
import PropTypes from 'prop-types';

export function Form({ form, onSubmit, children, id }) {
  const { handleSubmit, getValues } = form;

  const submitValues = async () => {
    const formValues = getValues();

    await onSubmit(formValues);
  };

  return (
    <FormProvider {...form}>
      <form id={id} noValidate onSubmit={handleSubmit(submitValues)}>
        {children}
      </form>
    </FormProvider>
  );
}

Form.propTypes = {
  form: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  children: PropTypes.node,
  id: PropTypes.string
};
