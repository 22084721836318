import { defaultTo } from 'lodash';
import cookie from './CookieStorage';
import storageTypes from '../constants/storageTypes';

class StorageManager {
  constructor() {
    this.storageAvailability = {};
    this.initStorage();
  }

  getStorageApi = type => {
    if (['sessionStorage', 'localStorage'].includes(type)) {
      try {
        return window[type];
      } catch (ex) {
        return null;
      }
    }
    return null;
  };

  // the order of the property is important, since we choose the first available storage as the best
  possibleStorages = {
    [storageTypes.local_storage]: this.getStorageApi('localStorage'),
    [storageTypes.cookies]: cookie,
    [storageTypes.session_storage]: this.getStorageApi('sessionStorage')
  };

  isStorageAvailable = storageType => {
    if (!this.possibleStorages[storageType]) {
      return false;
    }

    if (
      Object.prototype.hasOwnProperty.call(
        this.storageAvailability,
        storageType
      )
    ) {
      return this.storageAvailability[storageType];
    }

    try {
      const testKey = 'test';
      this.possibleStorages[storageType].setItem(testKey, '1');
      this.possibleStorages[storageType].removeItem(testKey);
      this.storageAvailability[storageType] = true;
    } catch (error) {
      this.storageAvailability[storageType] = false;
    }

    return this.storageAvailability[storageType];
  };

  checkStorageAvailability = storageType =>
    this.possibleStorages[storageType] && this.isStorageAvailable(storageType);

  initStorage = () => {
    if (this.storage) {
      return;
    }

    Object.keys(this.possibleStorages).every(type => {
      if (this.checkStorageAvailability(type)) {
        this.storage = this.possibleStorages[type];
        return false;
      }
      return true;
    });
  };

  getStorage = () => {
    if (this.storage) {
      return this.storage;
    }
    throw Error('No available storage');
  };

  set = (key, value) => {
    this.getStorage().setItem(key, JSON.stringify(value));
  };

  get = key => JSON.parse(defaultTo(this.getStorage().getItem(key), null));

  clear = key => {
    if (key) {
      this.getStorage().removeItem(key);
    } else {
      this.getStorage().clear();
    }
  };
}

const storageManager = new StorageManager();

export default {
  checkStorageAvailability: storageManager.checkStorageAvailability,
  get: storageManager.get,
  set: storageManager.set,
  clear: storageManager.clear
};
