import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';

export const getInitialState = () => ({
  isLoading: false
});

export default createReducer(getInitialState, {
  [`${ApiConstants.CHECK_USERNAME_AVAILABILITY}_REQUEST`]: () => ({
    isLoading: true
  }),

  [`${ApiConstants.RUN_USERNAME_ASYNC_VALIDATION}_START`]: () => ({
    isLoading: true
  }),

  [`${ApiConstants.RUN_USERNAME_ASYNC_VALIDATION}_END`]: () => ({
    isLoading: false
  }),

  [`${ApiConstants.CHECK_USERNAME_AVAILABILITY}_SUCCESS`]: () => ({
    isLoading: false
  }),

  [`${ApiConstants.CHECK_USERNAME_AVAILABILITY}_FAILURE`]: () => ({
    isLoading: false
  })
});
