import { useDispatch } from 'react-redux';

import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';

export const useNewCustomer = () => {
  const dispatch = useDispatch();

  const handleClickManageBenefits = () => {
    dispatch(goToPage(routerPaths.manageBenefits));
  };

  const handleClickBeneficiaries = () => {
    dispatch(goToPage(routerPaths.beneficiaries));
  };

  const handleClickLoadCompanyAccount = () => {
    dispatch(goToPage(routerPaths.loadCompanyAccount));
  };

  return {
    handleClickManageBenefits,
    handleClickBeneficiaries,
    handleClickLoadCompanyAccount
  };
};
