import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col, FormLabel } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Moment from 'moment';
import { OrderStatus, ProductName } from '@epi-shared/components';
import { formatCurrency } from '../../helpers/numeral';
import { addressLine } from '../../helpers/Formatters';
import {
  voucherOrder as voucherOrderSelector,
  isError as isErrorSelector
} from '../../selectors/orderDetails';
import { VoucherOrderHistoryDateFormat } from '../../constants/dateFormats';
import { DeliveryMethods } from '../../constants/deliveryMethods';
import { routerPaths } from '../../constants/routerPaths';
import * as apiActions from '../../actions/api';
import * as navigationActions from '../../actions/navigation';
import { NotFound } from '../NotFound/NotFound';
import './OrderDetails.scss';
import { NavigationHeader } from '../../components/NavigationHeader/NavigationHeader';
import { DownloadInvoiceButton } from '../../components/DownloadInvoiceButton/DownloadInvoiceButton';
import { LoadingContainer } from '../../components/LoadingContainer/LoadingContainer';

const OrderDetails = ({
  loadVoucherOrderDetails,
  match,
  voucherOrder,
  isError,
  goToPage
}) => {
  const { t } = useTranslation();
  const deliveryAddressToPickupPoint =
    voucherOrder &&
    voucherOrder.delivery.method === DeliveryMethods.ToPickupPoint &&
    t('containers.order_details.to_pickup_point');

  const deliveryAddressString =
    voucherOrder && voucherOrder.delivery.address
      ? `${addressLine(voucherOrder.delivery.address)}`
      : deliveryAddressToPickupPoint;

  useEffect(() => {
    loadVoucherOrderDetails(match.params.voucherOrderId);
  }, []);

  if (isError) {
    return <NotFound />;
  }

  return (
    <NavigationHeader
      withBackButton
      onClickBack={() => goToPage(routerPaths.orderHistory)}
      title={t('containers.order_details.title')}
    >
      <div className="OrderDetails">
        <LoadingContainer isLoading={!voucherOrder}>
          {voucherOrder && (
            <div>
              <DownloadInvoiceButton
                idType="order"
                id={match.params.voucherOrderId}
                className="btn-download"
              >
                <span>{t('containers.order_details.download')}</span>
              </DownloadInvoiceButton>
              <Container fluid className="px-0">
                <Col xs={12} md={6} lg={4}>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.date')}
                    </FormLabel>
                    <h3 className="small">
                      {Moment(voucherOrder.date).format(
                        VoucherOrderHistoryDateFormat
                      )}
                    </h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.orderer')}
                    </FormLabel>
                    <h3 className="small">{voucherOrder.orderer}</h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.order_number')}
                    </FormLabel>
                    <h3 className="small">
                      {voucherOrder.orderNumber ||
                        t('containers.order_details.pending')}
                    </h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.delivery_address')}
                    </FormLabel>
                    <h3 className="small">{deliveryAddressString || '-'}</h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.cost_center')}
                    </FormLabel>
                    <h3 className="small">
                      {voucherOrder.invoiceCostCenter || '-'}
                    </h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.product')}
                    </FormLabel>
                    <h3 className="small">
                      <ProductName product={voucherOrder.product} />{' '}
                      {formatCurrency(voucherOrder.faceValue)}
                    </h3>
                  </Row>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.amount')}
                    </FormLabel>
                    <h3 className="small">
                      {voucherOrder.totalQuantity || '-'}
                    </h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.order_summary')}
                    </FormLabel>
                    <h3 className="small">
                      {t('containers.order_details.order_summary_value', {
                        faceValue: formatCurrency(voucherOrder.faceValue),
                        quantity: voucherOrder.totalQuantity,
                        total: formatCurrency(voucherOrder.totalAmount)
                      })}
                    </h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.handling_fee')}
                    </FormLabel>
                    <h3 className="small">
                      {formatCurrency(voucherOrder.handlingFee)}
                    </h3>
                  </Row>
                  {voucherOrder.creditCardFee > 0 && (
                    <Row>
                      <FormLabel className="caption4">
                        {t('containers.order_details.credit_card_fee')}
                      </FormLabel>
                      <h3 className="small">
                        {formatCurrency(voucherOrder.creditCardFee)}
                      </h3>
                    </Row>
                  )}
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.total_without_vat')}
                    </FormLabel>
                    <h3 className="small">
                      {formatCurrency(
                        voucherOrder.totalAmount + voucherOrder.handlingFee
                      )}
                    </h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.total_vat', {
                        vatFeePercent: voucherOrder.vatFeePercent
                      })}
                    </FormLabel>
                    <h3 className="small">
                      {formatCurrency(voucherOrder.vatFeeAmount)}
                    </h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.total')}
                    </FormLabel>
                    <h3 className="small">
                      {formatCurrency(voucherOrder.totalAmountWithFees)}
                    </h3>
                  </Row>
                </Col>
                <Col xs={12} md={6} lg={4}>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.order_status')}
                    </FormLabel>
                    <h3 className="small">
                      <OrderStatus
                        paymentStatus={voucherOrder.paymentStatus}
                        voucherOrderStatus={voucherOrder.voucherOrderStatus}
                      />
                    </h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.tracking_code')}
                    </FormLabel>
                    <h3 className="small">
                      {voucherOrder.trackingCode ||
                        t('containers.order_details.pending')}
                    </h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.serial_numbers_of_vouchers')}
                    </FormLabel>
                    <h3 className="small">
                      {!voucherOrder.firstVoucherSerial &&
                      !voucherOrder.lastVoucherSerial
                        ? t('containers.order_details.pending')
                        : `${voucherOrder.firstVoucherSerial || ''}-${
                            voucherOrder.lastVoucherSerial || ''
                          }`}
                    </h3>
                  </Row>
                  <Row>
                    <FormLabel className="caption4">
                      {t('containers.order_details.vouchers_valid_until')}
                    </FormLabel>
                    <h3 className="small">
                      {Moment(voucherOrder.vouchersValidUntil).format(
                        VoucherOrderHistoryDateFormat
                      )}
                    </h3>
                  </Row>
                </Col>
              </Container>
            </div>
          )}
        </LoadingContainer>
      </div>
    </NavigationHeader>
  );
};

OrderDetails.propTypes = {
  voucherOrder: PropTypes.object,
  isError: PropTypes.bool.isRequired,
  loadVoucherOrderDetails: PropTypes.func.isRequired,
  match: PropTypes.any.isRequired,
  goToPage: PropTypes.func.isRequired
};

OrderDetails.defaultProps = {
  voucherOrder: null
};

const mapStateToProps = state => ({
  voucherOrder: voucherOrderSelector(state),
  isError: isErrorSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ ...apiActions, ...navigationActions }, dispatch);

const ConnectedOrderDetails = connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderDetails);

export { ConnectedOrderDetails as OrderDetails };
