import React from 'react';
import { useTranslation } from 'react-i18next';
import { PagePanel, Box, Button, EmailIcon } from 'edenred-ui';
import { useDispatch } from 'react-redux';

import { EmptyState } from '@epi-shared/components';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';

export const LinkSentMessage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const goToHomePage = () => {
    dispatch(goToPage(routerPaths.home));
  };

  return (
    <PagePanel>
      <EmptyState
        icon={<EmailIcon />}
        title={t('email_verification.request_new_link_success')}
      >
        <Box mt={6.5} mb={20}>
          <Button id="go-to-homepage" onClick={goToHomePage}>
            {t('email_verification.action_btn')}
          </Button>
        </Box>
      </EmptyState>
    </PagePanel>
  );
};
