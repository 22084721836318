import { setStartDate, setEndDate } from './ReportsTabHelpers';

export const REPORTS_INITAIL_DATA_LOADED = 'REPORTS_INITIAL_DATA_LOADED';
export const REQUEST_DOWNLOAD_REPORT = 'REQUEST_DOWNLOAD_REPORT';
export const REPORT_DOWNLOADED = 'REPORT_DOWNLOADED';
export const REPORT_DOWNLOAD_FAILED = 'REPORT_DOWNLOAD_FAILED';
export const SET_COLUMNS = 'SET_COLUMNS';
export const SET_DATA_SCOPE = 'SET_DATA_SCOPE';
export const SET_DOWNLOAD_AS = 'SET_DOWNLOAD_AS';
export const SET_DATE_RANGE_MODE = 'SET_DATE_RANGE_MODE';
export const SET_PICKER_DATE_RANGE = 'SET_PICKER_DATE_RANGE';
export const TOGGLE_BENEFIT_TYPE = 'TOGGLE_BENEFIT_TYPE';

export const dateRangeModes = {
  datePicker: 'datePicker',
  currentMonth: 'currentMonth',
  lastMonth: 'lastMonth'
};

export function reportsInitialDataLoaded(initialData) {
  return {
    type: REPORTS_INITAIL_DATA_LOADED,
    payload: initialData
  };
}

export function requestDownloadReport() {
  return {
    type: REQUEST_DOWNLOAD_REPORT
  };
}

export function reportDownloaded(report) {
  return {
    type: REPORT_DOWNLOADED,
    payload: report
  };
}

export function reportDownloadFailed() {
  return {
    type: REPORT_DOWNLOAD_FAILED
  };
}

export function setColumns(columnId) {
  return {
    type: SET_COLUMNS,
    payload: columnId
  };
}

export function setDataScope(columnGroupName) {
  return {
    type: SET_DATA_SCOPE,
    payload: columnGroupName
  };
}

export function setDownloadAs(format) {
  return {
    type: SET_DOWNLOAD_AS,
    payload: format
  };
}

export function setDateRangeMode(mode) {
  return {
    type: SET_DATE_RANGE_MODE,
    payload: mode
  };
}

export function setPickerDateRange(from, to) {
  return {
    type: SET_PICKER_DATE_RANGE,
    payload: { from, to }
  };
}

export function toggleBenefitType(benefitName) {
  return {
    type: TOGGLE_BENEFIT_TYPE,
    payload: benefitName
  };
}

let newDataScope;
export default function reportsTabReducer(state, action) {
  switch (action.type) {
    case REPORTS_INITAIL_DATA_LOADED:
      return {
        ...state,
        isLoading: false,
        activeBenefits: action.payload.map(b => {
          return { name: b, checked: true };
        })
      };
    case SET_COLUMNS:
      return {
        ...state,
        columns: state.columns.map(column => {
          if (column.id === action.payload) {
            return { ...column, active: !column.active };
          }
          return column;
        })
      };
    case SET_DATA_SCOPE:
      newDataScope = { ...state.dataScope };
      newDataScope[action.payload] = !newDataScope[action.payload];
      return {
        ...state,
        dataScope: newDataScope
      };
    case SET_DOWNLOAD_AS:
      return {
        ...state,
        downloadAs: action.payload
      };
    case SET_DATE_RANGE_MODE:
      return {
        ...state,
        dateRangeMode: action.payload,
        startDate: setStartDate(action.payload),
        endDate: setEndDate(action.payload)
      };
    case SET_PICKER_DATE_RANGE:
      return {
        ...state,
        pickerStartDate: action.payload.from,
        pickerEndDate: action.payload.to
      };
    case TOGGLE_BENEFIT_TYPE:
      return {
        ...state,
        activeBenefits: state.activeBenefits.map(b => {
          if (b.name === action.payload) {
            return { ...b, checked: !b.checked };
          }
          return b;
        })
      };
    case REQUEST_DOWNLOAD_REPORT:
      return {
        ...state,
        requestDownloadReport: true
      };
    case REPORT_DOWNLOADED:
      return {
        ...state,
        requestDownloadReport: false,
        report: action.payload
      };
    case REPORT_DOWNLOAD_FAILED:
      return {
        ...state,
        requestDownloadReport: false,
        report: null
      };
    default:
      return state;
  }
}
