import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Col } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Button, ButtonSize, PagePanel } from 'edenred-ui';
import { useForm, FormProvider } from 'react-hook-form';

import { FormFields } from '@epi-forms/helpers';
import { registeredVoucherOrderAction } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.slice';
import { getOrderData } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.selectors';

import { formatCurrency } from '../../helpers/numeral';
import { orderSummary as orderSummarySelector } from '../../selectors/voucherOrder';
import { getAvailableVouchersByType } from '../../selectors/api';
import {
  getVoucherTypeKey,
  getFaceValueChangedData,
  getVoucherReorderDetails
} from '../../selectors/voucherReorder';
import { getApplicationLocale } from '../../selectors/application';
import {
  getMaxOrderTotalAmount,
  getVoucherMinimumQuantity
} from '../../selectors/settings';
import { VoucherTypes } from '../../constants/voucherTypes';
import { routerPaths } from '../../constants/routerPaths';
import { FormConstants as forms } from '../../constants/forms';
import { VoucherQuantity } from '../../forms/VoucherQuantity/VoucherQuantity';
import { NavigationHeader } from '../../components/NavigationHeader/NavigationHeader';
import { BenefitSummaryContent } from '../../components/BenefitSummaryContent/BenefitSummaryContent';
import * as voucherReorderActions from '../../actions/voucherReorder';
import * as apiActions from '../../actions/api';
import * as navigationActions from '../../actions/navigation';

import './OrderAmount.scss';

function OrderAmount({
  i18n,
  vouchers,
  orderSummary,
  voucherTypeKey,
  locale,
  faceValueChangingInfo,
  maxOrderTotalAmount,
  voucherMinimumQuantity,
  anyTouched,
  goToPage,
  match
}) {
  const dispatch = useDispatch();
  const orderData = useSelector(getOrderData);

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.VoucherQuantity]: 0,
      [FormFields.VoucherId]: '',
      [FormFields.VoucherWorth]: 0
    }
  });
  const { handleSubmit, watch, setValue } = form;
  const voucherIdValue = watch(FormFields.VoucherId);

  useEffect(() => {
    setValue(FormFields.VoucherQuantity, orderData.totalQuantity);
    setValue(FormFields.VoucherId, orderData.voucherId);
    setValue(FormFields.VoucherWorth, orderData.faceValue);
  }, []);

  useEffect(() => {
    setValue(
      FormFields.VoucherWorth,
      (vouchers || []).find(v => v.id === voucherIdValue)?.value
    );
  }, [voucherIdValue]);

  const onSubmit = values => {
    dispatch(
      registeredVoucherOrderAction.addOrderInformation({
        totalQuantity: Number(values[FormFields.VoucherQuantity]),
        voucherId: values[FormFields.VoucherId],
        faceValue: values[FormFields.VoucherWorth]
      })
    );
    goToPage(routerPaths.voucherReorder, {
      params: {
        voucherType: VoucherTypes[voucherTypeKey],
        orderId: match.params.orderId
      }
    });
  };

  const useCalculator = VoucherTypes[voucherTypeKey] === VoucherTypes.Lunch;

  return (
    <NavigationHeader
      withBackButton
      title={i18n.t('containers.edit_order_amount.title')}
    >
      <FormProvider {...form}>
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
          <PagePanel
            className="OrderAmount"
            title={i18n.t(`containers.voucher_order.header`)}
          >
            <Col md={12} className="order-amount-order-calculator-container">
              <VoucherQuantity
                voucherTypeKey={voucherTypeKey}
                vouchers={vouchers}
                useCalculator={useCalculator}
                locale={locale}
                maxOrderTotalAmount={maxOrderTotalAmount}
                voucherMinimumQuantity={voucherMinimumQuantity}
                newFaceValueMessage={
                  (faceValueChangingInfo &&
                    !anyTouched &&
                    i18n.t(
                      'forms.voucher_quantity.voucher_face_value_changed',
                      {
                        oldValue: formatCurrency(
                          faceValueChangingInfo.oldValue
                        ),
                        newValue: formatCurrency(faceValueChangingInfo.newValue)
                      }
                    )) ||
                  undefined
                }
              />
            </Col>
            <Col md={12}>
              <hr />
            </Col>
            <Col md={12} className="mb-10 order-amount-order-details-container">
              <BenefitSummaryContent
                voucherTypeKey={voucherTypeKey}
                orderSummary={orderSummary}
              />
            </Col>
          </PagePanel>
          <Button
            id="order-amount-submit-btn"
            type="submit"
            size={ButtonSize.Medium}
            className="pull-right"
          >
            {i18n.t('containers.edit_order_amount.update_amount')}
          </Button>
        </form>
      </FormProvider>
    </NavigationHeader>
  );
}

OrderAmount.propTypes = {
  match: PropTypes.any.isRequired,
  voucherTypeKey: PropTypes.string.isRequired,
  vouchers: PropTypes.array.isRequired,
  orderSummary: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
  goToPage: PropTypes.func.isRequired,
  faceValueChangingInfo: PropTypes.object,
  i18n: PropTypes.object.isRequired,
  maxOrderTotalAmount: PropTypes.number.isRequired,
  anyTouched: PropTypes.bool.isRequired,
  voucherMinimumQuantity: PropTypes.number.isRequired
};

OrderAmount.defaultProps = { faceValueChangingInfo: undefined };

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    { ...voucherReorderActions, ...apiActions, ...navigationActions },
    dispatch
  );

const mapStateToProps = state => ({
  locale: getApplicationLocale(state),
  voucherTypeKey: getVoucherTypeKey(state),
  vouchers: getAvailableVouchersByType(getVoucherTypeKey(state))(state),
  faceValueChangingInfo: getFaceValueChangedData(state),
  orderSummary: orderSummarySelector(forms.EDIT_VOUCHER_QUANTITY_FORM)(state),
  initialValues: {
    voucher_details: getVoucherReorderDetails(state)
  },
  maxOrderTotalAmount: getMaxOrderTotalAmount(state),
  voucherMinimumQuantity: getVoucherMinimumQuantity(state)
});

const ConnectedOrderAmount = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(OrderAmount));

export { ConnectedOrderAmount as OrderAmount };
