import { useSelector } from 'react-redux';

import { getMainAvailableBalance } from '@epi-selectors/companyAccounts';
import { formatCurrencyDecimal, getNumber } from '@epi-helpers/numeral';
import {
  getFeesVatPercentage,
  getFeePercentage
} from '@epi-selectors/settings';

export const useTopupSummary = (
  beneficiariesAmount,
  topupPerPerson,
  employeesToTopup,
  showTable
) => {
  const balance = useSelector(getMainAvailableBalance);
  const vat = useSelector(getFeesVatPercentage);
  const fee = useSelector(getFeePercentage);
  const numeralTopupPerPerson = getNumber(topupPerPerson);

  const employeesToCount =
    showTable && employeesToTopup.length
      ? employeesToTopup.length
      : beneficiariesAmount;
  const feePerEmployee = Math.round(numeralTopupPerPerson * fee) / 100;
  const feeTotal = getNumber((feePerEmployee * employeesToCount).toFixed(2));
  const vatPerEmployee = Math.round(feePerEmployee * vat) / 100;
  const vatTotal = vatPerEmployee * employeesToCount;
  const topupTotal = topupPerPerson
    ? getNumber((numeralTopupPerPerson * employeesToCount).toFixed(2))
    : 0;
  const sumTotal = getNumber(
    (numeralTopupPerPerson * employeesToCount + feeTotal + vatTotal).toFixed(2)
  );
  const remainingBalance = balance - sumTotal;

  const formattedTopup = formatCurrencyDecimal(topupTotal);
  const formattedFee = formatCurrencyDecimal(feeTotal);
  const formattedVat = formatCurrencyDecimal(vatTotal);
  const formattedTopupWithFees = formatCurrencyDecimal(sumTotal);
  const formattedRemainingBalance = formatCurrencyDecimal(remainingBalance);

  return {
    formattedTopup,
    formattedFee,
    formattedVat,
    formattedTopupWithFees,
    formattedRemainingBalance,
    remainingBalance,
    fee,
    vat
  };
};
