import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isError: false,
  data: null,
  isLoading: true
};

const companyMetadataSlice = createSlice({
  name: 'companyMetadata',
  initialState,
  reducers: {
    addMetadata: (state, { payload }) => {
      state.data = { ...state.data, ...payload };
      state.isError = false;
      state.isLoading = false;
    },
    error: state => {
      state.isError = false;
      state.isLoading = false;
    }
  }
});

export const { reducer, actions: companyMetadataActions } =
  companyMetadataSlice;
