import { ApiConstants } from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  isLoading: false,
  loaded: false
});

export default createReducer(getInitialState, {
  [`${ApiConstants.GET_HAS_ORDERS}_REQUEST`]: () => ({
    ...getInitialState(),
    isLoading: true
  }),
  [`${ApiConstants.GET_HAS_ORDERS}_SUCCESS`]: () => ({
    loaded: true,
    isLoading: false
  }),
  [`${ApiConstants.GET_HAS_ORDERS}_FAILURE`]: () => ({
    ...getInitialState()
  })
});
