import React from 'react';

import LanguageEN from '@epi-assets/images/LanguageEN.png';
import LanguageFI from '@epi-assets/images/LanguageFI.png';
import { INavbarLanguageID } from '@epi-models/core/Navbar';

export function NavbarLanguageFlag({ languageId }: INavbarLanguageID) {
  const getImgSrcByLanguage = () => {
    switch (languageId) {
      case 'en':
        return LanguageEN;
      case 'fi':
        return LanguageFI;
      default:
        return undefined;
    }
  };

  const imgSrc = getImgSrcByLanguage();

  return <img src={imgSrc} width={20} height={15} />;
}
