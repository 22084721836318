import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { createRules, FormFields, useOnBlurTrim } from '@epi-forms/helpers';
import {
  companyName,
  maxLength,
  required as requiredValidation
} from '@epi-helpers/formHelpers/validators';

export function CompanyNameField({ disabled, readOnly, fieldName, required }) {
  const name = fieldName || FormFields.CompanyName;
  const { control, unregister } = useFormContext();
  const { t } = useTranslation();
  const onBlurTrim = useOnBlurTrim(name);
  const validators = [requiredValidation, maxLength(50), companyName];
  const rules = disabled || readOnly ? [] : createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  useEffect(() => {
    if (readOnly) {
      unregister(name);
    }
  }, [readOnly]);

  return (
    <TextField
      label={t('controls.company_name_label')}
      required={required}
      disabled={disabled}
      readOnly={readOnly}
      value={value}
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onBlur={onBlurTrim}
      onChange={setValue}
    />
  );
}

CompanyNameField.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  fieldName: PropTypes.string,
  required: PropTypes.bool
};

CompanyNameField.defaultProps = {
  disabled: false,
  readOnly: false,
  required: true
};
