import React from 'react';
import { useSelector } from 'react-redux';

import { Newsfeed as EdenredUiNewsfeed, Box } from 'edenred-ui';

import paymentLimitsImg from '@epi-assets/images/Edenred-EMP-banner-payment-limits.jpg';
import delicardImg from '@epi-assets/images/Delicard-EMP-banner-summer.jpg';
import taxationImg from '@epi-assets/images/Edenred-EMP-banner-benefit-taxation.jpg';
import { homeBanner } from '@epi-helpers/homeBanner';
import { getApplicationLanguageId } from '@epi-selectors/application';

export function Newsfeed() {
  const language = useSelector(getApplicationLanguageId);
  const banners = homeBanner(paymentLimitsImg, delicardImg, taxationImg).filter(
    item => item.content.locale === language
  );

  return (
    <Box mt={3} mx={-5}>
      <EdenredUiNewsfeed banners={banners} />
    </Box>
  );
}
