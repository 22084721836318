import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  isVoucherFaceValueUnavailable,
  isError as isErrorSelector
} from '@epi-selectors/voucherReorder';
import { join } from '../helpers/UrlHelpers';
import { reorderData as reorderDataLoadingSelector } from '../selectors/loading';
import { routerPaths } from '../constants/routerPaths';
import { VoucherReOrder } from '../containers/VoucherOrder/VoucherReOrder';
import { LoadingData } from './LoadingData';
import { ConditionalRedirect } from './ConditionalRedirect';
import * as voucherReorderActions from '../actions/voucherReorder';

export class ReorderRoute extends PureComponent {
  render() {
    const {
      path,
      match: {
        params: { orderId }
      }
    } = this.props;
    return (
      <LoadingData
        {...this.props}
        loadAction={() => voucherReorderActions.loadReorderData(orderId)}
        loadingSelector={state => reorderDataLoadingSelector(state, orderId)}
        errorSelector={state => isErrorSelector(state)}
      >
        <ConditionalRedirect
          redirect={`${join(path, routerPaths.voucherReorderEditAmount)}`}
          redirectSelector={isVoucherFaceValueUnavailable}
        >
          <VoucherReOrder />
        </ConditionalRedirect>
      </LoadingData>
    );
  }
}

ReorderRoute.propTypes = {
  match: PropTypes.object,
  path: PropTypes.string.isRequired
};
