import React from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';

import { createRules, FormFields, useOnBlurTrim } from '@epi-forms/helpers';
import {
  required,
  integer,
  minValue,
  maxValue
} from '@epi-helpers/formHelpers/validators';

export function BeneficiariesAmountField({
  disabled,
  readOnly,
  name,
  maxOrderTotalAmount
}) {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const onBlurTrim = useOnBlurTrim(name);
  const minValue1 = minValue(1);
  const maxValueTotalAmountValidator = maxValue(maxOrderTotalAmount);
  const validators = [
    required,
    integer,
    minValue1,
    maxValueTotalAmountValidator
  ];
  const rules = createRules(validators);
  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  return (
    <TextField
      label={t('forms.benefit_calculator.employees_number')}
      value={value}
      readOnly={readOnly}
      disabled={disabled}
      fullWidth
      id={name}
      error={!!error}
      helperText={error?.message}
      inputRef={ref}
      onBlur={onBlurTrim}
      onChange={setValue}
    />
  );
}

BeneficiariesAmountField.propTypes = {
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  name: PropTypes.string,
  maxOrderTotalAmount: PropTypes.number
};

BeneficiariesAmountField.defaultProps = {
  disabled: false,
  readOnly: false,
  name: FormFields.BeneficiariesAmount
};
