import React from 'react';
import PropTypes from 'prop-types';
import { Box, Table } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { currencyCommaConvert } from '@epi-helpers/numeral';

import { StatusDot } from '../../TopupReports.styles';
import { reportStatusesColors } from '../../utils';

export const TopupReportsItemsTable = ({ visibleReportItems }) => {
  const { t } = useTranslation();

  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Cell width="16%">
            {t('containers.deposit_money.reports_tab.name')}
          </Table.Cell>
          <Table.Cell width="25%">
            {t('containers.deposit_money.reports_tab.personal_id')}
          </Table.Cell>
          <Table.Cell width="15%">
            {t('containers.deposit_money.reports_tab.status')}
          </Table.Cell>
          <Table.Cell width="10%" align="right">
            {t('containers.deposit_money.reports_tab.load')}
          </Table.Cell>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {visibleReportItems?.map((item, i) => (
          <Table.Row key={item.personalId + i}>
            <Table.Cell width="47%">
              {item.firstName} {item.lastName}
            </Table.Cell>
            <Table.Cell width="20%">{item.personalId}</Table.Cell>
            <Table.Cell width="22%">
              <Box display="flex" alignItems="center">
                <StatusDot $dotColor={reportStatusesColors[item.status]} />
                {t(
                  `containers.deposit_money.reports_tab.statuses.${item.status}`
                )}
              </Box>
            </Table.Cell>
            <Table.Cell width="11%" align="right">
              {currencyCommaConvert(item.amount)}
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
};

TopupReportsItemsTable.propTypes = {
  visibleReportItems: PropTypes.array
};
