import { getNumber } from '../numeral';

export const integer = value => {
  if (/^[-+]?(\d+|Infinity)$/.test(value)) {
    return Number(value);
  }
  return Number(value.replace(/\D/g, ''));
};

export const decimal =
  (numberOfDecimals, roundingFunction = Math.round) =>
  value => {
    const number = getNumber(value);
    return (
      roundingFunction(number * 10 ** numberOfDecimals) / 10 ** numberOfDecimals
    );
  };

export const phoneNumber = value =>
  value ? `+${value.replace(/\D/g, '')}` : '';

export const toUpper = value =>
  value && (typeof value === 'string' || value instanceof String)
    ? value.toLocaleUpperCase()
    : '';

export {
  phoneNumber as normalizePhoneNumber,
  decimal as normalizeDecimal,
  integer as normalizeInteger,
  toUpper as upperCase
};
