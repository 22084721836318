import { put, call } from 'redux-saga/effects';

import { companyMetadataActions } from '@epi-store/companyMetadata/companyMetadata.slice';

import { getCompanyMetadata } from '../../repositories/CompanyAccountsRepository';

export default function* loadCompanyMetadata() {
  try {
    const metadata = yield call(getCompanyMetadata);

    yield put(companyMetadataActions.addMetadata(metadata));
  } catch (error) {
    yield put(companyMetadataActions.error());
  }
}
