export const availableLanguages = [
  {
    id: 'en',
    name: 'English',
    locale: 'en',
    cultureCode: 'en-US'
  },
  {
    id: 'fi',
    name: 'Suomeksi',
    locale: 'fi',
    default: true,
    cultureCode: 'fi-FI'
  }
];
