import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonMode, Box } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

export function BenefitGroupDetailActions({ onCancel, saveDisabled, onClick }) {
  const { t } = useTranslation();

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" px={3}>
      <Box px={1.5}>
        <Button
          id="benefit-group-detail-cancel"
          onClick={onCancel}
          mode={ButtonMode.Link}
        >
          {t('components.manage_benefit_lunch.detail_action_cancel')}
        </Button>
      </Box>
      <Box px={1.5}>
        <Button
          id="benefit-group-detail-save"
          mode={ButtonMode.Primary}
          type="submit"
          disabled={saveDisabled}
          onClick={onClick}
        >
          {t('components.manage_benefit_lunch.detail_action_save')}
        </Button>
      </Box>
    </Box>
  );
}

BenefitGroupDetailActions.propTypes = {
  saveDisabled: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};
