import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';

export const getInitialState = () => ({
  isError: false,
  active: false
});

export default createReducer(getInitialState, {
  [`${ApiConstants.SIGN_UP}_REQUEST`]: () => ({
    active: true,
    isError: false
  }),
  [`${ApiConstants.SIGN_UP}_FAILURE`]: () => ({
    isError: true
  })
});
