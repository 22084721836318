import config from '@epi-config';
import { get } from '@epi-helpers/FetchHelpers';

export const topupLoadApiUrl = `${config.apiHost}/api/top-up-load`;

export const topupEmployeesApiUrl = benefitType =>
  `${topupLoadApiUrl}/employees-top-ups/${benefitType}`;

export const getTopupInProgressRequest = (abortController = null) => {
  return get(
    `${topupLoadApiUrl}/in-progress`,
    null,
    null,
    abortController?.signal
  );
};

export const getTopupEmployeesRequest = (
  benefitType,
  abortController = null
) => {
  return get(
    topupEmployeesApiUrl(benefitType),
    null,
    null,
    abortController?.signal
  );
};

export const getTopupReportsRequest = (abortController = null) => {
  return get(`${topupLoadApiUrl}/summary`, null, null, abortController?.signal);
};

export const getTopupReportDetailsRequest = (
  topupId,
  abortController = null
) => {
  return get(
    `${topupLoadApiUrl}/${topupId}`,
    null,
    null,
    abortController?.signal
  );
};
