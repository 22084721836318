import { orderBy } from 'lodash';

// Scroll to the first element with the specified classname
export const scrollTo = className => {
  const elements = document.getElementsByClassName(className);

  if (elements.length > 0) {
    orderBy(elements, 'scrollTop', 'asc')[0].scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }
};

export const scrollPageTop = () => {
  const mainContainer = document.getElementsByClassName('sidebar-content')[0];
  // FYI: ScrollTo is not implemented for IE/Edge/Safari, we use a polyfill
  mainContainer.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
};

export const scrollToInvalidField = () => {
  setTimeout(() => scrollTo('has-error'), 0); // Run after rendering
};
