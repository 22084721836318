import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonSize } from 'edenred-ui';
import { withTranslation } from 'react-i18next';

class OrderButton extends PureComponent {
  render() {
    const { i18n, isLoading, currentPage, totalPages, onClick, disabled } =
      this.props;
    return (
      <Button
        id="order-btn"
        className="pull-right"
        type="submit"
        aria-hidden
        disabled={isLoading || disabled}
        onClick={() => onClick()}
        size={ButtonSize.Medium}
      >
        {currentPage < totalPages
          ? i18n.t('containers.voucher_order.continue')
          : i18n.t('containers.voucher_order.confirm')}
      </Button>
    );
  }
}

OrderButton.propTypes = {
  isLoading: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  i18n: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool.isRequired
};

OrderButton.defaultProps = {
  isLoading: false,
  onClick: () => {}
};

const OrderButtonWthI18n = withTranslation()(OrderButton);

export { OrderButtonWthI18n as OrderButton };
