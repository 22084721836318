import React, { useState, MouseEvent } from 'react';

import { Button, ButtonSize, ButtonMode } from 'edenred-ui';

import { IButtonProps } from '@epi-models/common';

export const SingleClickButton = ({
  onClick,
  ...buttonProps
}: IButtonProps) => {
  const [inCallback, setInCallback] = useState(false);
  const singleOnClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    if (!inCallback && onClick) {
      setInCallback(true);
      onClick(e);
      setTimeout(() => {
        setInCallback(false);
      }, 0);
    }
  };
  return (
    <Button
      {...buttonProps}
      type="button"
      onClick={singleOnClick}
      size={ButtonSize.Medium}
      mode={ButtonMode.Secondary}
    />
  );
};
