import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TextField } from 'edenred-ui';
import { createRules, FormFields } from '@epi-forms/helpers';
import { required } from '@epi-helpers/formHelpers/validators';

export function ConfirmPasswordField({ label, field = FormFields.Password }) {
  const name = FormFields.ConfirmPassword;
  const { control, getValues, watch, trigger } = useFormContext();
  const { t } = useTranslation();

  const isPasswordConfirmed = confirmedPassword =>
    confirmedPassword === getValues(field)
      ? undefined
      : t('validation_messages.confirm_password');

  const validators = [required, isPasswordConfirmed];
  const rules = createRules(validators);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error, isDirty }
  } = useController({
    name,
    control,
    rules
  });

  useEffect(() => {
    if (isDirty) {
      trigger(FormFields.ConfirmPassword);
    }
  }, [watch(field)]);

  return (
    <TextField
      type="password"
      label={label || t('controls.confirm_password_label')}
      required
      value={value}
      fullWidth
      id={name}
      error={!!error}
      helperText={error?.message || <>&nbsp;</>}
      inputRef={ref}
      onChange={setValue}
    />
  );
}

ConfirmPasswordField.propTypes = {
  label: PropTypes.string,
  field: PropTypes.string
};
