import config from '@epi-config';
import { post } from '@epi-helpers/FetchHelpers';
import { reduxStore } from '../store-configuration';
import Moment from './MomentExtensions';
import { shouldRefreshToken } from './AuthorizeHelper';
import { ApiConstants } from '../constants/actions';
import storage from './StorageHelper';

async function tokenRefresher() {
  const storedToken = storage.get('rememberMe');
  const { login } = reduxStore.getState();
  const { refreshToken } = login;

  reduxStore.dispatch({ type: `${ApiConstants.REFRESH_TOKEN}_REQUEST` });
  try {
    const response = await post(`${config.apiHost}/api/login/refresh`, {
      refreshToken
    });

    const newValidTo = Moment().unix() + response.expiresIn;
    const action = {
      type: `${ApiConstants.REFRESH_TOKEN}_SUCCESS`,
      payload: {
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        validTo: newValidTo
      }
    };

    if (storedToken?.refreshToken)
      storage.set('rememberMe', {
        ...storedToken,
        accessToken: response.accessToken,
        refreshToken: response.refreshToken,
        expiresIn: response.expiresIn,
        tokenType: response.tokenType
      });

    reduxStore.dispatch(action);
  } catch (ex) {
    if (ex.content?.error === 'invalid_grant') {
      const logoutAction = {
        type: `${ApiConstants.LOGOUT_ACTION}_REQUEST`,
        sessionExpired: true
      };
      reduxStore.dispatch(logoutAction);
    }
  }
}

export default function (refreshInterval) {
  setInterval(async () => {
    const { login } = reduxStore.getState();
    const {
      refreshingToken,
      refreshToken,
      validTo,
      expiresIn,
      isAuthenticated
    } = login;

    if (refreshingToken || !refreshToken || !isAuthenticated) {
      return;
    }

    const shouldRefresh = shouldRefreshToken(validTo, expiresIn);

    if (!shouldRefresh) {
      return;
    }

    tokenRefresher();
  }, refreshInterval);
}

export async function refreshRememberedUser() {
  const storedToken = storage.get('rememberMe');

  if (storedToken) {
    await tokenRefresher();
  }
}
