import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useTheme } from 'styled-components';
import { Box, Link } from 'edenred-ui';

export function RecaptchaTerms() {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box pt={2} textAlign="center" color={theme.altGray1}>
      <Trans
        defaults={t('recaptcha.terms_and_conditions')}
        components={{
          privacy: (
            <Link
              style={{ color: theme.altGray1 }}
              target="_blank"
              href={t('recaptcha.privacy_link')}
            />
          ),
          terms: (
            <Link
              style={{ color: theme.altGray1 }}
              target="_blank"
              href={t('recaptcha.terms_link')}
            />
          )
        }}
      />
    </Box>
  );
}
