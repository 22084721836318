import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Menu } from 'edenred-ui';

import { downloadInvoice } from '@epi-actions/api';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { VoucherTypes } from '@epi-constants/voucherTypes';

function VoucherOrderHistoryActions({
  voucherOrder,
  anchorEl,
  closeMenu,
  dispatch
}) {
  const { t } = useTranslation();

  const downloadVoucherOrderInvoice = () => {
    dispatch(downloadInvoice({ voucherOrderId: voucherOrder.id }));
    closeMenu();
  };

  const goToOrderDetails = () => {
    dispatch(
      goToPage(routerPaths.orderDetails, {
        params: {
          voucherType: VoucherTypes[voucherOrder.voucherType],
          voucherOrderId: voucherOrder.id
        }
      })
    );
    closeMenu();
  };

  const goToVoucherReorder = () => {
    dispatch(
      goToPage(routerPaths.voucherReorder, {
        params: {
          voucherType: VoucherTypes[voucherOrder.voucherType],
          orderId: voucherOrder.id
        }
      })
    );
    closeMenu();
  };

  return (
    <Menu
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={closeMenu}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
    >
      <Menu.Item
        onClick={downloadVoucherOrderInvoice}
        id="order-history_download"
      >
        {t('paper_vouchers.order_history.action_download')}
      </Menu.Item>
      <Menu.Item onClick={goToOrderDetails} id="order-history_details">
        {t('paper_vouchers.order_history.action_details')}
      </Menu.Item>
      <Menu.Item onClick={goToVoucherReorder} id="order-history_reorder">
        {t('paper_vouchers.order_history.action_reorder')}
      </Menu.Item>
    </Menu>
  );
}

VoucherOrderHistoryActions.propTypes = {
  voucherOrder: PropTypes.object,
  anchorEl: PropTypes.object,
  closeMenu: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

VoucherOrderHistoryActions.defaultProps = {
  voucherOrder: null,
  anchorEl: null
};

const ConnectedVoucherOrderHistoryActions = connect()(
  VoucherOrderHistoryActions
);

export { ConnectedVoucherOrderHistoryActions as VoucherOrderHistoryActions };
