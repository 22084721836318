import { useContext } from 'react';

import { ToastContext } from './ToastContext';

export const useToastContext = () => {
  const context = useContext(ToastContext);

  if (context === null) {
    throw new Error('useToastContext is not accessible');
  }
  return context;
};
