import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';

export const getInitialState = () => ({
  voucherOrder: null,
  noPersistance: true,
  isError: false
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_VOUCHER_ORDER_DETAILS}_REQUEST`]: () => ({
    voucherOrder: null,
    isError: false
  }),

  [`${ApiConstants.LOAD_VOUCHER_ORDER_DETAILS}_SUCCESS`]: (
    state,
    { voucherDetails }
  ) => ({
    voucherOrder: { ...voucherDetails } || {},
    isError: false
  }),
  [`${ApiConstants.LOAD_VOUCHER_ORDER_DETAILS}_FAILURE`]: () => ({
    voucherOrder: null,
    isError: true
  })
});
