import { call, put } from 'redux-saga/effects';

import { showNotification } from '@epi-actions/overlays';
import { ApiConstants } from '@epi-constants/actions';
import {
  fetchCompanyBenefitGroups,
  postCompanyBenefitGroup,
  updateCompanyBenefitGroup
} from '@epi-repositories/BenefitGroupRepository';

export function* getBenefitGroups({ benefitType }) {
  try {
    const benefitGroupsResponse = yield call(
      fetchCompanyBenefitGroups,
      benefitType
    );

    yield put({
      type: `${ApiConstants.GET_BENEFIT_GROUPS}_SUCCESS`,
      payload: benefitGroupsResponse
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.GET_BENEFIT_GROUPS}_FAILURE`,
      payload: ex
    });
  }
}

export function* editCompanyBenefitGroup({ payload, benefitType }) {
  try {
    yield call(updateCompanyBenefitGroup, payload);

    const benefitGroupsResponse = yield call(
      fetchCompanyBenefitGroups,
      benefitType
    );

    yield put({
      type: `${ApiConstants.UPDATE_BENEFIT_GROUP}_SUCCESS`,
      payload: benefitGroupsResponse
    });

    yield put(
      showNotification(
        { message: 'messages.successful_update_benefit_group' },
        'success'
      )
    );
  } catch (ex) {
    yield put({
      type: `${ApiConstants.UPDATE_BENEFIT_GROUP}_FAILURE`,
      payload: ex
    });
  }
}

export function* createCompanyBenefitGroup({ payload, benefitType }) {
  try {
    yield call(postCompanyBenefitGroup, payload);

    const benefitGroupsResponse = yield call(
      fetchCompanyBenefitGroups,
      benefitType
    );

    yield put({
      type: `${ApiConstants.CREATE_BENEFIT_GROUP}_SUCCESS`,
      payload: benefitGroupsResponse
    });

    yield put(
      showNotification(
        { message: 'messages.successful_create_benefit_group' },
        'success'
      )
    );
  } catch (ex) {
    yield put({
      type: `${ApiConstants.CREATE_BENEFIT_GROUP}_FAILURE`,
      payload: ex
    });
  }
}
