import tokeys from 'tokeys';

export const SystemRoles = {
  VoucherManager: 'voucherManager',
  Signatory: 'signatory'
};

export const Roles = tokeys([
  SystemRoles.VoucherManager,
  SystemRoles.Signatory
]);
