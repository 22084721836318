import { put, call, select } from 'redux-saga/effects';
import {
  changePassword,
  updateUserInfo,
  getUserInfo
} from '../../repositories/ProfileRepository';
import { getUserName } from '../../selectors/profile';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';
import { Notify } from '../../helpers/NotificationHelper';
import { ApiConstants, NavigationConstants } from '../../constants/actions';
import { getLoginState } from '../../selectors/login';
import { callWithPassword } from '../overlays';
import * as loginActions from '../../actions/login';

export function* ChangePassword(action) {
  const username = yield select(getUserName);
  try {
    yield call(changePassword, action.payload);
    yield put({
      type: `${ApiConstants.CHANGE_PASSWORD}_SUCCESS`
    });
    yield call(Notify, 'forms.change_password.confirmation_message');
    yield put(
      loginActions.getLoginAction(username, action.payload.newPassword, {
        isAutoLogin: true,
        autoRedirect: false
      })
    );
  } catch (error) {
    yield put({
      type: `${ApiConstants.CHANGE_PASSWORD}_FAILURE`,
      payload: error
    });
    const login = yield select(getLoginState);
    if (error.name === UnauthorizedErrorName && login.isAuthenticated) {
      yield put({
        type: `${ApiConstants.LOGOUT_ACTION}_REQUEST`,
        sessionExpired: true
      });
    }
  }
}

export function* UpdateUserInfo({ payload }) {
  const success = payload.isUserImpersonate
    ? yield call(updateUserInfo, payload)
    : yield call(
        callWithPassword,
        updateUserInfo,
        payload,
        'containers.user_profile.confirm_password.message'
      );
  if (success) {
    yield put({
      type: `${ApiConstants.UPDATE_USER_INFORMATION}_SUCCESS`,
      payload: payload
    });
    yield call(Notify, 'forms.user_information.confirmation_message');
  } else {
    yield put({
      type: `${ApiConstants.UPDATE_USER_INFORMATION}_FAILURE`,
      payload: new Error('forms.user_information.error_message')
    });

    yield call(Notify, 'forms.user_information.error_message', 'error');
  }
}

export function* GetUserInformation() {
  try {
    const userInfo = yield call(getUserInfo);
    yield put({
      type: `${ApiConstants.GET_USER_INFORMATION}_SUCCESS`,
      userInfo
    });
  } catch (error) {
    if (error.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    } else {
      yield call(Notify, 'forms.user_information.get_user_info_error', 'error');
    }
    yield put({
      type: `${NavigationConstants.GO_TO_PAGE}`,
      url: '/'
    });
  }
}
