import styled from 'styled-components';

export const AsyncEmailFieldStyles = styled.div`
  .field-label {
    display: flex;
    flex-flow: row nowrap;
    &--text {
      white-space: nowrap;
    }
    .spinner-container {
      position: relative;
      bottom: 0.5rem;
      left: -0.5rem;
      .spinner {
        width: 30px;
        height: 30px;
      }
    }
  }
`;
