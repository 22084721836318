import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  selectActiveBenefits,
  selectIsActiveBenefitsLoaded
} from '@epi-selectors/benefitGroupSettings';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { BenefitType } from '@epi-constants/benefitTypes';

import VirikeImg from '@epi-assets/images/Virike.jpg';
import LunariImg from '@epi-assets/images/Lunari.jpg';

export const useBenefits = () => {
  const { t } = useTranslation();
  const activeBenefits = useSelector(selectActiveBenefits);
  const isActiveBenefitsLoaded = useSelector(selectIsActiveBenefitsLoaded);
  const dispatch = useDispatch();

  const handleClickActiveBenefit = type => {
    dispatch(goToPage(routerPaths.manageBenefits + '/' + type));
  };

  const handleClickInactiveBenefit = () => {
    dispatch(goToPage(routerPaths.manageBenefits));
  };

  const benefitsElements = [
    {
      title: t('home.benefits_tile.lounari.title'),
      imgSrc: LunariImg,
      infoElementsArr: [
        t('home.benefits_tile.lounari.description_item_1'),
        t('home.benefits_tile.lounari.description_item_2')
      ],
      type: BenefitType.Lunch
    },
    {
      title: t('home.benefits_tile.virike.title'),
      imgSrc: VirikeImg,
      infoElementsArr: [
        t('home.benefits_tile.virike.description_item_1'),
        t('home.benefits_tile.virike.description_item_2')
      ],
      type: BenefitType.Recreational
    }
  ];

  const activeBenefitsMapper = activeBenefits
    .map(elem => benefitsElements.find(bene => bene.type === elem))
    .map(elem => ({
      ...elem,
      handleClick: () => handleClickActiveBenefit(elem.type)
    }));

  const inactiveBenefitsMapper = benefitsElements
    .filter(elem => !activeBenefitsMapper.find(bene => bene.type === elem.type))
    .map(elem => ({
      ...elem,
      handleClick: handleClickInactiveBenefit
    }));

  return {
    isActiveBenefitsLoaded,
    activeBenefits: activeBenefitsMapper,
    inactiveBenefits: inactiveBenefitsMapper
  };
};
