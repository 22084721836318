import { put } from 'redux-saga/effects';
import { SagaConstants } from '../../constants/actions';

function getActionType(value) {
  return value.replace(/(.{0,30})(_REQUEST|_SUCCESS|_FAILURE|_ABORT)/i, `$1`);
}

export function* setStartLoading(action) {
  const actionType = getActionType(action.type);
  yield put({
    type: `${SagaConstants.START_LOADING}`,
    actionType
  });
}

export function* setEndLoading(action) {
  const actionType = getActionType(action.type);
  yield put({
    type: `${SagaConstants.END_LOADING}`,
    actionType
  });
}

export default { setStartLoading, setEndLoading };
