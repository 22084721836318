const GET_DATA_SUCCESS = 'GET_REQUESTED_LOADS_SUCCESS';
const GET_DATA_FAIL = 'GET_REQUESTED_LOADS_FAIL';

function sortByDateAndFirstName(rows) {
  return rows.sort((a, b) => {
    let sortingResult = new Date(a.requestDate) - new Date(b.requestDate);
    if (sortingResult === 0) {
      sortingResult = a.firstName.localeCompare(b.firstName);
    }
    return sortingResult;
  });
}

export function getDataLoadedAction(requestedLoadsPayload) {
  return {
    type: GET_DATA_SUCCESS,
    data: requestedLoadsPayload
  };
}

export function getDataFailedAction() {
  return {
    type: GET_DATA_FAIL
  };
}

export default function requestedLoadsReducer(state, action) {
  switch (action.type) {
    case GET_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        original: action.data,
        rows: action.data.requestedLoads
          ? sortByDateAndFirstName(action.data.requestedLoads)
          : [],
        totalRequestedLoads: action.data.totalRequestedLoads,
        totalRequestedLoadsAmount: action.data.totalRequestedLoadsAmount,
        currentBalance: action.data.currentBalance,
        totalRows: action.data.length
      };
    case GET_DATA_FAIL:
      return {
        ...state,
        isLoading: false
      };
    default:
      return state;
  }
}
