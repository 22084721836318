import React, { useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { connect, useSelector, useDispatch } from 'react-redux';
import { LogoIcon, ImpersonationClientBox, Box } from 'edenred-ui';

import { generateUrlWithLocale } from '@epi-selectors/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { useMenuDimensions } from '@epi-shared/hooks';
import { getLoginState } from '@epi-selectors/login';
import { getLogoutAction } from '@epi-actions/login';
import { getUserInformation } from '@epi-actions/profile';
import { getUserCompany } from '@epi-actions/api';
import { getCompanyName } from '@epi-selectors/api';
import { getUserName } from '@epi-selectors/profile';
import { INavbarHeading } from '@epi-models/core/Navbar';

const HeadingContainer = styled.div`
  display: flex;
  justify-content: ${({ isMobile }) => (isMobile ? 'center' : 'flex-start')};
  align-items: center;
  height: 100%;
  padding: 20px;

  ${({ isMobile }) =>
    isMobile &&
    css`
      border-left: 1px solid ${({ theme }) => theme.altGray2};
      border-right: 1px solid ${({ theme }) => theme.altGray2};
    `};
`;

const HeadingLink = styled(Link)`
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    text-decoration: none;
  }
`;

const HeadingText = styled.h1`
  margin: 0;
  margin-left: 16px;
  font-size: ${({ theme, isMobile }) =>
    isMobile ? theme.sizeSmall : theme.sizeExtraLarge};
  font-weight: ${({ theme }) => theme.fontSemiBold};
  color: ${({ theme }) => theme.primaryFrontColor};
`;

const alertUser = e => {
  e.preventDefault();
  return '';
};

function NavbarHeading({ generateUrl }: INavbarHeading) {
  const { t } = useTranslation();
  const { isMobile } = useMenuDimensions();
  const { accessToken, isUserImpersonate } = useSelector(getLoginState);
  const { gray10 } = useTheme();
  const dispatch = useDispatch();
  const currentUsername = useSelector(getUserName);
  const companyName = useSelector(getCompanyName);

  const homeUrl = generateUrl(routerPaths.home);

  const handleLogout = () => dispatch(getLogoutAction());

  useEffect(() => {
    if (isUserImpersonate && accessToken && !currentUsername && !companyName) {
      dispatch(getUserInformation());
      dispatch(getUserCompany());
    }
  }, [isUserImpersonate, accessToken, currentUsername, companyName]);

  useEffect(() => {
    if (isUserImpersonate) {
      window.addEventListener('beforeunload', alertUser);

      return () => {
        window.removeEventListener('beforeunload', alertUser);
      };
    }
  }, [isUserImpersonate]);

  return (
    <HeadingContainer isMobile={isMobile}>
      <HeadingLink to={homeUrl}>
        <LogoIcon height={isMobile ? 36 : 48} />
        <HeadingText isMobile={isMobile}>{t('application.title')}</HeadingText>
      </HeadingLink>
      {isUserImpersonate && accessToken && (
        <Box
          display="flex"
          marginLeft={3}
          border="none"
          borderLeft="1px solid"
          borderColor={gray10}
          paddingLeft={3}
          alignItems="center"
          gap={1}
        >
          <ImpersonationClientBox
            clientName={currentUsername}
            companyNameOfClient={companyName}
            token={accessToken}
            callbackFunctionAfterTimeEnd={handleLogout}
          />
        </Box>
      )}
    </HeadingContainer>
  );
}

const mapStateToProps = state => ({
  generateUrl: url => generateUrlWithLocale(url)(state)
});

const ConnectedNavbarHeading = connect(mapStateToProps)(NavbarHeading);

export { ConnectedNavbarHeading as NavbarHeading };
