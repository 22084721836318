import createReducer from '../helpers/CreateReducer';
import { StaticFilesConstants } from '../constants/actions';

export const getInitialState = () => ({
  helpContent: null,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${StaticFilesConstants.LOAD_MAINTENANCE_CONTENT}_SUCCESS`]: (
    state,
    { content }
  ) => ({
    ...state,
    maintenanceContent: content
  }),
  [`${StaticFilesConstants.LOAD_MAINTENANCE_CONTENT}_FAILURE`]: state => ({
    ...state,
    maintenanceContent: null
  })
});
