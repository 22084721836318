import { ApiConstants } from '@epi-constants/actions';
import { estimateTransferMoney } from '@epi-repositories/DepositOrdersRepository';
import { call, put } from 'redux-saga/effects';

export function* loadTransferMoneyEstimation() {
  const storeKey = ApiConstants.TRANSFER_MONEY_ESTIMATION;
  try {
    const payload = yield call(estimateTransferMoney);

    yield put({
      type: `${storeKey}_SUCCESS`,
      payload
    });
  } catch (ex) {
    yield put({
      type: `${storeKey}_FAILED`,
      payload: ex,
      isError: true
    });
  }
}
