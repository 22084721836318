import React from 'react';
import { useTranslation } from 'react-i18next';
import { PagePanel, NotFoundIcon, Box } from 'edenred-ui';
import { EmptyState } from '@epi-shared/components';

export function TokenExpired() {
  const { t } = useTranslation();

  return (
    <PagePanel>
      <Box
        mt={6}
        minHeight={600}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <EmptyState
          icon={<NotFoundIcon />}
          title={t('sign_up.token_expired_heading')}
          description={t('sign_up.token_expired_description')}
        />
      </Box>
    </PagePanel>
  );
}
