import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';

export const getInitialState = () => ({
  addresses: undefined,
  isLoading: false,
  isLoaded: false,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_ADDRESSES}_REQUEST`]: () => ({
    isLoading: true,
    isLoaded: false
  }),
  [`${ApiConstants.LOAD_ADDRESSES}_SUCCESS`]: (state, { addresses }) => ({
    addresses,
    isLoading: false,
    isLoaded: true
  }),
  [`${ApiConstants.LOAD_ADDRESSES}_FAILURE`]: () => ({
    isLoading: false,
    isError: true
  }),
  [`${ApiConstants.LOGOUT_ACTION}_SUCCESS`]: () => getInitialState()
});
