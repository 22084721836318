import React from 'react';
import styled from 'styled-components';
import { CloseIcon, MenuIcon } from 'edenred-ui';

import { INavbarMobileMenuButton } from '@epi-models/core/Navbar';

const MenuButton = styled.button.attrs({ type: 'button' })`
  display: flex;
  padding: 20px;
  background: none;
  border: none;
`;

export function NavbarMobileMenuButton({
  isMenuOpen,
  onClick
}: INavbarMobileMenuButton) {
  const icon = isMenuOpen ? <CloseIcon /> : <MenuIcon />;

  return (
    <MenuButton id="navbar-mobile-menu-button" onClick={onClick}>
      {icon}
    </MenuButton>
  );
}
