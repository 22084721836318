import React from 'react';
import { Box, OutlineWarningIcon, PagePanel } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { EmptyState } from '@epi-shared/components';

export const LoginImpersonationError = () => {
  const { t } = useTranslation();

  return (
    <PagePanel>
      <Box
        mt={4.5}
        minHeight={600}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <EmptyState
          icon={<OutlineWarningIcon />}
          title={t('containers.login.impersonation_error_title')}
          description={t('containers.login.impersonation_error_subtitle')}
        />
      </Box>
    </PagePanel>
  );
};
