import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { getRequest, postRequest, putRequest } from './ApiRepository';

export const companyBenefitGroups = () =>
  `${config.apiHost}/api/company/benefit-groups`;

export const companyBenefitGroupsByType = benefitType =>
  `${config.apiHost}/api/company/benefit-groups?type=${benefitType}`;

export function* fetchCompanyBenefitGroups(benefitType) {
  return yield call(
    getRequest,
    companyBenefitGroupsByType(benefitType),
    Authorize.Always
  );
}

export function* updateCompanyBenefitGroup(benefitGroupData) {
  return yield call(
    putRequest,
    companyBenefitGroups(),
    Authorize.Always,
    benefitGroupData
  );
}

export function* postCompanyBenefitGroup(benefitGroupData) {
  return yield call(
    postRequest,
    companyBenefitGroups(),
    Authorize.Always,
    benefitGroupData
  );
}
