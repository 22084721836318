import React, { StrictMode, PureComponent } from 'react';
import PropTypes from 'prop-types';

class StrictModeContainer extends PureComponent {
  render() {
    const { children } = this.props;
    if (process.env.NODE_ENV === 'development') {
      return <StrictMode>{children}</StrictMode>;
    }
    return children;
  }
}

StrictModeContainer.propTypes = {
  children: PropTypes.node.isRequired
};

export { StrictModeContainer as StrictMode };
