import React from 'react';
import PropTypes from 'prop-types';
import { ButtonMode, Modal, Box, Button } from 'edenred-ui';
import { useTranslation, Trans } from 'react-i18next';

import {
  ModalContent,
  ModalTitle,
  ModalMessage,
  Bold
} from './TopupConfirmationModal.styles';

export function TopupConfirmationModal({
  showModal,
  handleTopupRequest,
  handleCloseModal,
  employeesCount,
  totalAmount,
  isTopupPending
}) {
  const { t } = useTranslation();

  return (
    <Modal
      open={showModal}
      onClose={handleCloseModal}
      id="topup-confirmation_modal"
    >
      <ModalContent>
        <ModalTitle>
          {t('containers.deposit_money.topup_tab.confirmation_modal.title')}
        </ModalTitle>
        <ModalMessage>
          <Trans
            defaults={t(
              'containers.deposit_money.topup_tab.confirmation_modal.content',
              {
                employeesCount,
                totalAmount
              }
            )}
            components={{
              bold: <Bold />
            }}
          />
        </ModalMessage>
        <Box marginBottom="24px">
          <Button
            onClick={handleTopupRequest}
            isProcessing={isTopupPending}
            id="topup-confirmation_confirm"
          >
            {t(
              'containers.deposit_money.topup_tab.confirmation_modal.buttons.confirm'
            )}
          </Button>
        </Box>
        <Button
          mode={ButtonMode.Link}
          onClick={handleCloseModal}
          id="topup-confirmation_cancel"
        >
          {t(
            'containers.deposit_money.topup_tab.confirmation_modal.buttons.cancel'
          )}
        </Button>
      </ModalContent>
    </Modal>
  );
}

TopupConfirmationModal.propTypes = {
  showModal: PropTypes.bool.isRequired,
  handleTopupRequest: PropTypes.func.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  employeesCount: PropTypes.number.isRequired,
  totalAmount: PropTypes.string.isRequired,
  isTopupPending: PropTypes.bool.isRequired
};
