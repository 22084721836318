import createReducer from '../../helpers/CreateReducer';
import { SagaConstants } from '../../constants/actions';

export const getInitialState = () => ({
  loadedActions: {},
  loadingActions: [],
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${SagaConstants.SAGA_DATA_LOADED}`]: (state, payload) => ({
    loadedActions: {
      ...state.loadedActions,
      [payload.action]: {
        loaded: true,
        reloadAfterLogout:
          payload.reloadAfterLogout ||
          (state.loadedActions[payload.action] &&
            state.loadedActions[payload.action].reloadAfterLogout)
      }
    }
  }),
  [`${SagaConstants.RESET_DATA_LOADED}`]: (state, payload) => ({
    loadedActions: payload.loadedActions
  }),
  [`${SagaConstants.START_LOADING}`]: (state, { actionType }) => ({
    loadingActions: [
      ...state.loadingActions.filter(i => i !== actionType),
      actionType
    ]
  }),
  [`${SagaConstants.END_LOADING}`]: (state, { actionType }) => ({
    loadingActions: [...state.loadingActions.filter(i => i !== actionType)]
  })
});
