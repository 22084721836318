import {
  ButtonComponent as Button,
  ButtonMode,
  DialogComponent,
  IconComponent as Icon
} from 'edenred-ui';
import PropTypes from 'prop-types';
import React, { useState, useContext } from 'react';
import i18n from 'i18next';
import { ReportsTabContext } from './ReportsTabContext';
import DateRangePicker from '../../../components/DateRangePicker/DateRangePicker';
import { dateToApiFormat } from '../../../helpers/dateHelper';
import { DateRangeDialogStyles } from './DateRangeDialog.styles';

const DateRangeDialog = ({ isOpen, closeAction }) => {
  const { reportsTabState, reportsTabActions } = useContext(ReportsTabContext);
  const [from, setFrom] = useState(
    reportsTabState.pickerStartDate
      ? new Date(reportsTabState.pickerStartDate)
      : null
  );
  const [to, setTo] = useState(
    reportsTabState.pickerEndDate
      ? new Date(reportsTabState.pickerEndDate)
      : null
  );

  const handleSave = (fromDate, toDate) => {
    reportsTabActions.setPickerDateRange(
      dateToApiFormat(fromDate),
      dateToApiFormat(toDate)
    );
    closeAction();
  };

  const handleCancel = () => {
    setFrom(null);
    setTo(null);
    reportsTabActions.setPickerDateRange(null, null);
    closeAction();
  };

  return (
    <DateRangeDialogStyles>
      <DialogComponent open={isOpen} data-test="date_range_close_dialog">
        <div className="header">
          <div className="close-icon">
            <Icon iconType="close" onClick={closeAction} />
          </div>
          <div className="title">
            {i18n.t('components.date_range_dialog.title')}
          </div>
        </div>
        <div className="content">
          <DateRangePicker
            from={from}
            to={to}
            setFrom={setFrom}
            setTo={setTo}
            disableFutureDates
          />
        </div>
        <div className="actions">
          <Button
            id="date-range-dialog-cancel-btn"
            mode={ButtonMode.Link}
            onClick={handleCancel}
          >
            {i18n.t('components.date_range_dialog.cancel')}
          </Button>
          <Button
            id="date-range-dialog-save-btn"
            mode={ButtonMode.Primary}
            disabled={!(from && to)}
            onClick={() => {
              handleSave(from, to);
            }}
          >
            {i18n.t('components.date_range_dialog.confirm')}
          </Button>
        </div>
      </DialogComponent>
    </DateRangeDialogStyles>
  );
};

DateRangeDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeAction: PropTypes.func.isRequired
};

export default DateRangeDialog;
