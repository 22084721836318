import React from 'react';
import { useTranslation } from 'react-i18next';
import { PagePanel, Box, LoadingSpinner } from 'edenred-ui';

import { changeUrlWithoutReloadPage } from '@epi-helpers/UrlHelpers';
import { routerPaths } from '@epi-constants/routerPaths';

import { useHome } from './hooks';

import {
  Newsfeed,
  BalanceTile,
  Benefits,
  NewCustomer,
  HomeNotification,
  SupportTile
} from './components';

export const Home = () => {
  const { t } = useTranslation();
  const { isLoading, isNewCustomer, customerData } = useHome();
  const url = window.location.href;

  if (url.includes('guid')) changeUrlWithoutReloadPage(routerPaths.home, '');

  if (isLoading) return <LoadingSpinner />;

  if (isNewCustomer) return <NewCustomer data={customerData} />;

  return (
    <>
      <HomeNotification />
      <PagePanel title={t('home.how_benefits_work.title')}>
        <Newsfeed />
        <Box display="flex">
          <BalanceTile />
          <SupportTile />
        </Box>
        <Benefits />
      </PagePanel>
    </>
  );
};
