import {
  ApiConstants,
  ApplicationConstants,
  OverlaysConstants
} from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  companyAlreadyExists: false,
  showModal: false,
  lastCompanyInformation: {},
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ApiConstants.CHECK_IF_COMPANY_EXISTS}_SUCCESS`]: (
    state,
    { companyAlreadyExists, lastCompanyInformation }
  ) => ({
    companyAlreadyExists,
    lastCompanyInformation,
    showModal: companyAlreadyExists
  }),
  [`${ApiConstants.CHECK_IF_COMPANY_EXISTS}_FAILURE`]: () => ({
    companyAlreadyExists: false,
    showModal: false,
    lastCompanyInformation: {}
  }),
  [`${OverlaysConstants.CLOSE_COMPANY_EXISTS_MODAL}`]: () => ({
    companyAlreadyExists: true,
    showModal: false
  }),
  [`${ApplicationConstants.CLEAN_COMPANY_EXISTS_INFO}`]: () => ({
    companyAlreadyExists: false,
    showModal: false,
    lastCompanyInformation: {}
  })
});
