import { VoucherReorderConstants } from '../constants/actions';

export const setReorderVoucherAndAmount = ({ quantity, voucherId }) => ({
  type: `${VoucherReorderConstants.SET_REORDER_VOUCHER_AND_AMOUNT}`,
  quantity,
  voucherId
});

export const loadReorderData = orderId => ({
  type: `${VoucherReorderConstants.LOAD_REORDER_DATA}_REQUEST`,
  orderId
});
