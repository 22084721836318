import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonMode, ButtonSize, NotificationType } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { showNotification } from '@epi-actions/overlays';
import { getProfileInfo } from '@epi-selectors/profile';
import { getApplicationLanguageId } from '@epi-selectors/application';
import { useToastContext } from '@epi-core/components/Toast/useToastContext';
import { requestConfirmationLinkApiUrl } from '@epi-repositories/EmailRepository';
import { fetchPost as Post } from '@epi-helpers/FetchHelpers';
import { getUserInformation } from '@epi-actions/profile';

export const useEmailConfirmation = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isEmailVerified, username } = useSelector(getProfileInfo);
  const { showToast, closeToast } = useToastContext();
  const language = useSelector(getApplicationLanguageId);

  const requestNewLink = () => {
    closeToast();

    const onSuccess = () => {
      dispatch(
        showNotification(
          { message: 'email_verification.request_new_link_success' },
          'success'
        )
      );
    };
    const onFailure = () => {
      dispatch(
        showNotification(
          { message: 'email_verification.get_new_verification_link_fail' },
          'error'
        )
      );
    };

    return Post(
      requestConfirmationLinkApiUrl,
      { username },
      onSuccess,
      onFailure
    );
  };

  const ResendLinkButton = (
    <Button
      id="resend-confirmation-button"
      mode={ButtonMode.Secondary}
      size={ButtonSize.Medium}
      onClick={requestNewLink}
      type="button"
    >
      {t('email_verification.notification_link')}
    </Button>
  );

  useEffect(() => {
    dispatch(getUserInformation());
    if (isEmailVerified === false) {
      showToast(
        NotificationType.Error,
        t('email_verification.notification_text'),
        ResendLinkButton
      );
    } else {
      closeToast();
    }
  }, [isEmailVerified, language]);
};
