import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { getRequest } from './ApiRepository';

export function* getFees() {
  return yield call(
    getRequest,
    `${config.apiHost}/api/voucher-fees`,
    Authorize.Never
  );
}
