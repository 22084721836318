import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { parseDecimal, formatCurrency } from '@epi-helpers/numeral';
import { TransferMoneyFormFields } from '@epi-containers/LoadCompanyAccount/helpers';
import {
  getPaymentMinimalValue,
  PaymentMethods
} from '@epi-constants/paymentMethods';
import { IApiPaymentMethods } from '@epi-models/containers/LoadCompanyAccount';
import { getApiPaymentsMethods } from '@epi-selectors/api';

export const usePaymentMethodOptions = () => {
  const { t } = useTranslation();
  const { watch } = useFormContext();

  const apiPaymentMethods: IApiPaymentMethods = useSelector(
    getApiPaymentsMethods
  );
  const getPaymentMinValue = useSelector(state =>
    getPaymentMinimalValue(PaymentMethods.Online)(state)
  );
  const amountValue = watch(TransferMoneyFormFields.Amount).replace(',', '.');

  const calculateTotalAmount = () => parseDecimal(amountValue);
  const isLessThanMinValue = () => {
    return +amountValue < getPaymentMinValue;
  };
  const onlinePaymentMethods = apiPaymentMethods.paymentMethods.filter(
    t => t.type === PaymentMethods.Online
  );
  const getErrorMessageKey = paymentMethodName => {
    if (getPaymentMinValue > +amountValue) {
      return {
        value: 'forms.payment_method.minimal_value_error',
        format: {
          minimalValue: formatCurrency(getPaymentMinValue),
          paymentMethod: t(paymentMethodName).toLowerCase()
        }
      };
    }

    return { value: 'forms.payment_method.maksuturva_error' };
  };

  return {
    calculateTotalAmount,
    apiPaymentMethods,
    isLessThanMinValue,
    onlinePaymentMethods,
    getErrorMessageKey
  };
};
