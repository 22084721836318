import { all, fork, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ApiConstants,
  NavigationConstants,
  OverlaysConstants,
  ProfileConstants
} from '../../constants/actions';
import { takeFirst } from '../saga';
import addNewPaymentToCard from './addNewPaymentToCard';
import addNewPaymentToDeposit from './addNewPaymentToDeposit';
import addNewPaymentToVoucher from './addNewPaymentToVoucher';
import { addSignatoryRoleToUser } from './addSignatoryRoleToUser';
import checkIfCompanyExists from './checkIfCompanyExists';
import createNewPayment from './createNewPayment';
import createVoucherOrder from './createVoucherOrder';
import createVoucherReorder from './createVoucherReorder';
import { createDepositOrder } from './depositCompanyAccount';
import { downloadDepositOrderReceipt } from './downloadDepositOrderReceipt';
import { downloadInvoice } from './downloadInvoice';
import { downloadTransactionReceipt } from './downloadTransactionReceipt';
import {
  createCompanyBenefitGroup,
  editCompanyBenefitGroup,
  getBenefitGroups
} from './getBenefitGroups';
import {
  getActiveBenefits,
  getBenefitGroupSettings
} from './getBenefitGroupSettings';
import { clearActionApiError, clearApiError, getApiError } from './getErrors';
import { getHasOrders } from './getHasOrders';
import getPaymentStatus from './getPaymentStatus';
import getUsernameAvailability from './getUsernameAvailability';
import loadAddresses from './loadAddresses';
import loadCardFees from './loadCardFees';
import loadCompanyAccounts from './loadCompanyAccounts';
import loadCompanyMetadata from './loadCompanyMetadata';
import loadContacts from './loadContacts';
import loadCountries from './loadCountries';
import loadPaymentMethods from './loadPaymentMethods';
import loadSettings from './loadSettings';
import loadTransactions from './loadTransactions';
import { loadTransferMoneyEstimation } from './loadTransferMoneyEstimation';
import loadUserCompany from './loadUserCompany';
import loadVoucherOrderByPayment from './loadVoucherOrderByPayment';
import { loadVoucherOrderDetails } from './loadVoucherOrderDetails';
import loadVoucherOrderFees from './loadVoucherOrderFees';
import loadVoucherOrdersHistory from './loadVoucherOrdersHistory';
import { loadVouchers } from './loadVouchers';
import { ApiLogin } from './login';
import ApiLogout from './logout';
import { ChangePassword, GetUserInformation, UpdateUserInfo } from './profile';
import resetPassword from './resetPassword';
import { setEndLoading, setStartLoading } from './setLoadingStatus';
import { createNewUser, goToSignUpPage } from './signUp';
import updateUserCompany from './updateUserCompany';
import watchTransactionsHistorySaga from './watchTransactionsHistoryQuery';

export function* watchApiAction() {
  yield all([
    takeFirst(`${ApiConstants.LOAD_SETTINGS}_REQUEST`, loadSettings),
    takeLatest(`${ApiConstants.LOAD_CARD_FEES}_REQUEST`, loadCardFees),
    takeLatest(`${ApiConstants.RESET_PASSWORD}_REQUEST`, resetPassword),
    takeLatest(`${ApiConstants.GET_PAYMENT_STATUS}_REQUEST`, getPaymentStatus),
    takeLatest(`${ApiConstants.LOAD_VOUCHERS}_REQUEST`, loadVouchers),
    takeFirst(`${ApiConstants.LOAD_ORDER_FEES}_REQUEST`, loadVoucherOrderFees),
    takeLatest(
      `${ApiConstants.LOAD_VOUCHER_ORDER_BY_PAYMENT}_REQUEST`,
      loadVoucherOrderByPayment
    ),
    takeLatest(
      `${ApiConstants.ADD_NEW_PAYMENT_TO_VOUCHER}_REQUEST`,
      addNewPaymentToVoucher
    ),
    takeLatest(
      `${ApiConstants.ADD_NEW_PAYMENT_TO_CARD}_REQUEST`,
      addNewPaymentToCard
    ),
    takeLatest(
      `${ApiConstants.ADD_NEW_PAYMENT_TO_DEPOSIT}_REQUEST`,
      addNewPaymentToDeposit
    ),
    takeFirst(`${ApiConstants.LOAD_USER_COMPANY}_REQUEST`, loadUserCompany),
    takeLatest(
      `${ApiConstants.UPDATE_USER_COMPANY}_REQUEST`,
      updateUserCompany
    ),
    takeLatest(`${ApiConstants.LOAD_COUNTRIES}_REQUEST`, loadCountries),
    takeLatest(
      `${ApiConstants.LOAD_PAYMENT_METHODS}_REQUEST`,
      loadPaymentMethods
    ),
    takeLatest(`${ApiConstants.SIGN_UP}_REQUEST`, createNewUser),
    takeLatest(NavigationConstants.GO_TO_SIGN_UP_PAGE, goToSignUpPage),
    takeLatest(
      `${ApiConstants.CREATE_VOUCHER_ORDER}_REQUEST`,
      createVoucherOrder
    ),
    takeLatest(
      `${ApiConstants.CREATE_VOUCHER_REORDER}_REQUEST`,
      createVoucherReorder
    ),
    takeLatest(`${ApiConstants.CREATE_NEW_PAYMENT}_REQUEST`, createNewPayment),
    takeLatest(
      `${ApiConstants.CHECK_IF_COMPANY_EXISTS}_REQUEST`,
      checkIfCompanyExists
    ),
    takeLatest(`${ApiConstants.CHANGE_PASSWORD}_REQUEST`, ChangePassword),
    takeLatest(`${ApiConstants.UPDATE_USER_INFORMATION}`, UpdateUserInfo),
    takeLatest(
      `${ApiConstants.GET_USER_INFORMATION}_REQUEST`,
      GetUserInformation
    ),
    takeLatest(`${ApiConstants.LOGIN_ACTION}_REQUEST`, ApiLogin),
    takeLatest(`${ApiConstants.LOGOUT_ACTION}_REQUEST`, ApiLogout),
    takeLatest(
      `${ApiConstants.LOAD_VOUCHER_ORDERS_HISTORY}_REQUEST`,
      loadVoucherOrdersHistory
    ),
    takeLatest(
      `${ApiConstants.LOAD_TRANSACTIONS_HISTORY}_REQUEST`,
      loadTransactions
    ),
    fork(watchTransactionsHistorySaga),
    takeLatest(
      `${ApiConstants.CHECK_USERNAME_AVAILABILITY}_REQUEST`,
      getUsernameAvailability
    ),
    takeLatest(
      `${ApiConstants.LOAD_VOUCHER_ORDER_DETAILS}_REQUEST`,
      loadVoucherOrderDetails
    ),
    takeLatest(`${ApiConstants.LOAD_CONTACTS}_REQUEST`, loadContacts),
    takeLatest(`${ApiConstants.LOAD_ADDRESSES}_REQUEST`, loadAddresses),
    takeLatest(action => /_FAILURE$/.test(action.type), getApiError),
    takeLatest(action => /_REQUEST$/.test(action.type), clearActionApiError),
    takeLatest(`${NavigationConstants.OPEN_PAGE_EVENT_START}`, clearApiError),
    takeLatest(
      `${ProfileConstants.CHANGE_SELECTED_PROFILE_TAB}`,
      clearApiError
    ),
    takeLatest(`${OverlaysConstants.LOGIN_POPUP_ACTION}`, clearApiError),
    takeEvery(action => /_REQUEST$/.test(action.type), setStartLoading),
    takeEvery(
      action => /_SUCCESS$|_FAILURE$|_ABORT$/.test(action.type),
      setEndLoading
    ),
    takeEvery(`${ApiConstants.DOWNLOAD_INVOICE}_REQUEST`, downloadInvoice),
    takeEvery(
      `${ApiConstants.ADD_SIGNATORY_ROLE_TO_USER}_REQUEST`,
      addSignatoryRoleToUser
    ),
    takeLatest(
      `${ApiConstants.LOAD_COMPANY_ACCOUNTS}_REQUEST`,
      loadCompanyAccounts
    ),
    takeLatest(
      `${ApiConstants.LOAD_COMPANY_METADATA}_REQUEST`,
      loadCompanyMetadata
    ),
    takeLatest(
      `${ApiConstants.DEPOSIT_COMPANY_ACCOUNT}_REQUEST`,
      createDepositOrder
    ),
    takeEvery(
      `${ApiConstants.GET_RECEIPT_FOR_DEPOSIT}_REQUEST`,
      downloadDepositOrderReceipt
    ),
    takeEvery(
      `${ApiConstants.GET_RECEIPT_FOR_TRANSACTION}_REQUEST`,
      downloadTransactionReceipt
    ),
    takeLatest(
      `${ApiConstants.BENEFIT_GROUP_SETTINGS}_REQUEST`,
      getBenefitGroupSettings
    ),
    takeLatest(
      `${ApiConstants.TRANSFER_MONEY_ESTIMATION}_REQUEST`,
      loadTransferMoneyEstimation
    ),
    takeLatest(`${ApiConstants.GET_HAS_ORDERS}_REQUEST`, getHasOrders),
    takeLatest(
      `${ApiConstants.GET_ACTIVE_BENEFITS}_REQUEST`,
      getActiveBenefits
    ),
    takeLatest(`${ApiConstants.GET_BENEFIT_GROUPS}_REQUEST`, getBenefitGroups),
    takeLatest(
      `${ApiConstants.UPDATE_BENEFIT_GROUP}_REQUEST`,
      editCompanyBenefitGroup
    ),
    takeLatest(
      `${ApiConstants.CREATE_BENEFIT_GROUP}_REQUEST`,
      createCompanyBenefitGroup
    )
  ]);
}
