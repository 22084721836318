import { put, call } from 'redux-saga/effects';
import { getCompanyTransactions } from '../../repositories/CompanyAccountsRepository';
import { ApiConstants } from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';
import * as overlaysActions from '../../actions/overlays';

function createTransactionHistoryRow(transactionJson) {
  return {
    id: transactionJson.id,
    title: transactionJson.title,
    description: transactionJson.description,
    balanceChange: transactionJson.balanceChange / 100,
    orderId: transactionJson.orderId,
    transactionType: transactionJson.transactionType,
    isDeposit: transactionJson.isDeposit,
    date: new Date(transactionJson.date)
  };
}

export function mapTransactionsHistory(transactionsResponse) {
  return transactionsResponse.transactions.map(t =>
    createTransactionHistoryRow(t)
  );
}

export default function* loadTransactions({ queryParameters }) {
  try {
    const transactionsHistoryResponse = yield call(
      getCompanyTransactions,
      queryParameters
    );

    const transactions = yield call(
      mapTransactionsHistory,
      transactionsHistoryResponse
    );

    yield put({
      type: `${ApiConstants.LOAD_TRANSACTIONS_HISTORY}_SUCCESS`,
      payload: {
        transactionsHistoryResponse: {
          transactions,
          totalPages: transactionsHistoryResponse.totalPages
        }
      }
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.LOAD_TRANSACTIONS_HISTORY}_FAILURE`,
      payload: ex
    });
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    } else {
      yield put(
        overlaysActions.showNotification(
          { message: 'messages.transactions_history_failed' },
          'error'
        )
      );
    }
  }
}
