import { pick, omit } from 'lodash';
import { PaymentMethods } from '../../constants/paymentMethods';

export function isOnlineOrCreditCardPayment(payment) {
  return (
    payment.method === PaymentMethods.Online ||
    payment.method === PaymentMethods.CreditCard
  );
}

export function getPaymentData({ payment }) {
  const method = (payment && payment.method) || null;
  const forwardInvoiceData =
    method === PaymentMethods.LinkToOtherPerson
      ? {
          ...payment.forward_invoice_data,
          ...pick(payment, ['invoiceCostCenter', 'invoiceContactPerson'])
        }
      : null;

  const downloadInvoiceData =
    method === PaymentMethods.PrintInvoicePdf
      ? payment.download_invoice_data
      : null;

  const onlineOrCreditCardPaymentInvoiceData =
    method === PaymentMethods.Online || method === PaymentMethods.CreditCard
      ? { methodCode: payment.methodCode }
      : null;
  return {
    ...omit(payment, [
      'methodCode',
      'download_invoice_data',
      'forward_invoice_data',
      'invoiceCostCenter',
      'invoiceContactPerson'
    ]),
    ...forwardInvoiceData,
    ...downloadInvoiceData,
    ...onlineOrCreditCardPaymentInvoiceData
  };
}
