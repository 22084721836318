export function getUnverifiedErrorName(fieldName) {
  return `${fieldName}_UNVERIFIED_ASYNC_VALIDATOR`;
}

export function getProcessingErrorName(fieldName) {
  return `${fieldName}_PROCESSING_ASYNC_VALIDATOR`;
}

export function getInvalidErrorName(fieldName) {
  return `${fieldName}_INVALID_ASYNC_VALIDATOR`;
}
