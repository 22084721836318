import { get } from 'lodash';
import { ApiConstants } from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  newCardFees: {},
  deliveryFees: {},
  lunchCommission: 0,
  recreationalCommission: 0,
  transportCommission: 0,
  feesVatPercentage: 0,
  isLoading: false,
  loaded: false,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_CARD_FEES}_REQUEST`]: () => ({ isLoading: true }),

  [`${ApiConstants.LOAD_CARD_FEES}_SUCCESS`]: (state, { payload }) => ({
    newCardFees: get(payload, 'newCardFees') || {},
    deliveryFees: get(payload, 'deliveryFees') || {},
    lunchCommission: get(payload, 'lunchCommission') || 0,
    recreationalCommission: get(payload, 'recreationalCommission') || 0,
    transportCommission: get(payload, 'transportCommission') || 0,
    feesVatPercentage: get(payload, 'feesVatPercentage') || 0,
    loaded: true,
    isLoading: false
  }),

  [`${ApiConstants.LOAD_CARD_FEES}_FAILURE`]: state =>
    !state.isError
      ? {
          isLoading: false,
          loaded: true,
          isError: true
        }
      : state
});
