import React from 'react';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Box, Button, ButtonMode, Link, SimpleList } from 'edenred-ui';
import { useDispatch } from 'react-redux';

import supportImg from '@epi-assets/images/SupportImage.png';
import { routerPaths } from '@epi-constants/routerPaths';
import { goToPage } from '@epi-actions/navigation';

export const SupportTile = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleGoToHelpPage = () => dispatch(goToPage(routerPaths.help));

  const links = [
    <Link
      target="_blank"
      href={t('home.support.guides.employer_guide.link_url')}
      key={t('home.support.guides.title')}
      isSizeMedium
      isFontRegularBold
      id="guide_employer"
    >
      {t('home.support.guides.employer_guide.link_text')}
    </Link>,
    <Link
      target="_blank"
      href={t('home.support.guides.beneficairy_guide.link_url')}
      key={t('home.support.help.title')}
      isSizeMedium
      isFontRegularBold
      id="guide_beneficiary"
    >
      {t('home.support.guides.beneficairy_guide.link_text')}
    </Link>
  ];

  return (
    <Box
      flex={1}
      height={320}
      p={3}
      ml={3}
      bgcolor={theme.primaryBackColorLight10}
      display="flex"
      justifyContent="space-between"
      minWidth={750}
    >
      <Box>
        <Box fontSize={theme.sizeLarge} fontWeight={theme.fontSemiBold} mb={3}>
          {t('home.support.guides.title')}
        </Box>
        <SimpleList data={links} />
        <Box
          fontSize={theme.sizeLarge}
          fontWeight={theme.fontSemiBold}
          mt={5}
          mb={1}
        >
          {t('home.support.help.title')}
        </Box>
        <Box fontSize={theme.sizeMedium} mb={2}>
          {t('home.support.help.text')}
        </Box>
        <Button
          mode={ButtonMode.Secondary}
          onClick={handleGoToHelpPage}
          id="support_link"
        >
          {t('home.support.help.button.text')}
        </Button>
      </Box>
      <Box pr={3}>
        <img src={supportImg} />
      </Box>
    </Box>
  );
};
