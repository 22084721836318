import createReducer from '@epi-helpers/CreateReducer';
import { ApiConstants } from '@epi-constants/actions';

const initialState = {
  benefitGroups: [],
  isLoaded: false,
  isError: false
};

function onSaveBenefitGroups(state, { payload }) {
  return {
    ...state,
    benefitGroups: payload,
    isLoaded: true,
    isError: false
  };
}

function onFailureBenefitGroups(state) {
  return {
    ...state,
    isLoaded: true,
    isError: true
  };
}

export default createReducer(() => initialState, {
  [`${ApiConstants.GET_BENEFIT_GROUPS}_SUCCESS`]: onSaveBenefitGroups,
  [`${ApiConstants.UPDATE_BENEFIT_GROUP}_SUCCESS`]: onSaveBenefitGroups,
  [`${ApiConstants.CREATE_BENEFIT_GROUP}_SUCCESS`]: onSaveBenefitGroups,
  [`${ApiConstants.GET_BENEFIT_GROUPS}_FAILURE`]: onFailureBenefitGroups,
  [`${ApiConstants.UPDATE_BENEFIT_GROUP}_FAILURE`]: onFailureBenefitGroups,
  [`${ApiConstants.CREATE_BENEFIT_GROUP}_FAILURE`]: onFailureBenefitGroups
});
