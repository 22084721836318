import styled from 'styled-components';

import { FormStepMode } from './FormStep';

const Container = styled.div`
  padding: 80px;
  background-color: ${({ theme, mode }) =>
    mode === FormStepMode.Contained && theme.primaryBackColorLight10};
  border: ${({ theme, mode }) =>
    mode === FormStepMode.Outlined && `1px solid ${theme.altGray2}`};
  display: flex;
  align-items: center;
  margin-top: 40px;
`;

const Content = styled.div`
  width: 100%;
  justify-content: space-between;
  display: flex;
  align-items: center;
  padding-right: 350px;
`;

export const FormStepStyles = {
  Container,
  Content
};
