import { get } from 'lodash';
import { ApiConstants } from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  voucherOrderFees: [],
  isLoading: false,
  loaded: false,
  noPersistance: true,
  vatFeePercent: 0
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_ORDER_FEES}_REQUEST`]: () => ({ isLoading: true }),

  [`${ApiConstants.LOAD_ORDER_FEES}_SUCCESS`]: (state, { payload }) => ({
    voucherOrderFees: get(payload, 'voucherFees') || [],
    loaded: true,
    isLoading: false,
    vatFeePercent: payload.vatFeePercent
  }),

  [`${ApiConstants.LOAD_ORDER_FEES}_FAILURE`]: state =>
    !state.isError
      ? {
          isLoading: false,
          loaded: true,
          isError: true
        }
      : state
});
