import React from 'react';
import i18n from 'i18next';
import { PagePanel, Tabs } from 'edenred-ui';

import { ReportTypeVirike } from '@epi-constants/externalReport';
import { TopupReports } from '@epi-pages/Reports/TopupReports';
import { TransactionsHistory } from '@epi-containers/TransactionsHistory/TransactionsHistory';
import ReportsTab from '@epi-containers/ExternalReports/Reports/ReportsTab';
import config from '@epi-config';

import { useReportsTabFooter } from './hooks/useReportsTabFooter';
import { useExternalReportsTabs } from './hooks/useExternalReportsTabs';

const { enableVirikeBenefit } = config;

const ExternalReportsTabs = () => {
  const { showFooter, handleOnTabChange, defaultTabName } =
    useExternalReportsTabs();
  const { footer } = useReportsTabFooter();
  const tabTransactions = {
    name: ReportTypeVirike.transactions,
    label: i18n.t('containers.reports.tabs.transactions'),
    id: 'tab-transactions',
    body: <TransactionsHistory />
  };

  const topUpLoads = {
    name: ReportTypeVirike.topups,
    label: i18n.t('containers.reports.tabs.topup'),
    id: 'tab-topup',
    body: <TopupReports />
  };

  const tabReports = {
    name: ReportTypeVirike.reports,
    label: i18n.t('containers.reports.tabs.reports'),
    id: 'tab-reports',
    body: <ReportsTab />
  };

  const tabs = enableVirikeBenefit
    ? [tabTransactions, topUpLoads, tabReports]
    : [tabTransactions, tabReports];

  return (
    <PagePanel
      title={i18n.t('containers.reports.title')}
      relative
      footer={showFooter && footer}
    >
      <Tabs
        tabs={tabs}
        onTabChange={handleOnTabChange}
        defaultTabName={defaultTabName}
      />
    </PagePanel>
  );
};

export default ExternalReportsTabs;
