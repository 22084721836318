import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Box } from 'edenred-ui';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { FormFields } from '@epi-forms/helpers';
import {
  AddressField,
  CityField,
  CompanyNameField,
  CountryField,
  ZipCodeField
} from '@epi-forms/controls';

import { arrayIncludesField } from '../../helpers/ArrayHelper';

function Address({ withCountry, disabled }) {
  return (
    <>
      <Box marginBottom="15px">
        <Row>
          <Col xs={12} md={5}>
            <CompanyNameField
              fieldName={FormFields.DeliveryCompanyName}
              readOnly={disabled}
            />
          </Col>
          <Col xs={12} md={5}>
            <AddressField
              name={FormFields.DeliveryAddress}
              readOnly={disabled}
            />
          </Col>
        </Row>
      </Box>
      <Row>
        <Col xs={6} md={2}>
          <ZipCodeField name={FormFields.DeliveryZipCode} readOnly={disabled} />
        </Col>
        <Col xs={6} md={3}>
          <CityField name={FormFields.DeliveryCity} readOnly={disabled} />
        </Col>
        {withCountry && (
          <Col xs={6} md={3}>
            <CountryField
              name={FormFields.DeliveryCountry}
              readOnly={disabled || arrayIncludesField(disabled, 'country')}
            />
          </Col>
        )}
      </Row>
    </>
  );
}

Address.propTypes = {
  disabled: PropTypes.bool,
  withCountry: PropTypes.bool
};

Address.defaultProps = {
  disabled: false,
  withCountry: false
};

const AddressWithI18n = withTranslation()(Address);

export { AddressWithI18n as Address };
