export const ManageBenefitLunchFormFields = {
  BenefitGroupName: 'groupName',
  DailyLimit: 'maxAmountPerPeriod'
};

export function getDailyLimitSelectFieldOptions(limits, selectedLimit) {
  const isSelectedObsoleteLimit =
    selectedLimit && !limits.includes(selectedLimit);

  return (isSelectedObsoleteLimit ? [...limits, selectedLimit] : limits).map(
    limit => ({
      id: limit,
      display: limit.toFixed(2),
      upToDate: limits.includes(limit)
    })
  );
}

export function mapFormToBenefitGroup(form, benefitGroup, benefitType) {
  return { ...benefitGroup, ...form, benefitType };
}
