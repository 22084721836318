import { additionalFlags } from './additionalFlags';
import { AzureVariable } from './envVariablesSchema';

const logEnvVariableError = (key, message) => {
  console.error(`${message}\nFor key: ${key}`);
};

const envVariablesErrorMessages = {
  commaUsedInNumber: `Use dot instead of comma as the decimal seperator.`,
  inproperBooleanValue: value =>
    `Boolean values should be passed down as "true" or "false". Received: ${value} - defaulting to false.`,
  noKeyInSchema: `The provided environmental variable doesn't exist in envVariablesSchema.`,
  parsedNaN: value => `Parsing the following value ${value} resulted in NaN.`,
  unhandledValueType: valueType =>
    `The specified variable env type is not handled: "${valueType}".`
};

function parseValue(key, value, valueType) {
  switch (valueType) {
    case AzureVariable.string:
      return value;

    case AzureVariable.number:
      if (value.includes(',')) {
        logEnvVariableError(key, envVariablesErrorMessages.commaUsedInNumber);
      }
      if (Number.isNaN(Number.parseFloat(value))) {
        logEnvVariableError(key, envVariablesErrorMessages.parsedNaN(value));
      }
      return Number.parseFloat(value);

    case AzureVariable.boolean:
      if (!['true', 'false'].includes(value)) {
        logEnvVariableError(
          key,
          envVariablesErrorMessages.inproperBooleanValue(value)
        );
      }
      return value.toLowerCase() === 'true' ? true : false;
    case undefined:
      logEnvVariableError(key, envVariablesErrorMessages.noKeyInSchema);
      return undefined;

    default:
      logEnvVariableError(
        key,
        envVariablesErrorMessages.unhandledValueType(valueType)
      );
      return undefined;
  }
}

export function processEnvVariables(rawEnvVariables, schema) {
  const parsedEnvVariables = Object.entries(rawEnvVariables).reduce(
    (acc, [key, stringValue]) => ({
      ...acc,
      [key]: parseValue(key, stringValue, schema[key])
    }),
    {}
  );
  return parsedEnvVariables;
}

export function addFlags(envVariables) {
  const processedAdditionalFlags = Object.entries(additionalFlags).reduce(
    (acc, [key, flagFunction]) => ({
      ...acc,
      [key]: flagFunction(envVariables)
    }),
    {}
  );

  return {
    ...envVariables,
    ...processedAdditionalFlags
  };
}
