import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useController, useFormContext } from 'react-hook-form';
import { TextField, IconComponent as Icon } from 'edenred-ui';

import { createRules } from '@epi-forms/helpers';
import { password, required } from '@epi-helpers/formHelpers/validators';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  margin: 0;
  font-size: ${({ theme }) => theme.sizeExtraExtraLarge};
`;

export function PasswordField({
  displayValidationResults,
  validators,
  name,
  label,
  showIconPassword
}) {
  const { control } = useFormContext();
  const defaultValidators = [required, password];
  const rules = createRules(validators || defaultValidators);
  const [showPassword, setShowPassword] = useState(false);

  const {
    field: { ref, value, onChange: setValue },
    fieldState: { error }
  } = useController({
    name,
    control,
    rules
  });

  const toggleShowPassword = () => setShowPassword(prevState => !prevState);

  return (
    <TextField
      type={showPassword ? 'text' : 'password'}
      label={label}
      required
      value={value}
      fullWidth
      error={displayValidationResults && !!error}
      helperText={displayValidationResults && (error?.message || <>&nbsp;</>)}
      id={name}
      inputRef={ref}
      onChange={setValue}
      InputProps={
        showIconPassword && {
          endAdornment: showPassword ? (
            <StyledIcon onClick={toggleShowPassword} iconType="eye-off" />
          ) : (
            <StyledIcon onClick={toggleShowPassword} iconType="eye" />
          )
        }
      }
    />
  );
}

PasswordField.propTypes = {
  displayValidationResults: PropTypes.bool,
  validators: PropTypes.array,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  showIconPassword: PropTypes.bool
};

PasswordField.defaultProps = {
  displayValidationResults: true,
  validators: null
};
