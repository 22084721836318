import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { getRequest, putRequest, postRequest } from './ApiRepository';

export const setNewPasswordUrl = `${config.apiHost}/api/password`;

export function* changePassword({ password, newPassword, confirmPassword }) {
  const payload = {
    password,
    newPassword,
    confirmPassword
  };
  return yield call(
    putRequest,
    `${config.apiHost}/api/password`,
    Authorize.Always,
    payload
  );
}

export function* resetPassword(data) {
  return yield call(
    postRequest,
    `${config.apiHost}/api/password/reset`,
    Authorize.Never,
    data
  );
}

export function* updateUserInfo(payload) {
  return yield call(
    putRequest,
    `${config.apiHost}/api/user`,
    Authorize.Always,
    payload
  );
}

export function* getUserInfo() {
  return yield call(getRequest, `${config.apiHost}/api/user`, Authorize.Always);
}
