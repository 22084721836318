import { put, call } from 'redux-saga/effects';
import { getVoucherOrder } from '../../repositories/VoucherOrdersRepository';
import { ApiConstants } from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';
import { getVoucherFaceValueById } from './apiHelpers';

export function* getVoucherOrderDetails(voucherOrderId) {
  const voucherOrderResponse = yield call(getVoucherOrder, voucherOrderId);
  const faceValue = yield call(
    getVoucherFaceValueById,
    voucherOrderResponse.voucherId
  );
  return {
    ...voucherOrderResponse,
    faceValue
  };
}

export function* loadVoucherOrderDetails(action) {
  try {
    const voucherDetails = yield call(
      getVoucherOrderDetails,
      action.voucherOrderId
    );

    yield put({
      type: `${ApiConstants.LOAD_VOUCHER_ORDER_DETAILS}_SUCCESS`,
      voucherDetails
    });
  } catch (ex) {
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    }
    yield put({
      type: `${ApiConstants.LOAD_VOUCHER_ORDER_DETAILS}_FAILURE`,
      payload: ex
    });
  }
}
