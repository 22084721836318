import { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportsTabContext } from '../Reports/ReportsTabContext';
import { dateRangeModes } from '../Reports/ReportsTabReducer';

const isAtLeastOneEmployeeDataColumnSelected = (columns, dataScope) => {
  return (
    columns.some(c => c.group === 'employeeData' && c.active) &&
    dataScope.employeeData
  );
};

const isAtLeastOneBenefitTypeSelected = activeBenefits => {
  return activeBenefits.some(b => b.checked);
};

const isDateRangeSet = state => {
  if (state.dateRangeMode === dateRangeModes.datePicker) {
    return state.pickerStartDate && state.pickerEndDate;
  }
  return state.startDate && state.endDate;
};

export function useReportsTabFooter() {
  const { t } = useTranslation();
  const { reportsTabState, reportsTabActions } = useContext(ReportsTabContext);
  const [isDownloading, setIsDownloading] = useState(false);

  useEffect(() => {
    if (reportsTabState.requestDownloadReport) {
      setIsDownloading(true);
      return;
    }
    setIsDownloading(false);
  }, [reportsTabState.requestDownloadReport]);

  const isDownloadDisabled =
    reportsTabState.isLoading ||
    isDownloading ||
    !isAtLeastOneEmployeeDataColumnSelected(
      reportsTabState.columns,
      reportsTabState.dataScope
    ) ||
    !isAtLeastOneBenefitTypeSelected(reportsTabState.activeBenefits) ||
    !isDateRangeSet(reportsTabState);

  return {
    footer: {
      submitText: t('containers.reports.download'),
      onSubmit: reportsTabActions.requestDownloadReport,
      disableSubmit: isDownloadDisabled
    }
  };
}
