import { takeLatest, put, select, all } from 'redux-saga/effects';
import {
  getOrderHistoryQueryPage,
  getOrderHistoryQueryRowsPerPage,
  getOrderHistoryQuerySortingBy,
  getOrderHistoryQueryDescending
} from '../selectors/orderHistory';
import { OrderHistoryConstants, ApiConstants } from '../constants/actions';
import { OrderHistorySorting } from '../constants/orderHistorySorting';

export function* setVoucherOrdersHistoryQueryParameters({ queryParameters }) {
  const queryPage = yield select(getOrderHistoryQueryPage);
  const queryRowsPerPage = yield select(getOrderHistoryQueryRowsPerPage);
  const querySortingBy = yield select(getOrderHistoryQuerySortingBy);
  const queryDescending = yield select(getOrderHistoryQueryDescending);

  let descending = queryDescending;
  if (
    queryParameters &&
    queryParameters.sortingBy &&
    queryParameters.descending === undefined
  ) {
    descending =
      queryParameters.sortingBy === querySortingBy ? !queryDescending : true;
  }

  const newQueryParameters = {
    page: (queryParameters && queryParameters.page) || queryPage,
    rowsPerPage:
      (queryParameters && queryParameters.rowsPerPage) || queryRowsPerPage,
    sortingBy:
      (queryParameters && queryParameters.sortingBy) ||
      querySortingBy ||
      OrderHistorySorting.ByDate,
    descending
  };

  yield put({
    type: `${OrderHistoryConstants.UPDATE_VOUCHER_ORDERS_HISTORY_QUERY_PARAMETERS}`,
    newQueryParameters
  });

  yield put({
    type: `${ApiConstants.LOAD_VOUCHER_ORDERS_HISTORY}_REQUEST`
  });
}

export function* resetVoucherOrdersHistoryQueryParameters() {
  yield put({
    type: `${OrderHistoryConstants.RESET_VOUCHER_ORDERS_HISTORY_QUERY_PARAMETERS}`
  });
}

export default function* watchOrderHistorySaga() {
  yield all([
    takeLatest(
      `${OrderHistoryConstants.SET_VOUCHER_ORDERS_HISTORY_QUERY_PARAMETERS}`,
      setVoucherOrdersHistoryQueryParameters
    ),
    takeLatest(
      `${ApiConstants.LOGIN_ACTION}_SUCCESS`,
      resetVoucherOrdersHistoryQueryParameters
    )
  ]);
}
