import { takeLatest, put, call, all } from 'redux-saga/effects';
import { getMaintenanceContent } from '../repositories/MaintenanceRepository';
import { StaticFilesConstants } from '../constants/actions';
import { addKeyToArray } from '../helpers/ArrayHelper';

function* loadMaintenancePageContent() {
  try {
    const fileContent = yield call(getMaintenanceContent);
    const content = yield call(addKeyToArray, fileContent.sections);
    yield put({
      type: `${StaticFilesConstants.LOAD_MAINTENANCE_CONTENT}_SUCCESS`,
      content
    });
  } catch (ex) {
    yield put({
      type: `${StaticFilesConstants.LOAD_MAINTENANCE_CONTENT}_FAILURE`,
      payload: ex
    });
  }
}

export default function* watchMaintenanceSaga() {
  yield all([
    takeLatest(
      `${StaticFilesConstants.LOAD_MAINTENANCE_CONTENT}_REQUEST`,
      loadMaintenancePageContent
    )
  ]);
}
