import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  TagComponent as Tag,
  Table,
  LunchIcon,
  RecreationalIcon,
  SimpleButton,
  MoreIcon,
  Box
} from 'edenred-ui';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { BenefitType } from '@epi-constants/benefitTypes';
import { isSupportedBenefit } from '@epi-helpers/benefitsHelper';
import { hasDatePassed, parseStringToDate } from '@epi-helpers/dateHelper';

import { BeneficiaryActions } from './BeneficiaryActions';

export function BeneficiariesRow({
  showDetail,
  beneficiary,
  resendActivationEmail,
  setEditingMode
}) {
  const { t } = useTranslation();
  const theme = useTheme();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);

  const isSupportedBenefitLunch =
    isSupportedBenefit(BenefitType.Lunch) &&
    beneficiary.benefitTypes
      ?.map(type => type.toLowerCase())
      ?.includes(BenefitType.Lunch)
      ? theme.primaryFrontColor
      : theme.altGray1;

  const isSupportedBenefitRecreational =
    isSupportedBenefit(BenefitType.Recreational) &&
    beneficiary.benefitTypes
      ?.map(type => type.toLowerCase())
      ?.includes(BenefitType.Recreational)
      ? theme.primaryFrontColor
      : theme.altGray1;

  const openMenu = event => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };

  const openBeneficiary = () => {
    showDetail(beneficiary.personId);
    closeMenu();
  };

  const closeMenu = () => {
    setMenuAnchorEl(null);
  };

  const editBeneficiary = () => {
    openBeneficiary();
    setEditingMode(true);
  };

  const resendEmail = () => {
    resendActivationEmail.callback(beneficiary.personId);
  };

  const endOfFundsDatePassed = !!hasDatePassed(
    parseStringToDate(beneficiary.endOfFunds)
  );

  return (
    <>
      <Table.Row
        sx={{ cursor: 'pointer' }}
        key={beneficiary.personId}
        onClick={openBeneficiary}
        disabled={endOfFundsDatePassed}
      >
        <Table.Cell>
          <Box display="flex" alignItems="center">
            {beneficiary.lastName} {beneficiary.firstName}
            {beneficiary.isNew && (
              <Box lineHeight="11px" ml={2}>
                <Tag label={t('containers.beneficiaries.table.new')} size="s" />
              </Box>
            )}
            {endOfFundsDatePassed && (
              <Box lineHeight="11px" ml={2}>
                <Tag
                  label={t('containers.beneficiaries.table.remove')}
                  size="s"
                  color={theme.gray70}
                  borderColor={theme.gray70}
                  fontWeight={theme.fontSemiBold}
                />
              </Box>
            )}
          </Box>
        </Table.Cell>
        <Table.Cell>{beneficiary.emailAddress}</Table.Cell>
        <Table.Cell>
          {
            <LunchIcon
              color={
                endOfFundsDatePassed ? theme.altGray1 : isSupportedBenefitLunch
              }
            />
          }
          {
            <RecreationalIcon
              color={
                endOfFundsDatePassed
                  ? theme.altGray1
                  : isSupportedBenefitRecreational
              }
            />
          }
        </Table.Cell>
        <Table.Cell align="right">
          <SimpleButton icon={<MoreIcon />} onClick={openMenu} />
        </Table.Cell>
      </Table.Row>
      <BeneficiaryActions
        anchorEl={menuAnchorEl}
        closeMenu={closeMenu}
        openModalAndFillForm={editBeneficiary}
        resendActivationEmail={resendEmail}
        endOfFundsDatePassed={endOfFundsDatePassed}
        openBeneficiary={openBeneficiary}
      />
    </>
  );
}

BeneficiariesRow.propTypes = {
  beneficiary: PropTypes.object.isRequired,
  showDetail: PropTypes.func.isRequired,
  resendActivationEmail: PropTypes.object.isRequired,
  setEditingMode: PropTypes.func.isRequired
};
