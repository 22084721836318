import { createAsyncThunk } from '@reduxjs/toolkit';

import { getReCaptchaSiteKey } from '@epi-selectors/settings';
import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import { signContractRequest } from '@epi-api/signContract';
import { showNotification } from '@epi-actions/overlays';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';

import { mapFormToContract } from '../../helpers';
import { stepsDataSelector } from '../selectors';
import { ContactRegistrationTypes } from '@epi-constants/contactRegistrationTypes';

export const signContract = createAsyncThunk(
  'contract/signContract',
  async (payload, thunkAPI) => {
    const state = thunkAPI.getState();

    const stepsData = stepsDataSelector(state);
    const reCaptchaSiteKey = getReCaptchaSiteKey(state);

    const contract = mapFormToContract(stepsData);
    const reCaptchaToken = await getRecaptchaToken(
      reCaptchaSiteKey,
      'sign_contract'
    );
    const contractWithRecaptcha = { ...contract, reCaptchaToken };

    try {
      const { token } = await signContractRequest(contractWithRecaptcha);
      return thunkAPI.dispatch(
        goToPage(
          `${routerPaths.contractSummary}?token=${token}&type=${ContactRegistrationTypes.ContractRegistration}`
        )
      );
    } catch (e) {
      return thunkAPI.dispatch(
        showNotification({ message: 'messages.error' }, 'error')
      );
    }
  }
);
