import React, { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Box } from 'edenred-ui';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';

import { ContactPersonField } from '@epi-forms/controls/ContactPersonField';
import { getFinnishAddresses } from '@epi-selectors/api';
import { EditDeliveryInfoRadio } from '@epi-forms/controls/EditDeliveryInfoRadio';
import { FormFields } from '@epi-forms/helpers';

import { DeliveryMethods } from '../../../constants/deliveryMethods';
import '../DeliveryInfo.scss';

function EditDeliveryInfo({ isLoading }) {
  const addresses = useSelector(getFinnishAddresses);
  const { getValues, setValue } = useFormContext();

  useEffect(() => {
    if (
      getValues(FormFields.DeliveryMethod) === DeliveryMethods.ToSavedAddress &&
      !isLoading &&
      addresses &&
      addresses.length === 0
    ) {
      setValue(FormFields.DeliveryMethod, DeliveryMethods.ToOtherAddress);
    }
  });

  return (
    <div className="DeliveryInfo">
      <Container fluid>
        <Box marginBottom="15px">
          <Row>
            <Col xs={12} md={6}>
              <Box margin="10px 0">
                <ContactPersonField />
              </Box>
            </Col>
          </Row>
        </Box>
        <Row>
          <Col xs={12}>
            <Box margin="10px 0">
              <EditDeliveryInfoRadio />
            </Box>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

EditDeliveryInfo.propTypes = {
  isLoading: PropTypes.bool
};

EditDeliveryInfo.defaultProps = {
  isLoading: false
};

const EditDeliveryInfoWithI18n = withTranslation()(connect()(EditDeliveryInfo));

export { EditDeliveryInfoWithI18n as EditDeliveryInfo };
