import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Modal, TitleText, ButtonMode } from 'edenred-ui';
import { useTheme } from 'styled-components';

export const CloseDetailVirike = ({
  isOpen,
  handleCloseModal,
  titleText,
  text,
  handleSubmit,
  submitText,
  cancelText
}) => {
  const { sizeMedium, gray85, fontBold } = useTheme();

  return (
    <Modal open={isOpen} onClose={handleCloseModal}>
      <Box>
        <TitleText color={gray85} fontSize={sizeMedium} fontWeight={fontBold}>
          {titleText}
        </TitleText>
      </Box>
      <Box width={305} mb={3}>
        {text}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={3}
      >
        <Button onClick={handleSubmit} id="delete-button">
          {submitText}
        </Button>
        <Button
          onClick={handleCloseModal}
          mode={ButtonMode.Link}
          id="cancel-delete-button"
        >
          {cancelText}
        </Button>
      </Box>
    </Modal>
  );
};

CloseDetailVirike.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  titleText: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  submitText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired
};
