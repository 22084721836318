import { get } from 'lodash';

// NOSONAR
const localeRegex = new RegExp(`^/([a-z]{2})(/|$)`, 'i');

export const getParameterValue = paramName => {
  const urlParams = window.location.search;
  const regex = new RegExp(`[?&]${paramName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(urlParams);
  if (!results) {
    return null;
  }
  if (!results[2]) {
    return '';
  }
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

const lastChar = str => (str && str.slice(-1)) || '';
const firstChar = str => (str && str.charAt(0)) || '';

export const join = (base, ...paths) => {
  const baseWithoutEndingSlash =
    lastChar(base) === '/' ? base.slice(0, -1) : base || '';

  const pathWithoutStartingSlash =
    firstChar(paths[0]) === '/' ? paths[0].substr(1) : paths[0] || '';

  const result = `${baseWithoutEndingSlash}/${pathWithoutStartingSlash}`;
  if (paths.length <= 1) {
    return result;
  }
  return join(result, ...paths.slice(1));
};

export const replaceParams = (str, args) => {
  const paramsToReplace = Object.keys(args || {});

  const result = paramsToReplace.reduce(
    (prev, value) =>
      prev.replace(
        new RegExp(`(/)(:${value}\\??)($|/)`, 'i'),
        `$1${args[value]}$3`
      ),
    str
  );
  // NOSONAR
  return result.replace(new RegExp(`(/)(:\\w+\\??)`, 'gi'), '');
};

export const joinLocale = (locale, path) =>
  firstChar(path) === '/' ? join('/', locale, path) : path;

export const removeLocale = url => url.replace(localeRegex, '/');

export const getLocale = url => get(url.match(localeRegex, '/'), '[1]');

export const addLocale = (locale, url) =>
  joinLocale(locale !== 'fi' ? locale : '', removeLocale(url));

export const changeUrlWithoutReloadPage = (url, page) => {
  return window.history.pushState(page, '', url + page);
};
