export const calculateComission = (benefitDetails, estimation) => {
  const benefitsComission = benefitDetails.reduce((prev, curr) => {
    return curr.checked ? prev + curr.commission + curr.commissionVAT : prev;
  }, 0);
  return (
    estimation.requestedLoads.commission +
    estimation.requestedLoads.commissionVAT +
    benefitsComission
  );
};

export const calculateSuggestedTransferAmount = (
  benefitDetails,
  estimation,
  totalComission
) => {
  const benefitsAmount = benefitDetails.reduce((prev, curr) => {
    return curr.checked ? prev + curr.value : prev;
  }, 0);
  return benefitsAmount + estimation.requestedLoads.value + totalComission;
};
