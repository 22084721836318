import { InputComponent as Input } from 'edenred-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { formatCurrencyDecimalConvert } from '@epi-helpers/numeral';

import { FieldWrap } from '../../../../components/LayoutUtils/FieldWrap';

import { SectionHeader } from '../../BeneficiariesTableStyles';
import {
  fieldWidth,
  labelWidth
} from '../BeneficiaryDetail/BeneficiaryDetailSlideoutStyles';

const BenefitsSection = ({ currentlySetBenefitValues }) => {
  const { t } = useTranslation();

  return (
    <>
      <SectionHeader>
        {t('containers.beneficiaries.detail.benefit_section.name')}
      </SectionHeader>
      {currentlySetBenefitValues?.cardLunch && (
        <FieldWrap width={fieldWidth}>
          <Input
            labelPositioning="side"
            labelWidth={labelWidth}
            label={t('containers.beneficiaries.detail.benefit_section.lunch')}
            value={`${formatCurrencyDecimalConvert(
              currentlySetBenefitValues.cardLunch
            )} ${t('containers.beneficiaries.detail.benefit_section.workday')}`}
            disabled
          />
        </FieldWrap>
      )}
      {currentlySetBenefitValues?.cardRecreational && (
        <FieldWrap width={fieldWidth}>
          <Input
            labelPositioning="side"
            labelWidth={labelWidth}
            label={t('containers.beneficiaries.detail.benefit_section.virike')}
            value={`${formatCurrencyDecimalConvert(
              currentlySetBenefitValues.cardRecreational
            )} ${t('containers.beneficiaries.detail.benefit_section.year')}`}
            disabled
          />
        </FieldWrap>
      )}
    </>
  );
};

BenefitsSection.propTypes = {
  currentlySetBenefitValues: PropTypes.object.isRequired
};

export default BenefitsSection;
