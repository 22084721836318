import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';

import PlusIcon from '@epi-assets/images/Plus.svg';

import * as Style from './AddBenefitGroupTile.styles';

export function AddBenefitGroupTile({ onClick }) {
  return (
    <Style.AddBenefitGroupTileContainer onClick={onClick}>
      <Style.AddBenefitGroup>
        <img src={PlusIcon} alt="plus icon" />
        <span>
          {i18n.t('components.manage_benefit_lunch.detail_create_title')}
        </span>
      </Style.AddBenefitGroup>
    </Style.AddBenefitGroupTileContainer>
  );
}

AddBenefitGroupTile.propTypes = {
  onClick: PropTypes.func.isRequired
};
