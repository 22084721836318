import createReducer from '../helpers/CreateReducer';
import {
  ApplicationConstants,
  VoucherReorderConstants
} from '../constants/actions';

export const getInitialState = () => ({
  voucherData: {},
  isError: false
});

export default createReducer(getInitialState, {
  [`${VoucherReorderConstants.LOAD_REORDER_DATA}_SUCCESS`]: (
    state,
    { voucherDetails }
  ) => ({
    voucherData: voucherDetails || {},
    isError: false
  }),
  [`${VoucherReorderConstants.LOAD_REORDER_DATA}_FAILURE`]: () => ({
    isError: true
  }),
  [`${ApplicationConstants.CLEAR_FORMS}`]: getInitialState,
  [`${VoucherReorderConstants.SET_REORDER_VOUCHER_AND_AMOUNT}`]: (
    state,
    { quantity, voucherId }
  ) => ({
    voucherData: { ...state.voucherData, voucherId, quantity }
  })
});
