import React, { Component } from 'react';
import { persistStore, createTransform } from 'redux-persist';
import createCompressor from 'redux-persist-transform-compress';
import { asyncSessionStorage, asyncLocalStorage } from 'redux-persist/storages';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { pick, omit } from 'lodash';
import numeral from 'numeral';
import config from '@epi-config';
import history from './routes/history';
import 'numeral/locales/fi';
import { FormPersistance } from './constants/forms';
import { reduxStore } from './store-configuration';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';
import { App } from './containers/App/App';
import '../styles/styles.scss';
export class AppProvider extends Component {
  constructor() {
    super();
    numeral.locale('fi');

    this.state = { rehydrated: false };
  }

  componentDidMount() {
    const valuesPathsToForget = this.getPathsToForget(
      FormPersistance.forget_values,
      'values'
    );
    const fieldsPathsToForget = this.getPathsToForget(
      FormPersistance.forget_fields,
      'fields'
    );
    const forgetPaths = [...valuesPathsToForget, ...fieldsPathsToForget];

    const transformSavedValues = createTransform(
      // transform state coming from redux on its way to being serialized and stored
      (inboundState, key) => {
        if (key === 'form') {
          const formsToPersist = pick(inboundState, FormPersistance.forms);

          return omit(formsToPersist, forgetPaths);
        }

        // Do not persist if object has the property 'noPersistance'
        if (inboundState.noPersistance) {
          return undefined;
        }

        return inboundState;
      },
      // transform state coming from storage, on its way to be rehydrated into redux
      outboundState => outboundState
    );

    const transforms = config.disablePersistanceCompression
      ? [transformSavedValues]
      : [transformSavedValues, createCompressor()];

    persistStore(
      reduxStore,
      {
        storage: config.enablePersistanceOnLocalStorage
          ? asyncLocalStorage
          : asyncSessionStorage,
        transforms
      },
      () => {
        this.setState({ rehydrated: true });
      }
    );
  }

  getPathsToForget = (forgetConfig, reduxFormProps) => {
    const forms = Object.getOwnPropertyNames(forgetConfig);
    const fieldsToForgetPaths = forms
      .map(form => {
        const fields = forgetConfig[form];
        return fields.map(field => `${form}.${reduxFormProps}.${field}`);
      })
      .reduce((a, b) => a.concat(b));
    return fieldsToForgetPaths;
  };

  render() {
    const { rehydrated } = this.state;
    if (!rehydrated) {
      return <div>Loading...</div>; // Here can be added a loading animation
    }
    return (
      <Provider store={reduxStore}>
        <Router history={history}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </Router>
      </Provider>
    );
  }
}
