import { takeLatest, put, all, take } from 'redux-saga/effects';
import { BeneficiaryConstants, ApiConstants } from '@epi-constants/actions';
import { getBenefitGroupSettings } from '@epi-actions/api';

export function* initializeBenefitSettingsContainer() {
  yield put(getBenefitGroupSettings());
  yield take(`${ApiConstants.BENEFIT_GROUP_SETTINGS}_SUCCESS`);
}

export default function* watchBeneficiarySaga() {
  yield all([
    takeLatest(
      `${BeneficiaryConstants.INITIALIZE_BENEFIT_SETTINGS_CONTAINER}`,
      initializeBenefitSettingsContainer
    )
  ]);
}
