const requestStartSuffix = '_REQUEST';
const requestSuccessSuffix = '_SUCCESS';
const requestFailedSuffix = '_FAILED';

function removeSuffix(input, suffix) {
  return input.substr(0, input.length - suffix.length);
}

export default (previousState = {}, action) => {
  const { type } = action;
  if (type.endsWith(requestStartSuffix)) {
    return {
      ...previousState,
      ...{
        [removeSuffix(type, requestStartSuffix)]: {
          isLoading: true,
          isLoaded: false
        }
      }
    };
  }

  if (type.endsWith(requestSuccessSuffix)) {
    return {
      ...previousState,
      ...{
        [removeSuffix(type, requestSuccessSuffix)]: {
          isLoading: false,
          isLoaded: true
        }
      }
    };
  }

  if (type.endsWith(requestFailedSuffix)) {
    return {
      ...previousState,
      ...{
        [removeSuffix(type, requestFailedSuffix)]: {
          isLoading: false,
          isLoaded: false
        }
      }
    };
  }

  return previousState;
};
