import { ApiConstants } from '../../constants/actions';
import createReducer from '../../helpers/CreateReducer';

export const getInitialState = () => ({
  isAdding: false
});

export default createReducer(getInitialState, {
  [`${ApiConstants.ADD_SIGNATORY_ROLE_TO_USER}_REQUEST`]: () => ({
    ...getInitialState(),
    isAdding: true
  }),
  [`${ApiConstants.ADD_SIGNATORY_ROLE_TO_USER}_SUCCESS`]: () => ({
    isAdding: false
  }),
  [`${ApiConstants.ADD_SIGNATORY_ROLE_TO_USER}_FAILURE`]: () => ({
    ...getInitialState()
  })
});
