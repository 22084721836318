import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonMode, Modal, Box, Button } from 'edenred-ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { getUserName } from '@epi-selectors/profile';

const ModalContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ModalTitle = styled(Box)`
  font-size: ${({ theme }) => theme.fontSize};
  font-weight: ${({ theme }) => theme.fontBold};
`;

const ModalMessage = styled(Box)`
  font-size: ${({ theme }) => theme.fontSize};
  margin: 24px 0;
`;

export function ContactDeletionModal({
  showModalByError,
  handleCloseModal,
  contactToDelete,
  deleteContact
}) {
  const { t } = useTranslation();
  const currentUsername = useSelector(getUserName);
  const { showModal, modalError } = showModalByError;

  const getDeleteModalContent = () => {
    if (modalError) {
      return (
        <>
          <ModalTitle>
            {t(`company_info.popup.${modalError}.title`) ||
              t(`company_info.popup.general_error.title`)}
          </ModalTitle>
          <ModalMessage>
            {t(`company_info.popup.${modalError}.message`) ||
              t(`company_info.popup.general_error.message`)}
          </ModalMessage>
          <Button onClick={handleCloseModal}>
            {t('company_info.popup.buttons.ok')}
          </Button>
        </>
      );
    }

    if (currentUsername === contactToDelete.userName) {
      return (
        <>
          <ModalTitle>
            {t('company_info.popup.cannot_delete_yourself.title')}
          </ModalTitle>
          <ModalMessage>
            {t('company_info.popup.cannot_delete_yourself.message')}
          </ModalMessage>
          <Button onClick={handleCloseModal}>
            {t('company_info.popup.buttons.ok')}
          </Button>
        </>
      );
    }

    return (
      <>
        <ModalTitle>
          {t('company_info.popup.confirmation.title', {
            firstname: contactToDelete.firstName,
            lastname: contactToDelete.lastName
          })}
        </ModalTitle>
        <ModalMessage>
          {t('company_info.popup.confirmation.message')}
        </ModalMessage>
        <Box marginBottom="24px">
          <Button onClick={deleteContact}>
            {t('company_info.popup.buttons.delete')}
          </Button>
        </Box>
        <Button mode={ButtonMode.Link} onClick={handleCloseModal}>
          {t('company_info.popup.buttons.cancel')}
        </Button>
      </>
    );
  };

  return (
    <Modal open={showModal} onClose={handleCloseModal}>
      <ModalContent>{getDeleteModalContent()}</ModalContent>
    </Modal>
  );
}

ContactDeletionModal.propTypes = {
  showModalByError: PropTypes.PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string
  ]).isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  contactToDelete: PropTypes.object.isRequired,
  deleteContact: PropTypes.func.isRequired
};
