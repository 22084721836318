import React from 'react';
import PropTypes from 'prop-types';

function DisplayAddress({ address }) {
  if (!address) {
    return null;
  }

  return (
    <div className="header3 small">
      {address && `${address.addressLine},`}
      <br />
      {address && `${address.postCode} ${address.city}`}
    </div>
  );
}

DisplayAddress.propTypes = {
  address: PropTypes.object.isRequired
};

export default DisplayAddress;
