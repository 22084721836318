import React from 'react';
import { call, put, select } from 'redux-saga/effects';
import {
  downloadOrderDocument as downloadOrderDocumentRepo,
  downloadOrderDocumentAfterPayment
} from '../../repositories/OrderDocumentsRepository';
import { getLoginState } from '../../selectors/login';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { OverlaysConstants, ApiConstants } from '../../constants/actions';
import { TranslationResource } from '../../helpers/TranslationResource';

function* downloadVoucherOrderInvoice(voucherOrderId) {
  try {
    const login = yield select(getLoginState);
    yield call(
      downloadOrderDocumentRepo,
      login.accessToken,
      login.tokenType,
      voucherOrderId
    );
    yield put({
      type: `${ApiConstants.DOWNLOAD_INVOICE}_SUCCESS`,
      idType: 'order',
      id: voucherOrderId
    });
  } catch (error) {
    if (error.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    } else {
      yield put({
        type: `${OverlaysConstants.SHOW_ERROR_NOTIFICATION}`,
        message: <TranslationResource message="messages.download_file_failed" />
      });
    }
    yield put({
      type: `${ApiConstants.DOWNLOAD_INVOICE}_FAILURE`,
      payload: error
    });
  }
}

function* downloadOrderDocumentByPayment(paymentId) {
  try {
    yield call(downloadOrderDocumentAfterPayment, paymentId);
    yield put({
      type: `${ApiConstants.DOWNLOAD_INVOICE}_SUCCESS`,
      idType: 'payment',
      id: paymentId
    });
  } catch (error) {
    if (error.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    } else {
      yield put({
        type: `${OverlaysConstants.SHOW_ERROR_NOTIFICATION}`,
        message: <TranslationResource message="messages.download_file_failed" />
      });
    }
  }
}

export function* downloadInvoice({ idType, id }) {
  if (idType === 'order') {
    yield call(downloadVoucherOrderInvoice, id);
  } else {
    yield call(downloadOrderDocumentByPayment, id);
  }
}
