import { OrderType } from '@epi-constants/orderType';
import createReducer from '../../helpers/CreateReducer';
import { getConstantValue } from '../../helpers/ConstantHelper';
import { ApiConstants } from '../../constants/actions';
import { PaymentMethods } from '../../constants/paymentMethods';
import { PaymentStatuses } from '../../constants/paymentStatuses';

export const getInitialState = () => ({
  status: '',
  paymentMethod: '',
  forwardEmail: '',
  orderType: '',
  isLoading: true,
  isError: false,
  isUnauthorized: false,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ApiConstants.GET_PAYMENT_STATUS}_REQUEST`]: () => ({
    isLoading: true,
    isError: false,
    isUnauthorized: false
  }),
  [`${ApiConstants.GET_PAYMENT_STATUS}_SUCCESS`]: (state, { payload }) => ({
    status: getConstantValue(PaymentStatuses, payload.status),
    paymentMethod: getConstantValue(PaymentMethods, payload.paymentMethod),
    forwardEmail: payload.forwardEmail,
    orderType:
      payload.orderType.toLowerCase() === OrderType.voucher
        ? OrderType.voucher
        : OrderType.digital,
    isError: false,
    isUnauthorized: false,
    isLoading: false
  }),
  [`${ApiConstants.GET_PAYMENT_STATUS}_FAILURE`]: (
    state,
    { isUnauthorized }
  ) => ({
    isError: true,
    isUnauthorized: isUnauthorized || false,
    isLoading: false
  })
});
