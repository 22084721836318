import { put, call, select } from 'redux-saga/effects';
import { isEqual, omit } from 'lodash';
import { checkIfCompanyExists as checkIfCompanyExistsRepository } from '../../repositories/CompaniesRepository';
import { getLastCompanyInformation } from '../../selectors/company';
import { ApiConstants } from '../../constants/actions';
import * as overlaysActions from '../../actions/overlays';

export function* shouldSkipChecking(company) {
  const lastCompany = yield select(getLastCompanyInformation);
  return isEqual(omit(lastCompany, 'sizeId'), omit(company, 'sizeId'));
}

export default function* checkIfCompanyExists({ company, callback }) {
  try {
    const skip = yield call(shouldSkipChecking, company);
    if (skip) {
      yield put({
        type: `${ApiConstants.CHECK_IF_COMPANY_EXISTS}_FINISHED`
      });
      yield callback && call(callback);
      return;
    }

    const response = yield call(checkIfCompanyExistsRepository, company);

    yield put({
      type: `${ApiConstants.CHECK_IF_COMPANY_EXISTS}_SUCCESS`,
      companyAlreadyExists: response,
      lastCompanyInformation: company
    });
    if (!response) {
      yield put({
        type: `${ApiConstants.CHECK_IF_COMPANY_EXISTS}_FINISHED`
      });
      yield callback && call(callback);
    }
  } catch (ex) {
    yield put({
      type: `${ApiConstants.CHECK_IF_COMPANY_EXISTS}_FAILURE`,
      payload: ex,
      isError: true
    });
    yield put(
      overlaysActions.showNotification(
        { message: 'messages.voucher_order_failed' },
        'error'
      )
    );
  }
}
