import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { Box, PagePanel } from 'edenred-ui';

import * as orderHistoryActions from '@epi-actions/orderHistory';
import * as apiActions from '@epi-actions/api';
import { Pagination } from '@epi-shared/components';
import { getInitialState } from '../../../../reducers/orderHistory';
import { VoucherOrderHistoryTable } from '../VoucherOrderHistoryTable';

function VoucherOrderHistory({
  voucherOrders,
  totalPages,
  loadVoucherOrdersHistory,
  clearSavedOrderHistory
}) {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const orderHistoryInitialState = getInitialState();
  const initialRowsPerPage =
    orderHistoryInitialState.queryParameters.rowsPerPage;

  useEffect(() => {
    loadVoucherOrdersHistory();
    return () => {
      clearSavedOrderHistory();
    };
  }, []);

  const handleChangePage = newPage => {
    setPage(newPage);
    loadVoucherOrdersHistory({ page: newPage });
  };

  const handleChangeRowsPerPage = newRowsPerPage => {
    setPage(1);
    loadVoucherOrdersHistory({ rowsPerPage: newRowsPerPage, page: 1 });
  };

  const hasVoucherOrders = voucherOrders && voucherOrders.length > 0;
  const showPagination = totalPages > 1;

  return (
    hasVoucherOrders && (
      <Box mt={-5}>
        <PagePanel title={t('paper_vouchers.order_history.title')}>
          <VoucherOrderHistoryTable />
          {showPagination && (
            <Box mt={3}>
              <Pagination
                page={page}
                count={totalPages}
                rowsPerPageOptions={[5, 10, 15]}
                defaultRowsPerPage={initialRowsPerPage}
                onChangePage={handleChangePage}
                onRowPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          )}
        </PagePanel>
      </Box>
    )
  );
}

VoucherOrderHistory.propTypes = {
  voucherOrders: PropTypes.array.isRequired,
  totalPages: PropTypes.number.isRequired,
  clearSavedOrderHistory: PropTypes.func.isRequired,
  loadVoucherOrdersHistory: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  voucherOrders: state.apiVoucherOrdersHistory.voucherOrders,
  totalPages: state.apiVoucherOrdersHistory.totalPages
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...orderHistoryActions,
      ...apiActions
    },
    dispatch
  );

const ConnectedVoucherOrderHistory = connect(
  mapStateToProps,
  mapDispatchToProps
)(VoucherOrderHistory);

export { ConnectedVoucherOrderHistory as VoucherOrderHistory };
