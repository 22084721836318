import { jwtDecode } from 'jwt-decode';

const SECOND_AS_MS = 1000;

export const setImpersonationRequest = token => {
  const dataFromToken = jwtDecode(token.accessToken);
  const today = new Date();
  const expireDay = new Date(dataFromToken.exp * SECOND_AS_MS);

  return {
    tokenType: 'Bearer',
    accessToken: token.accessToken,
    expiresIn: expireDay - today,
    refreshToken: '',
    roles: token.roles,
    validTo: dataFromToken.exp,
    isUserImpersonate: true
  };
};
