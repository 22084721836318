import styled from 'styled-components';

const sizeToValue = size => {
  let value;
  if (size === 'S') {
    value = '1rem';
  } else if (size === 'M') {
    value = '2rem';
  } else if (size === 'L') {
    value = '3rem';
  } else if (size === 'XL') {
    value = '4rem';
  } else if (size === 'XXL') {
    value = '5rem';
  } else {
    value = '0';
  }
  return value;
};

const Pad = styled.div`
  padding: ${({ vertical, horizontal }) => {
    return `${sizeToValue(vertical)} ${sizeToValue(horizontal)}`;
  }};
`;

export default Pad;
