import 'usertiming';
import isEmpty from 'lodash/isEmpty';

/**
 *  Simple UI speed measurement helper. Uses Performance interface provided by modern browsers,
 *  and applies polyfill for older ones. Not as heavy as React's own Perf tools, which should not be used in prod.
 *
 *  Use to add 2 'marks' to UI code (preferably one before XHR and one after render) and one measure to count time
 *  difference between them. Performance interface returns DOMHighResTimeStamp type of time value.
 *
 *  addMeasure will also trigger logMeasurements and clear previous marks and measurements, this can be improved
 *  in the future if this kind of tool if found useful. logMeasurements will push values to Google Analytics via
 *  Google Tag Manager provided dataLayer.
 *
 *  Support for older browsers and Safari is provided via usertiming.js polyfill.
 */

const { performance } = window;

export const MEASURES = {
  HOME: 'Load_Home',
  VOUCHER_QUANTITY: 'Load_Voucher_Quantity',
  ORDER_SUMMARY: 'Load_Order_Summary',
  SUBMIT_ORDER: 'Submit_Order',
  PAYMENT_METHOD: 'Load_Payment_Method',
  REORDER_SUMMARY: 'Load_ReOrder_Summary',
  SUBMIT_REORDER: 'Submit_ReOrder',
  ORDER_HISTORY: 'Load_Order_History',
  SUBMIT_ORDER_ONLINE_PAYMENT: 'Submit_Order_Online_Payment',
  SIGN_UP: 'Load_Sign_Up'
};

function startName(name) {
  return `${name}_start`;
}

function endName(name) {
  return `${name}_end`;
}

function isMeasureStarted(name) {
  const isStarted = performance.getEntriesByName(startName(name), 'mark');
  return isStarted && !isEmpty(isStarted);
}

function getActiveMeasure() {
  const measure = performance.getEntriesByType('mark');
  if (measure && measure[0]) {
    return measure[0].name.replace('_start', '');
  }
  return '';
}

function logMeasurements() {
  const overtimeLimit = 120; // if the measurement exceed this limit (seconds), we just consider it as an error
  const measurements = performance.getEntriesByType('measure');

  const data = measurements.map(m => ({
    event: 'uiTiming',
    timingCategory: 'UI speed measurements',
    timingVariable: m.name,
    timingLabel: 'load',
    timingValue: m.duration
  }));

  data.forEach(d => {
    if (window.dataLayer && d.timingValue < overtimeLimit * 1000) {
      window.dataLayer.push(d);
    } else {
      console.log(d);
    }
  });

  performance.clearMeasures();
  performance.clearMarks();
}

function start(name) {
  performance.mark(startName(name));
}

function stop(name) {
  const isStarted = performance.getEntriesByName(startName(name), 'mark');
  if (isStarted && !isEmpty(isStarted)) {
    performance.mark(endName(name));
    performance.measure(name, startName(name), endName(name));
    logMeasurements();
  }
}

function errorOn(name) {
  performance.clearMarks(startName(name));
  performance.clearMarks(endName(name));
}

function debouncer(wait) {
  let timeoutKey = null;

  return func => {
    if (timeoutKey) {
      clearTimeout(timeoutKey);
    }
    timeoutKey = setTimeout(() => {
      func();
      timeoutKey = null;
    }, wait);
  };
}

export default {
  start,
  stop,
  errorOn,
  isMeasureStarted,
  getActiveMeasure,
  debouncer
};
