import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Button,
  ButtonMode,
  Notification,
  NotificationType,
  ButtonSize
} from 'edenred-ui';

import { routerPaths } from '@epi-constants/routerPaths';
import { goToPage } from '@epi-actions/navigation';
import { TopSection } from '../../BeneficiariesListStyles';

function BeneficiariesTopSection({
  showBeneficiaryAddedNotification,
  setShowBeneficiaryAddedNotification,
  dispatch
}) {
  const { t } = useTranslation();

  return (
    <TopSection>
      <div className="employee-added-notification">
        {showBeneficiaryAddedNotification && (
          <Notification
            type={NotificationType.Success}
            text={
              <Box display="flex" alignItems="center">
                <Box flexGrow={1} mr={1}>
                  {t('employees.employee_added_notification')}
                </Box>
                <Box>
                  <Button
                    id="go-to-transfer-money-btn"
                    mode={ButtonMode.Secondary}
                    size={ButtonSize.Medium}
                    onClick={() =>
                      dispatch(goToPage(routerPaths.loadCompanyAccount))
                    }
                  >
                    {t('employees.employee_added_go_to_transfer_money_btn')}
                  </Button>
                </Box>
              </Box>
            }
            onClose={() => setShowBeneficiaryAddedNotification(false)}
          />
        )}
      </div>
    </TopSection>
  );
}

BeneficiariesTopSection.propTypes = {
  showBeneficiaryAddedNotification: PropTypes.bool.isRequired,
  setShowBeneficiaryAddedNotification: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired
};

export default connect()(BeneficiariesTopSection);
