import { createSelector } from 'reselect';

const contractSelector = state => state.contract;

export const activeStepSelector = createSelector(
  contractSelector,
  contractState => contractState.activeStep
);

export const stepsDataSelector = createSelector(
  contractSelector,
  contractState => contractState.stepsData
);

export const isProcessingSignContractSelector = createSelector(
  contractSelector,
  contractState => contractState.isProcessingSignContract
);
