import React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import SetUpAccountImg from '@epi-assets/images/SetUpAccount.jpg';
import { useNewCustomer } from '@epi-pages/Home/hooks/useNewCustomer';

import { Box, ImagePanel, Heading1, List, ListType } from 'edenred-ui';

import { ListContent } from './ListContent';

import { HomeNotification } from '../HomeNotification';

export const NewCustomer = ({ data }) => {
  const { t } = useTranslation();
  const { gray0 } = useTheme();
  const { isBenefitsSet, isEmployeesSet, isMoneyTransfered } = data;
  const {
    handleClickManageBenefits,
    handleClickBeneficiaries,
    handleClickLoadCompanyAccount
  } = useNewCustomer();

  return (
    <Box display="flex" justifyContent="center">
      <HomeNotification />
      <Box minHeight={328} pt={5} mx={2.5} maxWidth={1336}>
        <ImagePanel
          imageSrc={SetUpAccountImg}
          reverse
          dismissId="set-up-account-dismiss"
        >
          <Box height="100%" p={5} bgcolor={gray0}>
            <Heading1>{t('home.set_up_account.title')}</Heading1>
            <Box mt={4} mb={3}>
              <List
                type={ListType.Number}
                items={[
                  {
                    content: ListContent(
                      t('home.set_up_account.set_benefits'),
                      handleClickManageBenefits
                    ),
                    isChecked: isBenefitsSet
                  },
                  {
                    content: ListContent(
                      t('home.set_up_account.add_employees'),
                      handleClickBeneficiaries
                    ),
                    isChecked: isEmployeesSet
                  },
                  {
                    content: ListContent(
                      t('home.set_up_account.transfer_money'),
                      handleClickLoadCompanyAccount
                    ),
                    isChecked: isMoneyTransfered
                  }
                ]}
              />
            </Box>
          </Box>
        </ImagePanel>
      </Box>
    </Box>
  );
};

NewCustomer.propTypes = {
  data: PropTypes.object
};
