import React, { useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { useForm, FormProvider } from 'react-hook-form';
import { isEmpty } from 'lodash';

import {
  countries as countriesLoadingSelector,
  reorderData as reorderDataLoadingSelector,
  createVoucherReorder as createVoucherReorderLoadingSelector,
  disablePayment as disablePaymentLoadingSelector
} from '@epi-selectors/loading';
import {
  orderSummary as orderSummarySelector,
  getVoucherTypeKey,
  isError as isErrorSelector,
  getVoucherReorder
} from '@epi-selectors/voucherReorder';
import { getApplicationLocale } from '@epi-selectors/application';
import { getUserCompany } from '@epi-selectors/api';
import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import { FormConstants } from '@epi-constants/forms';
import { FormFields } from '@epi-forms/helpers';
import { getOrderData } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.selectors';
import { registeredVoucherOrderAction } from '@epi-store/registeredVoucherOrder/registeredVoucherOrder.slice';

import { getReCaptchaSiteKey } from '../../selectors/settings';
import { OrderType } from '../../constants/orderType';
import { routerPaths } from '../../constants/routerPaths';
import { replaceParams } from '../../helpers/UrlHelpers';
import { FormConstants as forms } from '../../constants/forms';
import { ApiConstants } from '../../constants/actions';
import { PaymentMethods } from '../../constants/paymentMethods';
import { scrollToInvalidField } from '../../helpers/DocumentHelper';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { DeliveryAndPayment } from '../../components/DeliveryAndPayment/DeliveryAndPayment';
import { NavigationHeader } from '../../components/NavigationHeader/NavigationHeader';
import { OrderButton } from '../../components/OrderButton/OrderButton';
import { VoucherTypes } from '../../constants/voucherTypes';
import * as applicationActions from '../../actions/application';
import * as voucherReorderActions from '../../actions/voucherReorder';
import * as apiActions from '../../actions/api';

import { useVoucherOrder } from './hooks/useVoucherOrder';
import './VoucherOrder.scss';

function VoucherReOrder({
  loadReorderData,
  i18n,
  voucherTypeKey,
  isLoadingReorderData,
  isCreatingVoucherReorder,
  userCompany,
  apiPaymentMethods,
  disablePayment,
  locale,
  match: {
    params: { orderId }
  },
  reCaptchaSiteKey,
  createVoucherReorder,
  loadPaymentMethods
}) {
  const dispatch = useDispatch();
  const { composeReorder } = useVoucherOrder();
  const voucherReorderDetails = useSelector(getVoucherReorder);
  const orderData = useSelector(getOrderData);
  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.PaymentMethod]: '',
      [FormFields.PaymentMethodCode]: '',
      [FormFields.AcceptTerms]: false
    }
  });
  const {
    formState: { isValid, errors },
    handleSubmit,
    trigger,
    getValues
  } = form;
  const paymentMethodValue = getValues(FormFields.PaymentMethod);
  const orderSummary = useSelector(
    orderSummarySelector(
      paymentMethodValue,
      orderData.totalQuantity,
      orderData.voucherWorth
    )
  );

  const handleLoadingPaymentMethods = () => {
    if (voucherReorderDetails?.totalAmount) {
      loadPaymentMethods(
        OrderType.voucher,
        voucherReorderDetails?.totalAmount,
        locale
      );
    }
  };

  const onSubmit = async values => {
    trigger();

    if (!isValid) {
      return;
    }

    const reCaptchaToken = await getRecaptchaToken(
      reCaptchaSiteKey,
      forms.REORDER_FORM
    );
    const payload = composeReorder({ ...orderData, ...values, reCaptchaToken });
    createVoucherReorder(payload);
  };

  const submitExternalPayment = async () => {
    onSubmit(getValues());
  };

  const isPaidOnline = [
    PaymentMethods.CreditCard,
    PaymentMethods.Online
  ].includes(paymentMethodValue);

  useEffect(() => {
    handleLoadingPaymentMethods();
    loadReorderData(orderId);
  }, []);

  useEffect(() => {
    dispatch(
      registeredVoucherOrderAction.addOrderInformation(voucherReorderDetails)
    );
  }, [voucherReorderDetails]);

  return (
    <NavigationHeader title={i18n.t('containers.voucher_reorder.title')}>
      <div className="VoucherOrder reorder">
        <FormProvider {...form}>
          <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <DeliveryAndPayment
              voucherTypeKey={voucherTypeKey}
              editAmountPage={replaceParams(
                routerPaths.voucherReorderEditAmount,
                {
                  voucherType: VoucherTypes[voucherTypeKey],
                  orderId
                }
              )}
              editDeliveryPage={replaceParams(
                routerPaths.voucherReorderEditDelivery,
                {
                  voucherType: VoucherTypes[voucherTypeKey],
                  orderId
                }
              )}
              editCompanyPage={replaceParams(
                routerPaths.voucherReorderEditCompany,
                {
                  voucherType: VoucherTypes[voucherTypeKey],
                  orderId
                }
              )}
              isLoading={isLoadingReorderData}
              orderSummary={orderSummary}
              apiPaymentMethods={apiPaymentMethods}
              onClickPaymentMethod={submitExternalPayment}
              userCompany={userCompany}
              locale={locale}
              paymentMethod={paymentMethodValue}
              disabled={disablePayment}
              formType={FormConstants.REORDER_FORM}
              voucherReorderDetails={orderData}
            />
            <ErrorPanel failedActionName={ApiConstants.CREATE_VOUCHER_ORDER} />
            {!isPaidOnline && (
              <OrderButton
                disabled={!isValid || !isEmpty(errors)}
                currentPage={1}
                totalPages={1}
                isLoading={isCreatingVoucherReorder}
                onClick={() => {
                  if (!isValid) {
                    scrollToInvalidField();
                  }
                }}
              />
            )}
          </form>
        </FormProvider>
      </div>
    </NavigationHeader>
  );
}

VoucherReOrder.propTypes = {
  loadReorderData: PropTypes.func.isRequired,
  loadPaymentMethods: PropTypes.func.isRequired,
  voucherTypeKey: PropTypes.string.isRequired,
  isLoadingReorderData: PropTypes.bool.isRequired,
  isCreatingVoucherReorder: PropTypes.bool.isRequired,
  orderSummary: PropTypes.object.isRequired,
  apiPaymentMethods: PropTypes.object.isRequired,
  paymentMethod: PropTypes.string,
  locale: PropTypes.string.isRequired,
  userCompany: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  createVoucherReorder: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  disablePayment: PropTypes.bool,
  reCaptchaSiteKey: PropTypes.string.isRequired
};

VoucherReOrder.defaultProps = {
  paymentMethod: '',
  disablePayment: false
};

const mapStateToProps = state => ({
  apiPaymentMethods: state.apiPaymentMethods,
  reCaptchaSiteKey: getReCaptchaSiteKey(state),
  locale: getApplicationLocale(state),
  isLoadingCountries: countriesLoadingSelector(state),
  orderSummary: orderSummarySelector(state),
  voucherTypeKey: getVoucherTypeKey(state),
  userCompany: getUserCompany(state),
  isLoadingReorderData: reorderDataLoadingSelector(state),
  isCreatingVoucherReorder: createVoucherReorderLoadingSelector(state),
  isError: isErrorSelector(state),
  disablePayment: disablePaymentLoadingSelector(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...apiActions,
      ...applicationActions,
      ...voucherReorderActions
    },
    dispatch
  );

const ConnectVoucherReOrder = connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(VoucherReOrder));

export { ConnectVoucherReOrder as VoucherReOrder };
