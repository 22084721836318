import { call } from 'redux-saga/effects';
import download from 'downloadjs';
import { getAuthorizationHeader } from '@epi-repositories/ApiRepository';
import { parse as parseContentDisposition } from 'content-disposition';
import { createHeaders } from './FetchHelpers';
import { Authorize } from '../routes/Authorize';

export function* downloadFile(url, headers, defaultFilename = 'file') {
  const method = 'GET';
  const resp = yield call(fetch, url, {
    method,
    headers: createHeaders(headers)
  });

  if (resp.status >= 300) {
    return Promise.reject(new Error());
  }

  const dispositionHeader = resp.headers.get('content-disposition');
  const disposition = parseContentDisposition(dispositionHeader);
  const dispositionFilename =
    disposition && disposition.parameters && disposition.parameters.filename;

  const blob = yield resp.blob();
  return yield call(download, blob, dispositionFilename || defaultFilename);
}

export function* downloadFileAuthenticated(url, defaultFilename = 'file') {
  const header = yield call(getAuthorizationHeader, Authorize.Always);
  return yield call(downloadFile, url, header, defaultFilename);
}

export default {
  downloadFile
};
