import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import i18n from 'i18next';

import { routerPaths } from '../../constants/routerPaths';

const RequestedLoadsSummaryStyle = styled.section`
  .requested-loads-summary {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    font-size: 15px;
    margin: 2rem 2rem;

    &__disclaimer {
      font-size: 12px;
      margin-left: 2rem;
    }
  }
`;

const RequestedLoadsSummary = ({
  totalRequestedLoads,
  totalRequestedLoadsAmount,
  currentBalance
}) => {
  if (totalRequestedLoads === 0 || totalRequestedLoadsAmount === 0) {
    return null;
  }

  return (
    <RequestedLoadsSummaryStyle>
      <div className="requested-loads-summary">
        {totalRequestedLoads && (
          <span>
            {`${totalRequestedLoads} ${i18n.t(
              'containers.deposit_money.requested_loads.summary'
            )}:
          ${totalRequestedLoadsAmount} €`}
          </span>
        )}
        {currentBalance && (
          <span>
            {i18n.t('containers.deposit_money.current_balance_label')}
            {`: ${currentBalance} €`}
          </span>
        )}
      </div>
      {totalRequestedLoads && (
        <p className="requested-loads-summary__disclaimer">
          <Trans
            defaults={i18n.t(
              'containers.deposit_money.requested_loads.description'
            )}
            components={{ a: <Link to={routerPaths.transactionsHistory} /> }}
          />
        </p>
      )}
    </RequestedLoadsSummaryStyle>
  );
};

RequestedLoadsSummary.propTypes = {
  totalRequestedLoads: PropTypes.number,
  totalRequestedLoadsAmount: PropTypes.number,
  currentBalance: PropTypes.number
};

RequestedLoadsSummary.defaultProps = {
  totalRequestedLoads: null,
  totalRequestedLoadsAmount: null,
  currentBalance: null
};

export default RequestedLoadsSummary;
