import { put, call } from 'redux-saga/effects';
import { getSettings } from '../../repositories/SettingsRepository';
import { ApiConstants, OverlaysConstants } from '../../constants/actions';

export default function* loadSettings() {
  try {
    const settings = yield call(getSettings);
    yield put({
      type: `${ApiConstants.LOAD_SETTINGS}_SUCCESS`,
      payload: settings
    });
  } catch (ex) {
    yield put({
      type: `${ApiConstants.LOAD_SETTINGS}_FAILURE`,
      payload: ex
    });
    yield put({
      type: `${OverlaysConstants.SHOW_ERROR_POPUP}`,
      closable: false,
      error: ex
    });
  }
}
