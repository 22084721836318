import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PagePanel, Box, Button, NoLinkIcon, LoadingSpinner } from 'edenred-ui';
import { useDispatch } from 'react-redux';

import { EmptyState } from '@epi-shared/components';
import { requestConfirmationLinkApiUrl } from '@epi-repositories/EmailRepository';
import { showNotification } from '@epi-actions/overlays';
import { IErrorMessage } from '@epi-models/pages/EmailConfirmation';
import { fetchPost as Post } from '@epi-helpers/FetchHelpers';

export const ErrorMessage = ({
  username,
  setIsError,
  setIsLinkSent
}: IErrorMessage) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const getNewConfirmationLinkRequest = () => {
    setIsLoading(true);

    const onSuccess = () => {
      setIsLoading(false);
      setIsError(false);
      setIsLinkSent(true);
    };
    const onFailure = () => {
      setIsLoading(false);
      dispatch(
        showNotification(
          { message: 'email_verification.get_new_verification_link_fail' },
          'error'
        )
      );
    };

    return Post(
      requestConfirmationLinkApiUrl,
      { username },
      onSuccess,
      onFailure
    );
  };

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <PagePanel>
      <EmptyState
        icon={<NoLinkIcon />}
        title={t('email_verification.error_title')}
        description={t('email_verification.link_expired')}
      >
        <Box mt={6.5} mb={20}>
          <Button
            id="request-new-link-btn"
            onClick={getNewConfirmationLinkRequest}
          >
            {t('email_verification.request_new_link_btn')}
          </Button>
        </Box>
      </EmptyState>
    </PagePanel>
  );
};
