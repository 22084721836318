import { call, select } from 'redux-saga/effects';
import { ensureDataLoaded } from '../saga';
import { getVouchers as getVouchersSelector } from '../../selectors/api';
import { ApiConstants } from '../../constants/actions';

export function* getVouchers() {
  yield call(ensureDataLoaded, ApiConstants.LOAD_VOUCHERS);
  return yield select(getVouchersSelector);
}

export function* getVoucherById(voucherId) {
  return (yield getVouchers()).find(v => v.id === voucherId) || {};
}

export function* getVoucherFaceValueById(voucherId) {
  return (yield getVoucherById(voucherId)).value;
}

export default { getVoucherById, getVoucherFaceValueById };
