import { isArray, keys } from 'lodash';
import { ErrorSeverity } from '@epi-constants/errorSeverity';

export const getDefaultMessage = i18n => {
  return i18n ? i18n.t('messages.error') : 'messages.error';
};

function createErrorData(message) {
  return {
    message,
    severity: ErrorSeverity.Error
  };
}

export function createErrorResponse(messages) {
  return {
    canRetry: false,
    errors: messages.map(m => createErrorData(m))
  };
}

export function getSingleErrorDataString(error, i18n) {
  let stringValue = ``;
  if (error.fieldName) {
    stringValue += `${error.fieldName}`;
  }

  if (error.elementIndex !== null && error.elementIndex !== undefined) {
    stringValue += `[${error.elementIndex}]`;
  }

  if (stringValue.length > 0) {
    stringValue += ':\t';
  }

  const message = error.message || getDefaultMessage(i18n);

  stringValue += message;

  if (error.code) {
    stringValue += `\t(${error.code})`;
  }

  return stringValue;
}

export function getErrorDataString(errorResponseContent, i18n) {
  if (!errorResponseContent) {
    return getDefaultMessage();
  }
  if (errorResponseContent.errors) {
    return errorResponseContent.errors
      .map(e => getSingleErrorDataString(e, i18n))
      .join('\n');
  }
  if (isArray(errorResponseContent)) {
    return keys(errorResponseContent)
      .map(k => errorResponseContent[k].join(', '))
      .join('\n');
  }
  return 'validation_messages.required';
}

export function getErrorsList(errorResponseContent) {
  let errors = [];
  if (!errorResponseContent) {
    return errors;
  }

  if (Array.isArray(errorResponseContent)) {
    errors = [...errorResponseContent];
  }

  if (errors.length === 0 && errorResponseContent.errors) {
    errors = [...errorResponseContent.errors];
  }

  if (
    errors.length === 0 &&
    errorResponseContent.content &&
    errorResponseContent.content.errors
  ) {
    errors = [...errorResponseContent.content.errors];
  }

  if (errors.length > 0) {
    return errors.map(ed => ({
      ...ed,
      value: getSingleErrorDataString(ed),
      key: `E_${Date.now()}`,
      translated: true
    }));
  }

  return Object.keys(errorResponseContent).map(k => ({
    key: k,
    value: errorResponseContent[k],
    translated: false
  }));
}
