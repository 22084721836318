import { takeLatest, all, select } from 'redux-saga/effects';
import { NavigationConstants } from '../constants/actions';
import { removeLocale } from '../helpers/UrlHelpers';
import { voucherOrderUrlPrefix } from '../constants/routerPaths';
import { Products } from '../constants/products';
import { FormConstants } from '../constants/forms';
import { getVoucherTypeKey } from '../constants/voucherTypes';
import { getVoucherById } from './api/apiHelpers';
import { orderSummary as orderSummarySelector } from '../selectors/voucherOrder';
import {
  orderSummary as reorderSummarySelector,
  getVoucherReorder
} from '../selectors/voucherReorder';

const eventType = {
  Detail: 'emp.detail',
  AddToCart: 'emp.addToCart',
  Purchase: 'emp.purchase',
  Checkout: 'emp.checkout',
  CheckoutComplete: 'emp.checkout_complete'
};

const UNKNOWN_VOUCHER_TYPE = 'unknown';

const getNormalizedVoucherType = voucherType => {
  if (!voucherType) {
    return UNKNOWN_VOUCHER_TYPE;
  }
  return voucherType.toLowerCase();
};

const getBaseInfoAboutProduct = voucherType => {
  const productName =
    getNormalizedVoucherType(voucherType) === 'lunch'
      ? Products.VoucherRestaurant
      : Products.VoucherRecreational;
  return {
    name: productName,
    brand: 'Voucher', // voucher or card (always voucher for now)
    category: voucherType
  };
};

const getDetailEvent = voucherType => ({
  event: eventType.Detail,
  ecommerce: {
    detail: {
      products: [
        {
          ...getBaseInfoAboutProduct(voucherType)
        }
      ]
    }
  }
});

const formName = voucherType =>
  getNormalizedVoucherType(voucherType) === 'lunch'
    ? FormConstants.LUNCH_VOUCHER_ORDER_FORM
    : FormConstants.RECREATIONAL_VOUCHER_ORDER_FORM;

const getVoucherIdAndPrice = voucher => ({
  id: voucher.code,
  price: voucher.value
});

function getAddToCardEvent(voucherType) {
  return {
    event: eventType.AddToCart,
    ecommerce: {
      add: {
        products: [
          {
            ...getBaseInfoAboutProduct(voucherType)
          }
        ]
      }
    }
  };
}

export function* trackReorderStart() {
  if (!window.dataLayer) {
    return;
  }
  const currentReorder = yield select(getVoucherReorder);
  const voucher = yield getVoucherById(currentReorder.voucherId);
  const data = {
    event: eventType.Checkout,
    ecommerce: {
      checkout: {
        actionField: {
          step: 1,
          option: 'order details'
        },
        products: [
          {
            ...getBaseInfoAboutProduct(voucher.type),
            quantity: currentReorder.quantity,
            ...getVoucherIdAndPrice(voucher)
          }
        ]
      }
    }
  };
  window.dataLayer.push(data);
}

export function* trackUserOpeningPage({ path }) {
  if (!window.dataLayer) {
    return;
  }

  const clearedPath = removeLocale(path);
  const splittedPath = clearedPath.replace(/^(\/|\/$)/g, '').split('/');
  if (splittedPath[0] === voucherOrderUrlPrefix) {
    const voucherType = getVoucherTypeKey(splittedPath[1]);
    const normalizedVoucherType = getNormalizedVoucherType(voucherType);
    if (normalizedVoucherType === UNKNOWN_VOUCHER_TYPE) {
      return;
    }

    let data = {};
    if (splittedPath.length === 2) {
      data = getDetailEvent(normalizedVoucherType);
    } else if (splittedPath[2] === 'information') {
      data = yield getAddToCardEvent(normalizedVoucherType);
    }
    window.dataLayer.push(data);
  }
}

const getCostsInfo = orderSummary => ({
  revenue: orderSummary.totalAmountWithFees,
  tax: orderSummary.vatFeeAmount,
  shipping: orderSummary.handlingFee
});

export function* trackUserFinishOrderProcess(voucherOrderId, voucherType) {
  if (!window.dataLayer) {
    return;
  }

  const orderSummaryValues = yield select(
    orderSummarySelector(formName(voucherType))
  );
  const data = {
    event: eventType.Purchase,
    ecommerce: {
      purchase: {
        actionField: {
          id: voucherOrderId,
          ...getCostsInfo(orderSummaryValues)
        },
        products: [
          {
            ...getBaseInfoAboutProduct(voucherType)
          }
        ]
      }
    }
  };
  window.dataLayer.push(data);
}

export function* trackReorderComplete() {
  if (!window.dataLayer) {
    return;
  }
  const orderSummaryValues = yield select(
    reorderSummarySelector(FormConstants.REORDER_FORM)
  );
  const data = {
    event: eventType.CheckoutComplete,
    ecommerce: {
      checkout: {
        actionField: {
          step: 2,
          option: 'order completed',
          ...getCostsInfo(orderSummaryValues)
        }
      }
    }
  };
  window.dataLayer.push(data);
}

export function* watchTrackingSaga() {
  yield all([
    takeLatest(
      `${NavigationConstants.OPEN_PAGE_EVENT_END}`,
      trackUserOpeningPage
    )
  ]);
}
