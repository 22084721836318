import React from 'react';
import PropTypes from 'prop-types';

function PanelComponent({ title, subtitle, children, ...otherProps }) {
  return (
    <div {...otherProps} className="px-10 panel panel-default">
      {!title && !subtitle ? null : (
        <div className="panel-heading">
          <span className="panel-title">
            {title && <h2>{title}</h2>}
            {subtitle && <h2 className="small">{subtitle}</h2>}
          </span>
        </div>
      )}
      <div className="panel-body">{children}</div>
    </div>
  );
}
PanelComponent.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  children: PropTypes.element.isRequired
};

PanelComponent.defaultProps = {
  title: null,
  subtitle: null
};

export default PanelComponent;
