import { connect } from 'react-redux';
import { LoadingSpinner } from 'edenred-ui';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { Route } from 'react-router-dom';

import { NotFound } from '../containers/NotFound/NotFound';

class LoadingData extends PureComponent {
  componentDidMount() {
    const { isLoaded, load, isError } = this.props;
    if (!isLoaded && !isError) {
      load();
    }
  }

  renderComponent = (props, component) => {
    const { isLoaded, isError, errorComponent } = this.props;
    if (isError) {
      return errorComponent();
    }
    return isLoaded ? component : <LoadingSpinner />;
  };

  render() {
    const { children } = this.props;
    return <Route render={props => this.renderComponent(props, children)} />;
  }
}

LoadingData.propTypes = {
  children: PropTypes.node.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  load: PropTypes.func.isRequired,
  loadAction: PropTypes.func, // eslint-disable-line
  loadingSelector: PropTypes.func.isRequired, // eslint-disable-line
  isError: PropTypes.bool,
  errorComponent: PropTypes.func
};

LoadingData.defaultProps = {
  loadAction: () => {},
  isError: false,
  errorComponent: () => <NotFound />
};

const mapStateToProps = (state, props) => ({
  isLoaded: !props.loadingSelector(state),
  isError: props.errorSelector && props.errorSelector(state)
});

const mapDispatchToProps = (dispatch, props) =>
  bindActionCreators(
    {
      load: props.loadAction
    },
    dispatch
  );

const ConnectedLoadingData = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoadingData);

export { ConnectedLoadingData as LoadingData };
