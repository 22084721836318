import createReducer from '../helpers/CreateReducer';
import { OrderHistoryConstants } from '../constants/actions';
import { OrderHistorySorting } from '../constants/orderHistorySorting';

export const getInitialState = () => ({
  noPersistance: true,
  queryParameters: {
    page: 1,
    rowsPerPage: 5,
    sortingBy: OrderHistorySorting.ByDate,
    descending: true
  }
});

export default createReducer(getInitialState, {
  [`${OrderHistoryConstants.RESET_VOUCHER_ORDERS_HISTORY_QUERY_PARAMETERS}`]:
    state => ({
      ...state,
      queryParameters: { ...getInitialState().queryParameters }
    }),

  [`${OrderHistoryConstants.UPDATE_VOUCHER_ORDERS_HISTORY_QUERY_PARAMETERS}`]: (
    state,
    payload
  ) => ({
    ...state,
    queryParameters: payload.newQueryParameters
  })
});
