import { put, call } from 'redux-saga/effects';
import { getFees } from '../../repositories/VoucherFeesRepository';
import { ApiConstants, OverlaysConstants } from '../../constants/actions';
import { UnauthorizedErrorName } from '../../constants/errorName';
import { HandleUnauthorizedErrorAndLogout } from '../../helpers/AuthorizeHelper';

export default function* loadVoucherOrderFees() {
  try {
    const voucherResponse = yield call(getFees);
    yield put({
      type: `${ApiConstants.LOAD_ORDER_FEES}_SUCCESS`,
      payload: voucherResponse
    });
  } catch (ex) {
    // change the loading status
    yield put({
      type: `${ApiConstants.LOAD_ORDER_FEES}_FAILURE`,
      payload: ex
    });
    if (ex.name === UnauthorizedErrorName) {
      yield call(HandleUnauthorizedErrorAndLogout);
    } else {
      // display the error message
      yield put({
        type: `${OverlaysConstants.SHOW_ERROR_POPUP}`,
        closable: false
      });
    }
  }
}
