/* eslint-disable no-restricted-syntax */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parse } from 'querystring';
import { LoadingSpinner } from 'edenred-ui';

import { getApiPayments } from '../../selectors/api';
import { replacePage } from '../../actions/navigation';
import './MaksturvaNewPayment.scss';
import { sendFormData } from './FormHelper';

const connectToStore = connect(
  state => ({
    apiPayments: getApiPayments(state)
  }),
  dispatch => ({
    cancelPayment: paymentId =>
      dispatch(replacePage(`order/status/${paymentId}`)),
    submitPayment: (actionUrl, formParams) =>
      sendFormData(actionUrl, formParams, 'POST')
  })
);

export function MaksturvaNewPaymentPage({
  apiPayments,
  location,
  cancelPayment,
  submitPayment
}) {
  const paymentDefined =
    !!apiPayments &&
    !!apiPayments?.newPaymentParams?.formParams &&
    !!apiPayments?.newPaymentParams?.actionUrl;

  useEffect(() => {
    if (paymentDefined) {
      const { actionUrl, formParams } = apiPayments.newPaymentParams;
      if (formParams && actionUrl) {
        submitPayment(actionUrl, formParams, 'POST');
      }
    } else {
      const queryObject = parse(location.search.substring(1));
      cancelPayment(queryObject.paymentId);
    }
  }, [paymentDefined]);

  return (
    <div className="MaksturvaNewPayment">
      <LoadingSpinner />
    </div>
  );
}

export const MaksturvaNewPayment = connectToStore(MaksturvaNewPaymentPage);

MaksturvaNewPaymentPage.propTypes = {
  apiPayments: PropTypes.object.isRequired,
  cancelPayment: PropTypes.func.isRequired,
  submitPayment: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired
};
