import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { downloadFileAuthenticated } from '@epi-helpers/DownloadFileHelper';
import { Authorize } from '../constants/authorize';
import { getRequest, postRequest } from './ApiRepository';

export function* getCompanyAccounts() {
  return yield call(
    getRequest,
    `${config.apiHost}/api/company-accounts`,
    Authorize.Always
  );
}

export function* getCompanyMetadata() {
  return yield call(
    getRequest,
    `${config.apiHost}/api/company-metadata`,
    Authorize.Always
  );
}

export function* getCompanyTransactions(query) {
  return yield call(
    getRequest,
    `${config.apiHost}/api/company-accounts/transactions`,
    Authorize.Always,
    { data: query }
  );
}

export function* depositCompanyAccount(payload) {
  return yield call(
    postRequest,
    `${config.apiHost}/api/company-accounts/deposits`,
    Authorize.Always,
    payload
  );
}

export function getReceiptForDepositOrder(depositId) {
  return downloadFileAuthenticated(
    `${config.apiHost}/api/company-accounts/deposits/${depositId}/receipt`,
    'receipt.pdf'
  );
}

export function getReceiptForTransaction(transactionId) {
  return downloadFileAuthenticated(
    `${config.apiHost}/api/company-accounts/transactions/${transactionId}/receipt`,
    'receipt.pdf'
  );
}
