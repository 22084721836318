import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Notification, NotificationType } from 'edenred-ui';

import { useToastContext } from '@epi-core/components/Toast/useToastContext';
import storage from '@epi-helpers/StorageHelper';

const HOME_NOTIFICATION = 'homeNotification';
const TODAY = new Date();
const END_DAY_OF_NOTIFICATION = new Date('2024-08-28').setHours(0, 0, 0, 0);

export const HomeNotification = () => {
  const { t } = useTranslation();
  const { content } = useToastContext();
  const [isOpen, setIsOpen] = useState(() => {
    if (TODAY.getTime() < END_DAY_OF_NOTIFICATION) {
      const storageData = storage.get(HOME_NOTIFICATION);
      return storageData !== HOME_NOTIFICATION;
    }
    return false;
  });

  const onClose = () => {
    storage.set(HOME_NOTIFICATION, HOME_NOTIFICATION);
    setIsOpen(false);
  };

  const textForNotification = (
    <>
      <Box fontWeight={600}>{t('home.notification_title')}</Box>
      <Box pt={2}>{t('home.notification_body')}</Box>
    </>
  );

  return (
    isOpen && (
      <Box
        position="absolute"
        top={content ? 78 : 0}
        left="50px"
        right="50px"
        zIndex={999}
      >
        <Notification
          type={NotificationType.Info}
          text={textForNotification}
          onClose={onClose}
        />
      </Box>
    )
  );
};
