export class ApiError extends Error {
  constructor(args, content) {
    super(args);
    this.name = ApiError.name;
    this.content = content;
  }

  get prop() {
    return this.content;
  }
}
