import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { connect } from 'react-redux';

import { validateEInvoiceRequest } from '@epi-api/eInvoice';
import { FormFields, useAsyncValidator } from '@epi-forms/helpers';
import { getRecaptchaToken } from '@epi-helpers/ReCaptchaHelper';
import { getReCaptchaSiteKey } from '@epi-selectors/settings';
import { EInvoiceContext } from './EInvoiceContext';

function EInvoice({ reCaptchaSiteKey, forwardedRef, children }) {
  const {
    formState: { errors },
    getValues
  } = useFormContext();

  const eInvoiceValidator = async abortController => {
    const reCaptchaToken = await getRecaptchaToken(
      reCaptchaSiteKey,
      'einvoice_validation'
    );

    const eInvoiceAddress = getValues(FormFields.EInvoiceAddress);
    const operator = getValues(FormFields.Operator);

    const { valid } = await validateEInvoiceRequest(
      { eInvoiceAddress, operator, reCaptchaToken },
      abortController
    );

    if (!valid) {
      throw new Error('EInvoice is not valid');
    }
  };

  const validatorGetValue = () => {
    return {
      [FormFields.EInvoiceAddress]: getValues(FormFields.EInvoiceAddress),
      [FormFields.Operator]: getValues(FormFields.Operator)
    };
  };

  const validatorCanValidate = () => {
    return (
      !!getValues(FormFields.EInvoiceAddress) &&
      !!getValues(FormFields.Operator) &&
      !errors[FormFields.EInvoiceAddress] &&
      !errors[FormFields.Operator]
    );
  };

  const validateEInvoice = useAsyncValidator({
    fieldName: FormFields.EInvoice,
    validator: eInvoiceValidator,
    validatorName: FormFields.EInvoice,
    getValue: validatorGetValue,
    canValidate: validatorCanValidate
  });

  useImperativeHandle(forwardedRef, () => ({
    validateEInvoice
  }));

  const contextValue = useMemo(
    () => ({ validateEInvoice }),
    [validateEInvoice]
  );

  return (
    <EInvoiceContext.Provider value={contextValue}>
      {children}
    </EInvoiceContext.Provider>
  );
}

EInvoice.propTypes = {
  reCaptchaSiteKey: PropTypes.string.isRequired,
  forwardedRef: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};

const mapStateToProps = state => ({
  reCaptchaSiteKey: getReCaptchaSiteKey(state)
});

const ConnectedEInvoice = connect(mapStateToProps)(EInvoice);

const ForwardedRefEInvoice = forwardRef((props, ref) => (
  <ConnectedEInvoice {...props} forwardedRef={ref} />
));

ForwardedRefEInvoice.displayName = 'ForwardedRefEInvoice';

export { ForwardedRefEInvoice as EInvoice };
