import React from 'react';
import { Box, InfoIcon, CloseIcon, SimpleButton } from 'edenred-ui';
import { useTranslation } from 'react-i18next';

import { INotificationInfo } from '@epi-models/containers/LoadCompanyAccount';

export const NotificationInfo = ({ onClose }: INotificationInfo) => {
  const { t } = useTranslation();
  return (
    <Box
      boxSizing="border-box"
      boxShadow="0 4px 16px -4px rgba(0, 0, 0, 0.2)"
      pt={2}
      pb={2}
      display="flex"
      alignItems="center"
    >
      <Box display="flex" ml={3} mr={3} alignItems="center" width="100%">
        <Box mr={3}>
          <InfoIcon />
        </Box>
        <Box width={1080} fontSize={16}>
          {t('containers.deposit_money.page_description')}
        </Box>
        <Box ml="auto">
          <SimpleButton
            noPadding
            icon={<CloseIcon />}
            onClick={onClose}
            id="transfer-money-notification-button-close"
          />
        </Box>
      </Box>
    </Box>
  );
};
