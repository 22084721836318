import React from 'react';
import { connect, useDispatch } from 'react-redux';
import { Box, Button } from 'edenred-ui';
import PropTypes from 'prop-types';
import { withTranslation, useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { Form } from '@epi-components/Form';
import { FormFields } from '@epi-forms/helpers';
import { PasswordField } from '@epi-forms/controls';
import { fetchPost as Post } from '@epi-helpers/FetchHelpers';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { showNotification } from '@epi-actions/overlays';
import { setNewPasswordUrl } from '@epi-repositories/ProfileRepository';
import { PasswordValidationContainer } from '@epi-shared/components';
import { isEmptyObj } from '@epi-helpers/isEmptyObj';

import { getParameterValue } from '../../helpers/UrlHelpers';
import { ApiConstants } from '../../constants/actions';
import { TranslationResource } from '../../helpers/TranslationResource';
import { LoadingPanel } from '../../components/LoadingPanel/LoadingPanel';
import { ErrorPanel } from '../../components/ErrorPanel/ErrorPanel';
import { required } from '../../helpers/formHelpers/validators';

function NewPassword({ initialValues: { token, username } }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      [FormFields.NewPassword]: '',
      [FormFields.ConfirmPassword]: ''
    }
  });
  const {
    getValues,
    formState: { isValid, errors, dirtyFields },
    watch
  } = form;

  const password = watch(FormFields.NewPassword);
  const isSubmitBtnDisabled =
    [FormFields.NewPassword, FormFields.ConfirmPassword].some(
      field => !dirtyFields[field]
    ) ||
    !isEmptyObj(errors) ||
    !isValid;

  const showError = err => {
    const message = err.prop.errors[0].message;
    const options = message
      ? { message, translated: true }
      : { message: 'messages.error' };

    dispatch(showNotification(options, 'error'));
  };

  const onNewPasswordSuccess = () => {
    dispatch(goToPage(routerPaths.login));
    dispatch(
      showNotification(
        { message: 'messages.successful_set_new_password' },
        'success'
      )
    );
  };

  const onNewPasswordFailure = err => {
    showError(err);
  };

  const setNewPassword = values => {
    return Post(
      setNewPasswordUrl,
      { ...values, username, token },
      onNewPasswordSuccess,
      onNewPasswordFailure
    );
  };

  const submitForm = values => {
    setNewPassword(values);
  };

  const compareConfirmPassword = () => {
    const formValues = getValues();
    if (formValues.newPassword !== formValues.confirmPassword)
      return (
        <TranslationResource message="validation_messages.confirm_password" />
      );
  };

  return (
    <Box mt="20px">
      <LoadingPanel fixedWidth>
        <Box p={3.125}>
          <Form form={form} onSubmit={submitForm}>
            <Box mt={1.25}>
              <h1>{t('containers.new_password.page_title')}</h1>
              <PasswordField
                displayValidationResults={false}
                name={FormFields.NewPassword}
                label={t('forms.new_password.password')}
                showIconPassword
              />
              <PasswordValidationContainer password={password} />
              <PasswordField
                name={FormFields.ConfirmPassword}
                label={t('forms.new_password.confirm_password')}
                validators={[required, compareConfirmPassword]}
              />
              <ErrorPanel failedActionName={ApiConstants.SET_NEW_PASSWORD} />
              <Box display="flex" justifyContent="flex-end">
                <Button
                  id="new-password-submit-btn"
                  type="submit"
                  disabled={isSubmitBtnDisabled}
                >
                  {t('containers.new_password.send_button')}
                </Button>
              </Box>
            </Box>
          </Form>
        </Box>
      </LoadingPanel>
    </Box>
  );
}

NewPassword.propTypes = {
  initialValues: PropTypes.object.isRequired
};

const mapStateToProps = () => ({
  initialValues: {
    username: decodeURIComponent(getParameterValue('username')),
    token: decodeURIComponent(getParameterValue('token'))
  }
});

const ConnectedNewPassword = connect(mapStateToProps)(
  withTranslation()(NewPassword)
);

export { ConnectedNewPassword as NewPassword };
