import styled from 'styled-components';

export const RequestedLoadsPageStyle = styled.div`
  margin: 2rem 2rem;
  .requested-loads {
    &__panel {
      margin-top: 2rem;
    }
    &__table {
      margin: 5rem 2rem;
    }
    &__action {
      color: ${props => props.theme.primaryBackColorLight};
      font-weight: 500;
      font-size: 15px;
      width: 100%;
      margin: auto auto;
      cursor: pointer;
    }
  }

  .panel {
    margin-top: 3rem;
  }
`;
