import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { replaceParams } from '../helpers/UrlHelpers';

class ConditionalRedirect extends PureComponent {
  renderComponent = props => {
    const { shouldRedirect, redirect, children } = this.props;
    return shouldRedirect ? (
      <Redirect
        to={{
          pathname: replaceParams(redirect, props.match.params)
        }}
      />
    ) : (
      React.cloneElement(children, {
        ...props
      })
    );
  };

  render() {
    return <Route render={props => this.renderComponent(props)} />;
  }
}

ConditionalRedirect.propTypes = {
  children: PropTypes.func.isRequired,
  redirect: PropTypes.string.isRequired,
  shouldRedirect: PropTypes.bool.isRequired,
  redirectSelector: PropTypes.func.isRequired // eslint-disable-line
};

const mapStateToProps = (state, props) => ({
  shouldRedirect: props.redirectSelector(state)
});

const ConnectedConditionalRedirect =
  connect(mapStateToProps)(ConditionalRedirect);

export { ConnectedConditionalRedirect as ConditionalRedirect };
