import { orderBy } from 'lodash';
import createReducer from '../../helpers/CreateReducer';
import { ApiConstants } from '../../constants/actions';

export const getInitialState = () => ({
  vouchers: [],
  filteredVouchers: [],
  isLoading: false,
  loaded: false,
  noPersistance: true
});

export default createReducer(getInitialState, {
  [`${ApiConstants.LOAD_VOUCHERS}_REQUEST`]: () => ({ isLoading: true }),

  [`${ApiConstants.LOAD_VOUCHERS}_SUCCESS`]: (
    state,
    { payload: { vouchers } }
  ) => {
    const mappedVouchers = vouchers.map(item => ({
      ...item,
      value: item.value
    }));
    const sortedVouchers = orderBy(mappedVouchers, item => item.value, 'desc');

    return {
      vouchers: sortedVouchers,
      loaded: true,
      isLoading: false
    };
  },

  [`${ApiConstants.LOAD_VOUCHERS}_FAILURE`]: state =>
    !state.isError
      ? {
          isLoading: false,
          loaded: true,
          vouchers: [],
          isError: true
        }
      : state
});
