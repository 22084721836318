import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { ButtonComponent as Button, ButtonMode } from 'edenred-ui';

import BenefitTypeIcon from '../BenefitTypeIcon/BenefitTypeIcon';

import * as Style from './BenefitTypeTile.styles';

function BenefitTypeTile({ benefit, active, disabled, match, history }) {
  const benefitName = i18n.t(
    `components.manage_benefits.new.benefits.${benefit}`
  );

  const buttonText = active
    ? i18n.t('components.manage_benefits.new.view_groups')
    : i18n.t('components.manage_benefits.new.activate');

  const handleClick = () => {
    history.push(`${match.path}/${benefit}`);
  };

  return (
    <Style.BenefitTypeTileContainer
      active={active}
      disabled={disabled}
      id={`benefit-tile_${benefitName}`}
    >
      {active && !disabled && (
        <Style.BenefitTypeStatus id={`status-active-${benefit}`}>
          <Style.BenefitTypeStatusText>
            {i18n.t('components.manage_benefits.new.active')}
          </Style.BenefitTypeStatusText>
        </Style.BenefitTypeStatus>
      )}
      <BenefitTypeIcon benefit={benefit} disabled={disabled} />
      <Style.BenefitTypeName disabled={disabled}>
        {benefitName}
      </Style.BenefitTypeName>
      {disabled ? (
        <Style.ComingSoonContainer>
          {i18n.t('components.manage_benefits.new.coming_soon')}
        </Style.ComingSoonContainer>
      ) : (
        <Style.ViewGroupsButtonContainer>
          <Button
            fullWidth
            id={`view-groups-${benefit}`}
            onClick={handleClick}
            mode={ButtonMode.Primary}
          >
            {buttonText}
          </Button>
        </Style.ViewGroupsButtonContainer>
      )}
    </Style.BenefitTypeTileContainer>
  );
}

BenefitTypeTile.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  benefit: PropTypes.string.isRequired,
  active: PropTypes.bool,
  disabled: PropTypes.bool
};

BenefitTypeTile.defaultProps = {
  active: false,
  disabled: false
};

export default withRouter(BenefitTypeTile);
