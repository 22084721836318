import React, { useEffect, useState } from 'react';
import { LoadingSpinner } from 'edenred-ui';

import {
  confirmEmailApiUrl,
  confirmEmailChangeApiUrl
} from '@epi-repositories/EmailRepository';
import { IEmailConfirmation } from '@epi-models/pages/EmailConfirmation';
import { fetchPost as Post } from '@epi-helpers/FetchHelpers';

import { ConfirmedMessage } from './components/ConfirmedMessage';
import { LinkSentMessage } from './components/LinkSentMessage';
import { ErrorMessage } from './components/ErrorMessage';

export const EmailConfirmation = ({
  location,
  isChange
}: IEmailConfirmation) => {
  const params = new URLSearchParams(location.search);
  const token = params.get('token');
  const username = params.get('username');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLinkSent, setIsLinkSent] = useState(false);

  const emailConfirmRequest = () => {
    const onSuccess = () => {
      setIsConfirmed(true);
      setIsError(false);
    };
    const onFailure = () => {
      setIsConfirmed(false);
      setIsError(true);
    };

    return Post(
      isChange ? confirmEmailChangeApiUrl : confirmEmailApiUrl,
      { token, username },
      onSuccess,
      onFailure
    );
  };

  useEffect(() => {
    emailConfirmRequest();
  }, []);

  if (isError) {
    return (
      <ErrorMessage
        username={username}
        setIsError={setIsError}
        setIsLinkSent={setIsLinkSent}
      />
    );
  }

  if (isConfirmed) {
    return <ConfirmedMessage />;
  }

  if (isLinkSent) {
    return <LinkSentMessage />;
  }

  return <LoadingSpinner />;
};
