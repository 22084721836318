import React from 'react';
import { Box, Notification } from 'edenred-ui';

import { useToastContext } from './useToastContext';

export const Toast = () => {
  const { closeToast, type, content, button } = useToastContext();

  return content ? (
    <Box position="absolute" top={0} left="50px" right="50px" zIndex={999}>
      <Notification
        type={type}
        text={content}
        onClose={closeToast}
        button={button}
      />
    </Box>
  ) : null;
};
