import React from 'react';
import { put, call } from 'redux-saga/effects';
import { getReceiptForTransaction } from '@epi-repositories/CompanyAccountsRepository';
import { OverlaysConstants, ApiConstants } from '@epi-constants/actions';
import { UnauthorizedErrorName } from '@epi-constants/errorName';
import { TranslationResource } from '@epi-helpers/TranslationResource';

export function* downloadTransactionReceipt({ id }) {
  try {
    yield call(getReceiptForTransaction, id);
    yield put({
      type: `${ApiConstants.GET_RECEIPT_FOR_TRANSACTION}_SUCCESS`,
      id
    });
  } catch (error) {
    if (error.name === UnauthorizedErrorName) {
      yield put({
        type: `${ApiConstants.GET_RECEIPT_FOR_TRANSACTION}_FAILURE`,
        id,
        isUnauthorized: true,
        payload: error
      });
    } else {
      yield put({
        type: `${OverlaysConstants.SHOW_ERROR_NOTIFICATION}`,
        message: <TranslationResource message="messages.download_file_failed" />
      });
    }
  }
}
