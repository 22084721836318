import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { getRequest, postRequest } from './ApiRepository';

export const contactsApiUrl = `${config.apiHost}/api/contacts`;

export function* getContacts() {
  return yield call(getRequest, `${contactsApiUrl}`, Authorize.Always);
}

export function* postNewContact(contactData) {
  return yield call(
    postRequest,
    `${contactsApiUrl}`,
    Authorize.IfLogged,
    contactData
  );
}
