import React from 'react';
import { Switch } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import {
  EdenredUiProvider,
  themes,
  getMaterialTheme,
  ThemeType
} from 'edenred-ui';
import { ThemeProvider } from 'styled-components';

import { Layout } from '@epi-core/components/Layout';

import i18n from '../../helpers/I18n';
import { DefaultRoutes as Routes } from '../../routes/DefaultRoutes';
import { Notifier } from '../../components/Notifier/Notifier';
import { ErrorPopup } from '../../components/ErrorPopup/ErrorPopup';
import { ConfirmPasswordPopup } from '../../components/ConfirmPasswordPopup/ConfirmPasswordPopup';
import { AppSetup } from '../../components/AppSetup/AppSetup';
import { StrictMode } from '../../components/StrictMode/StrictMode';
import { NotificationInfoBanner } from '../../components/NotificationInfoBanner';
import ToastContext from '../../core/components/Toast/ToastContext';

import './App.scss';

export function App() {
  const appTheme = themes[ThemeType.DEFAULT];
  const materialTheme = getMaterialTheme(appTheme);
  const theme = { ...materialTheme, ...appTheme };

  return (
    <EdenredUiProvider i18n={i18n}>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={theme}>
          <ToastContext>
            <AppSetup>
              <div>
                <StrictMode>
                  <ErrorPopup />
                  <ConfirmPasswordPopup />
                  <Notifier />
                </StrictMode>
                <Layout>
                  <div className="app-container">
                    <NotificationInfoBanner />
                    <Switch>
                      <Routes path="/en" />
                      <Routes path="/" />
                    </Switch>
                  </div>
                </Layout>
              </div>
            </AppSetup>
          </ToastContext>
        </ThemeProvider>
      </I18nextProvider>
    </EdenredUiProvider>
  );
}
