import styled from 'styled-components';

import { FormStepMode } from '../FormStep';

const Name = styled.div`
  display: flex;
  align-items: center;
  align-self: stretch;
`;

const Number = styled.div`
  height: 40px;
  width: 40px;
  line-height: 40px;
  border-radius: 50%;
  background-color: ${({ theme, mode }) =>
    mode === FormStepMode.Contained
      ? theme.gray0
      : theme.primaryBackColorLight10};
  margin-right: 16px;
  text-align: center;
`;

export const FormStepStyles = {
  Name,
  Number
};
