import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PagePanel, LoginIcon, Box } from 'edenred-ui';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';
import { EmptyState } from '@epi-shared/components';

function ContractCompleted({ dispatch }) {
  const { t } = useTranslation();

  return (
    <PagePanel>
      <Box
        mt={6}
        minHeight={600}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <EmptyState
          icon={<LoginIcon />}
          title={t('sign_up.contract_completed_heading')}
          btnText={t('sign_up.contract_completed_btn')}
          btnOnClick={() => dispatch(goToPage(routerPaths.login))}
        />
      </Box>
    </PagePanel>
  );
}

ContractCompleted.propTypes = {
  dispatch: PropTypes.func.isRequired
};

const ConnectedContractCompleted = connect()(ContractCompleted);

export { ConnectedContractCompleted as ContractCompleted };
