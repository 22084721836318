import React, { PureComponent } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { getSingleErrorDataString } from '@epi-helpers/ErrorDataHelper';
import { ErrorSeverity } from '@epi-constants/errorSeverity';

export const ErrorDataItem = ({ errorData }) => {
  const { t } = useTranslation();
  const isWarning = errorData && errorData.severity === ErrorSeverity.Warning;

  const getTranslatedErrorString = () => {
    if (errorData.value) {
      return errorData.translated ? errorData.value : t(errorData.value);
    }

    return getSingleErrorDataString(errorData);
  };

  return (
    <div className={`${isWarning ? 'text-warning' : 'text-danger'}`}>
      <span>{t(getTranslatedErrorString) || getTranslatedErrorString}</span>
    </div>
  );
};

ErrorDataItem.propTypes = {
  errorData: PropTypes.object.isRequired
};
