import { put, call } from 'redux-saga/effects';
import { goToReturnUrl } from '../navigation';
import { putUserCompany } from '../../repositories/CompaniesRepository';
import { ApiConstants } from '../../constants/actions';
import { Notify } from '../../helpers/NotificationHelper';
import { callWithPassword } from '../overlays';

export function* apiCall(params) {
  const {
    company_info: { address, sizeId, invoiceInformation },
    password
  } = params;
  return yield call(putUserCompany, {
    ...address,
    companySizeId: sizeId,
    password,
    invoiceInformation
  });
}

export default function* updateUserCompany({
  companyData,
  autoRedirect,
  isUserImpersonate
}) {
  const {
    company_info: { address, sizeId, invoiceInformation }
  } = companyData;
  const success = isUserImpersonate
    ? yield call(apiCall, companyData)
    : yield call(
        callWithPassword,
        apiCall,
        companyData,
        'forms.company_information.confirm_password.message'
      );
  if (success) {
    yield put({
      type: `${ApiConstants.UPDATE_USER_COMPANY}_SUCCESS`,
      address,
      sizeId,
      invoiceInformation
    });
    yield call(Notify, 'messages.company_info_updated');
    yield autoRedirect && call(goToReturnUrl);
  } else {
    yield put({
      type: `${ApiConstants.UPDATE_USER_COMPANY}_FAILURE`,
      payload: new Error('messages.company_info_updated_failed')
    });

    yield call(Notify, 'messages.company_info_updated_failed', 'error');
  }
}
