import { get } from 'lodash';
import { REHYDRATE } from 'redux-persist/constants';
import createReducer from '../helpers/CreateReducer';
import {
  findLanguageById,
  getDefaultLanguage
} from '../helpers/LanguageHelpers';
import { ApplicationConstants } from '../constants/actions';

export const getInitialState = () => {
  const defaultLanguage = getDefaultLanguage();
  return {
    language: defaultLanguage
  };
};

export default createReducer(getInitialState, {
  [`${ApplicationConstants.CHANGE_APP_LANGUAGE}`]: (state, payload) => {
    const language = findLanguageById(payload.languageId);
    return {
      language
    };
  },

  [`${REHYDRATE}`](state, action) {
    const newState = {
      ...action.payload.application,
      rehydrated: true
    };
    const languageId = get(action, 'payload.application.language.id');
    if (languageId) {
      newState.language = { id: languageId };
    }
    return newState;
  }
});
