import config from '@epi-config';
import { call } from 'redux-saga/effects';
import { Authorize } from '../constants/authorize';
import { postRequest, getRequest } from './ApiRepository';

export function* addSignatoryRoleToUser() {
  return yield call(
    postRequest,
    `${config.apiHost}/api/user/roles/signatory`,
    Authorize.Always
  );
}

export function* hasOrders() {
  return yield call(
    getRequest,
    `${config.apiHost}/api/user/has-orders`,
    Authorize.Always
  );
}
