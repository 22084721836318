import React from 'react';
import { useTranslation } from 'react-i18next';
import { PagePanel, SetBenefitsIcon, Box } from 'edenred-ui';
import { useDispatch } from 'react-redux';

import { EmptyState } from '@epi-shared/components';
import { goToPage } from '@epi-actions/navigation';
import { routerPaths } from '@epi-constants/routerPaths';

export const TopupUnavailable = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const goToBenefits = () => {
    dispatch(goToPage(routerPaths.manageBenefits));
  };

  return (
    <PagePanel>
      <Box
        mt={6}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        <EmptyState
          icon={<SetBenefitsIcon />}
          title={t(
            'containers.deposit_money.topup_tab.topup_unavailable.title'
          )}
          description={t(
            'containers.deposit_money.topup_tab.topup_unavailable.description'
          )}
          btnText={t(
            'containers.deposit_money.topup_tab.topup_unavailable.button'
          )}
          btnOnClick={goToBenefits}
        />
      </Box>
    </PagePanel>
  );
};
