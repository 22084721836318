import React from 'react';
import { useTranslation } from 'react-i18next';

import Row from '../../../../components/LayoutUtils/Row';

const EndOfFundsNotification = () => {
  const { t } = useTranslation();

  return (
    <Row>
      <p className="secondaryInfo">
        {t('containers.beneficiaries.detail.employee_not_editable_notifiation')}
      </p>
    </Row>
  );
};

export default EndOfFundsNotification;
