import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  NameRegex,
  AddressLineRegex,
  CityRegex,
  NotContainsNumbersRegex,
  socialSecurityNumber,
  phone
} from '../../../../helpers/formHelpers/validators';

export default function beneficiaryDatailValidationSchema(
  isUserImpersonate = false
) {
  const { t } = useTranslation();
  return Yup.object({
    firstName: Yup.string()
      .required(t('validation_messages.required'))
      .matches(
        NotContainsNumbersRegex,
        t('validation_messages.should_not_be_a_number')
      )
      .matches(NameRegex, t('validation_messages.invalid_firstName'))
      .max(
        31,
        t('validation_messages.max_length', {
          maxLen: 31
        })
      ),
    lastName: Yup.string()
      .required(t('validation_messages.required'))
      .matches(
        NotContainsNumbersRegex,
        t('validation_messages.should_not_be_a_number')
      )
      .matches(NameRegex, t('validation_messages.invalid_lastname'))
      .max(
        31,
        t('validation_messages.max_length', {
          maxLen: 31
        })
      ),
    addressLine: Yup.string()
      .required(t('validation_messages.required'))
      .matches(AddressLineRegex, t('validation_messages.invalid_address_line')),
    city: Yup.string()
      .required(t('validation_messages.required'))
      .max(
        50,
        t('validation_messages.max_length', {
          maxLen: 50
        })
      )
      .matches(CityRegex, t('validation_messages.invalid_city')),
    emailAddress: Yup.string()
      .email(t('validation_messages.invalid_email'))
      .required(t('validation_messages.required'))
      .max(
        60,
        t('validation_messages.max_length ', {
          maxLen: 60
        })
      ),
    postCode: Yup.string().test('postCode', null, function (value) {
      const { path, createError } = this;
      if (typeof value === 'undefined') {
        return createError({
          path,
          message: t('validation_messages.required')
        });
      }

      if (!/^\d{5}$/.test((value || '').replace(/\s/g, ''))) {
        return createError({
          path,
          message: t('validation_messages.invalid_zip_code')
        });
      }
      return true;
    }),
    phoneNumber: Yup.string()
      .max(
        20,
        t('validation_messages.max_length', {
          maxLen: 20
        })
      )
      .test('phoneNumber', null, function (value) {
        const { path, createError } = this;
        if (typeof value === 'undefined') {
          return createError({
            path,
            message: t('validation_messages.required')
          });
        }
        if (phone(value)) {
          return createError({
            path,
            message: t('validation_messages.invalid_phone_number')
          });
        }
        return true;
      }),
    personalId: Yup.string().test('personalId', null, function (value) {
      if (isUserImpersonate) {
        return true;
      }
      const { path, createError } = this;
      if (typeof value === 'undefined') {
        return createError({
          path,
          message: t('validation_messages.required')
        });
      }

      const validationResult = socialSecurityNumber(value);

      if (typeof validationResult !== 'undefined') {
        return createError({
          path,
          message: t(validationResult.props.message)
        });
      }
      return true;
    })
  });
}
