import React, { PureComponent } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { isLoaded as isLoadedSelector } from '../../selectors/saga';
import { ApiConstants } from '../../constants/actions';
import * as apiActions from '../../actions/api';
import { refreshRememberedUser } from '../../helpers/refreshTokenHelper';

class AppSetup extends PureComponent {
  componentDidMount() {
    const { loadSettings, getHasOrders } = this.props;
    refreshRememberedUser().then(() => {
      loadSettings();
      getHasOrders();
    });
  }

  render() {
    const { children, isLoaded } = this.props;
    if (!isLoaded) {
      return <div>Loading...</div>;
    }
    return children;
  }
}

AppSetup.propTypes = {
  loadSettings: PropTypes.func.isRequired,
  getHasOrders: PropTypes.func.isRequired,
  isLoaded: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired
};

const isLoaded = isLoadedSelector([
  ApiConstants.LOAD_SETTINGS,
  ApiConstants.GET_HAS_ORDERS
]);
const mapStateToProps = state => ({
  isLoaded: isLoaded(state)
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      ...apiActions
    },
    dispatch
  );

const ConnectedAppSetup = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppSetup);

export { ConnectedAppSetup as AppSetup };
